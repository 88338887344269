import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ContractPromise } from "@polkadot/api-contract";

import useResize from "../../hooks/useResize";
import { abiTradingPairAzero } from "../../Utils/abi/abiTradingPairAzero";
import { getPoolTotalSupply } from "../../Utils/functions/contractsFunctions";
import { getTokenPairByAddresses } from "../../shared/api";

import Wrapper from "../../Components/Helpers/Wrapper";

import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { Typography, Box } from "@mui/material";

import { HeroContainer, Page } from "../../Style/general";
import { MainCreatePoolPaper } from "../../Style/Pages/Pool";

import LeftBox from "../../Components/CreatePool/LeftBox";
import RightBox from "../../Components/CreatePool/RightBox";
import { getLimitedInput } from "../../Utils/functions/globalFunctions";
import { getMaxGasLimit } from "@scio-labs/use-inkathon";
import moment from "moment";

const CreatePool = () => {
  const navigate = useNavigate();
  const resize = useResize();
  const dispatch = useDispatch();

  const { network_token } = useSelector((state) => state.tokens);
  const { connectedNetwork } = useSelector((state) => state.account);
  const { address } = useSelector((state) => state.account);
  const { api } = useSelector((state) => state.contracts);
  const { psp22_balance } = useSelector((state) => state.account);
  const { azero_balance } = useSelector((state) => state.account);

  const [open, setOpen] = useState(false); // boolean variable to open or close tokens dialog
  const [tokenLetter, setTokenLetter] = useState("");

  const [inputDisabled, setInputDisabled] = useState(true); // inputs disable state
  const [isBtnDisabled, setIsBtnDisabled] = useState(true); // main btn disable state
  const [initBtnMsg, setInitBtnMsg] = useState("Choose two tokens"); // main btn text state
  const [valueMsg, setValueMsg] = useState(""); // value errors text state
  const [psp22TokensMsg, setPsp22TokensMsg] = useState(""); // psp22 tokens errors text state
  const [azeroTokensMsg, setAzeroTokensMsg] = useState(""); // azero token errors text state
  const [tokensAreSameMsg, setTokensAreSameMsg] = useState(""); // same tokens error text state

  const [pairExist, setPairExist] = useState(false); // boolean variable to check if pair does exist
  const [currentPair, setCurrentPair] = useState(); // current pair on the dashboard
  const [fee, setFee] = useState(1);
  const [disabledFee, setDisabledFee] = useState(true); // fee disable state
  const [unlockLpDate, setUnlockLpDate] = useState();
  const [disabledDatePicker, setDisabledDatePicker] = useState(true); // fee disable state

  const [tokenA, setTokenA] = useState(); //token A
  const [tokenAValue, setTokenAValue] = useState(); // selected value from tokens dialog
  const [inputAValue, setInputAValue] = useState(""); //input A value

  const [tokenB, setTokenB] = useState(); //token B
  const [tokenBValue, setTokenBValue] = useState(); // selected value from tokens dialog
  const [inputBValue, setInputBValue] = useState(""); //input B value

  const marks = [
    {
      value: 0.1,
      label: "0.1",
    },
    {
      value: 3,
      label: "3",
    },
  ];

  // A function to set fee value for new pair
  const setFeeFunction = (e) => {
    setFee(e.target.value);
  };

  // A function to set unlock lp date for specific pool
  const setUnlockDate = (date) => {
    date = moment(date).format("LLL");
    setUnlockLpDate(date);
  };

  const handleTokenValue = (e, letter) => {
    if (e.target.value) {
      if (getLimitedInput(e.target.value)) {
        letter === "A" ? setInputAValue(e.target.value) : setInputBValue(e.target.value);
      }
    } else {
      letter === "A" ? setInputAValue() : setInputBValue();
    }
  };

  // open tokens dialog
  const handleClickOpenA = (letter) => {
    setTokenLetter(letter);
    setOpen(true);
  };
  // open tokens dialog
  const handleClickOpenB = (letter) => {
    setTokenLetter(letter);
    setOpen(true);
  };

  // close tokens dialog and set the chosen value
  const handleCloseToken = (value) => {
    setOpen(false);
    if (value) {
      if (tokenLetter === "A") {
        setTokenAValue(value);
        setTokenA(value);
      } else if (tokenLetter === "B") {
        setTokenBValue(value);
        setTokenB(value);
      }
    }
  };

  // A function to check if tokens are the same or not
  const checkIfTokensAreDiff = async (token_a, token_b) => {
    let same = false;
    if (token_a?.address && token_b?.address) {
      token_a?.address === token_b?.address ? (same = true) : (same = false);
    }
    return same;
  };

  const checkIfPairExist = async (token_a, token_b) => {
    // setIsBtnDisabled(true);
    let pairExist;
    await checkIfTokensAreDiff(token_a, token_b).then(async (res) => {
      if (!res) {
        let pair = await getTokenPairByAddresses(token_a.address, token_b.address, connectedNetwork);
        pair = pair.data.data[0];
        let totalSupply = await getPairTotalSupply(pair?.pair_address);
        pairExist = pair?.pair_address ? true : false;
        setPairExist(pair?.pair_address ? true : false);

        setCurrentPair(pair);
        setTokensAreSameMsg();

        if (
          tokenA.address.toLowerCase() !== network_token?.address?.toLowerCase() &&
          tokenB.address.toLowerCase() !== network_token?.address?.toLowerCase()
        ) {
          setIsBtnDisabled(true);
          setInitBtnMsg(`${connectedNetwork === "azero" ? "Azero" : "shiden"} must replace one PSP22 token`);
        } else {
          if (pairExist && totalSupply === 0) {
            setDisabledFee(true);
            setDisabledDatePicker(true);
            setInputDisabled(false);
            let inputA = Number(inputAValue);
            let inputB = Number(inputBValue);
            if (inputA > 0 && inputB > 0) {
              setIsBtnDisabled(false);
              setInitBtnMsg("Add Liquidity to Pool");
              if (inputA < 1 || inputB < 1) {
                setIsBtnDisabled(true);
                setValueMsg(
                  `* The minimum amount of tokens to initialize a liquidity pool must be equal or greater than 1.`
                );
                return;
              } else {
                setValueMsg();
              }
              if (tokenA?.address?.toLowerCase() !== network_token?.address?.toLowerCase()) {
                if (inputA > psp22_balance) {
                  setIsBtnDisabled(true);
                  setPsp22TokensMsg(`*Insufficient ${tokenA.symbol.toUpperCase()} tokens amount.`);
                  return;
                } else {
                  setIsBtnDisabled(false);
                  setPsp22TokensMsg();
                }
              } else if (tokenA?.address.toLowerCase() === network_token.address.toLowerCase()) {
                if (inputA > azero_balance) {
                  setAzeroTokensMsg(`*Insufficient ${tokenA.symbol.toUpperCase()} tokens amount.`);
                  setIsBtnDisabled(true);
                } else {
                  setIsBtnDisabled(false);
                  setAzeroTokensMsg();
                }
              }
              if (tokenB?.address?.toLowerCase() !== network_token?.address?.toLowerCase()) {
                if (inputB > psp22_balance) {
                  setPsp22TokensMsg(`*Insufficient ${tokenB.symbol.toUpperCase()} tokens amount.`);
                  setIsBtnDisabled(true);
                  return;
                } else {
                  setIsBtnDisabled(false);
                  setPsp22TokensMsg();
                }
              } else if (tokenB?.address.toLowerCase() === network_token.address.toLowerCase()) {
                if (inputB > azero_balance) {
                  setAzeroTokensMsg(`*Insufficient ${tokenB.symbol.toUpperCase()} tokens amount.`);
                  setIsBtnDisabled(true);
                } else {
                  setIsBtnDisabled(false);
                  setAzeroTokensMsg();
                }
              }
            } else {
              setIsBtnDisabled(true);
            }
          } else {
            if (pairExist && totalSupply > 0) {
              setIsBtnDisabled(true);
              setDisabledFee(true);
              setInitBtnMsg("Pool already exist");
            } else {
              setDisabledDatePicker(false);
              setDisabledFee(false);
              setInputDisabled(false);

              if (unlockLpDate) {
                setInitBtnMsg("Initialize pair");
                setIsBtnDisabled(false);
                return;
              } else {
                setInitBtnMsg("Please select date to unlock lp");
                setIsBtnDisabled(true);
                return;
              }
            }
          }
        }
      } else {
        setIsBtnDisabled(true);
        setInitBtnMsg("Can't init pair with similar tokens.");
        let msg = (
          <Typography sx={{ marginBlock: "20px 50px", lineHeight: "200%" }}>
            Pool can't be created using the same token twice.
            <br />
            Please choose another token to create a pool.
          </Typography>
        );
        setTokensAreSameMsg(msg);
        setPairExist(false);
      }
    });
  };

  //  get pool total supply
  const getPairTotalSupply = async (pair_address) => {
    let totalSupply;
    if (api[0]) {
      let gasLimit = getMaxGasLimit(api[0]);
      let contract = new ContractPromise(api[0], abiTradingPairAzero, pair_address);
      totalSupply = await getPoolTotalSupply(contract, address[0], gasLimit, connectedNetwork);
      totalSupply = totalSupply.total_supply;
    }
    return totalSupply;
  };

  useEffect(() => {
    if (tokenA && tokenB) {
      checkIfPairExist(tokenA, tokenB);
    } else {
      if ((tokenA && !tokenB) || (!tokenA && tokenB)) {
        setIsBtnDisabled(true);
        setInitBtnMsg("Select two tokens");
      } else if (!tokenA && !tokenB) {
        setIsBtnDisabled(true);
        setInitBtnMsg("No tokens selected");
      }
    }
  }, [tokenA, tokenB, inputAValue, inputBValue, tokenAValue, tokenBValue, isBtnDisabled, unlockLpDate]);

  return (
    <Wrapper>
      <Page>
        <HeroContainer paddingtop="40px">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              opacity: "0.6",
              width: "fit-content",
              cursor: "pointer",
            }}
          >
            <ArrowCircleDownIcon
              fontSize="medium"
              sx={{ transform: "rotateY(0deg) rotate(90deg)" }}
              onClick={() => {
                navigate(-1);
              }}
            />
            <Typography
              sx={{ fontSize: "18px" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Back to pools
            </Typography>
          </Box>
        </HeroContainer>

        <HeroContainer>
          <MainCreatePoolPaper elevation={3}>
            <LeftBox
              pairExist={pairExist}
              tokensAreSameMsg={tokensAreSameMsg}
              tokenA={tokenA}
              tokenB={tokenB}
              currentPair={currentPair}
              getPairTotalSupply={getPairTotalSupply}
            />
            <RightBox
              tokenA={tokenA}
              inputAValue={inputAValue}
              tokenB={tokenB}
              inputBValue={inputBValue}
              handleTokenValue={handleTokenValue}
              inputDisabled={inputDisabled}
              handleClickOpenA={handleClickOpenA}
              handleClickOpenB={handleClickOpenB}
              fee={fee}
              disabledFee={disabledFee}
              setFeeFunction={setFeeFunction}
              unlockDateFN={setUnlockDate}
              unlockDate={unlockLpDate}
              disabledDatePicker={disabledDatePicker}
              marks={marks}
              open={open}
              tokenLetter={tokenLetter}
              tokenAValue={tokenAValue}
              tokenBValue={tokenBValue}
              handleCloseToken={handleCloseToken}
              pairExist={pairExist}
              checkIfPairExist={checkIfPairExist}
              currentPair={currentPair}
              isBtnDisabled={isBtnDisabled}
              initBtnMsg={initBtnMsg}
              valueMsg={valueMsg}
              psp22TokensMsg={psp22TokensMsg}
              azeroTokensMsg={azeroTokensMsg}
              // disableButton={disableButton}
              getPairTotalSupply={getPairTotalSupply}
            />
          </MainCreatePoolPaper>
        </HeroContainer>
      </Page>
    </Wrapper>
  );
};

export default CreatePool;
