// **** TPA ABI **** //
// **** lOCAL TESTING **** //
export const abiTradingPairAzero = {
  source: {
    hash: "0x6e8dad1f832428b375220e9b8d9cf6ddb5be12dc6096fc1297c446ebffe42ef0",
    language: "ink! 4.3.0",
    compiler: "rustc 1.68.0-nightly",
    build_info: {
      build_mode: "Release",
      cargo_contract_version: "2.0.0",
      rust_toolchain: "nightly-aarch64-apple-darwin",
      wasm_opt_settings: {
        keep_debug_symbols: false,
        optimization_passes: "Z",
      },
    },
  },
  contract: {
    name: "trading_pair_azero",
    version: "2.5.2",
    authors: ["Yonatan Hornstein <yonatan@panoramaswap.com>"],
  },
  spec: {
    constructors: [
      {
        args: [
          {
            label: "psp22_contract",
            type: {
              displayName: ["AccountId"],
              type: 2,
            },
          },
          {
            label: "fee",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            label: "panx_contract",
            type: {
              displayName: ["AccountId"],
              type: 2,
            },
          },
          {
            label: "vault",
            type: {
              displayName: ["AccountId"],
              type: 2,
            },
          },
          {
            label: "lp_lock_timestamp",
            type: {
              displayName: ["u64"],
              type: 5,
            },
          },
          {
            label: "deployer",
            type: {
              displayName: ["AccountId"],
              type: 2,
            },
          },
        ],
        default: false,
        docs: [],
        label: "new",
        payable: false,
        returnType: {
          displayName: ["ink_primitives", "ConstructorResult"],
          type: 6,
        },
        selector: "0x9bae9d5e",
      },
    ],
    docs: [],
    environment: {
      accountId: {
        displayName: ["AccountId"],
        type: 2,
      },
      balance: {
        displayName: ["Balance"],
        type: 0,
      },
      blockNumber: {
        displayName: ["BlockNumber"],
        type: 28,
      },
      chainExtension: {
        displayName: ["ChainExtension"],
        type: 29,
      },
      hash: {
        displayName: ["Hash"],
        type: 27,
      },
      maxEventTopics: 4,
      timestamp: {
        displayName: ["Timestamp"],
        type: 5,
      },
    },
    events: [
      {
        args: [
          {
            docs: [],
            indexed: false,
            label: "provider",
            type: {
              displayName: ["AccountId"],
              type: 2,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "a0_deposited_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "psp22_deposited_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "shares_given",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        docs: [],
        label: "LiquidityPoolProvision",
      },
      {
        args: [
          {
            docs: [],
            indexed: false,
            label: "caller",
            type: {
              displayName: ["AccountId"],
              type: 2,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "shares_given",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "a0_given_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "psp22_given_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "new_shares_balance",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        docs: [],
        label: "LiquidityPoolWithdrawal",
      },
      {
        args: [
          {
            docs: [],
            indexed: false,
            label: "caller",
            type: {
              displayName: ["AccountId"],
              type: 2,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "a0_deposited_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "psp22_given_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "psp22_given_to_vault",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        docs: [],
        label: "A0Swap",
      },
      {
        args: [
          {
            docs: [],
            indexed: false,
            label: "caller",
            type: {
              displayName: ["AccountId"],
              type: 2,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "psp22_deposited_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "a0_given_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "a0_given_to_vault",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        docs: [],
        label: "PSP22Swap",
      },
    ],
    lang_error: {
      displayName: ["ink", "LangError"],
      type: 8,
    },
    messages: [
      {
        args: [
          {
            label: "psp22_deposit_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            label: "a0_deposit_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            label: "expected_lp_tokens",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            label: "slippage",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [" function to provide liquidity to a PSP22/A0 trading pair contract."],
        label: "provide_to_pool",
        mutates: true,
        payable: true,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 9,
        },
        selector: "0x63ba72af",
      },
      {
        args: [
          {
            label: "shares",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [" function to withdraw specific amount of LP share tokens and receive AZERO coins and PSP22 tokens."],
        label: "withdraw_specific_amount",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 9,
        },
        selector: "0xa1ea9e79",
      },
      {
        args: [
          {
            label: "shares_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [
          " function to get the amount of withdrawable PSP22 and A0 by given number of LP shares without LP fees.",
        ],
        label: "get_withdraw_tokens_amount",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 12,
        },
        selector: "0x8c4f5c84",
      },
      {
        args: [
          {
            label: "shares_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [" function to get the amount of withdrawable PSP22 and A0 by given number of LP shares with LP fees."],
        label: "get_withdraw_tokens_amount_with_lp",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 12,
        },
        selector: "0x3c148e18",
      },
      {
        args: [
          {
            label: "shares_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [
          " function to get the amount of withdrawable pooled PSP22 tokens by given number of LP shares without LP fees.",
        ],
        label: "get_psp22_withdraw_tokens_amount",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 15,
        },
        selector: "0xcfc29e0c",
      },
      {
        args: [
          {
            label: "shares_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [" function to get the amount of PSP22 LP fee tokens by number of shares"],
        label: "get_psp22_lp_fee_tokens",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 15,
        },
        selector: "0x9126b075",
      },
      {
        args: [],
        default: false,
        docs: [
          " function to get the percentage difference between the PSP22 pooled tokens without LP fee and with LP fees",
        ],
        label: "get_psp22_difference_by_percentage",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 15,
        },
        selector: "0x9be9217b",
      },
      {
        args: [
          {
            label: "shares_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [
          " function to get the amount of withdrawable pooled AZERO coins by given number of LP shares without LP fees.",
        ],
        label: "get_a0_withdraw_tokens_amount",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 15,
        },
        selector: "0xbaad2a32",
      },
      {
        args: [
          {
            label: "shares_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [" function to get the amount of A0 LP fee tokens by number of shares"],
        label: "get_a0_lp_fee_tokens",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 15,
        },
        selector: "0x72a4dbc6",
      },
      {
        args: [],
        default: false,
        docs: [
          " function to get the percentage difference between the AZERO pooled coins without LP fee and with LP fees",
        ],
        label: "get_a0_difference_by_percentage",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 15,
        },
        selector: "0xb8c940a0",
      },
      {
        args: [
          {
            label: "account_id",
            type: {
              displayName: ["AccountId"],
              type: 2,
            },
          },
        ],
        default: false,
        docs: [" function to get the callers pooled PSP22 and A0."],
        label: "get_account_locked_tokens",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 12,
        },
        selector: "0xf1e79c0a",
      },
      {
        args: [
          {
            label: "a0_deposit_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            label: "psp22_deposit_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [],
        label: "get_expected_lp_token_amount",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 15,
        },
        selector: "0x00254cc1",
      },
      {
        args: [],
        default: false,
        docs: [" function to get the amount of A0 the caller will get for 1 PSP22 token."],
        label: "get_price_for_one_psp22",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 15,
        },
        selector: "0x25599b1b",
      },
      {
        args: [
          {
            label: "psp22_amount_in",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [" function to get the amount of A0 the caller will get for given PSP22 amount."],
        label: "get_est_price_psp22_to_a0",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 15,
        },
        selector: "0x24603984",
      },
      {
        args: [
          {
            label: "a0_amout_in",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [" function to get the amount of PSP22 the caller will get for given A0 amount (swap use)"],
        label: "get_est_price_a0_to_psp22_for_swap",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 15,
        },
        selector: "0x6e944f85",
      },
      {
        args: [
          {
            label: "a0_amount_in",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [" function to get the amount of PSP22 the caller will get for given A0 amount (front-end use)"],
        label: "get_est_price_a0_to_psp22",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 15,
        },
        selector: "0x7fa84c2b",
      },
      {
        args: [
          {
            label: "psp22_amount_in",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [" function to get the estimated price impact for given psp22 token amount"],
        label: "get_price_impact_psp22_to_a0",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 15,
        },
        selector: "0x91e4bad2",
      },
      {
        args: [
          {
            label: "a0_amount_in",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [" function to get the estimated price impact for given A0 amount"],
        label: "get_price_impact_a0_to_psp22",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 15,
        },
        selector: "0x3b087f6a",
      },
      {
        args: [
          {
            label: "psp22_amount_to_transfer",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            label: "a0_amount_to_validate",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            label: "slippage",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [" function to swap PSP22 to A0"],
        label: "swap_psp22",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 9,
        },
        selector: "0x7afc2194",
      },
      {
        args: [
          {
            label: "psp22_amount_to_validate",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            label: "slippage",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [" function to swap A0 to PSP22"],
        label: "swap_a0",
        mutates: true,
        payable: true,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 9,
        },
        selector: "0x92b0ce2b",
      },
      {
        args: [],
        default: false,
        docs: [" function to get caller redeemable amount of pooled PSP22"],
        label: "get_psp22_redeemable_amount",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 15,
        },
        selector: "0xf3d26b7a",
      },
      {
        args: [],
        default: false,
        docs: [" function for caller to redeem LP incentive tokens."],
        label: "redeem_redeemable_amount",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 9,
        },
        selector: "0x7f8d53af",
      },
      {
        args: [
          {
            label: "caller",
            type: {
              displayName: ["AccountId"],
              type: 2,
            },
          },
        ],
        default: false,
        docs: [" function to get the amount of tokens to give to caller each day."],
        label: "get_amount_to_give_each_day_to_caller",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 17,
        },
        selector: "0x57871274",
      },
      {
        args: [],
        default: false,
        docs: [],
        label: "get_generated_lp_fees",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 18,
        },
        selector: "0xb49c3eb0",
      },
      {
        args: [
          {
            label: "owner",
            type: {
              displayName: ["AccountId"],
              type: 2,
            },
          },
        ],
        default: false,
        docs: [],
        label: "get_account_overall_staking_rewards",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 17,
        },
        selector: "0xa69436ce",
      },
      {
        args: [
          {
            label: "owner",
            type: {
              displayName: ["AccountId"],
              type: 2,
            },
          },
        ],
        default: false,
        docs: [],
        label: "get_account_overall_lp_fee_rewards",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 18,
        },
        selector: "0xc6b57480",
      },
      {
        args: [],
        default: false,
        docs: [],
        label: "get_contract_overall_generated_fee",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 18,
        },
        selector: "0x93da6005",
      },
      {
        args: [],
        default: false,
        docs: [" function to get AzeroTradingPair contract address (self)"],
        label: "get_account_id",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 19,
        },
        selector: "0x79718546",
      },
      {
        args: [],
        default: false,
        docs: [],
        label: "get_deployer_account",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 19,
        },
        selector: "0x979507d9",
      },
      {
        args: [],
        default: false,
        docs: [" function to get AzeroTradingPair contract address (self)"],
        label: "get_caller_id",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 19,
        },
        selector: "0xb3811b28",
      },
      {
        args: [],
        default: false,
        docs: [" function to fetch current price for one PSP22"],
        label: "get_current_price",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 17,
        },
        selector: "0x9b85f745",
      },
      {
        args: [],
        default: false,
        docs: [" function to get total supply of LP shares"],
        label: "get_total_supply",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 17,
        },
        selector: "0xb079adab",
      },
      {
        args: [],
        default: false,
        docs: [" function to get trading contract AZERO balance"],
        label: "get_a0_balance",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 17,
        },
        selector: "0x1377ba56",
      },
      {
        args: [
          {
            label: "account",
            type: {
              displayName: ["AccountId"],
              type: 2,
            },
          },
        ],
        default: false,
        docs: [" function to get shares of specific account"],
        label: "get_lp_token_of",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 17,
        },
        selector: "0xe419c292",
      },
      {
        args: [],
        default: false,
        docs: [],
        label: "get_psp22_balance",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 17,
        },
        selector: "0x2f3d9314",
      },
      {
        args: [],
        default: false,
        docs: [" function to get current fee"],
        label: "get_fee",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 17,
        },
        selector: "0x0be94636",
      },
      {
        args: [],
        default: false,
        docs: [],
        label: "get_transactions_num",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 20,
        },
        selector: "0x76ba4f65",
      },
      {
        args: [
          {
            label: "value1",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            label: "value2",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [" function to calculate the percentage between values."],
        label: "check_difference",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 15,
        },
        selector: "0xfa57141c",
      },
      {
        args: [
          {
            label: "a0_deposit_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            label: "a0_contract_balance",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [],
        label: "get_psp22_amount_for_lp",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 17,
        },
        selector: "0x5d749da7",
      },
      {
        args: [
          {
            label: "psp22_deposit_amount",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            label: "a0_contract_balance",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [],
        label: "get_a0_amount_for_lp",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 17,
        },
        selector: "0x5a9bc0b4",
      },
      {
        args: [],
        default: false,
        docs: [" function to get current timpstamp in seconds"],
        label: "get_current_timestamp",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 21,
        },
        selector: "0x17ecd294",
      },
      {
        args: [],
        default: false,
        docs: [" function to get LP lock timestamp"],
        label: "get_lp_lock_timestamp",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 21,
        },
        selector: "0x5eff45bc",
      },
      {
        args: [
          {
            label: "to",
            type: {
              displayName: ["psp22_external", "TransferInput1"],
              type: 2,
            },
          },
          {
            label: "value",
            type: {
              displayName: ["psp22_external", "TransferInput2"],
              type: 0,
            },
          },
          {
            label: "data",
            type: {
              displayName: ["psp22_external", "TransferInput3"],
              type: 22,
            },
          },
        ],
        default: false,
        docs: [],
        label: "PSP22::transfer",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 23,
        },
        selector: "0xdb20f9f5",
      },
      {
        args: [
          {
            label: "from",
            type: {
              displayName: ["psp22_external", "TransferFromInput1"],
              type: 2,
            },
          },
          {
            label: "to",
            type: {
              displayName: ["psp22_external", "TransferFromInput2"],
              type: 2,
            },
          },
          {
            label: "value",
            type: {
              displayName: ["psp22_external", "TransferFromInput3"],
              type: 0,
            },
          },
          {
            label: "data",
            type: {
              displayName: ["psp22_external", "TransferFromInput4"],
              type: 22,
            },
          },
        ],
        default: false,
        docs: [],
        label: "PSP22::transfer_from",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 23,
        },
        selector: "0x54b3c76e",
      },
      {
        args: [
          {
            label: "spender",
            type: {
              displayName: ["psp22_external", "IncreaseAllowanceInput1"],
              type: 2,
            },
          },
          {
            label: "delta_value",
            type: {
              displayName: ["psp22_external", "IncreaseAllowanceInput2"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [],
        label: "PSP22::increase_allowance",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 23,
        },
        selector: "0x96d6b57a",
      },
      {
        args: [],
        default: false,
        docs: [],
        label: "PSP22::total_supply",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 17,
        },
        selector: "0x162df8c2",
      },
      {
        args: [
          {
            label: "owner",
            type: {
              displayName: ["psp22_external", "AllowanceInput1"],
              type: 2,
            },
          },
          {
            label: "spender",
            type: {
              displayName: ["psp22_external", "AllowanceInput2"],
              type: 2,
            },
          },
        ],
        default: false,
        docs: [],
        label: "PSP22::allowance",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 17,
        },
        selector: "0x4d47d921",
      },
      {
        args: [
          {
            label: "owner",
            type: {
              displayName: ["psp22_external", "BalanceOfInput1"],
              type: 2,
            },
          },
        ],
        default: false,
        docs: [],
        label: "PSP22::balance_of",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 17,
        },
        selector: "0x6568382f",
      },
      {
        args: [
          {
            label: "spender",
            type: {
              displayName: ["psp22_external", "DecreaseAllowanceInput1"],
              type: 2,
            },
          },
          {
            label: "delta_value",
            type: {
              displayName: ["psp22_external", "DecreaseAllowanceInput2"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [],
        label: "PSP22::decrease_allowance",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 23,
        },
        selector: "0xfecb57d5",
      },
      {
        args: [
          {
            label: "spender",
            type: {
              displayName: ["psp22_external", "ApproveInput1"],
              type: 2,
            },
          },
          {
            label: "value",
            type: {
              displayName: ["psp22_external", "ApproveInput2"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [],
        label: "PSP22::approve",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 23,
        },
        selector: "0xb20f1bbd",
      },
    ],
  },
  storage: {
    root: {
      layout: {
        struct: {
          fields: [
            {
              layout: {
                struct: {
                  fields: [
                    {
                      layout: {
                        root: {
                          layout: {
                            leaf: {
                              key: "0x270a8fc3",
                              ty: 0,
                            },
                          },
                          root_key: "0x270a8fc3",
                        },
                      },
                      name: "supply",
                    },
                    {
                      layout: {
                        root: {
                          layout: {
                            leaf: {
                              key: "0xc2664826",
                              ty: 0,
                            },
                          },
                          root_key: "0xc2664826",
                        },
                      },
                      name: "balances",
                    },
                    {
                      layout: {
                        root: {
                          layout: {
                            leaf: {
                              key: "0xf8d71e22",
                              ty: 0,
                            },
                          },
                          root_key: "0xf8d71e22",
                        },
                      },
                      name: "allowances",
                    },
                  ],
                  name: "Data",
                },
              },
              name: "psp22",
            },
            {
              layout: {
                leaf: {
                  key: "0x00000000",
                  ty: 1,
                },
              },
              name: "transasction_number",
            },
            {
              layout: {
                leaf: {
                  key: "0x00000000",
                  ty: 2,
                },
              },
              name: "psp22_token",
            },
            {
              layout: {
                leaf: {
                  key: "0x00000000",
                  ty: 0,
                },
              },
              name: "fee",
            },
            {
              layout: {
                leaf: {
                  key: "0x00000000",
                  ty: 0,
                },
              },
              name: "total_supply",
            },
            {
              layout: {
                root: {
                  layout: {
                    leaf: {
                      key: "0x754f6d2e",
                      ty: 0,
                    },
                  },
                  root_key: "0x754f6d2e",
                },
              },
              name: "balances",
            },
            {
              layout: {
                leaf: {
                  key: "0x00000000",
                  ty: 2,
                },
              },
              name: "panx_contract",
            },
            {
              layout: {
                root: {
                  layout: {
                    leaf: {
                      key: "0xf13aa691",
                      ty: 0,
                    },
                  },
                  root_key: "0xf13aa691",
                },
              },
              name: "lp_tokens_allowances",
            },
            {
              layout: {
                leaf: {
                  key: "0x00000000",
                  ty: 2,
                },
              },
              name: "vault",
            },
            {
              layout: {
                leaf: {
                  key: "0x00000000",
                  ty: 0,
                },
              },
              name: "psp22_lp_fee_vault",
            },
            {
              layout: {
                leaf: {
                  key: "0x00000000",
                  ty: 0,
                },
              },
              name: "azero_lp_fee_vault",
            },
            {
              layout: {
                leaf: {
                  key: "0x00000000",
                  ty: 0,
                },
              },
              name: "contract_overall_generated_psp22_fee",
            },
            {
              layout: {
                leaf: {
                  key: "0x00000000",
                  ty: 0,
                },
              },
              name: "contract_overall_generated_azero_fee",
            },
            {
              layout: {
                root: {
                  layout: {
                    leaf: {
                      key: "0xcfae497d",
                      ty: 0,
                    },
                  },
                  root_key: "0xcfae497d",
                },
              },
              name: "psp22_to_give_in_a_day",
            },
            {
              layout: {
                root: {
                  layout: {
                    leaf: {
                      key: "0x39dd79c3",
                      ty: 0,
                    },
                  },
                  root_key: "0x39dd79c3",
                },
              },
              name: "account_overall_staking_rewards",
            },
            {
              layout: {
                root: {
                  layout: {
                    struct: {
                      fields: [
                        {
                          layout: {
                            leaf: {
                              key: "0x5a958657",
                              ty: 0,
                            },
                          },
                          name: "0",
                        },
                        {
                          layout: {
                            leaf: {
                              key: "0x5a958657",
                              ty: 0,
                            },
                          },
                          name: "1",
                        },
                      ],
                      name: "(A, B)",
                    },
                  },
                  root_key: "0x5a958657",
                },
              },
              name: "account_overall_lp_fee_rewards",
            },
            {
              layout: {
                root: {
                  layout: {
                    leaf: {
                      key: "0x64d569ad",
                      ty: 5,
                    },
                  },
                  root_key: "0x64d569ad",
                },
              },
              name: "last_redeemed",
            },
            {
              layout: {
                leaf: {
                  key: "0x00000000",
                  ty: 5,
                },
              },
              name: "lp_lock_timestamp",
            },
            {
              layout: {
                leaf: {
                  key: "0x00000000",
                  ty: 2,
                },
              },
              name: "deployer",
            },
          ],
          name: "TradingPairAzero",
        },
      },
      root_key: "0x00000000",
    },
  },
  types: [
    {
      id: 0,
      type: {
        def: {
          primitive: "u128",
        },
      },
    },
    {
      id: 1,
      type: {
        def: {
          primitive: "i64",
        },
      },
    },
    {
      id: 2,
      type: {
        def: {
          composite: {
            fields: [
              {
                type: 3,
                typeName: "[u8; 32]",
              },
            ],
          },
        },
        path: ["ink_primitives", "types", "AccountId"],
      },
    },
    {
      id: 3,
      type: {
        def: {
          array: {
            len: 32,
            type: 4,
          },
        },
      },
    },
    {
      id: 4,
      type: {
        def: {
          primitive: "u8",
        },
      },
    },
    {
      id: 5,
      type: {
        def: {
          primitive: "u64",
        },
      },
    },
    {
      id: 6,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 7,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 8,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 7,
          },
          {
            name: "E",
            type: 8,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 7,
      type: {
        def: {
          tuple: [],
        },
      },
    },
    {
      id: 8,
      type: {
        def: {
          variant: {
            variants: [
              {
                index: 1,
                name: "CouldNotReadInput",
              },
            ],
          },
        },
        path: ["ink_primitives", "LangError"],
      },
    },
    {
      id: 9,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 10,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 8,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 10,
          },
          {
            name: "E",
            type: 8,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 10,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 7,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 11,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 7,
          },
          {
            name: "E",
            type: 11,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 11,
      type: {
        def: {
          variant: {
            variants: [
              {
                index: 0,
                name: "CallerInsufficientPSP22Balance",
              },
              {
                index: 1,
                name: "CallerInsufficientAZEROBalance",
              },
              {
                index: 2,
                name: "NotEnoughAllowance",
              },
              {
                index: 3,
                name: "Overflow",
              },
              {
                index: 4,
                name: "ZeroSharesGiven",
              },
              {
                index: 5,
                name: "SlippageTolerance",
              },
              {
                index: 6,
                name: "PSP22TransferFromFailed",
              },
              {
                index: 7,
                name: "PSP22TransferFailed",
              },
              {
                index: 8,
                name: "A0TransferFailed",
              },
              {
                index: 9,
                name: "CallerInsufficientLPBalance",
              },
              {
                index: 10,
                name: "ContractOutOfA0",
              },
              {
                index: 11,
                name: "ContractOutOfPSP22",
              },
              {
                index: 12,
                name: "NotEnoughOwnerLPAllowance",
              },
              {
                index: 13,
                name: "ZeroDaysPassed",
              },
              {
                index: 14,
                name: "ZeroDailyPSP22",
              },
              {
                index: 15,
                name: "ZeroDailyA0",
              },
              {
                index: 16,
                name: "UpdateIncentiveProgramError",
              },
              {
                index: 17,
                name: "RemoveLpIncentiveProgramError",
              },
              {
                index: 18,
                name: "LpStillLocked",
              },
              {
                index: 19,
                name: "ZeroRedeemableAmount",
              },
            ],
          },
        },
        path: ["trading_pair_azero", "trading_pair_azero", "TradingPairErrors"],
      },
    },
    {
      id: 12,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 13,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 8,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 13,
          },
          {
            name: "E",
            type: 8,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 13,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 14,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 11,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 14,
          },
          {
            name: "E",
            type: 11,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 14,
      type: {
        def: {
          tuple: [0, 0],
        },
      },
    },
    {
      id: 15,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 16,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 8,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 16,
          },
          {
            name: "E",
            type: 8,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 16,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 0,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 11,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 0,
          },
          {
            name: "E",
            type: 11,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 17,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 0,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 8,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 0,
          },
          {
            name: "E",
            type: 8,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 18,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 14,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 8,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 14,
          },
          {
            name: "E",
            type: 8,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 19,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 2,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 8,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 2,
          },
          {
            name: "E",
            type: 8,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 20,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 1,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 8,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 1,
          },
          {
            name: "E",
            type: 8,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 21,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 5,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 8,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 5,
          },
          {
            name: "E",
            type: 8,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 22,
      type: {
        def: {
          sequence: {
            type: 4,
          },
        },
      },
    },
    {
      id: 23,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 24,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 8,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 24,
          },
          {
            name: "E",
            type: 8,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 24,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 7,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 25,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 7,
          },
          {
            name: "E",
            type: 25,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 25,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 26,
                    typeName: "String",
                  },
                ],
                index: 0,
                name: "Custom",
              },
              {
                index: 1,
                name: "InsufficientBalance",
              },
              {
                index: 2,
                name: "InsufficientAllowance",
              },
              {
                index: 3,
                name: "ZeroRecipientAddress",
              },
              {
                index: 4,
                name: "ZeroSenderAddress",
              },
              {
                fields: [
                  {
                    type: 26,
                    typeName: "String",
                  },
                ],
                index: 5,
                name: "SafeTransferCheckFailed",
              },
            ],
          },
        },
        path: ["openbrush_contracts", "traits", "errors", "psp22", "PSP22Error"],
      },
    },
    {
      id: 26,
      type: {
        def: {
          primitive: "str",
        },
      },
    },
    {
      id: 27,
      type: {
        def: {
          composite: {
            fields: [
              {
                type: 3,
                typeName: "[u8; 32]",
              },
            ],
          },
        },
        path: ["ink_primitives", "types", "Hash"],
      },
    },
    {
      id: 28,
      type: {
        def: {
          primitive: "u32",
        },
      },
    },
    {
      id: 29,
      type: {
        def: {
          variant: {},
        },
        path: ["ink_env", "types", "NoChainExtension"],
      },
    },
  ],
  version: "4",
};

// **** PRODUCTION  **** //
// export const abiTradingPairAzero = {
//   source: {
//     hash: "0x7a9a8fcf30b5c09f91f7c50d3eb12262dbbabb08bb8d7213ec648554a78075ee",
//     language: "ink! 4.0.0",
//     compiler: "rustc 1.65.0",
//     build_info: {
//       build_mode: "Debug",
//       cargo_contract_version: "2.0.0-beta",
//       rust_toolchain: "stable-aarch64-apple-darwin",
//       wasm_opt_settings: {
//         keep_debug_symbols: false,
//         optimization_passes: "Z",
//       },
//     },
//   },
//   contract: {
//     name: "trading_pair_azero",
//     version: "2.5.2",
//     authors: ["Yonatan Hornstein <yonatan@panoramaswap.com>"],
//   },
//   spec: {
//     constructors: [
//       {
//         args: [
//           {
//             label: "psp22_contract",
//             type: {
//               displayName: ["AccountId"],
//               type: 1,
//             },
//           },
//           {
//             label: "fee",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//           {
//             label: "panx_contract",
//             type: {
//               displayName: ["AccountId"],
//               type: 1,
//             },
//           },
//           {
//             label: "vault",
//             type: {
//               displayName: ["AccountId"],
//               type: 1,
//             },
//           },
//         ],
//         docs: [],
//         label: "new",
//         payable: false,
//         returnType: {
//           displayName: ["ink_primitives", "ConstructorResult"],
//           type: 6,
//         },
//         selector: "0x9bae9d5e",
//       },
//     ],
//     docs: [],
//     events: [
//       {
//         args: [
//           {
//             docs: [],
//             indexed: false,
//             label: "provider",
//             type: {
//               displayName: ["AccountId"],
//               type: 1,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "a0_deposited_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "psp22_deposited_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "shares_given",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: [],
//         label: "LiquidityPoolProvision",
//       },
//       {
//         args: [
//           {
//             docs: [],
//             indexed: false,
//             label: "caller",
//             type: {
//               displayName: ["AccountId"],
//               type: 1,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "shares_given",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "a0_given_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "psp22_given_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "new_shares_balance",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: [],
//         label: "LiquidityPoolWithdrawal",
//       },
//       {
//         args: [
//           {
//             docs: [],
//             indexed: false,
//             label: "caller",
//             type: {
//               displayName: ["AccountId"],
//               type: 1,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "a0_deposited_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "psp22_given_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "psp22_given_to_vault",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: [],
//         label: "A0Swap",
//       },
//       {
//         args: [
//           {
//             docs: [],
//             indexed: false,
//             label: "caller",
//             type: {
//               displayName: ["AccountId"],
//               type: 1,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "psp22_deposited_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "a0_given_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "a0_given_to_vault",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: [],
//         label: "PSP22Swap",
//       },
//     ],
//     lang_error: {
//       displayName: ["ink", "LangError"],
//       type: 8,
//     },
//     messages: [
//       {
//         args: [
//           {
//             label: "psp22_deposit_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//           {
//             label: "expected_lp_tokens",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//           {
//             label: "slippage",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: ["function to provide liquidity to a PSP22/A0 trading pair contract."],
//         label: "provide_to_pool",
//         mutates: true,
//         payable: true,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 9,
//         },
//         selector: "0x63ba72af",
//       },
//       {
//         args: [
//           {
//             label: "shares",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: ["function to withdraw specific amount of LP share tokens and receive AZERO coins and PSP22 tokens. "],
//         label: "withdraw_specific_amount",
//         mutates: true,
//         payable: true,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 9,
//         },
//         selector: "0xa1ea9e79",
//       },
//       {
//         args: [
//           {
//             label: "shares_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: ["function to get the amount of withdrawable PSP22 and A0 by given number of LP shares without LP fees."],
//         label: "get_withdraw_tokens_amount",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 12,
//         },
//         selector: "0x8c4f5c84",
//       },
//       {
//         args: [
//           {
//             label: "shares_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: ["function to get the amount of withdrawable PSP22 and A0 by given number of LP shares with LP fees."],
//         label: "get_withdraw_tokens_amount_with_lp",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 12,
//         },
//         selector: "0x3c148e18",
//       },
//       {
//         args: [
//           {
//             label: "shares_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: [
//           "function to get the amount of withdrawable pooled PSP22 tokens by given number of LP shares without LP fees.",
//         ],
//         label: "get_psp22_withdraw_tokens_amount",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 15,
//         },
//         selector: "0xcfc29e0c",
//       },
//       {
//         args: [
//           {
//             label: "shares_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: ["function to get the amount of PSP22 LP fee tokens by number of shares"],
//         label: "get_psp22_lp_fee_tokens",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 15,
//         },
//         selector: "0x9126b075",
//       },
//       {
//         args: [],
//         docs: [
//           "function to get the percentage difference between the PSP22 pooled tokens without LP fee and with LP fees ",
//         ],
//         label: "get_psp22_difference_by_percentage",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 15,
//         },
//         selector: "0x9be9217b",
//       },
//       {
//         args: [
//           {
//             label: "shares_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: [
//           "function to get the amount of withdrawable pooled AZERO coins by given number of LP shares without LP fees.",
//         ],
//         label: "get_a0_withdraw_tokens_amount",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 15,
//         },
//         selector: "0xbaad2a32",
//       },
//       {
//         args: [
//           {
//             label: "shares_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: ["function to get the amount of A0 LP fee tokens by number of shares"],
//         label: "get_a0_lp_fee_tokens",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 15,
//         },
//         selector: "0x72a4dbc6",
//       },
//       {
//         args: [],
//         docs: [
//           "function to get the percentage difference between the AZERO pooled coins without LP fee and with LP fees ",
//         ],
//         label: "get_a0_difference_by_percentage",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 15,
//         },
//         selector: "0xb8c940a0",
//       },
//       {
//         args: [
//           {
//             label: "account_id",
//             type: {
//               displayName: ["AccountId"],
//               type: 1,
//             },
//           },
//         ],
//         docs: ["function to get the callers pooled PSP22 and A0."],
//         label: "get_account_locked_tokens",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 12,
//         },
//         selector: "0xf1e79c0a",
//       },
//       {
//         args: [
//           {
//             label: "a0_deposit_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: [],
//         label: "get_expected_lp_token_amount",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 15,
//         },
//         selector: "0x00254cc1",
//       },
//       {
//         args: [],
//         docs: ["function to get the amount of A0 the caller will get for 1 PSP22 token."],
//         label: "get_price_for_one_psp22",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 15,
//         },
//         selector: "0x25599b1b",
//       },
//       {
//         args: [
//           {
//             label: "psp22_amount_in",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: ["function to get the amount of A0 the caller will get for given PSP22 amount."],
//         label: "get_est_price_psp22_to_a0",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 15,
//         },
//         selector: "0x24603984",
//       },
//       {
//         args: [
//           {
//             label: "a0_amout_in",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: ["function to get the amount of PSP22 the caller will get for given A0 amount (swap use)"],
//         label: "get_est_price_a0_to_psp22_for_swap",
//         mutates: false,
//         payable: true,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 15,
//         },
//         selector: "0x6e944f85",
//       },
//       {
//         args: [
//           {
//             label: "a0_amout_in",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: ["function to get the amount of PSP22 the caller will get for given A0 amount (front-end use)"],
//         label: "get_est_price_a0_to_psp22",
//         mutates: false,
//         payable: true,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 15,
//         },
//         selector: "0x7fa84c2b",
//       },
//       {
//         args: [
//           {
//             label: "psp22_amount_in",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: ["function to get the estimated price impact for given psp22 token amount"],
//         label: "get_price_impact_psp22_to_a0",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 15,
//         },
//         selector: "0x91e4bad2",
//       },
//       {
//         args: [
//           {
//             label: "a0_amount_in",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: ["function to get the estimated price impact for given A0 amount"],
//         label: "get_price_impact_a0_to_psp22",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 15,
//         },
//         selector: "0x3b087f6a",
//       },
//       {
//         args: [
//           {
//             label: "psp22_amount_to_transfer",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//           {
//             label: "a0_amount_to_validate",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//           {
//             label: "slippage",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: ["function to swap PSP22 to A0"],
//         label: "swap_psp22",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 9,
//         },
//         selector: "0x7afc2194",
//       },
//       {
//         args: [
//           {
//             label: "psp22_amount_to_validate",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//           {
//             label: "slippage",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: ["function to swap A0 to PSP22"],
//         label: "swap_a0",
//         mutates: true,
//         payable: true,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 9,
//         },
//         selector: "0x92b0ce2b",
//       },
//       {
//         args: [
//           {
//             label: "recipient",
//             type: {
//               displayName: ["AccountId"],
//               type: 1,
//             },
//           },
//           {
//             label: "shares_to_transfer",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: ["function used to transfer LP share tokens from caller to recipient."],
//         label: "transfer_lp_tokens",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 9,
//         },
//         selector: "0xf72939c5",
//       },
//       {
//         args: [
//           {
//             label: "spender",
//             type: {
//               displayName: ["AccountId"],
//               type: 1,
//             },
//           },
//           {
//             label: "shares_to_approve",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: ["function used to approve the amount of LP token shares for the spender to spend from owner."],
//         label: "approve_lp_tokens",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 9,
//         },
//         selector: "0x6ab0a958",
//       },
//       {
//         args: [
//           {
//             label: "owner",
//             type: {
//               displayName: ["AccountId"],
//               type: 1,
//             },
//           },
//           {
//             label: "to",
//             type: {
//               displayName: ["AccountId"],
//               type: 1,
//             },
//           },
//           {
//             label: "shares_to_transfer",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: [],
//         label: "transfer_lp_tokens_from_to",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 9,
//         },
//         selector: "0x8136bce6",
//       },
//       {
//         args: [],
//         docs: ["function to get caller redeemable amount of pooled PSP22"],
//         label: "get_psp22_redeemable_amount",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 15,
//         },
//         selector: "0xf3d26b7a",
//       },
//       {
//         args: [],
//         docs: ["function for caller to redeem LP incentive tokens."],
//         label: "redeem_redeemable_amount",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 9,
//         },
//         selector: "0x7f8d53af",
//       },
//       {
//         args: [
//           {
//             label: "owner",
//             type: {
//               displayName: ["AccountId"],
//               type: 1,
//             },
//           },
//           {
//             label: "spender",
//             type: {
//               displayName: ["AccountId"],
//               type: 1,
//             },
//           },
//         ],
//         docs: [],
//         label: "get_lp_tokens_allowance",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 17,
//         },
//         selector: "0xac55ed2e",
//       },
//       {
//         args: [
//           {
//             label: "caller",
//             type: {
//               displayName: ["AccountId"],
//               type: 1,
//             },
//           },
//         ],
//         docs: ["function to get the amount of tokens to give to caller each day."],
//         label: "get_amount_to_give_each_day_to_caller",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 17,
//         },
//         selector: "0x57871274",
//       },
//       {
//         args: [],
//         docs: [],
//         label: "get_generated_lp_fees",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 18,
//         },
//         selector: "0xb49c3eb0",
//       },
//       {
//         args: [
//           {
//             label: "owner",
//             type: {
//               displayName: ["AccountId"],
//               type: 1,
//             },
//           },
//         ],
//         docs: [],
//         label: "get_account_overall_staking_rewards",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 17,
//         },
//         selector: "0xa69436ce",
//       },
//       {
//         args: [
//           {
//             label: "owner",
//             type: {
//               displayName: ["AccountId"],
//               type: 1,
//             },
//           },
//         ],
//         docs: [],
//         label: "get_account_overall_lp_fee_rewards",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 18,
//         },
//         selector: "0xc6b57480",
//       },
//       {
//         args: [],
//         docs: [],
//         label: "get_contract_overall_generated_fee",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 18,
//         },
//         selector: "0x93da6005",
//       },
//       {
//         args: [],
//         docs: ["function to get AzeroTradingPair contract address (self)"],
//         label: "get_account_id",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 19,
//         },
//         selector: "0x79718546",
//       },
//       {
//         args: [],
//         docs: ["function to fetch current price for one PSP22"],
//         label: "get_current_price",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 17,
//         },
//         selector: "0x9b85f745",
//       },
//       {
//         args: [],
//         docs: ["function to get total supply of LP shares"],
//         label: "get_total_supply",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 17,
//         },
//         selector: "0xb079adab",
//       },
//       {
//         args: [],
//         docs: ["function to get trading contract AZERO balance"],
//         label: "get_a0_balance",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 17,
//         },
//         selector: "0x1377ba56",
//       },
//       {
//         args: [
//           {
//             label: "account",
//             type: {
//               displayName: ["AccountId"],
//               type: 1,
//             },
//           },
//         ],
//         docs: ["function to get shares of specific account"],
//         label: "get_lp_token_of",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 17,
//         },
//         selector: "0xe419c292",
//       },
//       {
//         args: [],
//         docs: [],
//         label: "get_psp22_balance",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 17,
//         },
//         selector: "0x2f3d9314",
//       },
//       {
//         args: [],
//         docs: ["function to get current fee "],
//         label: "get_fee",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 17,
//         },
//         selector: "0x0be94636",
//       },
//       {
//         args: [],
//         docs: [],
//         label: "get_transactions_num",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 20,
//         },
//         selector: "0x76ba4f65",
//       },
//       {
//         args: [
//           {
//             label: "value1",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//           {
//             label: "value2",
//             type: {
//               displayName: ["Balance"],
//               type: 4,
//             },
//           },
//         ],
//         docs: ["function to calculate the percentage between values."],
//         label: "check_difference",
//         mutates: true,
//         payable: true,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 15,
//         },
//         selector: "0xfa57141c",
//       },
//       {
//         args: [],
//         docs: ["function to get current timpstamp in seconds"],
//         label: "get_current_timestamp",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 21,
//         },
//         selector: "0x17ecd294",
//       },
//       {
//         args: [
//           {
//             label: "code_hash",
//             type: {
//               displayName: [],
//               type: 2,
//             },
//           },
//         ],
//         docs: [],
//         label: "set_code",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 6,
//         },
//         selector: "0x694fb50f",
//       },
//     ],
//   },
//   storage: {
//     root: {
//       layout: {
//         struct: {
//           fields: [
//             {
//               layout: {
//                 leaf: {
//                   key: "0x00000000",
//                   ty: 0,
//                 },
//               },
//               name: "transasction_number",
//             },
//             {
//               layout: {
//                 leaf: {
//                   key: "0x00000000",
//                   ty: 1,
//                 },
//               },
//               name: "psp22_token",
//             },
//             {
//               layout: {
//                 leaf: {
//                   key: "0x00000000",
//                   ty: 4,
//                 },
//               },
//               name: "fee",
//             },
//             {
//               layout: {
//                 leaf: {
//                   key: "0x00000000",
//                   ty: 4,
//                 },
//               },
//               name: "total_supply",
//             },
//             {
//               layout: {
//                 root: {
//                   layout: {
//                     leaf: {
//                       key: "0x754f6d2e",
//                       ty: 4,
//                     },
//                   },
//                   root_key: "0x754f6d2e",
//                 },
//               },
//               name: "balances",
//             },
//             {
//               layout: {
//                 leaf: {
//                   key: "0x00000000",
//                   ty: 1,
//                 },
//               },
//               name: "panx_contract",
//             },
//             {
//               layout: {
//                 root: {
//                   layout: {
//                     leaf: {
//                       key: "0xf13aa691",
//                       ty: 4,
//                     },
//                   },
//                   root_key: "0xf13aa691",
//                 },
//               },
//               name: "lp_tokens_allowances",
//             },
//             {
//               layout: {
//                 leaf: {
//                   key: "0x00000000",
//                   ty: 1,
//                 },
//               },
//               name: "vault",
//             },
//             {
//               layout: {
//                 leaf: {
//                   key: "0x00000000",
//                   ty: 4,
//                 },
//               },
//               name: "traders_fee",
//             },
//             {
//               layout: {
//                 leaf: {
//                   key: "0x00000000",
//                   ty: 4,
//                 },
//               },
//               name: "psp22_lp_fee_vault",
//             },
//             {
//               layout: {
//                 leaf: {
//                   key: "0x00000000",
//                   ty: 4,
//                 },
//               },
//               name: "azero_lp_fee_vault",
//             },
//             {
//               layout: {
//                 leaf: {
//                   key: "0x00000000",
//                   ty: 4,
//                 },
//               },
//               name: "contract_overall_generated_psp22_fee",
//             },
//             {
//               layout: {
//                 leaf: {
//                   key: "0x00000000",
//                   ty: 4,
//                 },
//               },
//               name: "contract_overall_generated_azero_fee",
//             },
//             {
//               layout: {
//                 root: {
//                   layout: {
//                     leaf: {
//                       key: "0xcfae497d",
//                       ty: 4,
//                     },
//                   },
//                   root_key: "0xcfae497d",
//                 },
//               },
//               name: "psp22_to_give_in_a_day",
//             },
//             {
//               layout: {
//                 root: {
//                   layout: {
//                     leaf: {
//                       key: "0x39dd79c3",
//                       ty: 4,
//                     },
//                   },
//                   root_key: "0x39dd79c3",
//                 },
//               },
//               name: "account_overall_staking_rewards",
//             },
//             {
//               layout: {
//                 root: {
//                   layout: {
//                     struct: {
//                       fields: [
//                         {
//                           layout: {
//                             leaf: {
//                               key: "0x5a958657",
//                               ty: 4,
//                             },
//                           },
//                           name: "0",
//                         },
//                         {
//                           layout: {
//                             leaf: {
//                               key: "0x5a958657",
//                               ty: 4,
//                             },
//                           },
//                           name: "1",
//                         },
//                       ],
//                       name: "(A, B)",
//                     },
//                   },
//                   root_key: "0x5a958657",
//                 },
//               },
//               name: "account_overall_lp_fee_rewards",
//             },
//             {
//               layout: {
//                 root: {
//                   layout: {
//                     leaf: {
//                       key: "0x64d569ad",
//                       ty: 5,
//                     },
//                   },
//                   root_key: "0x64d569ad",
//                 },
//               },
//               name: "last_redeemed",
//             },
//             {
//               layout: {
//                 leaf: {
//                   key: "0x00000000",
//                   ty: 4,
//                 },
//               },
//               name: "staking_percentage",
//             },
//           ],
//           name: "TradingPairAzero",
//         },
//       },
//       root_key: "0x00000000",
//     },
//   },
//   types: [
//     {
//       id: 0,
//       type: {
//         def: {
//           primitive: "i64",
//         },
//       },
//     },
//     {
//       id: 1,
//       type: {
//         def: {
//           composite: {
//             fields: [
//               {
//                 type: 2,
//                 typeName: "[u8; 32]",
//               },
//             ],
//           },
//         },
//         path: ["ink_primitives", "types", "AccountId"],
//       },
//     },
//     {
//       id: 2,
//       type: {
//         def: {
//           array: {
//             len: 32,
//             type: 3,
//           },
//         },
//       },
//     },
//     {
//       id: 3,
//       type: {
//         def: {
//           primitive: "u8",
//         },
//       },
//     },
//     {
//       id: 4,
//       type: {
//         def: {
//           primitive: "u128",
//         },
//       },
//     },
//     {
//       id: 5,
//       type: {
//         def: {
//           primitive: "u64",
//         },
//       },
//     },
//     {
//       id: 6,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 7,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 8,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 7,
//           },
//           {
//             name: "E",
//             type: 8,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 7,
//       type: {
//         def: {
//           tuple: [],
//         },
//       },
//     },
//     {
//       id: 8,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 index: 1,
//                 name: "CouldNotReadInput",
//               },
//             ],
//           },
//         },
//         path: ["ink_primitives", "LangError"],
//       },
//     },
//     {
//       id: 9,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 10,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 8,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 10,
//           },
//           {
//             name: "E",
//             type: 8,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 10,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 7,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 11,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 7,
//           },
//           {
//             name: "E",
//             type: 11,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 11,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 index: 0,
//                 name: "CallerInsufficientPSP22Balance",
//               },
//               {
//                 index: 1,
//                 name: "CallerInsufficientAZEROBalance",
//               },
//               {
//                 index: 2,
//                 name: "NotEnoughAllowance",
//               },
//               {
//                 index: 3,
//                 name: "Overflow",
//               },
//               {
//                 index: 4,
//                 name: "ZeroSharesGiven",
//               },
//               {
//                 index: 5,
//                 name: "SlippageTolerance",
//               },
//               {
//                 index: 6,
//                 name: "PSP22TransferFromFailed",
//               },
//               {
//                 index: 7,
//                 name: "PSP22TransferFailed",
//               },
//               {
//                 index: 8,
//                 name: "A0TransferFailed",
//               },
//               {
//                 index: 9,
//                 name: "CallerInsufficientLPBalance",
//               },
//               {
//                 index: 10,
//                 name: "ContractOutOfA0",
//               },
//               {
//                 index: 11,
//                 name: "ContractOutOfPSP22",
//               },
//               {
//                 index: 12,
//                 name: "NotEnoughOwnerLPAllowance",
//               },
//               {
//                 index: 13,
//                 name: "ZeroDaysPassed",
//               },
//               {
//                 index: 14,
//                 name: "ZeroDailyPSP22",
//               },
//               {
//                 index: 15,
//                 name: "ZeroDailyA0",
//               },
//               {
//                 index: 16,
//                 name: "UpdateIncentiveProgramError",
//               },
//               {
//                 index: 17,
//                 name: "RemoveLpIncentiveProgramError",
//               },
//             ],
//           },
//         },
//         path: ["trading_pair_azero", "trading_pair_azero", "TradingPairErrors"],
//       },
//     },
//     {
//       id: 12,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 13,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 8,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 13,
//           },
//           {
//             name: "E",
//             type: 8,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 13,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 14,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 11,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 14,
//           },
//           {
//             name: "E",
//             type: 11,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 14,
//       type: {
//         def: {
//           tuple: [4, 4],
//         },
//       },
//     },
//     {
//       id: 15,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 16,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 8,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 16,
//           },
//           {
//             name: "E",
//             type: 8,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 16,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 4,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 11,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 4,
//           },
//           {
//             name: "E",
//             type: 11,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 17,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 4,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 8,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 4,
//           },
//           {
//             name: "E",
//             type: 8,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 18,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 14,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 8,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 14,
//           },
//           {
//             name: "E",
//             type: 8,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 19,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 1,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 8,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 1,
//           },
//           {
//             name: "E",
//             type: 8,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 20,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 0,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 8,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 0,
//           },
//           {
//             name: "E",
//             type: 8,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 21,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 5,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 8,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 5,
//           },
//           {
//             name: "E",
//             type: 8,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//   ],
//   version: "4",
// };
