import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Wrapper from "../Helpers/Wrapper";

const TouButton = () => {
  return (
    <Wrapper>
      {/* Terms of use */}
      <Typography sx={{ textAlign: "left", fontSize: "12px", marginTop: "20px" }}>
        By confirming the transaction you agree to our{" "}
        <Link to="/terms-of-use" target={"_blank"} style={{ color: "white" }}>
          Terms of use
        </Link>
        .
      </Typography>
    </Wrapper>
  );
};

export default TouButton;
