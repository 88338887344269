import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { insert_psp22_balance, insert_azero_balance } from "../../features/account";
import { set_refresh, set_swap_from_input, set_swap_to_input } from "../../features/inputs";

import { web3FromSource } from "@polkadot/extension-dapp";
import { ContractPromise } from "@polkadot/api-contract";
import { getGasLimit, getMaxGasLimit } from "@scio-labs/use-inkathon";

import { useAuth } from "../../hooks/useAuth";
import { getTokenPairByAddresses, insertPairPrice, insertTxn } from "../../shared/api";
import { getPriceForOnePsp22 } from "../../Utils/functions/contractsFunctions";
import { abiPsp22Token } from "../../Utils/abi/abiPsp22Token";
import { abiTradingPairAzero } from "../../Utils/abi/abiTradingPairAzero";

import moment from "moment";

import { Button, Typography } from "@mui/material";

import Wrapper from "../Helpers/Wrapper";
import ApprovalButton from "./ApprovalButton";
import ApprovalModal from "../Modals/ApprovalModal";
import SwapModal from "../Modals/SwapModal";
import { getRatio } from "../../Utils/constants";
import Cookies from "universal-cookie";
import { ratioMultiplier } from "../../Utils/functions/globalFunctions";
import useJwtExpiration from "../../hooks/useJwtExpiration";
import { errorHandler } from "../../Utils/functions/scErrorHandler";
import { useLastInjector } from "../../hooks/useLastInjector";

const SwapButton = ({ variant, pairFee }) => {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const { isAuthenticated } = useAuth();
  const { lastInjector } = useLastInjector();
  // const [jwtToken, setJwtToken] = useState(cookies.get("accessToken") && cookies.get("accessToken"));
  // const isJwtExpired = useJwtExpiration(jwtToken);

  const { tokens } = useSelector((state) => state);
  const { network_token } = useSelector((state) => state.tokens);
  const { from_token } = useSelector((state) => state.tokens);
  const { to_token } = useSelector((state) => state.tokens);
  const { account } = useSelector((state) => state);
  const { azero_balance } = useSelector((state) => state.account);
  const { psp22_balance } = useSelector((state) => state.account);
  const { address } = useSelector((state) => state.account); //get logged user address.
  const { connectedNetwork } = useSelector((state) => state.account);
  const { api } = useSelector((state) => state.contracts);
  const { inputs } = useSelector((state) => state);
  const { priceImpact } = useSelector((state) => state.inputs);
  const { swap_to_input } = useSelector((state) => state.inputs);
  const { swap_from_input } = useSelector((state) => state.inputs);

  const [currentPair, setCurrentPair] = useState({}); // current pair obj

  const [disabled, setDisabled] = useState(false);
  const [buttonMsg, setButtonMsg] = useState(`Swap Tokens`);

  const [loader, setLoader] = useState(false);

  const [msgBoolean, setMsgBoolean] = useState(true);
  const [txnFailed, setTxnFailed] = useState(false);
  const [openSwap, setOpenSwap] = useState(false);
  const handleSwapOpen = () => setOpenSwap(true);
  const handleSwapClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenSwap(false);
  };

  const [txnMsg, setTxnMsg] = useState("");
  const [approveBtnText, setApproveBtnText] = useState(`Approve`);
  const [needToApprove, setNeedToApprove] = useState(false); // need to approve boolean
  const [msgApprovalBoolean, setApprovalMsgBoolean] = useState(true); // show approval msg
  const [aprvFailed, setAprvFailed] = useState(false); // approval failed
  const [cancelled, setCancelled] = useState(false); // txn canceled

  const [openApprove, setOpenApprove] = useState(false);
  const handleApproveOpen = () => setOpenApprove(true);
  const handleApproveClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenApprove(false);
  };
  const [inputToApprove, setInputToApprove] = useState(); // input value to approve
  const [tokenToApprove, setTokenToApprove] = useState(); // token to approve
  const [psp22TokenAddress, setPsp22TokenAddress] = useState(); // psp22 token address

  const [allowanceGas, setAllowanceGas] = useState(); // allowance gas for approving

  const CurrentDate = moment().format();

  const getPair = async () => {
    let pair = await getTokenPairByAddresses(from_token?.address, to_token?.address, connectedNetwork);
    setCurrentPair(pair.data.data[0]);
  };

  useEffect(() => {
    getPair();
  }, [from_token, to_token]);

  // Allowance function which show if user needs to approve tokens or not
  const allowance = async () => {
    let gasLimit;
    let contract;

    if (api[0]) {
      gasLimit = getMaxGasLimit(api[0]);
      contract = new ContractPromise(api[0], abiPsp22Token, from_token?.address);
    }

    let currentPairAddress = currentPair?.pair_address;
    from_token && setTokenToApprove(from_token);

    setInputToApprove(swap_from_input);

    try {
      await contract?.query["psp22::allowance"](address[0], { gasLimit }, address[0], currentPairAddress).then(
        (result) => {
          let allowanceValue = result.output.toHuman().Ok.replace(/,/g, "") / getRatio(connectedNetwork);
          setAllowanceGas(result.gasRequired);
          if (allowanceValue === 0 || swap_from_input > allowanceValue) {
            setPsp22TokenAddress(from_token?.address);
            if (Number(swap_from_input) > psp22_balance) {
              setDisabled(true);
              setApproveBtnText(`Insufficient ${from_token?.symbol} balance`);
            } else {
              setApproveBtnText(`Approve ${from_token.symbol}`);
            }
            setNeedToApprove(true);
          } else if (swap_from_input >= allowanceValue >= 0) {
            setNeedToApprove(false);
          }
        }
      );
    } catch (err) {}
  };

  // set approval msg to in progress and open modal
  const approvalInProgress = () => {
    handleApproveOpen();
  };

  // set approval msg to completed and close modal
  const approvalCompleted = () => {
    setApprovalMsgBoolean(false);
    setNeedToApprove(false);
    setTimeout(() => {
      handleApproveClose();
      setApprovalMsgBoolean(true);
    }, 1500);
  };

  // set approval msg to Failed and close modal
  const approvalFailed = (msg) => {
    let word = "Rejected";
    if (msg.includes(word)) {
      setCancelled(true);
    }
    setApprovalMsgBoolean(false);
    setNeedToApprove(true);
    setAprvFailed(true);
    setTimeout(() => {
      setApprovalMsgBoolean(true);
      handleApproveClose();
      setCancelled(false);
      setAprvFailed(false);
    }, 2000);
  };

  // set swap msg to in progress and open modal
  const txnInProgress = () => {
    handleSwapOpen();
  };

  // set swap msg to completed and close modal
  const txnCompleted = (balance, from_input, token_address) => {
    dispatch(set_refresh(true));
    dispatch(set_swap_from_input(""));
    dispatch(set_swap_to_input(""));
    let newBalance = balance - Number(from_input);
    token_address.toLowerCase() !== network_token?.address?.toLowerCase()
      ? dispatch(insert_psp22_balance(newBalance))
      : dispatch(insert_azero_balance(newBalance));
    setMsgBoolean(false);
    setTimeout(() => {
      handleSwapClose();
      setLoader(false);
      setMsgBoolean(true);
    }, 1500);
  };

  // set swap msg to completed and close modal
  const txnFailedFn = (cancelled) => {
    if (cancelled === "Cancelled") {
      setCancelled(true);
    }
    setMsgBoolean(false);
    setTxnFailed(true);
    dispatch(insert_psp22_balance());
    dispatch(insert_azero_balance());
    setTimeout(() => {
      handleSwapClose();
      setLoader(false);
      setMsgBoolean(true);
      setCancelled(false);
      setTxnFailed(false);
    }, 5000);
  };

  // A function to swap PANX to AZERO
  const swapPsp22ToAzero = async () => {
    setLoader(true);

    let errorModule;
    let gas = 0;
    let gasLimit;
    let contract;

    let currentPairAddress = currentPair.pair_address;
    let slippage = ratioMultiplier(account?.slippage, connectedNetwork);
    let input = ratioMultiplier(swap_from_input, connectedNetwork);
    let output = ratioMultiplier(swap_to_input, connectedNetwork);

    if (api[0]) {
      gasLimit = getMaxGasLimit(api[0]);
      contract = api[0] && new ContractPromise(api[0], abiTradingPairAzero, currentPairAddress);
    }

    let price = await getPriceForOnePsp22(contract, address[0], gasLimit, connectedNetwork);
    let pairPriceObj = {
      pair_address: currentPair.pair_address,
      token_a_address: from_token.address,
      token_b_address: to_token.address,
      pair_price: null,
      price_insert_date: CurrentDate,
    };

    //transaction transaction obj
    let txn = {
      acc_address: address[0],
      pair_address: currentPair.pair_address,
      from_token_address: from_token.address,
      from_token_symbol: from_token.symbol,
      from_token_amount: swap_from_input,
      to_token_address: to_token.address,
      to_token_symbol: to_token.symbol,
      to_token_amount: swap_to_input,
      price: price,
    };

    try {
      if (isAuthenticated) {
        await contract?.query["swapPsp22"](address[0], { gasLimit }, input, output, slippage).then((res) => {
          errorModule = res.output.toHuman().Ok;
          if (errorModule === "Ok") {
            gas = res.gasRequired;
          } else {
            setTxnMsg(<Typography sx={{ fontSize: "12px" }}>{errorHandler(errorModule.Err)}</Typography>);
            txnFailedFn();
          }
        });
        // return;
        errorModule === "Ok" &&
          (await contract?.tx["swapPsp22"]({ gasLimit: gas }, input, output, slippage).signAndSend(
            address[0],
            { signer: lastInjector.signer },
            ({ events = [], status }) => {
              if (status.isInBlock || status.isFinalized) {
                events
                  // find/filter for failed events
                  .filter(({ event }) => api[0].events.system.ExtrinsicFailed.is(event))
                  // we know that data for system.ExtrinsicFailed is
                  // (DispatchError, DispatchInfo)
                  .forEach(
                    ({
                      event: {
                        data: [error, info],
                      },
                    }) => {
                      if (error.isModule) {
                        // for module errors, we have the section indexed, lookup
                        const decoded = api[0].registry.findMetaError(error.asModule);
                        const { docs, method, section } = decoded;
                        if (method === "ContractTrapped") {
                          setTxnMsg(
                            <Typography sx={{ fontSize: "12px" }}>Please readjust your slippage details. </Typography>
                          );
                        } else {
                          setTxnMsg(<Typography sx={{ fontSize: "12px" }}>{docs.join("")}</Typography>);
                        }
                        txnFailedFn();
                      } else {
                        // Other, CannotLookup, BadOrigin, no extra info
                        // console.log(error.toString());
                      }
                    }
                  );
              }

              if (status.isInBlock) {
                txnInProgress();
              } else if (status.isFinalized) {
                // Loop through Vec<EventRecord> to display all events
                events.forEach(async ({ phase, event: { data, method, section } }) => {
                  if (method === "ExtrinsicSuccess") {
                    let price = await getPriceForOnePsp22(contract, address[0], gas, connectedNetwork);
                    pairPriceObj.pair_price = price;
                    insertPairPrice(pairPriceObj, cookies.get("accessToken"), connectedNetwork);
                    insertTxn(txn, cookies.get("accessToken"), connectedNetwork);
                    txnCompleted(psp22_balance, inputs?.swap_from_input, from_token?.address);
                  } else if (method === "ExtrinsicFailed") {
                    txnFailedFn();
                  }
                });
              }
            }
          ));
      } else {
        setTxnMsg(
          <Typography sx={{ fontSize: "12px" }}>
            You must be authenticated to the system.
            <br /> Please try to logout and login to your wallet again.
          </Typography>
        );
        txnFailedFn();
      }
    } catch (err) {
      if (err.message === "Cancelled") {
        txnFailedFn(err.message);
      } else {
        setTxnMsg(<Typography sx={{ fontSize: "12px" }}>{err.message}</Typography>);
        txnFailedFn();
      }
    }
  };

  // A function to swap AZERO to PSP22
  const swapAzeroToPsp22 = async () => {
    setLoader(true);

    let errorModule;
    let gasLimit;
    let contract;
    let currentPairAddress = currentPair.pair_address;
    let gas = 0;

    let slippage = ratioMultiplier(account?.slippage, connectedNetwork);
    let value = ratioMultiplier(swap_from_input, connectedNetwork);
    let output = ratioMultiplier(swap_to_input, connectedNetwork);

    if (api[0]) {
      gasLimit = getMaxGasLimit(api[0]);
      contract = api[0] && new ContractPromise(api[0], abiTradingPairAzero, currentPairAddress);
    }
    let price = await getPriceForOnePsp22(contract, address[0], gasLimit, connectedNetwork);

    let pairPriceObj = {
      pair_address: currentPair.pair_address,
      token_a_address: from_token.address,
      token_b_address: to_token.address,
      pair_price: null,
      price_insert_date: CurrentDate,
    };

    //transaction transaction obj
    let txn = {
      acc_address: address[0],
      pair_address: currentPair.pair_address,
      from_token_address: from_token.address,
      from_token_symbol: from_token.symbol,
      from_token_amount: swap_from_input,
      to_token_address: to_token.address,
      to_token_symbol: to_token.symbol,
      to_token_amount: swap_to_input,
      price: price,
    };

    try {
      if (isAuthenticated) {
        await contract?.query["swapA0"](address[0], { value, gasLimit }, output, slippage).then(async (res) => {
          errorModule = res.output.toHuman().Ok;
          if (errorModule === "Ok") {
            gas = res.gasRequired;
          } else {
            setTxnMsg(<Typography sx={{ fontSize: "12px" }}>{errorHandler(errorModule.Err)}</Typography>);
            txnFailedFn();
          }
        });

        errorModule === "Ok" &&
          (await contract?.tx["swapA0"]({ value, gasLimit: gas }, output, slippage).signAndSend(
            address[0],
            { signer: lastInjector.signer },
            ({ events = [], status }) => {
              if (status.isInBlock || status.isFinalized) {
                events
                  // find/filter for failed events
                  .filter(({ event }) => api[0].events.system.ExtrinsicFailed.is(event))
                  // we know that data for system.ExtrinsicFailed is
                  // (DispatchError, DispatchInfo)
                  .forEach(
                    ({
                      event: {
                        data: [error, info],
                      },
                    }) => {
                      if (error.isModule) {
                        // for module errors, we have the section indexed, lookup
                        const decoded = api[0].registry.findMetaError(error.asModule);
                        const { docs, method, section } = decoded;
                        if (method === "ContractTrapped") {
                          setTxnMsg(
                            <Typography sx={{ fontSize: "12px" }}>Please readjust your slippage settings. </Typography>
                          );
                        } else {
                          setTxnMsg(<Typography sx={{ fontSize: "12px" }}>{docs.join("")}</Typography>);
                        }
                        txnFailedFn();
                      } else {
                        // Other, CannotLookup, BadOrigin, no extra info
                        // console.log(error.toString());
                      }
                    }
                  );
              }

              if (status.isInBlock) {
                txnInProgress();
              } else if (status.isFinalized) {
                // Loop through Vec<EventRecord> to display all events
                events.forEach(async ({ phase, event: { data, method, section } }) => {
                  if (method === "ExtrinsicSuccess") {
                    let price = await getPriceForOnePsp22(contract, address[0], gas, connectedNetwork);
                    pairPriceObj.pair_price = price;
                    insertPairPrice(pairPriceObj, cookies.get("accessToken"), connectedNetwork);
                    insertTxn(txn, cookies.get("accessToken"), connectedNetwork);
                    txnCompleted(azero_balance, inputs?.swap_from_input, from_token?.name);
                  } else if (method === "ExtrinsicFailed") {
                    txnFailedFn();
                  }
                });
              }
            }
          ));
      } else {
        setTxnMsg(
          <Typography sx={{ fontSize: "12px" }}>
            You must be authenticated to the system.
            <br /> Please try to logout and login to your wallet again.
          </Typography>
        );
        txnFailedFn();
      }
    } catch (err) {
      if (err.message === "Cancelled") {
        txnFailedFn(err.message);
      } else {
        setTxnMsg(<Typography sx={{ fontSize: "12px" }}>{err.message}</Typography>);
        txnFailedFn(err);
      }
    }
  };

  // The main function of swapping tokens right now.
  const swapTokens = () => {
    if (swap_from_input > 0 && swap_to_input > 0) {
      // console.log("from_token?.name.toLowerCase():", from_token?.name.toLowerCase());
      from_token?.address.toLowerCase() === network_token.address.toLowerCase()
        ? swapAzeroToPsp22()
        : swapPsp22ToAzero();
    }
  };

  //  a function to disabled or able the swap button
  const disableButton = () => {
    if (swap_from_input === null || isNaN(swap_from_input) || swap_from_input == 0) {
      setDisabled(true);
    } else if (swap_from_input > 0) {
      setDisabled(false);
      setButtonMsg(`Swap Tokens`);
      // setApproveBtnText(`Approve`);
      if (priceImpact > 70) {
        setDisabled(true);
        return;
      } else if (
        from_token?.address.toLowerCase() !== network_token?.address?.toLowerCase() &&
        parseFloat(swap_from_input) > account.psp22_balance
      ) {
        setDisabled(true);
        setButtonMsg(`Insufficient ${from_token?.symbol.toUpperCase()} balance`);
      } else if (
        from_token?.address.toLowerCase() === network_token?.address?.toLowerCase() &&
        parseFloat(swap_from_input) > account.azero_balance
      ) {
        setDisabled(true);
        setButtonMsg(`Insufficient ${from_token?.symbol.toUpperCase()} balance`);
      }
    }
  };

  useEffect(() => {
    if (from_token?.address?.toLowerCase() === network_token?.address?.toLowerCase()) {
      setNeedToApprove(false);
    }
  }, [tokens]);

  useEffect(() => {
    disableButton();
    if (from_token?.address?.toLowerCase() !== network_token?.address?.toLowerCase()) {
      allowance();
    } else {
      setNeedToApprove(false);
    }
  }, [swap_from_input, account, priceImpact]);

  return (
    <Wrapper>
      {needToApprove === true ? (
        <ApprovalButton
          variant="swap_btn"
          psp22TokenAddress={psp22TokenAddress}
          allowanceGas={allowanceGas}
          approvalInProgress={approvalInProgress}
          approvalCompleted={approvalCompleted}
          approvalFailed={approvalFailed}
          handleApproveOpen={handleApproveOpen}
          inputToApprove={inputToApprove}
          disabled={disabled}
          approveBtnText={approveBtnText}
          currentAddress={currentPair?.pair_address}
        />
      ) : (
        <Wrapper>
          {priceImpact > 70 ? (
            <Button variant={variant ? variant : "contained"} disabled={"true"} onClick={handleSwapOpen}>
              {buttonMsg}
            </Button>
          ) : (
            <Button variant={variant ? variant : "contained"} disabled={disabled} onClick={handleSwapOpen}>
              {buttonMsg}
            </Button>
          )}
        </Wrapper>
      )}

      {/* Approval Modal */}
      <ApprovalModal
        openApprove={openApprove}
        handleApproveClose={handleApproveClose}
        msgApprovalBoolean={msgApprovalBoolean}
        tokenToApprove={tokenToApprove}
        aprvFailed={aprvFailed}
        cancelled={cancelled}
      />
      {/* Swap Modal */}
      <SwapModal
        swapTokens={swapTokens}
        openSwap={openSwap}
        handleSwapClose={handleSwapClose}
        msgBoolean={msgBoolean}
        priceImpact={priceImpact}
        pairFee={pairFee}
        loader={loader}
        txnFailed={txnFailed}
        cancelled={cancelled}
        txnMsg={txnMsg}
      />
    </Wrapper>
  );
};

export default SwapButton;

// console.log("current status is" + status);
//           if (status.isInBlock || status.isFinalized) {
//   events
//     // find/filter for failed events
//     .filter(({ event }) => api[0].events.system.ExtrinsicFailed.is(event))
//     // we know that data for system.ExtrinsicFailed is
//     // (DispatchError, DispatchInfo)
//     .forEach(
//       ({
//         event: {
//           data: [error, info],
//         },
//       }) => {
//         if (error.isModule) {
//           // for module errors, we have the section indexed, lookup
//           const decoded = api[0].registry.findMetaError(error.asModule);
//           const { docs, method, section } = decoded;
//           console.log(`${section}.${method}: ${docs.join(" ")}`);
//         } else {
//           // Other, CannotLookup, BadOrigin, no extra info
//           console.log(error.toString());
//         }
//       }
//     );
// }
