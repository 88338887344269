// **** PAIR CREATOR ABI **** //
// **** lOCAL TESTING **** //
export const abiPairCreator = {
  source: {
    hash: "0x7646008905e5ce7779af06b6cd74a3cc1c4336362a2d511e600d9700a42d5db2",
    language: "ink! 4.3.0",
    compiler: "rustc 1.68.0-nightly",
    build_info: {
      build_mode: "Release",
      cargo_contract_version: "2.0.0",
      rust_toolchain: "nightly-aarch64-apple-darwin",
      wasm_opt_settings: {
        keep_debug_symbols: false,
        optimization_passes: "Z",
      },
    },
  },
  contract: {
    name: "contract_creator",
    version: "1.1.0",
    authors: ["Yonatan Hornstein <yonatan@panoramaswap.com>"],
  },
  spec: {
    constructors: [
      {
        args: [],
        default: false,
        docs: [],
        label: "new",
        payable: false,
        returnType: {
          displayName: ["ink_primitives", "ConstructorResult"],
          type: 0,
        },
        selector: "0x9bae9d5e",
      },
    ],
    docs: [],
    environment: {
      accountId: {
        displayName: ["AccountId"],
        type: 7,
      },
      balance: {
        displayName: ["Balance"],
        type: 8,
      },
      blockNumber: {
        displayName: ["BlockNumber"],
        type: 6,
      },
      chainExtension: {
        displayName: ["ChainExtension"],
        type: 13,
      },
      hash: {
        displayName: ["Hash"],
        type: 3,
      },
      maxEventTopics: 4,
      timestamp: {
        displayName: ["Timestamp"],
        type: 9,
      },
    },
    events: [
      {
        args: [
          {
            docs: [],
            indexed: false,
            label: "caller",
            type: {
              displayName: ["AccountId"],
              type: 7,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "psp22_address",
            type: {
              displayName: ["AccountId"],
              type: 7,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "lp_fee",
            type: {
              displayName: ["Balance"],
              type: 8,
            },
          },
        ],
        docs: [],
        label: "NewTPA",
      },
      {
        args: [
          {
            docs: [],
            indexed: false,
            label: "caller",
            type: {
              displayName: ["AccountId"],
              type: 7,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "psp22_1_address",
            type: {
              displayName: ["AccountId"],
              type: 7,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "psp22_2_address",
            type: {
              displayName: ["AccountId"],
              type: 7,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "lp_fee",
            type: {
              displayName: ["Balance"],
              type: 8,
            },
          },
        ],
        docs: [],
        label: "NewTPP",
      },
    ],
    lang_error: {
      displayName: ["ink", "LangError"],
      type: 2,
    },
    messages: [
      {
        args: [
          {
            label: "azero_trading_pair_hash",
            type: {
              displayName: ["Hash"],
              type: 3,
            },
          },
          {
            label: "version",
            type: {
              displayName: ["u32"],
              type: 6,
            },
          },
          {
            label: "psp22_addrr",
            type: {
              displayName: ["AccountId"],
              type: 7,
            },
          },
          {
            label: "fee",
            type: {
              displayName: ["Balance"],
              type: 8,
            },
          },
          {
            label: "panx_contract",
            type: {
              displayName: ["AccountId"],
              type: 7,
            },
          },
          {
            label: "vault_address",
            type: {
              displayName: ["AccountId"],
              type: 7,
            },
          },
          {
            label: "lp_lock_timestamp",
            type: {
              displayName: ["u64"],
              type: 9,
            },
          },
        ],
        default: false,
        docs: [],
        label: "create_azero_trading_pair",
        mutates: true,
        payable: true,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 10,
        },
        selector: "0xcf7179bf",
      },
    ],
  },
  storage: {
    root: {
      layout: {
        struct: {
          fields: [],
          name: "PairCreator",
        },
      },
      root_key: "0x00000000",
    },
  },
  types: [
    {
      id: 0,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 1,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 2,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 1,
          },
          {
            name: "E",
            type: 2,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 1,
      type: {
        def: {
          tuple: [],
        },
      },
    },
    {
      id: 2,
      type: {
        def: {
          variant: {
            variants: [
              {
                index: 1,
                name: "CouldNotReadInput",
              },
            ],
          },
        },
        path: ["ink_primitives", "LangError"],
      },
    },
    {
      id: 3,
      type: {
        def: {
          composite: {
            fields: [
              {
                type: 4,
                typeName: "[u8; 32]",
              },
            ],
          },
        },
        path: ["ink_primitives", "types", "Hash"],
      },
    },
    {
      id: 4,
      type: {
        def: {
          array: {
            len: 32,
            type: 5,
          },
        },
      },
    },
    {
      id: 5,
      type: {
        def: {
          primitive: "u8",
        },
      },
    },
    {
      id: 6,
      type: {
        def: {
          primitive: "u32",
        },
      },
    },
    {
      id: 7,
      type: {
        def: {
          composite: {
            fields: [
              {
                type: 4,
                typeName: "[u8; 32]",
              },
            ],
          },
        },
        path: ["ink_primitives", "types", "AccountId"],
      },
    },
    {
      id: 8,
      type: {
        def: {
          primitive: "u128",
        },
      },
    },
    {
      id: 9,
      type: {
        def: {
          primitive: "u64",
        },
      },
    },
    {
      id: 10,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 11,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 2,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 11,
          },
          {
            name: "E",
            type: 2,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 11,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 7,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 12,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 7,
          },
          {
            name: "E",
            type: 12,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 12,
      type: {
        def: {
          variant: {
            variants: [
              {
                index: 0,
                name: "InstantiatingFailed",
              },
            ],
          },
        },
        path: ["contract_creator", "pair_creator", "PairCreatorErrors"],
      },
    },
    {
      id: 13,
      type: {
        def: {
          variant: {},
        },
        path: ["ink_env", "types", "NoChainExtension"],
      },
    },
  ],
  version: "4",
};

// **** PRODUCTION **** //
// export const abiPairCreator = {
//   source: {
//     hash: "0x9a2dfffc46990e8403060c2fc517ac6362d50e6439b5505a6515fa83a891f4e9",
//     language: "ink! 4.0.0",
//     compiler: "rustc 1.65.0",
//     build_info: {
//       build_mode: "Debug",
//       cargo_contract_version: "2.0.0-beta",
//       rust_toolchain: "stable-aarch64-apple-darwin",
//       wasm_opt_settings: {
//         keep_debug_symbols: false,
//         optimization_passes: "Z",
//       },
//     },
//   },
//   contract: {
//     name: "contract_creator",
//     version: "1.1.0",
//     authors: ["Yonatan Hornstein <yonatan@panoramaswap.com>"],
//   },
//   spec: {
//     constructors: [
//       {
//         args: [],
//         docs: [],
//         label: "new",
//         payable: false,
//         returnType: {
//           displayName: ["ink_primitives", "ConstructorResult"],
//           type: 0,
//         },
//         selector: "0x9bae9d5e",
//       },
//     ],
//     docs: [],
//     events: [
//       {
//         args: [
//           {
//             docs: [],
//             indexed: false,
//             label: "caller",
//             type: {
//               displayName: ["AccountId"],
//               type: 7,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "psp22_address",
//             type: {
//               displayName: ["AccountId"],
//               type: 7,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "lp_fee",
//             type: {
//               displayName: ["Balance"],
//               type: 8,
//             },
//           },
//         ],
//         docs: [],
//         label: "NewTPA",
//       },
//       {
//         args: [
//           {
//             docs: [],
//             indexed: false,
//             label: "caller",
//             type: {
//               displayName: ["AccountId"],
//               type: 7,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "psp22_1_address",
//             type: {
//               displayName: ["AccountId"],
//               type: 7,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "psp22_2_address",
//             type: {
//               displayName: ["AccountId"],
//               type: 7,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "lp_fee",
//             type: {
//               displayName: ["Balance"],
//               type: 8,
//             },
//           },
//         ],
//         docs: [],
//         label: "NewTPP",
//       },
//     ],
//     lang_error: {
//       displayName: ["ink", "LangError"],
//       type: 2,
//     },
//     messages: [
//       {
//         args: [
//           {
//             label: "azero_trading_pair_hash",
//             type: {
//               displayName: ["Hash"],
//               type: 3,
//             },
//           },
//           {
//             label: "version",
//             type: {
//               displayName: ["u32"],
//               type: 6,
//             },
//           },
//           {
//             label: "psp22_addrr",
//             type: {
//               displayName: ["AccountId"],
//               type: 7,
//             },
//           },
//           {
//             label: "fee",
//             type: {
//               displayName: ["Balance"],
//               type: 8,
//             },
//           },
//           {
//             label: "panx_contract",
//             type: {
//               displayName: ["AccountId"],
//               type: 7,
//             },
//           },
//           {
//             label: "vault_address",
//             type: {
//               displayName: ["AccountId"],
//               type: 7,
//             },
//           },
//         ],
//         docs: [],
//         label: "create_azero_trading_pair",
//         mutates: true,
//         payable: true,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 9,
//         },
//         selector: "0xcf7179bf",
//       },
//       {
//         args: [
//           {
//             label: "psp22_trading_pair_hash",
//             type: {
//               displayName: ["Hash"],
//               type: 3,
//             },
//           },
//           {
//             label: "version",
//             type: {
//               displayName: ["u32"],
//               type: 6,
//             },
//           },
//           {
//             label: "psp22_token1_addrr",
//             type: {
//               displayName: ["AccountId"],
//               type: 7,
//             },
//           },
//           {
//             label: "psp22_token2_addrr",
//             type: {
//               displayName: ["AccountId"],
//               type: 7,
//             },
//           },
//           {
//             label: "fee",
//             type: {
//               displayName: ["Balance"],
//               type: 8,
//             },
//           },
//           {
//             label: "panx_contract",
//             type: {
//               displayName: ["AccountId"],
//               type: 7,
//             },
//           },
//           {
//             label: "vault_address",
//             type: {
//               displayName: ["AccountId"],
//               type: 7,
//             },
//           },
//         ],
//         docs: [],
//         label: "create_psp22_trading_pair",
//         mutates: true,
//         payable: true,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 9,
//         },
//         selector: "0x0a81709c",
//       },
//       {
//         args: [
//           {
//             label: "multi_sig_hash",
//             type: {
//               displayName: ["Hash"],
//               type: 3,
//             },
//           },
//           {
//             label: "version",
//             type: {
//               displayName: ["u32"],
//               type: 6,
//             },
//           },
//           {
//             label: "vault_address",
//             type: {
//               displayName: ["AccountId"],
//               type: 7,
//             },
//           },
//         ],
//         docs: [],
//         label: "create_multi_sig_wallet",
//         mutates: true,
//         payable: true,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 9,
//         },
//         selector: "0x1a9e2c62",
//       },
//     ],
//   },
//   storage: {
//     root: {
//       layout: {
//         struct: {
//           fields: [],
//           name: "PairCreator",
//         },
//       },
//       root_key: "0x00000000",
//     },
//   },
//   types: [
//     {
//       id: 0,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 1,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 2,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 1,
//           },
//           {
//             name: "E",
//             type: 2,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 1,
//       type: {
//         def: {
//           tuple: [],
//         },
//       },
//     },
//     {
//       id: 2,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 index: 1,
//                 name: "CouldNotReadInput",
//               },
//             ],
//           },
//         },
//         path: ["ink_primitives", "LangError"],
//       },
//     },
//     {
//       id: 3,
//       type: {
//         def: {
//           composite: {
//             fields: [
//               {
//                 type: 4,
//                 typeName: "[u8; 32]",
//               },
//             ],
//           },
//         },
//         path: ["ink_primitives", "types", "Hash"],
//       },
//     },
//     {
//       id: 4,
//       type: {
//         def: {
//           array: {
//             len: 32,
//             type: 5,
//           },
//         },
//       },
//     },
//     {
//       id: 5,
//       type: {
//         def: {
//           primitive: "u8",
//         },
//       },
//     },
//     {
//       id: 6,
//       type: {
//         def: {
//           primitive: "u32",
//         },
//       },
//     },
//     {
//       id: 7,
//       type: {
//         def: {
//           composite: {
//             fields: [
//               {
//                 type: 4,
//                 typeName: "[u8; 32]",
//               },
//             ],
//           },
//         },
//         path: ["ink_primitives", "types", "AccountId"],
//       },
//     },
//     {
//       id: 8,
//       type: {
//         def: {
//           primitive: "u128",
//         },
//       },
//     },
//     {
//       id: 9,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 10,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 2,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 10,
//           },
//           {
//             name: "E",
//             type: 2,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 10,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 7,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 11,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 7,
//           },
//           {
//             name: "E",
//             type: 11,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 11,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 index: 0,
//                 name: "InstantiatingFailed",
//               },
//             ],
//           },
//         },
//         path: ["contract_creator", "pair_creator", "PairCreatorErrors"],
//       },
//     },
//   ],
//   version: "4",
// };
