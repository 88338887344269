import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { checkIfNumIsExp, customToFixed, fixedNumber, numberWithCommas } from "../../Utils/functions/globalFunctions";
import {
  getTokenPairByAddress,
  getAzeroVolumeAndTxns,
  getPanxVolumeAndTxns,
  getVolumePerToken,
} from "../../shared/api";

import Wrapper from "../Helpers/Wrapper";

import { Box, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead } from "@mui/material";
import { StyledTableRow, TopStyledTableRow } from "../../Style/Tables/SpecPoolTable";
import { CellBoxValue, StyledTableCell } from "../../Style/Tables/AllPoolsTable";
import useResize from "../../hooks/useResize";
import ReserveBalance from "../Helpers/ReserveBalance";
import { ContractPromise } from "@polkadot/api-contract";
import { abiTradingPairAzero } from "../../Utils/abi/abiTradingPairAzero";
import { getPoolTotalSupply } from "../../Utils/functions/contractsFunctions";
import { Contract } from "@polkadot/api-contract/base";
import fromExponential from "from-exponential";
import { getMaxGasLimit } from "@scio-labs/use-inkathon";

const SpecPoolTableBody = ({ currentContract, currentPair, completed }) => {
  const resize = useResize();

  const { connectedNetwork } = useSelector((state) => state.account);
  const { network_token } = useSelector((state) => state.tokens);
  const { tokens } = useSelector((state) => state.tokens);
  const { address } = useSelector((state) => state.account);
  const { api } = useSelector((state) => state.contracts);
  const { swap_completed } = useSelector((state) => state.inputs);

  const [poolTotalSupply, setPoolTotalSupply] = useState();

  const [volumeTokenA, setVolumeTokenA] = useState();
  const [volumeTokenB, setVolumeTokenB] = useState();

  const [psp22Fees, setPsp22Fees] = useState();
  const [psp22Apy, setPsp22Apy] = useState();
  const [azeroFees, setAzeroFees] = useState();
  const [azeroApy, setAzeroApy] = useState();

  const getData = async () => {
    await getTokenAVolume(currentPair?.token_a_address);
    await getTokenBVolume(currentPair?.token_b_address);
  };

  useEffect(() => {
    getData();

    const dataInterval = setInterval(() => {
      getData();
    }, 15000);

    return () => {
      clearInterval(dataInterval);
    };
  }, [currentPair]);

  const getTokenAVolume = async (address) => {
    let tokenADataDaily = await getVolumePerToken(address, 1, currentPair?.pair_address);
    tokenADataDaily = tokenADataDaily?.data?.data?.total_amount;
    setVolumeTokenA(tokenADataDaily > 1 ? numberWithCommas(customToFixed(tokenADataDaily, 0)) : tokenADataDaily);
    calculateFeesApy(tokenADataDaily, address);
    return tokenADataDaily;
  };

  const getTokenBVolume = async (address) => {
    let tokenBDataDaily = await getVolumePerToken(address, 1, currentPair?.pair_address);
    tokenBDataDaily = tokenBDataDaily?.data?.data?.total_amount;
    setVolumeTokenB(tokenBDataDaily > 1 ? numberWithCommas(customToFixed(tokenBDataDaily, 0)) : tokenBDataDaily);
    calculateFeesApy(tokenBDataDaily, address);
    return tokenBDataDaily;
  };

  const calculateFeesApy = (volume, address) => {
    if (address === network_token?.address?.toLowerCase()) {
      let fee = volume * 0.03;
      setAzeroFees(customToFixed(fee, 4));
      let apy = (fee * 0.03 * 365) / 100;
      setAzeroApy(customToFixed(apy, 4));
    } else {
      let fee = volume * 0.03;
      setPsp22Fees(customToFixed(fee, 4));
      let apy = (fee * 0.03 * 365) / 100;
      setPsp22Apy(customToFixed(apy, 4));
    }
  };

  // const getTokensName = (address) => {
  //   let token = tokens.filter((t) => t.address === address);
  //   return token[0]?.name;
  // };

  const getTokenAImage = (address_a) => {
    let img1 = tokens?.filter((t) => t.address === address_a);
    img1 = img1[0]?.icon;
    return (
      <Wrapper>
        <img src={img1} style={{ width: "16px", borderRadius: "50px" }} />
      </Wrapper>
    );
  };

  const getTokenBImage = (address_b) => {
    let img1 = tokens?.filter((t) => t.address === address_b);
    img1 = img1[0]?.icon;
    return (
      <Wrapper>
        <img src={img1} style={{ width: "15px", borderRadius: "50px" }} />
      </Wrapper>
    );
  };

  // A function to get total Pool Supply to calculate user shares with %
  const getPoolTotalSupp = async () => {
    const gasLimit = getMaxGasLimit(api[0]);
    let { total_supply: totalSupply } = await getPoolTotalSupply(
      currentContract,
      address[0],
      gasLimit,
      connectedNetwork
    );
    setPoolTotalSupply(totalSupply);
  };

  useEffect(() => {
    api[0] && getPoolTotalSupp();
  }, [currentContract, address, api, completed, swap_completed]);

  return (
    <Wrapper>
      <TableBody>
        <StyledTableRow>
          <StyledTableCell>
            {address[0] ? (
              <Box>
                {poolTotalSupply ? (
                  <Box>
                    {checkIfNumIsExp(poolTotalSupply)
                      ? fromExponential(poolTotalSupply)
                      : numberWithCommas(
                          poolTotalSupply > 0.0001 ? customToFixed(poolTotalSupply, 4) : poolTotalSupply
                        )}
                  </Box>
                ) : (
                  <Skeleton animation="wave" sx={{ width: "100px" }} />
                )}
              </Box>
            ) : (
              <Skeleton animation="wave" sx={{ width: "100px" }} />
            )}
          </StyledTableCell>

          <StyledTableCell>
            {address[0] ? (
              <Box>
                <CellBoxValue>
                  {resize > 768 && getTokenAImage(currentPair?.token_a_address)}
                  <ReserveBalance
                    tokenAddress={currentPair?.token_a_address}
                    pairAddress={currentPair?.pair_address}
                    volumeTokenA={volumeTokenA}
                  />{" "}
                </CellBoxValue>
                <CellBoxValue>
                  {resize > 768 && getTokenBImage(currentPair?.token_b_address)}
                  <ReserveBalance
                    tokenAddress={currentPair?.token_b_address}
                    pairAddress={currentPair?.pair_address}
                    volumeTokenB={volumeTokenB}
                  />{" "}
                </CellBoxValue>
              </Box>
            ) : (
              // <Box sx={{ display: "flex", alignItems: "center" }}>{numberWithCommas(currentPair.liquidity)}$</Box>
              <Skeleton animation="wave" sx={{ width: "100px" }} />
            )}
          </StyledTableCell>

          <StyledTableCell>
            {address[0] ? (
              <Box>
                <CellBoxValue>
                  {resize > 768 && getTokenAImage(currentPair?.token_a_address)}
                  {volumeTokenA ? numberWithCommas(volumeTokenA) : "0.000"}
                </CellBoxValue>
                <CellBoxValue>
                  {resize > 768 && getTokenBImage(currentPair?.token_b_address)}
                  {volumeTokenB ? numberWithCommas(volumeTokenB) : "0.000"}
                </CellBoxValue>
              </Box>
            ) : (
              // <Box sx={{ display: "flex", alignItems: "center" }}>{numberWithCommas(currentPair.volume)}$</Box>
              <Skeleton animation="wave" sx={{ width: "100px" }} />
            )}
          </StyledTableCell>

          {resize > 768 && (
            <StyledTableCell>
              {address[0] ? (
                <Box>
                  <CellBoxValue>
                    {resize > 768 && getTokenAImage(currentPair?.token_a_address)}
                    {currentPair?.token_a_address !== network_token?.address?.toLowerCase()
                      ? numberWithCommas(psp22Fees)
                      : numberWithCommas(azeroFees)}{" "}
                  </CellBoxValue>
                  <CellBoxValue>
                    {resize > 768 && getTokenBImage(currentPair?.token_b_address)}
                    {currentPair?.token_b_address !== network_token?.address?.toLowerCase()
                      ? numberWithCommas(psp22Fees)
                      : numberWithCommas(azeroFees)}{" "}
                  </CellBoxValue>
                  {/* {numberWithCommas(currentPair.fees)}$ */}
                </Box>
              ) : (
                <Skeleton animation="wave" sx={{ width: "100px" }} />
              )}
            </StyledTableCell>
          )}
          <StyledTableCell>
            {address[0] ? (
              <Box>
                <CellBoxValue>
                  {resize > 768 && getTokenAImage(currentPair?.token_a_address)}
                  {currentPair?.token_a_address !== network_token?.address?.toLowerCase()
                    ? numberWithCommas(psp22Apy)
                    : numberWithCommas(azeroApy)}
                  %{" "}
                </CellBoxValue>
                <CellBoxValue>
                  {resize > 768 && getTokenBImage(currentPair?.token_b_address)}
                  {currentPair?.token_b_address !== network_token?.address?.toLowerCase()
                    ? numberWithCommas(psp22Apy)
                    : numberWithCommas(azeroApy)}
                  %{" "}
                </CellBoxValue>
              </Box>
            ) : (
              // <Box sx={{ display: "flex", alignItems: "center" }}>{numberWithCommas(currentPair.apy)}%</Box>
              <Skeleton animation="wave" sx={{ width: "100px" }} />
            )}
          </StyledTableCell>
        </StyledTableRow>
      </TableBody>
    </Wrapper>
  );
};

export default SpecPoolTableBody;
