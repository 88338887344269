import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import Wrapper from "../Helpers/Wrapper";

import { Modal, Box, Typography, Paper, Button, Tooltip, CircularProgress, Card } from "@mui/material";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { numberWithCommas } from "../../Utils/functions/globalFunctions";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  textAlign: "center",
  width: "100%",
  maxWidth: "400px",
  height: "auto",
  color: "white",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
  "@media(max-width:768px)": {
    width: "90%",
  },
};

const WithdrawFromPoolModal = ({
  openWithdrawFromModal,
  closeWithdrawFromPool,
  msgBoolean,
  loader,
  withdrawFailed,
  withdrawTokensFromPool,
  withdrawnPsp22,
  withdrawnAzero,
  cancelled,
  tokenA,
  tokenB,
  txnMsg,
}) => {
  const { pool_withdraw_input } = useSelector((state) => state.inputs); // get user pool tokens amount from account state
  const { tokens } = useSelector((state) => state.tokens);
  const { network_token } = useSelector((state) => state.tokens);

  const handleAddToPool = async () => {
    withdrawTokensFromPool();
  };

  useEffect(() => {}, [pool_withdraw_input]);

  return (
    <Wrapper>
      <Modal open={openWithdrawFromModal} onClose={closeWithdrawFromPool}>
        {/* Swap dashboard paper */}

        <Paper elevation={5} sx={style}>
          {!loader ? (
            <Box>
              <CloseIcon
                id="testClose"
                onClick={closeWithdrawFromPool}
                sx={{ cursor: "pointer", position: "absolute", right: "10px", top: "10px" }}
              />
              <Typography sx={{ textAlign: "left", marginBottom: "10px" }}>You Will Receive</Typography>
              <Card className="swap_input" sx={{ padding: "20px" }}>
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Typography>
                      {tokenA?.address.toLowerCase() !== network_token?.address?.toLowerCase()
                        ? numberWithCommas(withdrawnPsp22)
                        : numberWithCommas(withdrawnAzero)}
                    </Typography>
                    <Typography sx={{ display: "flex", alignItems: "center", textTransform: "uppercase" }}>
                      <img
                        src={tokenA?.icon}
                        alt=""
                        style={{ width: "20px", borderRadius: "50%", marginRight: "5px" }}
                      />{" "}
                      {tokenA?.symbol}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                    <Typography>
                      {tokenB?.address.toLowerCase() !== network_token?.address?.toLowerCase()
                        ? numberWithCommas(withdrawnPsp22)
                        : numberWithCommas(withdrawnAzero)}
                    </Typography>
                    <Typography sx={{ display: "flex", alignItems: "center", textTransform: "uppercase" }}>
                      <img
                        src={tokenB?.icon}
                        alt=""
                        style={{ width: "18px", borderRadius: "50%", marginRight: "5px" }}
                      />
                      {tokenB?.symbol}
                    </Typography>
                  </Box>
                </Box>
              </Card>
              {/* Expected Output */}
              <Typography sx={{ display: "flex", fontSize: "12px", marginTop: "10px", opacity: "0.6" }}>
                <i> Output is estimated.</i>{" "}
              </Typography>
              <Button variant="swap_btn" onClick={handleAddToPool}>
                Confirm
              </Button>{" "}
            </Box>
          ) : (
            <Box
              sx={{
                minWidth: "300px",
                minHeight: "150px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {msgBoolean ? (
                <Box>
                  <CircularProgress color="inherit" />
                  <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Withdrawal in progress...</Typography>
                </Box>
              ) : (
                <Box>
                  {!withdrawFailed ? (
                    <Box>
                      <CheckCircleOutlineIcon fontSize="large" />
                      <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Tokens withdrawn!</Typography>
                    </Box>
                  ) : (
                    <Box>
                      <HighlightOffIcon fontSize="large" sx={{ color: "#e84142" }} />
                      {cancelled ? (
                        <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Withdrawal Cancelled!</Typography>
                      ) : (
                        <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Withdrawal Failed!</Typography>
                      )}
                      {cancelled ? (
                        <></>
                      ) : (
                        <Typography sx={{ fontSize: "14px", opacity: "0.5" }} component="p">
                          {txnMsg}{" "}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Paper>
      </Modal>
    </Wrapper>
  );
};

export default WithdrawFromPoolModal;
