import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { set_pool_bottom_input } from "../../features/inputs";

import Wrapper from "../Helpers/Wrapper";
import { StyledTextField } from "../../Style/general";
import { Box, Button, Typography } from "@mui/material";
import { BoxInputTitle, InputBoxBottom, InputBoxTop, InputBoxWrapper } from "../../Style/Components/inputs";
import {
  checkIfNumIsExp,
  customToFixed,
  getLimitedInput,
  preventLetters,
  ratioMultiplier,
} from "../../Utils/functions/globalFunctions";
import TokenBalance from "../Helpers/TokenBalance";
import {
  getBottomInputAmount,
  getNetworkTokenDepositAmountForLp,
  getPoolTotalSupply,
  getPsp22DepositAmountForLp,
} from "../../Utils/functions/contractsFunctions";
import fromExponential from "from-exponential";
import { getRatio } from "../../Utils/constants";

const PoolBottomInput = ({ token, contract, gas }) => {
  const dispatch = useDispatch();

  const { connectedNetwork } = useSelector((state) => state.account);
  const { network_token } = useSelector((state) => state.tokens);
  const { account } = useSelector((state) => state); // get account state from redux
  const { address } = useSelector((state) => state.account); // get account state from redux
  const { pool_top_input } = useSelector((state) => state.inputs); //get inputs state from redux

  const [inputValue, setInputValue] = useState(); // input value state from input handler
  const [bottomInputValue, setBottomInputValue] = useState(); // input value from calculations

  const [psp22Reserve, setPsp22Reserve] = useState();
  const [networkTokenReserve, setNetworkTokenReserve] = useState();

  const getPsp22Balance = async () => {
    let res = await contract?.query["getPsp22Balance"](address[0], { gasLimit: gas });
    let psp22Balance = res?.output?.toHuman()?.Ok?.replace(/,/g, "") / getRatio(connectedNetwork);
    setPsp22Reserve(psp22Balance);
  };

  const getAzeroBalance = async () => {
    let res = await contract?.query["getA0Balance"](address[0], { gasLimit: gas });
    let azeroBalance = res?.output?.toHuman()?.Ok?.replace(/,/g, "") / getRatio(connectedNetwork);
    setNetworkTokenReserve(azeroBalance);
  };

  const getBottomInputValue = async () => {
    let { total_supply } = await getPoolTotalSupply(contract, address[0], gas, connectedNetwork);
    if (total_supply > 0) {
      if (pool_top_input > 0) {
        let amountToAdd = await getBottomInputAmount(
          contract,
          networkTokenReserve,
          psp22Reserve,
          address[0],
          gas,
          connectedNetwork,
          token.name,
          pool_top_input
        );

        if (checkIfNumIsExp(amountToAdd)) {
          amountToAdd = fromExponential(Number(amountToAdd));
        }
        if (!getLimitedInput(Number(amountToAdd))) {
          amountToAdd = customToFixed(amountToAdd, 12);
        }
        setBottomInputValue(
          checkIfNumIsExp(Number(amountToAdd)) ? fromExponential(Number(amountToAdd)) : Number(amountToAdd)
        );
        dispatch(
          set_pool_bottom_input(
            checkIfNumIsExp(Number(amountToAdd)) ? fromExponential(Number(amountToAdd)) : Number(amountToAdd)
          )
        );
      } else {
        setBottomInputValue("");
        dispatch(set_pool_bottom_input(""));
      }
    } else {
      // let amountToAdd = await getBottomInputAmount(
      //   contract,
      //   azeroReserve,
      //   psp22Reserve,
      //   address[0],
      //   gas,
      //   connectedNetwork,
      //   network_token?.address,
      //   pool_top_input
      // );
    }
  };

  useEffect(() => {
    getPsp22Balance();
    getAzeroBalance();
    getBottomInputValue();
  }, [pool_top_input, account, contract]);

  useEffect(() => {
    const toInputInterval = setInterval(() => {
      getBottomInputValue();
    }, 15000);

    return () => {
      clearInterval(toInputInterval);
    };
  }, []);

  const handleValue = (e) => {
    if (e.target) {
      if (getLimitedInput(e.target.value)) {
        setInputValue(e.target.value);
        dispatch(set_pool_bottom_input(e.target.value));
      } else {
        setInputValue();
        dispatch(set_pool_bottom_input());
      }
    }
  };

  return (
    <Wrapper>
      {/* Bottom input for choosing what user will get */}
      <InputBoxWrapper>
        <InputBoxTop>
          <BoxInputTitle fontSize="12px">input</BoxInputTitle>
          <Box sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
            <Typography sx={{ marginRight: "5px", fontSize: "14px" }}>Balance:</Typography>
            <TokenBalance tokenAddress={token?.address} token={token} />
          </Box>
        </InputBoxTop>

        <InputBoxBottom>
          <StyledTextField
            value={bottomInputValue && bottomInputValue}
            onChange={(e) => handleValue(e)}
            onKeyDown={(evt) => preventLetters(evt)}
            placeholder="0.00"
            type="number"
          />
          <Button variant="choose_token_btn" disabled>
            <img src={token?.icon} alt="" />
            <span>{token?.symbol}</span>
          </Button>
        </InputBoxBottom>
      </InputBoxWrapper>
    </Wrapper>
  );
};

export default PoolBottomInput;
