import { ContractPromise } from "@polkadot/api-contract";
import { getMaxGasLimit } from "@scio-labs/use-inkathon";
import { abiPsp22Token } from "../abi/abiPsp22Token";
import { getRatio } from "../constants";
import fromExponential from "from-exponential";
import { ratioMultiplier } from "./globalFunctions";
import { getTokenPairByAddresses } from "../../shared/api";
import { abiTradingPairAzero } from "../abi/abiTradingPairAzero";

export const makeDummyTransfer = async (value, api, address, bool, network) => {
  let newValue;
  try {
    if (network === "azero") {
      const info = await api.tx.balances
        .transfer("5DaYseV9GSrGKrJYmKU5yymF9izPM2ZzG8f93xQK6hectHuo", BigInt(parseInt(value)))
        .paymentInfo(address);
      newValue = !bool
        ? value - info.partialFee / getRatio(network) - 0.4
        : value - info.partialFee / getRatio(network) - 0.004;
    } else {
      newValue = value - 0.004;
    }
    return newValue;
  } catch (err) {}
};

export const getPriceForOnePsp22 = async (contract, acc_address, gasLimit, network) => {
  let value = 0;
  let price = 0;
  try {
    await contract?.query["getPriceForOnePsp22"](acc_address, { value, gasLimit }).then((res) => {
      res = res?.output?.toHuman().Ok.Ok.replace(/,/g, "");
      price = res / getRatio(network);
    });
  } catch (err) {}
  return fromExponential(price);
};

// A function to get total Pool Supply to calculate user shares with %
export const getPoolTotalSupply = async (contract, acc_address, gasLimit, network) => {
  let totalSupply = {
    total_supply: null,
    gas: null,
  };
  let value = 0;
  try {
    await contract?.query["getTotalSupply"](acc_address, { value, gasLimit }).then((res) => {
      let number = res?.output?.toHuman().Ok.replace(/,/g, "");
      totalSupply.total_supply = number / getRatio(network);
      totalSupply.gas = res.gasRequired;
    });
  } catch (err) {}
  return totalSupply;
};

// Get psp22  token balance
export const getPsp22TokenBalance = async (api, tokenAddress, address, network_token, network) => {
  let gasLimit;
  let pspBalance;

  if (api[0]) {
    gasLimit = getMaxGasLimit(api[0]);
  }

  try {
    if (tokenAddress !== network_token?.address?.toLowerCase()) {
      const psp22_contract = api[0] && new ContractPromise(api[0], abiPsp22Token, tokenAddress);
      let value = 0;
      if (psp22_contract) {
        await psp22_contract?.query["psp22::balanceOf"](address[0], { value, gasLimit }, address[0]).then((res) => {
          res = res?.output?.toHuman()?.Ok?.replace(/,/g, "");
          pspBalance = res / getRatio(network);
        });
        return pspBalance;
      }
    }
  } catch (err) {}
};

// get AZERO token balance
export const getAzeroBalance = async (api, address, network) => {
  let azeroBalance;
  try {
    if (address[0]) {
      let data = await api[0]?.query?.system.account(address[0]);
      azeroBalance = data?.data?.free / getRatio(network);
    }
    return isNaN(azeroBalance) ? "0.0000" : azeroBalance;
  } catch (err) {}
};

//********   NEW   *********/
//  A function which calculate the bottom input amount on specific pool page.
export const getBottomInputAmount = async (
  contract,
  networkTokenReserve,
  psp22Reserve,
  address,
  gas,
  connectedNetwork,
  token_name,
  pool_top_input
) => {
  let value = 0;
  let bottomInputValue;

  let t_name = token_name.toLowerCase();
  let net_name = connectedNetwork.toLowerCase();
  if (t_name.includes(net_name)) {
    try {
      await contract?.query["getA0AmountForLp"](
        address,
        { value, gasLimit: gas },
        ratioMultiplier(pool_top_input),
        ratioMultiplier(networkTokenReserve)
      ).then((res) => {
        res = res?.output?.toHuman()?.Ok?.replace(/,/g, "") / getRatio(connectedNetwork);
        bottomInputValue = res;
      });
    } catch (e) {}
  } else {
    try {
      await contract?.query["getPsp22AmountForLp"](
        address,
        { value, gasLimit: gas },
        ratioMultiplier(pool_top_input),
        ratioMultiplier(networkTokenReserve)
      ).then((res) => {
        res = res?.output?.toHuman()?.Ok?.replace(/,/g, "") / getRatio(connectedNetwork);
        bottomInputValue = res;
      });
    } catch (e) {
      // console.log(e);
    }
  }
  return bottomInputValue;
};

export const getExpectedLpTokens = async (
  tokenA,
  tokenB,
  connectedNetwork,
  api,
  network_token,
  tokenAValue,
  tokenBValue,
  address
) => {
  let expLpTokens;
  let gasLimit;
  let contract;
  let getCurrentPair = await getTokenPairByAddresses(tokenA?.address, tokenB?.address, connectedNetwork);
  let pairAddress = getCurrentPair.data.data[0]?.pair_address;
  if (api[0]) {
    gasLimit = getMaxGasLimit(api[0]);
    contract = new ContractPromise(api[0], abiTradingPairAzero, pairAddress);
  }

  let psp22Tokens =
    tokenA?.address?.toLowerCase() !== network_token?.address?.toLowerCase() ? tokenAValue : tokenBValue;
  let networkTokenAmount =
    tokenA?.address?.toLowerCase() === network_token?.address?.toLowerCase() ? tokenAValue : tokenBValue;

  psp22Tokens = psp22Tokens && BigInt(psp22Tokens * getRatio(connectedNetwork));

  if (psp22Tokens > 0) {
    networkTokenAmount = ratioMultiplier(networkTokenAmount, connectedNetwork);

    try {
      await contract?.query["getExpectedLpTokenAmount"](address[0], { gasLimit }, networkTokenAmount, psp22Tokens).then(
        (res) => {
          expLpTokens = res?.output?.toHuman().Ok.Ok.replace(/,/g, "") / getRatio(connectedNetwork);
        }
      );
    } catch (e) {}
  }
  return expLpTokens;
};
