export const errorHandler = (errorName) => {
  switch (errorName) {
    case "CallerInsufficientPSP22Balance":
      return "Caller has Insufficient PSP22 Balance to perform the transaction.";
    case "CallerInsufficientAZEROBalance":
      return "Caller has Insufficient AZERO Balance to perform the transaction.";
    case "NotEnoughAllowance":
      return "Trading pair doesn't have enough allowance to perform the transaction, kindly approve again.";
    case "Overflow":
      return "Overflow error, kindly redo the transaction.";
    case "ZeroSharesGiven":
      return "Zero LP shares given to the caller, kindly readjust your PSP22 and AZERO amount.";
    case "SlippageTolerance":
      return "Slippage tolerance encountered, kindly readjust your slippage settings.";
    case "PSP22TransferFromFailed":
      return "PSP22 transfer_from cross contract call failed.";
    case "PSP22TransferFailed":
      return "PSP22 transfer cross contract call failed.";
    case "A0TransferFailed":
      return "AZERO coin transfer failed.";
    case "CallerInsufficientLPBalance":
      return "Caller has Insufficient LP share tokens to perform the transaction.";
    case "ContractOutOfA0":
      return "Contract has no AZERO coins in the liquidity pool.";
    case "ContractOutOfPSP22":
      return "Contract has no PSP22 tokens in the liquidity pool.";
    case "NotEnoughOwnerLPAllowance":
      return "Trading pair doesn't have enough LP share tokens allowance to perform the transaction, kindly approve LP share tokens again.";
    case "CallerCollectedTGEAlready":
      return "Caller already collected the TGE tokens.";
    case "CallerInsufficientLockedTokens":
      return "Caller has insufficient locked tokens.";
    case "ZeroDaysPassed":
      return "Zero days passed since the last redeem, kindly wait 24 hours.";
    case "CallerRedeemedAirdrop":
      return "Caller already redeemed the airdrop.";
    case "InstantiatingFailed":
      return "Transaction failed during Instantiating.";
    case "LpStillLocked":
      return "LP tokens are locked until unlock date.";
    default:
      return "An unknown error has occurred.";
  }
};
