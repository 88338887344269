import Trade from "../Pages/Trade/Trade";
import Assets from "../Pages/Assets/Assets";
import Liquidity from "../Pages/Pool/Liquidity";
import LiquidityPairs from "../Pages/Pool/LiquidityPairs";
import CreatePool from "../Pages/Pool/CreatePool";
import TermsOfUse from "../Pages/TermsOfUse/TermsOfUse";

import WavesIcon from "@mui/icons-material/Waves";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import WorkIcon from "@mui/icons-material/Work";
import NotFound404 from "../Pages/404/NotFound404";
import WalletIcon from "@mui/icons-material/Wallet";
import TokensTransfer from "../Pages/Assets/TokensTransfer";
import { useSelector } from "react-redux";

const routes = [
  {
    path: "/",
    element: <Trade />,
    title: "Trade",
    navbar: true,
    icon: <ImportExportIcon />,
  },
  {
    path: "/liquidity",
    element: <Liquidity />,
    title: "Pool",
    navbar: true,
    icon: <WavesIcon />,
  },
  {
    path: "/liquidity/:name",
    element: <LiquidityPairs />,
  },
  {
    path: "/assets",
    element: <Assets />,
    title: "Assets",
    navbar: true,
    icon: <WorkIcon />,
  },
  {
    path: "/assets/transfer",
    element: <TokensTransfer />,
  },
  {
    path: "https://pay.c14.money/",
    title: "Buy Azero",
    newFeature: true,
    navbar: true,
    net: "azero",
    icon: <CurrencyExchangeIcon />,
  },
  // {
  //   path: "/multi-sig",
  //   // element: <Assets />,
  //   title: "Multi-Sig Wallet",
  //   soon: true,
  //   navbar: true,
  //   icon: <WalletIcon />,
  // },
  {
    path: "/create-pool",
    element: <CreatePool />,
    title: "Create Pool",
    navbar: false,
  },
  {
    path: "/terms-of-use",
    element: <TermsOfUse />,
    title: "Terms of Use",
    navbar: false,
  },
  {
    path: "*",
    element: <NotFound404 />,
    title: "Page Not Found",
    navbar: false,
  },
];

export default routes;
