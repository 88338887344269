import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch, connect } from "react-redux";
import { animateScroll as scroll } from "react-scroll";

import { insert_pool_tokens_balance, insert_psp22_balance } from "../../features/account";

import Wrapper from "../../Components/Helpers/Wrapper";

import { Typography, Box, Skeleton, TableContainer, Table, TableHead, Button, Tab, styled, Paper } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Page } from "../../Style/general";
import {
  AnalyticsDataBox,
  AnalyticsTopDataContainer,
  DetailsPaper,
  InputsPaper,
  TopDetailsBox,
  TopDetailsPaper,
  TopDetailsTokenNameBox,
} from "../../Style/Pages/LiquidityPairs";

import {
  AccPoolDetailsBox,
  DetailsBox,
  DetailsContainer,
  HeroBox,
  HeroContainer,
  HeroPaper,
  HeroTypography,
  TopStyledTableRow,
} from "../../Style/Tables/SpecPoolTable";

import PoolTabs from "../../Components/Tabs/PoolTabs";
import {
  checkIfNumIsExp,
  customToFixed,
  numberWithCommas,
  ratioMultiplier,
} from "../../Utils/functions/globalFunctions";
import useResize from "../../hooks/useResize";
import {
  get25TokenTxns,
  getLastPairPrice,
  getSumTxns,
  getTokenPairByAddress,
  getUserByAddress,
  getVolumePerToken,
} from "../../shared/api";
import { getPoolTotalSupply } from "../../Utils/functions/contractsFunctions";
import { abiTradingPairAzero } from "../../Utils/abi/abiTradingPairAzero";
import { ContractPromise } from "@polkadot/api-contract";
import { set_add_lp_complete } from "../../features/inputs";
import {
  GraphPaper,
  GraphVolumeBox,
  StatsContainer,
  TransactionPaper,
  TxnTablesTypography,
} from "../../Style/Pages/Stats";

import { StyledTableCell } from "../../Style/Tables/AllPoolsTable";
import TxnTable from "../../Components/Tables/TxnTable";
import SpecPoolTableBody from "../../Components/Tables/SpecPoolTableBody";
import PairPricesGraph from "../../Components/Graphs/PairPricesGraph";

import fromExponential from "from-exponential";
import { getMaxGasLimit } from "@scio-labs/use-inkathon";
import { getRatio } from "../../Utils/constants";
import { TabContext, TabList } from "@mui/lab";
import { colors } from "../../Style/themes";

import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { StyledTabPanel } from "../../Style/Components/tabs";
import { errorHandler } from "../../Utils/functions/scErrorHandler";
import { ClaimablePanxBtn } from "../../Style/Pages/Assets";
import { useAuth } from "../../hooks/useAuth";
import { useLastInjector } from "../../hooks/useLastInjector";
import ClaimTenModal from "../../Components/Modals/ClaimTenModal";
import moment from "moment";
import CountdownTimer from "../../Components/Helpers/CountdownTimer";

const addressStyle = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  width: "250px",
  whiteSpace: "nowrap",
  color: "#9f9f9f",
};

const ElegantButton = styled(Tab)(({ theme }) => ({
  background: "#282830",
  border: "1px solid #34343d",
  borderRadius: "10px 10px 0 0",
  padding: "10px 20px",
  fontSize: "16px",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    color: "#F0F0F0",
    cursor: "pointer",
  },
  "&.Mui-selected": {
    backgroundColor: "#33333b" /* your chosen color */,
  },
}));

const LiquidityPairs = () => {
  const navigate = useNavigate();
  const resize = useResize();
  const dispatch = useDispatch();

  const { name: pair_address } = useParams();
  const { isAuthenticated } = useAuth();
  const { lastInjector } = useLastInjector();

  const { network_token } = useSelector((state) => state.tokens);
  const { tokens } = useSelector((state) => state.tokens); // tokens list from redux
  const { api } = useSelector((state) => state.contracts);
  const { address } = useSelector((state) => state.account); // logged user address from redux
  const { connectedNetwork } = useSelector((state) => state.account);
  const { pool_withdraw_input } = useSelector((state) => state.inputs); // logged user address from redux

  const [userAnalyticsData, setUserAnalyticsData] = useState({
    pooledAzeroWithFee: { title: "Pooled Azero includes LP fees", data: 0.0 },
    pooledPsp22WithFee: { title: "Pooled Psp22 includes LP fees", data: 0.0 },
    azeroLpFeeTokens: { title: "Azero LP fee tokens", data: 0.0 },
    psp22LpFeeTokens: { title: "Psp22 LP fee tokens", data: 0.0 },
    azeroProfitPer: { title: "Azero profit in %", data: 0.0 },
    psp22ProfitPer: { title: "Psp22 profit in %", data: 0.0 },
  });

  const [allUsersAnalyticsData, setAllUsersAnalyticsData] = useState({
    claimableLpFees: {
      title: "Total Claimable LP fees",
      data: {
        psp22ClaimableLpFees: 0.0,
        azeroClaimableLpFees: 0.0,
      },
    },
    generatedLpFees: {
      title: "Total generated LP Fees",
      data: {
        psp22GeneratedLpFees: 0.0,
        azeroGeneratedLpFees: 0.0,
      },
    },
  });

  const [redeemableAmount, setRedeemableAmount] = useState({
    psp22: { amount: 0, icon: "" },
  });

  const [dailyEarnedAmount, setDailyEarnedAmount] = useState({
    psp22: { amount: 0, icon: "" },
  });

  const [userExist, setUserExist] = useState(false);
  const [currentPair, setCurrentPair] = useState({});
  const [currentLockDate, setCurrentLockDate] = useState();

  const [tokenA, setTokenA] = useState({});
  const [tokenAVolume, setTokenAVolume] = useState();
  const [tokenATxn, setTokenATxn] = useState();

  const [tokenB, setTokenB] = useState({});
  const [tokenBVolume, setTokenBVolume] = useState();
  const [tokenBTxn, setTokenBTxn] = useState();

  const [transactions, setTransactions] = useState([]);

  const [poolTotalSupply, setPoolTotalSupply] = useState();
  const [userPoolTokens, setUserPoolTokens] = useState();
  const [userPercentageShare, setUserPercentageShare] = useState();
  const [pooledTokenA, setPooledTokenA] = useState();
  const [pooledTokenB, setPooledTokenB] = useState();
  const [completed, setCompleted] = useState(false);

  const [gas, setGas] = useState(null);
  const [currentContract, setCurrentContract] = useState();
  const [tabValue, setTabValue] = useState("manage");

  const [errMsg, setErrMsg] = useState("");

  const [claimTxt, setClaimTxt] = useState("Claim");
  const [claimed, setClaimed] = useState(false);
  const [msgClaimBoolean, setClaimMsgBoolean] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [claimFailed, setClaimFailed] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [openClaim, setOpenClaim] = useState(false);

  const handleClaimOpen = () => setOpenClaim(true);
  const handleClaimClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenClaim(false);
  };

  // Handle tab value for changing tabs.
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // get token pair by pair_address
  const getTokenPair = async () => {
    let pair = await getTokenPairByAddress(pair_address, connectedNetwork);
    setCurrentPair(pair.data.data[0]);
  };

  // A function to get tokens name and image
  const getTokensNameAndImage = (address) => {
    let token = tokens.filter((t) => t.address === address);
    return (
      <TopDetailsTokenNameBox>
        <img alt="" src={token[0]?.icon} />{" "}
      </TopDetailsTokenNameBox>
    );
  };

  // determine and set tokenA and tokenB
  const setTokensAB = useCallback(() => {
    let token_a = tokens.filter((t) => t.address === currentPair?.token_a_address);
    let token_b = tokens.filter((t) => t.address === currentPair?.token_b_address);
    setTokenA(token_a[0]);
    setTokenB(token_b[0]);
  }, [currentPair, connectedNetwork]);

  useEffect(() => {
    setTokensAB();
  }, [currentPair, connectedNetwork]);

  // A function to get user Pool Token
  const getUserPoolData = async (address, contract, gas) => {
    let azeroTokensWithLpFee;
    let azeroLpFeeTokens;
    let azeroDiffPercentage;
    let claimableAzeroLpFees;
    let overallGenAzeroLpFees;

    let psp22TokensWithLpFee;
    let psp22LpFeeTokens;
    let psp22DiffPercentage;
    let claimablePsp22LpFees;
    let overallGenPsp22LpFees;

    let poolTokens;
    let balance;
    let value = 0;

    // get user pooled tokens amount
    try {
      await contract?.query["getLpTokenOf"](address, { value, gasLimit: gas }, address).then((res) => {
        poolTokens = res.output.toHuman().Ok.replace(/,/g, "") / getRatio(connectedNetwork);
        poolTokens = checkIfNumIsExp(poolTokens) ? fromExponential(poolTokens) : poolTokens;
        setUserPoolTokens(poolTokens);
        dispatch(insert_pool_tokens_balance(poolTokens));
      });
    } catch (err) {}

    // get user share of the pool by percentages
    try {
      await getPoolTotalSupply(contract, address, gas, connectedNetwork).then((res) => {
        let percentageShares = (poolTokens / res.total_supply) * 100;
        percentageShares = checkIfNumIsExp(percentageShares) ? fromExponential(percentageShares) : percentageShares;
        setUserPercentageShare(customToFixed(percentageShares, 4));
      });
    } catch (err) {}

    // Contract calls to get the amount of earned tokens every 24 hours and redeemable tokens.
    // getRedeemableAmount contract call
    try {
      await contract?.query["getPsp22RedeemableAmount"](address, { value, gasLimit: gas }).then((res) => {
        let errorModule = res.output.toHuman().Ok;
        if (errorModule.Ok) {
          let redeemablePsp22 = res.output.toHuman().Ok.Ok.replace(/,/g, "") / getRatio(connectedNetwork);
          setRedeemableAmount({
            psp22: {
              amount: redeemablePsp22,
              icon:
                tokenA?.address?.toLowerCase() !== network_token?.address?.toLowerCase() ? tokenA?.icon : tokenB?.icon,
            },
          });
        } else {
        }
      });
    } catch (err) {}

    // getAmountToGiveEachDayToCaller contract call
    try {
      await contract?.query["getAmountToGiveEachDayToCaller"](address, { value, gasLimit: gas }, address).then(
        (res) => {
          if (res.output.toHuman().Ok) {
            let amountToGivePsp22 = res.output.toHuman().Ok.replace(/,/g, "") / getRatio(connectedNetwork);
            setDailyEarnedAmount({
              psp22: {
                amount: amountToGivePsp22,
                icon:
                  tokenA.address.toLowerCase() !== network_token?.address?.toLowerCase() ? tokenA.icon : tokenB.icon,
              },
            });
          }
        }
      );
    } catch (err) {}

    balance = ratioMultiplier(poolTokens, connectedNetwork);
    // get Pooled tokens amount for each token.
    try {
      await contract?.query["getWithdrawTokensAmount"](address, { value, gasLimit: gas }, balance).then((res) => {
        if (res.output.toHuman().Ok.Ok) {
          let azero = res.output.toHuman().Ok.Ok[0].replace(/,/g, "") / getRatio(connectedNetwork);
          let psp22 = res.output.toHuman().Ok.Ok[1].replace(/,/g, "") / getRatio(connectedNetwork);
          psp22 = checkIfNumIsExp(psp22) ? customToFixed(psp22, 12) : psp22;
          azero = checkIfNumIsExp(azero) ? fromExponential(azero) : azero;
          if (tokenA && tokenB) {
            if (tokenA?.address?.toLowerCase() === network_token.address.toLowerCase()) {
              setPooledTokenA(azero);
              setPooledTokenB(psp22);
            } else if (tokenA?.address?.toLowerCase() !== network_token?.address?.toLowerCase()) {
              setPooledTokenA(psp22);
              setPooledTokenB(azero);
            }
          } else {
            setPooledTokenA("0.0000");
            setPooledTokenB("0.0000");
          }
        } else {
          setPooledTokenA("0.0000");
          setPooledTokenB("0.0000");
        }
      });
    } catch (err) {}

    // getWithdrawTokensAmountWithLp contract call
    try {
      await contract?.query["getWithdrawTokensAmountWithLp"](address, { value, gasLimit: gas }, balance).then((res) => {
        if (res.output.toHuman().Ok.Ok) {
          azeroTokensWithLpFee = res.output.toHuman().Ok.Ok[0].replace(/,/g, "") / getRatio(connectedNetwork);
          psp22TokensWithLpFee = res.output.toHuman().Ok.Ok[1].replace(/,/g, "") / getRatio(connectedNetwork);
        }
      });
      // await contract?.query["getPsp22WithdrawTokensAmount"](address, { value, gasLimit: gas }, balance).then((res) => {
      //   if (res.output.toHuman().Ok.Ok) {
      //     psp22TokensWithLpFee = res.output.toHuman().Ok.Ok.replace(/,/g, "") / ratio;
      //   }
      // });

      // await contract?.query["getA0WithdrawTokensAmount"](address, { value, gasLimit: gas }, balance).then((res) => {
      //   if (res.output.toHuman().Ok.Ok) {
      //     azeroTokensWithLpFee = res.output.toHuman().Ok.Ok.replace(/,/g, "") / ratio;
      //   }
      // });
    } catch (err) {}

    // getPsp22LpFeeTokens contract call
    try {
      await contract?.query["getPsp22LpFeeTokens"](address, { value, gasLimit: gas }, balance).then((res) => {
        if (res.output.toHuman().Ok.Ok) {
          psp22LpFeeTokens = res.output.toHuman().Ok.Ok.replace(/,/g, "") / getRatio(connectedNetwork);
        }
      });
    } catch (err) {}

    // getPsp22DifferenceByPercentage contract call
    try {
      await contract?.query["getPsp22DifferenceByPercentage"](address, { value, gasLimit: gas }).then((res) => {
        if (res.output.toHuman().Ok.Ok) {
          psp22DiffPercentage = res.output.toHuman().Ok.Ok.replace(/,/g, "") / getRatio(connectedNetwork);
        }
      });
    } catch (err) {}

    // getA0LpFeeTokens contract call
    try {
      await contract?.query["getA0LpFeeTokens"](address, { value, gasLimit: gas }, balance).then((res) => {
        if (res.output.toHuman().Ok.Ok) {
          azeroLpFeeTokens = res.output.toHuman().Ok.Ok.replace(/,/g, "") / getRatio(connectedNetwork);
        }
      });
    } catch (err) {}

    // getA0DifferenceByPercentage contract call
    try {
      await contract?.query["getA0DifferenceByPercentage"](address, { value, gasLimit: gas }).then((res) => {
        if (res.output.toHuman().Ok.Ok) {
          azeroDiffPercentage = res.output.toHuman().Ok.Ok.replace(/,/g, "") / getRatio(connectedNetwork);
        }
      });
    } catch (err) {}

    // get total claimable lp fee tokens contract call
    try {
      await contract?.query["getGeneratedLpFees"](address, { value, gasLimit: gas }).then((res) => {
        if (res.output.toHuman().Ok) {
          claimablePsp22LpFees = res.output.toHuman().Ok[0].replace(/,/g, "") / getRatio(connectedNetwork);
          claimableAzeroLpFees = res.output.toHuman().Ok[1].replace(/,/g, "") / getRatio(connectedNetwork);
        }
      });
    } catch (err) {}

    // getContractOverallGeneratedFee contract call
    try {
      await contract?.query["getContractOverallGeneratedFee"](address, { value, gasLimit: gas }).then((res) => {
        if (res.output.toHuman().Ok) {
          overallGenPsp22LpFees = res.output.toHuman().Ok[0].replace(/,/g, "") / getRatio(connectedNetwork);
          overallGenAzeroLpFees = res.output.toHuman().Ok[1].replace(/,/g, "") / getRatio(connectedNetwork);
        }
      });
    } catch (err) {}

    // Set logged user analytics state with all the new data and without changing the titles.
    setUserAnalyticsData({
      ...userAnalyticsData,
      azeroLpFeeTokens: {
        ...userAnalyticsData.azeroLpFeeTokens,
        data: checkIfNumIsExp(azeroLpFeeTokens) ? fromExponential(azeroLpFeeTokens) : azeroLpFeeTokens,
      },
      psp22LpFeeTokens: {
        title: userAnalyticsData.psp22LpFeeTokens.title.replace(
          "Psp22",
          tokenA?.address.toLowerCase() !== network_token?.address?.toLowerCase() ? tokenA?.symbol : tokenB?.symbol
        ),
        data: checkIfNumIsExp(psp22LpFeeTokens) ? fromExponential(psp22LpFeeTokens) : psp22LpFeeTokens,
      },
      pooledAzeroWithFee: {
        ...userAnalyticsData.pooledAzeroWithFee,
        data: checkIfNumIsExp(azeroTokensWithLpFee) ? fromExponential(azeroTokensWithLpFee) : azeroTokensWithLpFee,
      },
      pooledPsp22WithFee: {
        title: userAnalyticsData.pooledPsp22WithFee.title.replace(
          "Psp22",
          tokenA?.address.toLowerCase() !== network_token?.address?.toLowerCase() ? tokenA?.symbol : tokenB?.symbol
        ),
        data: checkIfNumIsExp(psp22TokensWithLpFee) ? fromExponential(psp22TokensWithLpFee) : psp22TokensWithLpFee,
      },
      azeroProfitPer: {
        ...userAnalyticsData.azeroProfitPer,
        data: checkIfNumIsExp(azeroDiffPercentage) ? fromExponential(azeroDiffPercentage) : azeroDiffPercentage,
      },
      psp22ProfitPer: {
        title: userAnalyticsData.psp22ProfitPer.title.replace(
          "Psp22",
          tokenA?.address.toLowerCase() !== network_token?.address?.toLowerCase() ? tokenA?.symbol : tokenB?.symbol
        ),
        data: checkIfNumIsExp(psp22DiffPercentage) ? fromExponential(psp22DiffPercentage) : psp22DiffPercentage,
      },
    });

    // Set all user analytics state with all the new data and without changing the titles.
    setAllUsersAnalyticsData({
      ...allUsersAnalyticsData,

      claimableLpFees: {
        ...allUsersAnalyticsData.claimableLpFees,
        data: {
          azeroClaimableLpFees: checkIfNumIsExp(claimableAzeroLpFees)
            ? fromExponential(claimableAzeroLpFees)
            : claimableAzeroLpFees,
          psp22ClaimableLpFees: checkIfNumIsExp(claimablePsp22LpFees)
            ? fromExponential(claimablePsp22LpFees)
            : claimablePsp22LpFees,
        },
      },
      generatedLpFees: {
        ...allUsersAnalyticsData.generatedLpFees,

        data: {
          azeroGeneratedLpFees: checkIfNumIsExp(overallGenAzeroLpFees)
            ? fromExponential(overallGenAzeroLpFees)
            : overallGenAzeroLpFees,
          psp22GeneratedLpFees: checkIfNumIsExp(overallGenPsp22LpFees)
            ? fromExponential(overallGenPsp22LpFees)
            : overallGenPsp22LpFees,
        },
      },
    });
  };

  // A function to set Transaction as completed and init useEffect function to update user Pool Tokens
  const setTxnCompleted = () => {
    dispatch(set_add_lp_complete(!completed));
    setCompleted(!completed);
  };

  useEffect(() => {
    if (currentContract && gas) {
      getUserPoolData(address[0], currentContract, gas);
    }
  }, [gas, currentContract, tokenA, tokenB]);

  const getDataFormat = (format_data) => {
    return typeof format_data.data.data === "number" ? format_data.data.data : format_data.data.data.total_amount;
  };

  const getTokensData = async (token_a, token_b) => {
    let tokens_arr = [token_a, token_b];

    for (let i = 0; i < tokens_arr.length; i++) {
      let total = await tokens_arr[i]?.request(`${tokens_arr[i].token_address}`, 0, `${tokens_arr[i].pair_address}`);
      let daily = await tokens_arr[i]?.request(`${tokens_arr[i].token_address}`, 1, `${tokens_arr[i].pair_address}`);

      let data_total = getDataFormat(total);
      let data_daily = getDataFormat(daily);

      const token_data = {
        total: data_total,
        daily: data_daily,
      };

      tokens_arr[i].stateUpdater(token_data);
    }
  };

  // A function to get the last 25 transactions of panx & azero
  const Psp22Azero25Transactions = async () => {
    let transactions = await get25TokenTxns(pair_address, connectedNetwork);
    setTransactions(transactions.data.data);
  };

  const getBothTokensData = () => {
    //get tokens amount data
    getTokensData(
      {
        token_address: tokenA?.address,
        pair_address,
        request: getSumTxns,
        stateUpdater: setTokenATxn,
      },
      {
        token_address: tokenB?.address,
        pair_address,
        request: getSumTxns,
        stateUpdater: setTokenBTxn,
      }
    );
    //get tokens volume data
    getTokensData(
      {
        token_address: tokenA?.address,
        pair_address,
        request: getVolumePerToken,
        stateUpdater: setTokenAVolume,
      },
      {
        token_address: tokenB?.address,
        pair_address,
        request: getVolumePerToken,
        stateUpdater: setTokenBVolume,
      }
    );
  };

  const handleCopyClick = () => {
    navigator.clipboard.writeText(pair_address);
  };

  const getTokenImgForCard = (key) => {
    let bool = key?.toLowerCase().includes("azero");
    if (!bool) {
      return tokenA?.address?.toLowerCase() !== network_token?.address?.toLowerCase() ? tokenA?.icon : tokenB?.icon;
    } else if (bool) {
      return tokenA?.address?.toLowerCase() === network_token?.address?.toLowerCase() ? tokenA?.icon : tokenB?.icon;
    }
  };

  // set claim msg to in progress and open modal
  const claimTenInProgress = () => {
    handleClaimOpen();
  };
  // set claim msg to completed and close modal
  const redeemCompleted = () => {
    setClaimMsgBoolean(false);
    setClaimed(true);
    setClaimTxt("Tokens Claimed");
    getUserPoolData();
    setTimeout(() => {
      setClaimed(false);
      handleClaimClose();
      setClaimMsgBoolean(true);
      setClaimTxt("Claim");
      setDisabled(true);
    }, 1500);
  };

  // set claim msg to Failed and close modal
  const redeemFailed = (cancelled) => {
    if (cancelled === "Cancelled") {
      setCancelled(true);
    }
    setClaimMsgBoolean(false);
    setClaimFailed(true);
    setClaimTxt("Claim failed");
    setTimeout(() => {
      setClaimMsgBoolean(true);
      handleClaimClose();
      setCancelled(false);
      setClaimFailed(false);
      setClaimTxt("Claim");
    }, 3000);
  };

  // disabled redeem panx button.
  const disabledClaimButton = () => {
    if (!redeemableAmount.psp22.amount) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  // redeem claimable panx every 24 hours.
  const redeem = async () => {
    claimTenInProgress();

    let errorModule;
    let redeemGas = 0;
    let value = 0;

    try {
      if (isAuthenticated) {
        await currentContract?.query["redeemRedeemableAmount"](address[0], { value, gasLimit: gas }).then((res) => {
          errorModule = res.output.toHuman().Ok;
          if (errorModule === "Ok") {
            redeemGas = res.gasRequired;
          } else {
            setClaimTxt(<Typography sx={{ fontSize: "12px" }}>{errorHandler(errorModule.Err)}</Typography>);
            redeemFailed();
          }
        });

        errorModule === "Ok" &&
          (await currentContract.tx["redeemRedeemableAmount"]({ value, gasLimit: redeemGas }).signAndSend(
            address[0],
            { signer: lastInjector.signer },
            ({ events = [], status }) => {
              if (status.isInBlock || status.isFinalized) {
                events
                  // find/filter for failed events
                  .filter(({ event }) => api[0].events.system.ExtrinsicFailed.is(event))
                  // we know that data for system.ExtrinsicFailed is
                  // (DispatchError, DispatchInfo)
                  .forEach(
                    ({
                      event: {
                        data: [error, info],
                      },
                    }) => {
                      if (error.isModule) {
                        // for module errors, we have the section indexed, lookup
                        const decoded = api[0].registry.findMetaError(error.asModule);
                        const { docs, method, section } = decoded;

                        // txnFailedFn();
                      } else {
                        // Other, CannotLookup, BadOrigin, no extra info
                        // console.log(error.toString());
                      }
                    }
                  );
              }

              if (status.isInBlock) {
                setClaimTxt("Claiming...");
              } else if (status.isFinalized) {
                // Loop through Vec<EventRecord> to display all events
                events.forEach(({ phase, event: { data, method, section } }) => {
                  if (method === "ExtrinsicSuccess") {
                    redeemCompleted();
                  } else if (method === "ExtrinsicFailed") {
                    redeemFailed();
                  }
                });
              }
            }
          ));
      } else {
        setClaimTxt(
          <Typography sx={{ fontSize: "12px" }}>
            You must be authenticated to the system.
            <br /> Please try to logout and login to your wallet again.
          </Typography>
        );
        claimFailed();
      }
    } catch (err) {
      if (err.message === "Cancelled") {
        redeemFailed(err.message);
      } else {
        setClaimTxt(<Typography sx={{ fontSize: "12px" }}>{err.message}</Typography>);
        redeemFailed();
      }
    }
  };

  useEffect(() => {
    if (currentContract && gas) {
      getUserPoolData(address[0], currentContract, gas);
    }
  }, [tokenA, tokenB, pool_withdraw_input, gas, currentContract, claimed]);

  useEffect(() => {
    disabledClaimButton();
  }, [redeemableAmount]);

  // Get user pool Tokens if there is an address connected
  // Re-render when address is changed and transaction is completed
  useEffect(() => {
    try {
      if (address.length > 0 && api[0] && pair_address !== "undefined") {
        const contract = new ContractPromise(api[0], abiTradingPairAzero, pair_address);
        let gasLimit = getMaxGasLimit(api[0]);
        setGas(gasLimit);
        setCurrentContract(contract);
        checkIfUserExist();
        getPoolTotalSupp();
        // getUserPoolShare(address[0], contract, gasLimit);
      }
    } catch (err) {
      // console.log(err);
    }
  }, [currentPair, api, address, completed, connectedNetwork]);

  useEffect(() => {
    getTokenImgForCard();
    Psp22Azero25Transactions();
    getBothTokensData();

    const dataInterval = setInterval(() => {
      Psp22Azero25Transactions();
      getBothTokensData();
    }, 15000);

    return () => {
      clearInterval(dataInterval);
    };
  }, [api, tokenA, tokenB, connectedNetwork]);

  useEffect(() => {
    scroll.scrollToTop({ duration: 500 });
  }, []);

  useEffect(() => {
    getTokenPair();
  }, [api]);

  useEffect(() => {
    setTokensAB();
    setCurrentLockDate(new Date(currentPair?.unlock_lp_date).getTime());
  }, [currentPair]);

  // Check if logged user exists on the pool providers list.
  const checkIfUserExist = async () => {
    let isUserExist = await getUserByAddress(pair_address, address[0]);
    isUserExist = isUserExist?.data?.data;
    setUserExist(isUserExist ? true : false);
  };

  // A function to get total Pool Supply to calculate user shares with %
  const getPoolTotalSupp = async () => {
    const gasLimit = getMaxGasLimit(api[0]);
    let { total_supply: totalSupply } = await getPoolTotalSupply(
      currentContract,
      address[0],
      gasLimit,
      connectedNetwork
    );
    setPoolTotalSupply(totalSupply);
  };

  return (
    <Wrapper>
      {currentPair ? (
        <Page elevation={0} sx={{ position: "relative" }}>
          <HeroContainer>
            <Box>
              {/* Back to pools button */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  opacity: "0.6",
                  width: "fit-content",
                  cursor: "pointer",
                  marginBottom: "20px",
                }}
              >
                <ArrowCircleDownIcon
                  fontSize="medium"
                  sx={{ transform: "rotateY(0deg) rotate(90deg)" }}
                  onClick={() => {
                    navigate("/liquidity");
                  }}
                />
                <Typography
                  sx={{ fontSize: "18px" }}
                  onClick={() => {
                    navigate("/liquidity");
                  }}
                >
                  Back to pools list
                </Typography>
              </Box>

              {/* V2 Badge */}
              {pair_address === "5HkiqCjmhSbCFxYiKge5BfKimdKp3sjQ77YLEESPBMQqMNjH" && (
                <Box
                  sx={{
                    position: "absolute",
                    right: "0",
                    top: "0",
                    backgroundColor: "#ffffffc7",
                    borderRadius: "0 10px 0 0",
                    fontSize: "18px",
                    color: "#282830",
                    padding: "5px",
                  }}
                >
                  <b>V2</b>
                </Box>
              )}

              {/* Pool icons and token names */}
              <TopDetailsBox>
                {getTokensNameAndImage(currentPair?.token_a_address)}
                <Typography variant="pair_title">{currentPair?.token_a_symbol}</Typography>
                <Typography sx={{ paddingInline: "10px", fontSize: "30px" }}>/</Typography>
                {getTokensNameAndImage(currentPair?.token_b_address)}
                <Typography variant="pair_title">{currentPair?.token_b_symbol}</Typography>
              </TopDetailsBox>

              {/* Address + copy button */}
              <Box sx={{ marginBlock: "20px" }}>
                {address[0] ? (
                  <Box sx={{ display: "flex" }}>
                    <Box sx={resize < 769 && addressStyle}>
                      <Typography variant="pool_details_title">{pair_address} </Typography>
                    </Box>
                    <Button
                      sx={{ color: colors.white, minWidth: "0px", padding: "0", marginLeft: "2px" }}
                      onClick={handleCopyClick}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </Button>
                  </Box>
                ) : (
                  <Skeleton animation="wave" sx={{ width: "250px" }} />
                )}
              </Box>
            </Box>
          </HeroContainer>

          <Box sx={{ background: "linear-gradient(to bottom, #282830 48px, #33333b 48px)", paddingBottom: "50px" }}>
            <DetailsContainer>
              <TabContext value={tabValue}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <TabList
                    onChange={handleChange}
                    TabIndicatorProps={{
                      style: { background: "none", color: "white" },
                    }}
                    textColor="inherit"
                  >
                    <ElegantButton disableRipple label="Manage" value="manage" />
                    <ElegantButton disableRipple label="Analytics" value="analytics" />
                  </TabList>
                  <Box>
                    <CountdownTimer currentLockDate={currentLockDate} />
                  </Box>
                </Box>
                {/* MANAGE POOL TAB */}
                <StyledTabPanel value="manage">
                  <DetailsBox flexDirection={resize > 768 ? "row" : "column"}>
                    {/* LEFT PAPER for showing pool details */}
                    <DetailsPaper elevation={3} width={resize < 769 && "100%"}>
                      <TopDetailsPaper elevation={5}>
                        {pair_address === "5H1jcbhtrxbgmsYHSgNYpCMYqpsrKVYkPTyGoYkGPk5uWXjL" && (
                          <Box
                            sx={{
                              position: "absolute",
                              right: "0",
                              top: "0",
                              backgroundColor: "#ffffffc7",
                              borderRadius: "0 10px 0 0",
                              fontSize: "18px",
                              color: "#282830",
                              padding: "5px",
                            }}
                          >
                            <b>V3</b>
                          </Box>
                        )}
                        {/* Current pool details */}
                        <TableContainer
                          sx={{
                            marginTop: "10px",
                            display: "block",
                            whiteSpace: "nowrap",
                            overflow: "auto hidden",
                            maxWidth: "100%",
                          }}
                        >
                          {" "}
                          <Table>
                            <TableHead>
                              <TopStyledTableRow>
                                <StyledTableCell>LP Tokens</StyledTableCell>
                                <StyledTableCell>Reserves</StyledTableCell>
                                {/* <StyledTableCell>Liquidity</StyledTableCell> */}
                                <StyledTableCell>Volume(24H)</StyledTableCell>
                                {resize > 768 && <StyledTableCell>Fees(24H)</StyledTableCell>}
                                <StyledTableCell>APY</StyledTableCell>
                              </TopStyledTableRow>
                            </TableHead>
                            <SpecPoolTableBody
                              currentContract={currentContract}
                              currentPair={currentPair}
                              completed={completed}
                            />
                          </Table>
                        </TableContainer>{" "}
                      </TopDetailsPaper>

                      {/* Current account pool details */}
                      <Box>
                        <AccPoolDetailsBox columns="2" margintop="20px">
                          <Typography variant="pool_details_title">Your pool share</Typography>
                          <Typography variant="pool_details_title">Your pool tokens</Typography>
                          {userPercentageShare > 0 ? (
                            <Typography variant="pool_details_amount" sx={{ display: "flex" }}>
                              {numberWithCommas(userPercentageShare)}%
                            </Typography>
                          ) : (
                            <Typography variant="pool_details_amount">0.0000%</Typography>

                            // <Skeleton animation="wave" sx={{ width: "100px" }} />
                          )}
                          {userPoolTokens ? (
                            <Typography variant="pool_details_amount">{numberWithCommas(userPoolTokens)}</Typography>
                          ) : (
                            <Typography variant="pool_details_amount">0.0000</Typography>

                            // <Skeleton animation="wave" sx={{ width: "100px" }} />
                          )}
                        </AccPoolDetailsBox>
                        {/* Current account pool details */}
                        <AccPoolDetailsBox columns="2">
                          <Typography variant="pool_details_title">
                            Pooled <span style={{ textTransform: "uppercase" }}>{tokenA?.symbol}</span>
                          </Typography>
                          <Typography variant="pool_details_title">
                            Pooled <span style={{ textTransform: "uppercase" }}>{tokenB?.symbol}</span>
                          </Typography>
                          {pooledTokenA > 0 ? (
                            <Typography variant="pool_details_amount">
                              <img src={tokenA?.icon} alt="" />
                              {numberWithCommas(pooledTokenA)}
                            </Typography>
                          ) : (
                            <Typography variant="pool_details_amount">0.0000</Typography>
                            // <Skeleton animation="wave" sx={{ width: "100px" }} />
                          )}

                          {pooledTokenB > 0 ? (
                            <Typography variant="pool_details_amount">
                              <img src={tokenB?.icon} alt="" />
                              {numberWithCommas(pooledTokenB)}
                            </Typography>
                          ) : (
                            <Typography variant="pool_details_amount">0.0000</Typography>
                            // <Skeleton animation="wave" sx={{ width: "100px" }} />
                          )}
                        </AccPoolDetailsBox>
                      </Box>
                    </DetailsPaper>

                    {/* RIGHT PAPER for displaying add/remove inputs */}
                    <InputsPaper elevation={5} sx={{ position: "relative" }} width={resize < 769 && "100%"}>
                      {/* <Box sx={{ position: "absolute", top: "3%", right: "3%", zIndex: "1" }}>
                        <SlippageButton slippage={slippage} handleOpen={handleOpen} />
                      </Box> */}

                      <PoolTabs
                        setTxnCompleted={setTxnCompleted}
                        userPoolTokens={userPoolTokens}
                        contract={currentContract}
                        gas={gas}
                        poolTotalSupply={poolTotalSupply}
                      />
                    </InputsPaper>
                  </DetailsBox>
                </StyledTabPanel>

                {/* ANALYTICS TAB */}
                <StyledTabPanel value="analytics">
                  {/* ANALYTICS TAB */}
                  <StyledTabPanel value="analytics">
                    {/* Logged user analytics */}
                    <Paper elevation={5} sx={{ padding: "20px" }}>
                      {userExist ? (
                        <Wrapper>
                          <Box sx={{ marginBottom: "20px" }}>
                            <TxnTablesTypography variant="stats_trade_chart">Your Analytics</TxnTablesTypography>
                          </Box>

                          <AnalyticsTopDataContainer columns={resize > 768 ? "2" : "1"}>
                            {Object.keys(userAnalyticsData).map((key) => (
                              <AnalyticsDataBox key={key}>
                                <Typography variant="pool_details_title">{userAnalyticsData[key].title}</Typography>
                                <Typography variant="pool_details_amount">
                                  {userAnalyticsData[key].data}
                                  {userAnalyticsData[key]?.title?.includes("%") && "%"}
                                </Typography>
                              </AnalyticsDataBox>
                            ))}
                            <AnalyticsDataBox>
                              <Typography variant="pool_details_title">Amount of tokens to redeem</Typography>
                              {Object.entries(redeemableAmount).map((key, value) => (
                                <Box sx={{ display: "flex", alignItems: "center" }} key={key}>
                                  <img
                                    src={key[1].icon}
                                    alt=""
                                    width={22}
                                    height={22}
                                    style={{ borderRadius: "50px", marginRight: "7px" }}
                                  />
                                  <Typography variant="pool_details_amount">{key[1].amount}</Typography>
                                </Box>
                              ))}

                              <ClaimablePanxBtn
                                variant="contained"
                                disabled={disabled}
                                onClick={redeem}
                                sx={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "10px",
                                  "@media(max-width:400px)": {
                                    top: "auto",
                                    bottom: "10px",
                                  },
                                }}
                              >
                                {claimTxt}
                              </ClaimablePanxBtn>
                            </AnalyticsDataBox>
                            <AnalyticsDataBox>
                              <Typography variant="pool_details_title">Amount earned every 24 hours</Typography>
                              {Object.entries(dailyEarnedAmount).map((key, value) => (
                                <Box sx={{ display: "flex", alignItems: "center" }} key={key}>
                                  <img
                                    src={key[1].icon}
                                    alt=""
                                    width={22}
                                    height={22}
                                    style={{ borderRadius: "50px", marginRight: "7px" }}
                                  />
                                  <Typography variant="pool_details_amount">{key[1].amount}</Typography>
                                </Box>
                              ))}
                            </AnalyticsDataBox>
                          </AnalyticsTopDataContainer>
                        </Wrapper>
                      ) : (
                        <Wrapper>
                          <Typography>
                            You have not provided liquidity yet.
                            <br />
                            Please go back to manage and add your own liquidity to the pool.
                          </Typography>
                        </Wrapper>
                      )}
                    </Paper>

                    {/* All Users analytics */}
                    <Paper elevation={5} sx={{ padding: "20px", marginTop: "40px" }}>
                      <Box sx={{ marginBottom: "20px" }}>
                        <TxnTablesTypography variant="stats_trade_chart">Pool Users Analytics</TxnTablesTypography>
                      </Box>

                      <AnalyticsTopDataContainer columns={resize > 768 ? "2" : "1"}>
                        {Object.keys(allUsersAnalyticsData).map((key) => (
                          <AnalyticsDataBox key={key}>
                            <Typography variant="pool_details_title">{allUsersAnalyticsData[key].title}</Typography>
                            <Box>
                              {Object.entries(allUsersAnalyticsData[key].data).map((key, value) => (
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                  <img
                                    src={getTokenImgForCard(key[0])}
                                    alt=""
                                    width={22}
                                    height={22}
                                    style={{ borderRadius: "50px", marginRight: "7px" }}
                                  />
                                  <Typography variant="pool_details_amount">{key[1]}</Typography>
                                </Box>
                              ))}
                            </Box>
                          </AnalyticsDataBox>
                        ))}
                      </AnalyticsTopDataContainer>
                    </Paper>

                    {/* Graph component */}
                    {/* Graph and tokens volumes */}
                    <StatsContainer>
                      <GraphVolumeBox>
                        <GraphPaper elevation={5}>
                          <Box
                            sx={{
                              paddingLeft: "20px",
                              "@media(max-width:768px)": {
                                marginBottom: "30px",
                              },
                            }}
                          >
                            <TxnTablesTypography variant="stats_trade_chart">Price Chart</TxnTablesTypography>
                          </Box>
                          <PairPricesGraph tokenA={tokenA} tokenB={tokenB} currentPair={currentPair} />
                        </GraphPaper>
                      </GraphVolumeBox>
                    </StatsContainer>

                    {/* Recent Swaps */}
                    <StatsContainer>
                      <Box>
                        <TransactionPaper elevation={5}>
                          <Box>
                            <TxnTablesTypography variant="stats_trade_chart">Recent Swaps</TxnTablesTypography>
                          </Box>
                          <TxnTable data={transactions} tokenA={tokenA} tokenB={tokenB}></TxnTable>
                        </TransactionPaper>
                      </Box>
                    </StatsContainer>
                  </StyledTabPanel>
                </StyledTabPanel>
              </TabContext>
            </DetailsContainer>
          </Box>

          <ClaimTenModal
            openClaim={openClaim}
            handleClaimClose={handleClaimClose}
            msgClaimBoolean={msgClaimBoolean}
            claimFailed={claimFailed}
            cancelled={cancelled}
          />
        </Page>
      ) : (
        <Page>
          <HeroContainer>
            <HeroPaper>
              <HeroBox>
                <Link to="/liquidity">
                  <ArrowBackIosNewIcon sx={{ color: "white !important", width: "40px", height: "auto" }} />
                </Link>
                <HeroTypography>Back to all pools</HeroTypography>
              </HeroBox>
            </HeroPaper>
          </HeroContainer>

          <DetailsContainer>
            <Box
              sx={{
                position: "absolute",
                top: "40%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* <Typography variant="h1">404 </Typography> */}
              <Typography variant="h3">Pool Not Found</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                {/* <Button
                  variant="contained"
                  // onClick={() => {
                  //   navigate(-1);
                  // }}
                >
                  Go Back
                </Button> */}
                <Button
                  variant="contained"
                  onClick={() => {
                    window.open("https://panoramaswap.com/contact/", "_blank");
                  }}
                >
                  Report A Problem
                </Button>
              </Box>
            </Box>
          </DetailsContainer>
        </Page>
      )}
    </Wrapper>
  );
};

export default LiquidityPairs;
