import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { web3Accounts, web3FromSource } from "@polkadot/extension-dapp";

export const useLastInjector = () => {
  const { caller_account } = useSelector((state) => state.account);
  const { allAccounts } = useSelector((state) => state.accounts);
  const [lastInjector, setLastInjector] = useState();
  // get lasInjector to change the caller source
  const getLastInjector = async () => {
    const caller_acc = allAccounts[0]?.filter((acc) => acc?.address === caller_account[0]?.address);
    let wallet_source = localStorage.getItem("NIGHTLY_CONNECT_SELECTOR_RECENT_STANDARD_WALLET_AlephZero");
    if (!!caller_account[0] && wallet_source) {
      let injector = await web3FromSource(caller_acc[0].meta.source);
      setLastInjector(injector);
    }
  };

  // useEffect to get last injector.
  useEffect(() => {
    try {
      caller_account && getLastInjector();
    } catch (err) {
      // console.log(err);
    }
  }, [caller_account]);

  return { lastInjector };
};
