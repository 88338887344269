import styled from "@emotion/styled";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  LinearProgress,
  CircularProgress,
  TableContainer,
} from "@mui/material";
import { colors } from "../themes";

export const StatsContainer = styled(Container)(({ theme }) => ({
  maxWidth: "1400px !important",
  width: "100%",
  paddingTop: "40px",
  paddingLeft: "0px !important",
  paddingRight: "0px !important",
}));

//  Volume details box style
export const VolumeDetailsBox = styled(Paper)(({ theme, columns, padding, width }) => ({
  width: width && width,
  padding: padding ? padding : "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "10px",
  "@media(max-width:992px)": {
    width: "50%",
    height: "100%",
  },
  "@media(max-width:768px)": {
    width: "100%",
    height: "50%",
  },
}));

//  Volume details inner box style
export const VolumeInnerBox = styled(Box)(({ theme, columns, padding, width }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const VolumeDetailsBoxTypography = styled(Typography)(({ theme, columns, padding, width }) => ({
  ">img": {
    width: "20px",
    marginLeft: "3px",
    borderRadius: "50%",
  },
}));

// Graph and Volumes grid
export const GraphVolumeBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "20px",
  "@media(max-width:992px)": {},
}));

//  Graph Paper
export const GraphPaper = styled(Paper)(({ theme }) => ({
  padding: "20px 0px 10px 0",
  borderRadius: "10px",
  width: "100%",
}));

//  Graph Paper
export const VolumesMainWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  height: "100%",
  gridArea: "volumes",
  "@media(max-width:992px)": {
    flexDirection: "row",
  },
  "@media(max-width:768px)": {
    flexDirection: "column",
  },
}));

//  Transaction table style
export const TransactionPaper = styled(Paper)(({ theme }) => ({
  padding: "20px",
  borderRadius: "10px",
  marginTop: "10px",
}));

export const TxnAmountBox = styled(Box)(({ theme, columns, padding, width }) => ({
  display: "flex",
  gap: "20px",
  marginTop: "20px",
  "@media(max-width:768px)": { flexDirection: "column" },
}));

export const TxnTablesTypography = styled(Typography)(({ theme }) => ({
  "@media(max-width:768px)": {
    fontSize: "24px",
  },
  "@media(max-width:450px)": {
    fontSize: "20px",
  },
}));

export const TableWrapper = styled(TableContainer)(({ theme, customHeight, radius }) => ({
  maxHeight: customHeight ? customHeight : "250px",
  "&::-webkit-scrollbar": {
    width: "8px",
  },
  "&::-webkit-scrollbar-track": {
    background: "#979797",
    height: "10px",
    borderRadius: !radius && "15px",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: !radius && "10px",
    background: "#d9d9d9",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#c9c9c9",
  },
}));
