import { TabPanel } from "@mui/lab";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledTabPanel = styled(TabPanel)(({ theme, marginT }) => ({
  padding: "10px",
}));

export const PoolPercentageBtnBox = styled(Box)(({ theme, marginT }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
  gap: "5px",
}));
