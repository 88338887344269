import React from "react";
import Wrapper from "../Helpers/Wrapper";

import { Credits, CreditsWrapper } from "../../Style/footer";
import { Box, Divider } from "@mui/material";

import whiteLogo from "../../Images/logos/panorama_white.png";
import blackLogo from "../../Images/logos/panorama_black.png";

import twitter from "../../Images/icons/social/twitter.png";
import discord from "../../Images/icons/social/discord.png";
import telegram from "../../Images/icons/social/telegram.png";

const Footer = () => {
  return (
    <Wrapper>
      <CreditsWrapper>
        <Box sx={{ display: "flex", gap: "20px" }}>
          <a href="https://twitter.com/PanoramaSwap?t=9D9Iywj5QL4ccnnM9FGqTg&s=09" target="_blank" rel="noreferrer">
            <img src={twitter} alt="" width={30} />
          </a>
          <a href="https://discord.com/invite/fx9zP93Ez6" target="_blank" rel="noreferrer">
            <img src={discord} alt="" width={30} />
          </a>
          <a href="https://t.me/panoramaswap" target="_blank" rel="noreferrer">
            <img src={telegram} alt="" width={30} />
          </a>
        </Box>
        <Credits>All Rights Reserved © 2023</Credits>
      </CreditsWrapper>
    </Wrapper>
  );
};

export default Footer;
