import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Wrapper from "../Helpers/Wrapper";
import { preventLetters } from "../../Utils/functions/globalFunctions";
import { set_txn_slippage } from "../../features/account";

import { Backdrop, Box, Button, Fade, Modal, Paper, ToggleButtonGroup, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { SlippageTitle, StyledSlippageTextField, StyledToggleButton } from "../../Style/general";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  maxWidth: "450px",
  // minWidth: "350px",
  width: "100%",
  boxShadow: 24,
  p: 4,
  "@media(max-width:768px)": {
    width: "90%",
  },
};

const SlippageModal = (props) => {
  const dispatch = useDispatch();

  const [disableSlippage, setDisableSlippage] = useState(false);
  // const [slippage, setSlippage] = useState(account.slippage); // slippage
  const [customSlippage, setCustomSlippage] = useState(null); // custom slippage
  const [toggleSlippage, setToggleSlippage] = useState("0.1%"); // toggle buttons slippage
  const [alertMsg, setAlertMsg] = useState(""); //alertMSG
  const [alertColor, setAlertColor] = useState(null); //alertMSG

  const modalClose = () => {
    setCustomSlippage(null);
    setAlertMsg();
    setToggleSlippage("0.1%");
    props.handleClose();
  };
  // A function to handle toggle buttons slippage settings
  const handleToggleSlippage = (e, newSlippage) => {
    e.target.value && setCustomSlippage(null);
    newSlippage && setToggleSlippage(newSlippage);
    setDisableSlippage(false);
    setAlertMsg(null);
  };

  // A function to handle custom slippage input
  const handleCustomSlippage = (e) => {
    if (e.target.value.length > e.target.max && e.target.value !== 0) {
      e.target.value = e.target.value.slice(0, e.target.max);
    }

    if (e.target.value > 5) {
      if (Number(e.target.value) > 49) {
        setAlertMsg("Please enter a valid slippage percentage.");
        setAlertColor("#f44336");
        setDisableSlippage(true);
        setCustomSlippage("");
      } else if (5 < e.target.value < 50) {
        setAlertMsg("Your transaction may be frontrun.");
        setAlertColor("#ffa726");
        setDisableSlippage(false);
        // setCustomSlippage(+e.target.value);
      }
    } else {
      if (Number(e.target.value) === 0 && e.target.value.length > 0) {
        setAlertMsg("Slippage percentage can't be 0.");
        setAlertColor("#f44336");
        setDisableSlippage(true);
        setCustomSlippage("");
      } else if (e.target.value.length === 0) {
        setAlertMsg("Slippage percentage can't be empty.");
        setAlertColor("#f44336");
        setDisableSlippage(true);
        setCustomSlippage("");
      } else if (Number(e.target.value) < 0.01 && e.target.value.length > 0) {
        setAlertMsg("Please enter a valid slippage percentage.");
        setAlertColor("#f44336");
        setDisableSlippage(true);
        setCustomSlippage("");
      } else {
        setAlertMsg("");
        setDisableSlippage(false);
        // setCustomSlippage(+e.target.value);
      }
    }

    setCustomSlippage(+e.target.value);

    e.target.value && setToggleSlippage(null);
  };

  // A function to save slippage setting
  const saveSlippageSetting = () => {
    let newSlippage;
    if (toggleSlippage && !customSlippage) {
      newSlippage = toggleSlippage.replace("%", "");
    } else if (customSlippage && !toggleSlippage) {
      newSlippage = customSlippage;
    } else if (toggleSlippage && customSlippage) {
      setAlertMsg("Please use only one type of slippage.");
    }
    dispatch(set_txn_slippage(Number(newSlippage)));
    // setSlippage(newSlippage);
    props.handleClose();
  };

  return (
    <Wrapper>
      <Modal
        open={props.open}
        onClose={modalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={Backdrop}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <Paper
              elevation={3}
              sx={{
                maxWidth: "450px",
                width: "100%",
                height: "auto",
                padding: "25px",
                borderRadius: "10px",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <SlippageTitle>slippage settings</SlippageTitle>
                <CloseIcon onClick={() => modalClose()} sx={{ cursor: "pointer" }} />
              </Box>
              <hr style={{ opacity: "0.15", borderRadius: "50px", marginBlock: "20px" }} />
              <Box sx={{ display: "flex", flexDirection: "column", rowGap: "20px" }}>
                <ToggleButtonGroup
                  value={toggleSlippage}
                  exclusive
                  onChange={handleToggleSlippage}
                  sx={{ width: "100%", display: "flex", columnGap: "20px" }}
                >
                  <StyledToggleButton value="0.1%">0.1% </StyledToggleButton>
                  <StyledToggleButton value="0.5%">0.5% </StyledToggleButton>
                  <StyledToggleButton value="1%">1% </StyledToggleButton>
                </ToggleButtonGroup>
                <StyledSlippageTextField
                  defaultValue={customSlippage && customSlippage}
                  placeholder="Insert custom slippage..."
                  type="number"
                  onInput={(e) => handleCustomSlippage(e)}
                  onKeyDown={(evt) => preventLetters(evt)}
                  inputProps={{
                    max: 4,
                  }}
                />
                {alertMsg && (
                  <Typography component="small" sx={{ color: alertColor }}>
                    {alertMsg}
                  </Typography>
                )}
                <Button variant="save_settings" onClick={() => saveSlippageSetting()} disabled={disableSlippage}>
                  save settings
                </Button>
              </Box>
            </Paper>
          </Box>
        </Fade>
      </Modal>
    </Wrapper>
  );
};

export default SlippageModal;
