import { Box, Typography } from "@mui/material";
import React from "react";
import Wrapper from "../../Components/Helpers/Wrapper";
import { HeroContainer, Page } from "../../Style/general";

const TermsOfUse = () => {
  return (
    <Wrapper>
      <Page sx={{ paddingTop: "60px" }}>
        <HeroContainer>
          <h1>Terms of Use</h1>
          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            Updated: September 09, 2022
            <br />
            PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS WEBSITE.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <strong> 1. General</strong>
            <br />
            By accessing this website, you are agreeing to be bound by these Terms of Use as stated herein. These
            <br />
            Terms of Use (the Terms of Use, the Agreement) (together with our Privacy Policy) governs [name of
            <br />
            legal entity/natural person] (hereinafter __, We, or Our) relationship with you as a user (the User) of our
            <br />
            services (the Services), using the Services, our Software and our website (panoramaswap.com and
            <br />
            panoramaswap.app) (the Website) (jointly Panorama Swap Services).
            <br />
            The present Terms of Use establish the contractual relationship between Panorama Swap and the User
            <br />
            of the Panorama Swap Services. By using the Panorama Swap Services made available thereof, the User
            <br />
            confirms that he understands, accepts, and binds himself to respect and follow these Terms of User. If
            <br />
            the User does not agree with these Terms of Use, he should stop using the Website and/or the Services
            <br />
            immediately.
            <br />
            The present Terms of Use along with our Privacy Policy represents the entirety of the agreement
            <br />
            between you and Panorama Swap and governs your interactions with Panorama Swap and use of the
            <br />
            Panorama Swap Services. It supersedes all prior agreements (including prior versions of these Terms of
            <br />
            Use). If you have a separate written agreement with Panorama Swap, that agreement will govern to the
            <br />
            extent it amends any section of these Terms of Use.
            <br />
            By making use of Panorama Swap Services, you acknowledge and agree that you are aware of the risks
            <br />
            associated with transactions of digital asset, you assume all risks related to the use of Panorama Swap
            <br />
            Services and transactions of digital currencies and their derivatives, and Panorama Swap is not liable for
            <br />
            any such risks or adverse outcomes.
            <br />
            Digital Asset means a digital currency and their derivatives, which is a digital representation of value
            <br />
            based on (or built on top of) a cryptographic protocol of a computer network.
            <br />
            Panorama Swap may revise these Terms of Use for the Website at any time without notice. By using the
            <br />
            Panorama Swap Services, the User is agreeing to be bound by the then current version of these Terms of
            <br />
            Use.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <strong>2. Panorama Swap Services</strong>
            <br />
            Panorama Swap is an automated market maker running on Aleph Zero blockchain. Panorama Swap is
            <br />
            the provider of a suite of on-chain Decentralized finance (DeFi) protocols.
            <br />
            Aleph Zero is an open-source project licensed under Apache 2.0.
            <br />
            Aleph Zero is a layer 1 blockchain platform based on a peer-reviewed consensus protocol, AlephBFT. The
            <br />
            consensus utilizes a Directed Acyclic Graph architecture as an intermediary data structure.
            <br />
            The Panorama Swap is driven entirely by on chain smart contract logic, it is decentralized and
            <br />
            permissionless.
            <br />
            The main utility token of the Panorama Swap Service is PANX.
            <br />
            PANX serves as a mechanism for orchestrating and voting on governance proposals as well as giving
            <br />
            investors the opportunity to stake and earn yield.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            The user has to invoke the approve function to give the trading pair smart contract the approval to use
            <br />
            some amount of tokens in the name of the user. Each successful swap, the allowance of the trading pair
            <br />
            smart contract will deduct therefore the user must use approve function each time in order to be able
            <br />
            to swap.
            <br />
            The Price impact value means the percentage difference between the old price (before swap) to the
            <br />
            new price after successful swap (post swap).
            <br />
            The Slippage means the difference between the expected price of an order and the price when the
            <br />
            order actually executes, users can set slippage tolerance percentages in order to set the maximum
            <br />
            amount of tokens the user is willing to lose.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <br />
            <strong>
              Yield farming
              <br />
            </strong>
            Yield farming is available on Panorama Swap.
            <br />
            Panorama Swap allows users to earn yield from staking, earn fees by providing liquidity and earn extra
            <br />
            yield through yield farming.
            <br />
            The fee starts to generate after the tokens are locked into a liquidity fund.
            <br />
            Panorama Swap do not set expected yield returns.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <br />
            <strong>
              Liquidity Provision
              <br />
            </strong>
            The Users are able to create any PSP22/PSP22 or PSP22/AZERO liquidity pools which will create pairs
            <br />
            that can be traded.
            <br />
            Liquidity providers are also known as market makers and they participate by providing their crypto
            <br />
            assets to assist with platform decentralization of trading. In return, liquidity providers are rewarded by
            <br />
            liquidity provider's fees over time.
            <br />
            Liquidity pool creators can choose and set the liquidity providers fee from 1%.
            <br />
            Liquidity pool creators don’t have any advantages or benefits over regular liquidity provider.
            <br />
            If the tokens are deposited into a liquidity pool and their price changes a few days later, the amount of
            <br />
            money lost due to that change is called impermanent loss.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <br />
            <strong>Fees</strong>
            <br />
            When the User make a swap in a Panorama Swap liquidity pool the transaction is charged a fee of which
            <br />
            some percentage is distributed to liquidity providers.
            <br />A nominal amount of Aleph Zero (AZERO) is required to process Aleph Zero network fees on each trade.
          </Typography>

          <Typography sx={{ marginBottom: "10px", fontSize: "18px" }}>
            <strong>
              3. User Account, Password, and Security
              <br />
            </strong>
            To start to use Panorama Swap Services, the User need to have wallets created by third party service,
            <br />
            and use this wallet to connect to Panorama Swap.
            <br />
            Wallets supported by Panorama Swap:
          </Typography>

          <ul style={{ paddingInlineStart: "13px" }}>
            <li>Polkadot.js</li>
            <li>Subwallet</li>
            <li>Nova wallet (Mobile).</li>
          </ul>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            The User is entirely responsible for maintaining the confidentiality of User password and account on a<br />
            third-party service. The User hereby acknowledges that the User is entirely responsible for all activities
            <br />
            that occur under the third-party service User’s account.
            <br />
            Panorama Swap shall not be liable for any loss that the User may incur as a result of a third party using
            <br />
            the User’s password or account, either with or without your knowledge. The User also acknowledges
            <br />
            that the User may be held liable for losses incurred by Panorama Swap or any other party due to any
            <br />
            authorized or unauthorized third-party accessing and using the User’s account or password.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <strong>
              4. Payments
              <br />
            </strong>
            Panorama Swap itself does not collect, process, or store your payment details. The specified billing
            <br />
            information is collected by the respective payment system, which is responsible for the security of
            <br />
            transfer and storage of the paid funds.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <strong>
              5. License
              <br />
            </strong>
            Panorama Swap grants you a non-exclusive, revocable, worldwide, royalty-free, non-transferable license
            <br />
            (the License) to access and use the Website and the Services. In general, you may not sub-license the
            <br />
            License, but you may grant sublicenses to any contractors you employ for the sole purpose of using the
            <br />
            Website and the Services to assist you as the User. This Agreement does not give you any title,
            <br />
            ownership, or interest (including any intellectual property rights) in the Website, the Services, or the
            <br />
            content of the Panorama Swap.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <br />
            <strong>
              Restrictions
              <br />
            </strong>
            You will not, and will not attempt to, directly or indirectly (a) reverse engineer, decompile, disassemble,
            <br />
            or otherwise attempt to discern the source code, underlying ideas, algorithms, or any files contained in
            <br />
            or generated by the Panorama Swap; (b) copy, modify, adapt, or translate any aspect of the Panorama
            <br />
            Swap, or otherwise make any use, resell, distribute, or sublicense any part of the Panorama Swap other
            <br />
            than in connection with this Agreement; (c) access the Panorama Swap on behalf of a competing service
            <br />
            or permit any individual acting on behalf of a competing service to access the Panorama Swap; (d)
            <br />
            remove or modify any proprietary marking or restrictive legends placed on the Panorama Swap; or (e)
            <br />
            use the Panorama Swap in violation of any applicable law or regulation. You will use commercially
            <br />
            reasonable efforts to prevent unauthorized access to or use of the Panorama Swap and not share your
            <br />
            password or let anyone access your third-party accounts.
            <br />
            The foregoing license does not cover information to which any granted or pending intellectual property
            <br />
            right other than copyright apply (e.g., trademarks, designs, and database rights). In that case, and
            <br />
            whenever a commercial use is required, users are encouraged to seek specific and explicit permission
            <br />
            from the concerned right-holder(s).
            <br />
            Under no circumstance shall the users use the Panorama Swap logo or the official name and credentials
            <br />
            of the Panorama Swap to imply affiliation, endorsement, or other official links to Panorama Swap
            <br />
            without prior written and formal authorization from Panorama Swap.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <strong>
              6. Lawful Conduct
              <br />
            </strong>
            As a condition of use, the User hereby agrees to never use the Website and/or the Services for any
            <br />
            purpose that is unlawful or prohibited by the terms, conditions, and notices provided herein. The User
            <br />
            may not use the Website and/or Services in any manner that could damage, disable, overburden, or
            <br />
            impair any Panorama Swap server or website, or the network(s) connected to any Panorama Swap
            <br />
            server, or interfere with any other party’s use and enjoyment of the Website or Services. The User may
            <br />
            not attempt to gain unauthorized access to any services, other accounts, computer systems, or
            <br />
            networks connected to the Website or any Panorama Swap server or any of the Services through
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            hacking, password mining, or any other means. The User may not obtain or attempt to obtain any
            <br />
            materials or information through any means not intentionally made available by Panorama Swap
            <br />
            through the Website or Services.
            <br />
            The User shall comply with all applicable German, foreign or international laws and regulations, and the
            <br />
            User hereby agrees that the User shall be responsible for compliance with all applicable local laws. If the
            <br />
            User does not agree with any of these terms, User is hereby prohibited from lawfully using or accessing
            <br />
            the Website and/or the Services and User’s continued unlawful use of the Website and/or the Services
            <br />
            shall constitute a violation of these Terms of Use.
            <br />
            Panorama Swap reserves the right to pursue all legal remedies to enforce its rights under these Terms of
            <br />
            Use.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <strong>
              7. Disclaimer of Warranties
              <br />
            </strong>
            The Website and the materials contained herein are provided by Panorama Swap on an “as is” and “as
            <br />
            available” basis. Panorama Swap makes no warranties, express or implied, and hereby disclaims and
            <br />
            negates all other warranties, including but not limited to, implied warranties or conditions of
            <br />
            merchantability, fitness for a particular purpose, noninfringement of intellectual property, or other
            <br />
            violation of any third-party rights. Panorama Swap does not warrant or make any representations
            <br />
            concerning the accuracy, likely results, or reliability of the use of the materials on any part of this
            <br />
            website or otherwise relating to such materials, or on any third-party websites, databases, blogs, or
            <br />
            social media sites linked to the Website.
            <br />
            By accessing the Website and the Services, you acknowledge and agree that you have read these Terms
            <br />
            of Use, are at least eighteen (18) years of age, use the Services strictly for your personal, non-
            <br />
            commercial use,&nbsp;and wish to be bound by the Terms of Use.
            <br />
            You agree to use the Panorama Swap Services only for purposes that are permitted by (a) these Terms
            <br />
            of Use and (b) any applicable law, regulation, or generally accepted practices or guidelines in the
            <br />
            relevant jurisdictions
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <strong>
              8. Statements
              <br />
            </strong>
            Throughout the Panorama Swap Services, there may be certain statements that describe a forward-
            <br />
            looking approach to our company, our products, and our objectives. These statements are based on
            <br />
            certain assumptions and reflect Panorama Swap's current expectations. As a result, statements are
            <br />
            subject to several risks and uncertainties that could cause actual results or events to differ materially
            <br />
            from current expectations. Panorama Swap does not make any warranties or give any assurances that
            <br />
            any statements will materialize. You, as a User, are cautioned and are hereby given notice, to not place
            <br />
            any undue reliance on these statements, which reflect expectations only as of the date of this website.
            <br />
            Panorama Swap disclaims any intention or obligation to update or revise any of these statements
            <br />
            contemplated herein.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <strong>
              9. Revisions or Modifications
              <br />
            </strong>
            Any materials appearing may include technical, typographical, or photographic errors. Panorama Swap
            <br />
            does not warrant that any of the materials on its website are accurate, complete, or current. Panorama
            <br />
            Swap may make changes to the materials contained on the Website at any time without notice.
            <br />
            Panorama Swap does not, however, make any commitment to update the materials.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <strong>
              10. Limitation on Liability
              <br />
            </strong>
            In no event shall Panorama Swap, its directors, officers, employees, agents, investors, or licensors be
            <br />
            liable for any damages, including but not limited to damages for special, direct, indirect, consequential,
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            or incidental loss, loss of data, loss of profit or damages resulting from a business interruption, arising
            <br />
            out of the use or inability to use the materials on the Website, whether such damages occur in an action
            <br />
            of contract, negligence or other tortious action and regardless of whether Panorama Swap or a<br />
            Panorama Swap authorized representative has been notified orally or in writing of the possibility of such
            <br />
            damages. Because some jurisdictions do not allow limitations of liability on implied warranties,
            <br />
            consequential or incidental damages, these limitations may not apply to you.
            <br />
            Even if Panorama Swap has been warned of the possibility of such damages and even in the case of
            <br />
            claims based on negligence or strict liability, the maximum liability for all claims relating to the
            <br />
            Panorama Swap will not exceed the aggregate amount of fees paid and payable to us during the six
            <br />
            months preceding the date on which the claim arises. You also understand and agree that Pocket IP is
            <br />
            not a party to any agreements between you and third-party services. Additionally, you acknowledge that
            <br />
            we are not liable for any conduct or issues between you and third-party services, in all cases to the
            <br />
            maximum extent permitted by law.
            <br />
            Panorama Swap will not be held responsible for any use, disclosure, modification, or deletion of User
            <br />
            Data that is transmitted to, or accessed by a product that is not within the Website and/or the Services.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <strong>
              11. Third-Party Links
              <br />
            </strong>
            Panorama Swap may provide links to third-party websites, databases, blogs, or other social media
            <br />
            outlets (Third-Party Links) through the Website. These Third-Party Links shall cause the User to leave the
            <br />
            Website. These Third-Party Links are not under the control of Panorama Swap.
            <br />
            Panorama Swap is not responsible for the contents of any such third-party linked sites, databases, blogs,
            <br />
            or other social media outlets. The inclusion of any link does not imply any endorsement whatsoever by
            <br />
            Panorama Swap of the Third-Party Links or any viewpoint expressed on any linked website, blog, or
            <br />
            social media outlets. Panorama Swap is providing these Third-Party Links only as a convenience. Use or
            <br />
            access to any Third-Party Links is at the User's own risk.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <strong>
              12. Third-Party Services
              <br />
            </strong>
            Panorama Swap relies on third-party vendors and partners to provide the necessary infrastructure,
            <br />
            software, storage, and related technology solutions to offer the Services. You acknowledge and agree to
            <br />
            Panorama Swap’s use of these vendors and partners.
            <br />
            The privacy of your data that is handled by these third-party solutions is addressed in the Privacy Policy
            <br />
            Section. Further, in providing the Services, we also make certain services and integrations available to
            <br />
            you from third parties that are not under Panorama Swap’s control nor endorsed by Panorama Swap. If
            <br />
            you use these services from third parties, you do so at your own risk and have a separate contractual
            <br />
            relationship with that party.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <strong>
              13. Intellectual Property
              <br />
            </strong>
            The Services, including our features, functionality, and appearance, are owned by Panorama Swap and
            <br />
            are protected by German and international copyright, trademark, patent, trade secret, and other
            <br />
            applicable intellectual property, property, and proprietary rights laws. You agree not to copy, modify,
            <br />
            create derivative works of, republish, publicly display, sell, offer to sell, or exploit any of our
            protected
            <br />
            materials (unless it's expressly permitted by our Website).
            <br />
            If you have questions about whether or how to use our materials or any part of the Website, please
            <br />
            contact us at support@panoramaswap.com.
            <br />
            By providing comments, suggestions, or recommendations for improvements or changes to the Website
            <br />
            and/or the Services, you grant us, under all right, title, and interest in these comments and suggestions,
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            a non-exclusive, royalty free, worldwide, transferable, sub-licensable, irrevocable, and perpetual license
            <br />
            to use these comments and suggestions or to incorporate them into the Website and/or the Services.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <strong>
              14. Confidentiality
              <br />
            </strong>
            The User’s Confidential Information (the User Confidential Information) means information on your legal
            <br />
            or business affairs, clients, and other data. We will (a) only use the User Confidential Information to
            <br />
            perform obligations and rights under this Agreement, (b) restrict disclosure of User Confidential
            <br />
            Information within our organization to only those employees or contractors that need to know and
            <br />
            comply with our obligations under this Agreement; and (c) not disclose User Confidential Information to
            <br />
            any third party unless authorized by the disclosing party in writing. We will meet our obligations under
            <br />
            GDPR and CCPA as outlined in our Privacy Policy.
            <br />
            Panorama Swap Confidential Information includes the software, all source code, the documentation,
            <br />
            trade secrets, designs, discoveries, inventions, production methods, and all non-public information or
            <br />
            material about our legal or business affairs, but it does not include any non-public data in the User
            <br />
            Confidential Information. You will (a) only use the Panorama Swap Confidential Information to perform
            <br />
            obligations and rights under this Agreement, (b) restrict disclosure of Panorama Swap Confidential
            <br />
            Information within the receiving party’s organization to only those employees or contractors need to
            <br />
            know and are required to comply with your obligations under this Agreement; and (c) not disclose
            <br />
            Panorama Swap Confidential Information to any other third party unless authorized by in writing.
            <br />
            Panorama Swap and the User agree to protect each other's confidential information using at least the
            <br />
            degree of care that it uses to protect its confidential information but no less than a reasonable degree of
            <br />
            care. If the receiving party receives a request to disclose the disclosing party's confidential information
            <br />
            under a judicial or government order, it will not do so without first giving the disclosing party notice of
            <br />
            the request and sufficient opportunity to contest the order, to the extent legally permissible.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <strong>
              15. Termination
              <br />
            </strong>
            Your data is your own and preserving your access to your data is important to us. If you terminate your
            <br />
            use of the Website and the Services, we will provide you an opportunity to export your data to the
            <br />
            extent possible. In addition, you can download your data through the Panorama Swap, at any time. If
            <br />
            you would like to have your data deleted, please contact us at support@panoramaswap.com.
            <br />
            You may terminate your use of the Panorama Swap Services at any time. If this Agreement is
            <br />
            terminated, you will pay us for all amounts payable as of the effective date of termination. All rights and
            <br />
            licenses granted by Panorama Swap will immediately cease, and any Panorama Swap’s Confidential
            <br />
            Information in your possession will be promptly destroyed.
            <br />
            We reserve the right to suspend or end the Panorama Swap Services at any time. If we suspend or
            <br />
            terminate your use of the Panorama Swap Services, we will let you know in advance to retrieve your
            <br />
            data though in some cases (such as a flagrant violation of this Agreement or court order), we may have
            <br />
            to terminate immediately.
            <br />
            Sections 10, 13, and 15 will survive any termination or expiration of these Terms of Use.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <strong>
              16. Publicity
              <br />
            </strong>
            Panorama Swap will not identify you as a customer and include your name on our website, investment
            <br />
            materials, and sales collateral.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <strong>
              17. Notice and User Inquiries
              <br />
            </strong>
            All inquiries by the User(s) or notices to Panorama Swap regarding these Terms of Use and/or any other
            <br />
            inquiry regarding the Website and/or the Services shall be made through emailing Panorama Swap at
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            support@panoramaswap.com. We will attempt to respond to your inquiries promptly. All other
            <br />
            inquiries and/or notices that violate and/or disseminate from this procedure enumerated herein will not
            <br />
            be acknowledged as proper notice by Panorama Swap and will not receive a response.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <strong>
              18. Miscellaneous
              <br />
              Disputes
              <br />
            </strong>
            Any dispute arising out of or related to these Terms of Use is personal to you and Panorama Swap and
            <br />
            will be resolved solely through individual arbitration and will not be br/ought as a class arbitration,
            class
            <br />
            action, or any other type of representative proceeding.
            <br />
            Both parties will use their best efforts to settle in good faith and through amicable negotiations any
            <br />
            disputes which may occur between them arising out of or relating to this Agreement; the existence,
            <br />
            validity, termination, interpretation of any term hereof; and disputes regarding your use of the Website
            <br />
            and the Services. If you have any such dispute or claim, you agree that before you commence any
            <br />
            proceeding or request for arbitration or take any action that is intended or would reasonably be
            <br />
            expected to harm Panorama Swap or its reputation, you will first contact Panorama Swap about the
            <br />
            problem at support@panoramaswap.com. You agree to provide Panorama Swap with 60 calendar days
            <br />
            to investigate the dispute and attempt to provide a consensual resolution and agree to negotiate and
            <br />
            act in good faith during this period. To the extent that such disputes cannot be resolved, the only forum
            <br />
            and remedy will be final and binding arbitration conducted confidentially by a single arbitrator per the
            <br />
            LCIA Arbitration Rules of the London Court of International Arbitration, which is hereby incorporated by
            <br />
            reference. The LCIA Arbitration Rules of the London Court of International Arbitration are available on
            <br />
            the LCIA website.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <br />
            <strong>
              Taxation
              <br />
            </strong>
            You agree and understand that Panorama Swap does not provide legal, tax, or investment advice, and
            <br />
            do not provide support in matters related to taxes, payment of taxes and any other your interactions
            <br />
            with tax authorities in your jurisdiction.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <br />
            <strong>
              Modification and Notices
              <br />
            </strong>
            Panorama Swap, in its sole discretion, reserves the right to amend these Terms of Use from time to
            <br />
            time. You can see the date of our last chance at these Terms of Use at the top of this page. Any updates
            <br />
            will be made available here and will be effective immediately. Continued use of the Website and/or the
            <br />
            Services following any modification constitutes your acceptance of the modification. Panorama Swap
            <br />
            also reserves the right to modify or discontinue any individual feature associated with the Website
            <br />
            and/or the Services, with or without notice. Any notices to Panorama Swap should be sent to
            <br />
            support@panoramaswap.com, and any notices to you will be sent to the User’s email address. You
            <br />
            consent to the use of electronic notices under this Agreement.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <br />
            <strong>
              Relationship
              <br />
            </strong>
            Each party is an independent contractor, and nothing here should be seen as forming a partnership,
            <br />
            joint venture, or agency relationship.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <br />
            <strong>
              Assignability
              <br />
            </strong>
            Only Panorama Swap in its discretion, may assign these Terms of Use to any subsidiary or affiliate or
            <br />
            under a sale, reorganization, merger, or transfer to another entity. Notwithstanding the foregoing, this
            <br />
            paragraph will not affect your rights to terminate under section 15 of these Terms of Use.
            <br />
            Severability
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            If a court of competent jurisdiction determines that a section of these Terms of Use is invalid, illegal, or
            <br />
            unenforceable for any reason, that section will be limited or amended to the minimum extent
            <br />
            necessary, and all of the other sections of these Terms of Use will remain in full force and effect.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <br />
            <strong>
              Force Majeure
              <br />
            </strong>
            Neither you nor Panorama Swap will be liable for any error or interruption that is caused by a condition
            <br />
            that was beyond its reasonable control such as natural disasters or governmental decrees for as long as
            <br />
            they persist.
          </Typography>

          <Typography sx={{ marginBottom: "30px", fontSize: "18px" }}>
            <br />
            <strong>
              Choice of Law and Venue
              <br />
            </strong>
            The laws of Germany will govern this Agreement without giving effect to conflict of law principles or the
            <br />
            location of your use, and you agree to submit to the exclusive jurisdiction and venue of German courts.
          </Typography>
          <br />
        </HeroContainer>
      </Page>
    </Wrapper>
  );
};

export default TermsOfUse;
