import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useResize from "../../hooks/useResize";

import { customToFixed, getTokenVolume, numberWithCommas } from "../../Utils/functions/globalFunctions";

import Wrapper from "../Helpers/Wrapper";
import { Typography, Box, Card, TableBody, TableHead, Table, TableContainer } from "@mui/material";
import { CellBoxValue, StyledTableCell, StyledTableRow, TopStyledTableRow } from "../../Style/Tables/AllPoolsTable";

import ReserveBalance from "../Helpers/ReserveBalance";

const AllPoolsTableRow = ({ pair, id }) => {
  const dispatch = useDispatch();
  const resize = useResize();
  const navigate = useNavigate();

  const { tokens } = useSelector((state) => state.tokens);
  const { network_token } = useSelector((state) => state.tokens);
  const { api } = useSelector((state) => state.contracts);
  const { connectedNetwork } = useSelector((state) => state.account);

  const [volumeTokenA, setVolumeTokenA] = useState();
  const [volumeTokenB, setVolumeTokenB] = useState();

  const [psp22Fees, setPsp22Fees] = useState();
  const [psp22Apy, setPsp22Apy] = useState();
  const [azeroFees, setAzeroFees] = useState();
  const [azeroApy, setAzeroApy] = useState();

  const getData = async () => {
    await getTokenAVolume(pair?.token_a_address);
    await getTokenBVolume(pair?.token_b_address);
  };

  useEffect(() => {
    getData();
    const dataInterval = setInterval(() => {
      getData();
    }, 20000);

    return () => {
      clearInterval(dataInterval);
    };
  }, [pair]);

  // Get token A volume and calculate fees and apy
  const getTokenAVolume = async (address) => {
    if (address) {
      let vol = await getTokenVolume(address, pair.pair_address, connectedNetwork);
      setVolumeTokenA(vol);
      calculateFeesApy(vol, pair.token_a_address);
      return vol;
    }
  };
  // Get token A volume and calculate fees and apy
  const getTokenBVolume = async (address) => {
    if (address) {
      let vol = await getTokenVolume(address, pair.pair_address, connectedNetwork);
      setVolumeTokenB(vol);
      calculateFeesApy(vol, pair.token_b_address);
      return vol;
    }
  };

  // calculate token fees and apy
  const calculateFeesApy = (volume, token_address) => {
    if (token_address?.toLowerCase() === network_token?.address?.toLowerCase()) {
      let fee = volume * 0.03;
      setAzeroFees(fee > 1 ? customToFixed(fee, 0) : fee);
      let apy = (fee * 0.03 * 365) / 100;
      setAzeroApy(apy > 1 ? customToFixed(apy, 0) : customToFixed(apy, apy == 0 ? 0 : 4));
    } else {
      let fee = volume * 0.03;
      setPsp22Fees(fee > 1 ? customToFixed(fee, 0) : fee);
      let apy = (fee * 0.03 * 365) / 100;
      setPsp22Apy(apy > 1 ? customToFixed(apy, 0) : customToFixed(apy, apy == 0 ? 0 : 4));
    }
  };

  // Get tokens images functions
  const getTokenImage = (address) => {
    let img = tokens?.filter((t) => t.address === address);
    img = img[0]?.icon;
    return (
      <Wrapper>
        <img src={img} style={{ width: "20px", borderRadius: "50px" }} alt="" />
      </Wrapper>
    );
  };

  return (
    <Wrapper>
      {resize > 768 ? (
        <StyledTableRow
          onClick={() => {
            navigate(`/liquidity/${pair.pair_address}`);
          }}
          key={id}
          hover={true}
        >
          {/* Tokens names and images */}
          <StyledTableCell>
            <CellBoxValue>
              {getTokenImage(pair.token_a_address)}
              {getTokenImage(pair.token_b_address)}
              <Box sx={{ display: "flex", marginLeft: "3px", flexDirection: resize < 769 ? "column" : "row" }}>
                <Typography
                  sx={{
                    marginLeft: "3px",
                    textTransform: "uppercase",
                    "@media(max-width:768px)": {
                      fontSize: "12px",
                    },
                  }}
                >
                  {pair.token_a_symbol}/
                </Typography>

                <Typography
                  sx={{
                    textTransform: "uppercase",
                    "@media(max-width:768px)": {
                      fontSize: "12px",
                    },
                  }}
                >
                  {pair.token_b_symbol}
                </Typography>
              </Box>
            </CellBoxValue>
          </StyledTableCell>

          {/* Tokens Reserves balances */}
          <StyledTableCell>
            <Box>
              <CellBoxValue>
                <ReserveBalance tokenAddress={pair.token_a_address} pairAddress={pair.pair_address} />{" "}
                {resize > 768 && getTokenImage(pair.token_a_address)}
              </CellBoxValue>
              <CellBoxValue>
                <ReserveBalance tokenAddress={pair.token_b_address} pairAddress={pair.pair_address} />{" "}
                {resize > 768 && getTokenImage(pair.token_b_address)}
              </CellBoxValue>
            </Box>
          </StyledTableCell>

          {/* Tokens volumes */}
          <StyledTableCell>
            <Box>
              <CellBoxValue>
                {volumeTokenA ? numberWithCommas(volumeTokenA) : "0.000"}
                {resize > 768 && getTokenImage(pair.token_a_address)}
              </CellBoxValue>
              <CellBoxValue>
                {volumeTokenB ? numberWithCommas(volumeTokenB) : "0.000"}
                {resize > 768 && getTokenImage(pair.token_b_address)}
              </CellBoxValue>
            </Box>
          </StyledTableCell>

          {/* Tokens Fees */}
          {resize > 768 && (
            <StyledTableCell>
              <Box>
                <CellBoxValue>
                  {pair.token_a_address !== network_token?.address?.toLowerCase()
                    ? numberWithCommas(psp22Fees)
                    : numberWithCommas(azeroFees)}{" "}
                  {resize > 768 && getTokenImage(pair.token_a_address)}
                </CellBoxValue>
                <CellBoxValue>
                  {pair.token_b_address !== network_token?.address?.toLowerCase()
                    ? numberWithCommas(psp22Fees)
                    : numberWithCommas(azeroFees)}{" "}
                  {resize > 768 && getTokenImage(pair.token_b_address)}
                </CellBoxValue>
              </Box>
            </StyledTableCell>
          )}

          {/* Tokens APY */}
          <StyledTableCell>
            <Box>
              <CellBoxValue>
                {pair.token_a_address !== network_token?.address?.toLowerCase()
                  ? numberWithCommas(psp22Apy)
                  : numberWithCommas(azeroApy)}
                % {resize > 768 && getTokenImage(pair.token_a_address)}
              </CellBoxValue>
              <CellBoxValue>
                {pair.token_b_address !== network_token?.address?.toLowerCase()
                  ? numberWithCommas(psp22Apy)
                  : numberWithCommas(azeroApy)}
                % {resize > 768 && getTokenImage(pair.token_b_address)}
              </CellBoxValue>
            </Box>
          </StyledTableCell>

          {resize > 768 && (
            <Wrapper>
              {pair.pair_address === "5H1jcbhtrxbgmsYHSgNYpCMYqpsrKVYkPTyGoYkGPk5uWXjL" && (
                <Box
                  sx={{
                    position: "absolute",
                    right: "0",
                    top: "0",
                    backgroundColor: "#ffffffc7",
                    // borderRadius: "0 10px 0 0",
                    fontSize: "12px",
                    color: "#282830",
                    padding: "5px",
                  }}
                >
                  <b>V3</b>
                </Box>
              )}
            </Wrapper>
          )}
        </StyledTableRow>
      ) : (
        <Box
          onClick={() => {
            navigate(`/liquidity/${pair.pair_address}`);
          }}
          key={id}
          sx={{
            padding: "20px 10px",
            border: "1px solid rgba(255, 255, 255, 0.2)",
            borderRadius: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.3)",
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {getTokenImage(pair.token_a_address)}
            {getTokenImage(pair.token_b_address)}{" "}
            <Box sx={{ display: "flex", marginLeft: "3px" }}>
              <Typography
                sx={{
                  marginLeft: "3px",
                  textTransform: "uppercase",
                  fontSize: "20px",
                }}
              >
                {pair.token_a_symbol}-
              </Typography>

              <Typography
                sx={{
                  textTransform: "uppercase",
                  fontSize: "20px",
                }}
              >
                {pair.token_b_symbol}
              </Typography>
            </Box>
          </Box>
          <TableContainer
            sx={{
              marginTop: "20px",
              display: "block",
              whiteSpace: "nowrap",
              overflow: "auto hidden",
              maxWidth: "100%",
            }}
          >
            <Table aria-label="simple table" sx={{ marginBottom: "10px" }}>
              <TableHead>
                <TopStyledTableRow>
                  <StyledTableCell>Reserves</StyledTableCell>
                  {/* <StyledTableCell>Liquidity</StyledTableCell> */}
                  <StyledTableCell>Volume(24H)</StyledTableCell>
                  {resize > 768 && <StyledTableCell>Fees(24H)</StyledTableCell>}
                  <StyledTableCell>APY</StyledTableCell>
                </TopStyledTableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow
                  onClick={() => {
                    navigate(`/liquidity/${pair.pair_address}`);
                  }}
                  key={id}
                  width="100%"
                >
                  {/* Tokens Reserves balances */}
                  <StyledTableCell width="33%">
                    <Box>
                      <CellBoxValue>
                        <ReserveBalance tokenAddress={pair.token_a_address} pairAddress={pair.pair_address} />{" "}
                        {getTokenImage(pair.token_a_address)}
                      </CellBoxValue>
                      <CellBoxValue>
                        <ReserveBalance tokenAddress={pair.token_b_address} pairAddress={pair.pair_address} />{" "}
                        {getTokenImage(pair.token_b_address)}
                      </CellBoxValue>
                    </Box>
                  </StyledTableCell>

                  {/* Tokens volumes */}
                  <StyledTableCell width="33%">
                    <Box>
                      <CellBoxValue>
                        {volumeTokenA ? numberWithCommas(volumeTokenA) : "0.000"}
                        {getTokenImage(pair.token_a_address)}
                      </CellBoxValue>
                      <CellBoxValue>
                        {volumeTokenB ? numberWithCommas(volumeTokenB) : "0.000"}
                        {getTokenImage(pair.token_b_address)}
                      </CellBoxValue>
                    </Box>
                  </StyledTableCell>

                  {/* Tokens Fees */}
                  {resize > 768 && (
                    <StyledTableCell>
                      <Box>
                        <CellBoxValue>
                          {pair.token_a_address !== network_token?.address?.toLowerCase()
                            ? numberWithCommas(psp22Fees)
                            : numberWithCommas(azeroFees)}{" "}
                          {getTokenImage(pair.token_a_address)}
                        </CellBoxValue>
                        <CellBoxValue>
                          {pair.token_b_address !== network_token?.address?.toLowerCase()
                            ? numberWithCommas(psp22Fees)
                            : numberWithCommas(azeroFees)}{" "}
                          {getTokenImage(pair.token_b_address)}
                        </CellBoxValue>
                      </Box>
                    </StyledTableCell>
                  )}

                  {/* Tokens APY */}
                  <StyledTableCell width="33%">
                    <Box>
                      <CellBoxValue>
                        {pair.token_a_address !== network_token?.address?.toLowerCase()
                          ? numberWithCommas(psp22Apy)
                          : numberWithCommas(azeroApy)}
                        % {getTokenImage(pair.token_a_address)}
                      </CellBoxValue>
                      <CellBoxValue>
                        {pair.token_b_address !== network_token?.address?.toLowerCase()
                          ? numberWithCommas(psp22Apy)
                          : numberWithCommas(azeroApy)}
                        % {getTokenImage(pair.token_b_address)}
                      </CellBoxValue>
                    </Box>
                  </StyledTableCell>

                  {resize > 768 && (
                    <Wrapper>
                      {pair.pair_address === "5HkiqCjmhSbCFxYiKge5BfKimdKp3sjQ77YLEESPBMQqMNjH" && (
                        <Box
                          sx={{
                            position: "absolute",
                            right: "0",
                            top: "0",
                            backgroundColor: "#ffffffc7",
                            // borderRadius: "0 10px 0 0",
                            fontSize: "12px",
                            color: "#282830",
                            padding: "5px",
                          }}
                        >
                          <b>V2</b>
                        </Box>
                      )}
                    </Wrapper>
                  )}
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Wrapper>
  );
};

export default AllPoolsTableRow;
