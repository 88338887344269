import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

// Styled input box wrapper
export const InputBoxWrapper = styled(Box)(({ theme, marginT }) => ({
  marginTop: marginT ? marginT : "",
  display: "flex",
  flexDirection: "column",
}));

// Styled top box input
export const InputBoxTop = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

// Styled bottom box input
export const InputBoxBottom = styled(Box)(({ theme, width, border, marginTop }) => ({
  textTransform: "capitalize",
  marginTop: marginTop ? marginTop : "10px",
  borderRadius: "10px",
  display: "flex",
  alignItems: "center",
  background: "#26262c",
  width: width,
  border: border && "1px solid rgba(255, 255, 255, 0.16)",
}));

// Styled  box title
export const BoxInputTitle = styled(Typography)(({ theme, fontSize }) => ({
  fontSize: fontSize ? fontSize : "16px",
  opacity: 0.5,
  textTransform: "capitalize",
  "@media(max-width:768px)": {
    fontSize: "12px",
  },
}));
