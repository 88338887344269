// **** PSP22 ABI **** //
// **** lOCAL TESTING **** //
export const abiPsp22Token = {
  source: {
    hash: "0x953a36509e197b5f14e8bea9011a901e88b5be8fd1b529b583879cc5ab6307c8",
    language: "ink! 4.3.0",
    compiler: "rustc 1.68.0-nightly",
    build_info: {
      build_mode: "Release",
      cargo_contract_version: "2.0.0",
      rust_toolchain: "nightly-aarch64-apple-darwin",
      wasm_opt_settings: {
        keep_debug_symbols: false,
        optimization_passes: "Z",
      },
    },
  },
  contract: {
    name: "my_psp22",
    version: "1.0.0",
    authors: ["Yonatan Hornstein <yonatan@panoramaswap.com>"],
  },
  spec: {
    constructors: [
      {
        args: [
          {
            label: "initial_supply",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
          {
            label: "name",
            type: {
              displayName: ["Option"],
              type: 4,
            },
          },
          {
            label: "symbol",
            type: {
              displayName: ["Option"],
              type: 4,
            },
          },
          {
            label: "decimal",
            type: {
              displayName: ["u8"],
              type: 2,
            },
          },
        ],
        default: false,
        docs: [],
        label: "new",
        payable: false,
        returnType: {
          displayName: ["ink_primitives", "ConstructorResult"],
          type: 5,
        },
        selector: "0x9bae9d5e",
      },
    ],
    docs: [],
    environment: {
      accountId: {
        displayName: ["AccountId"],
        type: 8,
      },
      balance: {
        displayName: ["Balance"],
        type: 0,
      },
      blockNumber: {
        displayName: ["BlockNumber"],
        type: 20,
      },
      chainExtension: {
        displayName: ["ChainExtension"],
        type: 21,
      },
      hash: {
        displayName: ["Hash"],
        type: 18,
      },
      maxEventTopics: 4,
      timestamp: {
        displayName: ["Timestamp"],
        type: 19,
      },
    },
    events: [
      {
        args: [
          {
            docs: [],
            indexed: true,
            label: "from",
            type: {
              displayName: ["Option"],
              type: 17,
            },
          },
          {
            docs: [],
            indexed: true,
            label: "to",
            type: {
              displayName: ["Option"],
              type: 17,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "value",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        docs: ["Event emitted when a token transfer occurs."],
        label: "Transfer",
      },
      {
        args: [
          {
            docs: [],
            indexed: true,
            label: "owner",
            type: {
              displayName: ["AccountId"],
              type: 8,
            },
          },
          {
            docs: [],
            indexed: true,
            label: "spender",
            type: {
              displayName: ["AccountId"],
              type: 8,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "value",
            type: {
              displayName: ["Balance"],
              type: 0,
            },
          },
        ],
        docs: [
          "Event emitted when an approval occurs that `spender` is allowed to withdraw",
          "up to the amount of `value` tokens from `owner`.",
        ],
        label: "Approval",
      },
    ],
    lang_error: {
      displayName: ["ink", "LangError"],
      type: 7,
    },
    messages: [
      {
        args: [
          {
            label: "spender",
            type: {
              displayName: ["psp22_external", "DecreaseAllowanceInput1"],
              type: 8,
            },
          },
          {
            label: "delta_value",
            type: {
              displayName: ["psp22_external", "DecreaseAllowanceInput2"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [],
        label: "PSP22::decrease_allowance",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 10,
        },
        selector: "0xfecb57d5",
      },
      {
        args: [
          {
            label: "owner",
            type: {
              displayName: ["psp22_external", "BalanceOfInput1"],
              type: 8,
            },
          },
        ],
        default: false,
        docs: [],
        label: "PSP22::balance_of",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 13,
        },
        selector: "0x6568382f",
      },
      {
        args: [
          {
            label: "spender",
            type: {
              displayName: ["psp22_external", "IncreaseAllowanceInput1"],
              type: 8,
            },
          },
          {
            label: "delta_value",
            type: {
              displayName: ["psp22_external", "IncreaseAllowanceInput2"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [],
        label: "PSP22::increase_allowance",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 10,
        },
        selector: "0x96d6b57a",
      },
      {
        args: [
          {
            label: "to",
            type: {
              displayName: ["psp22_external", "TransferInput1"],
              type: 8,
            },
          },
          {
            label: "value",
            type: {
              displayName: ["psp22_external", "TransferInput2"],
              type: 0,
            },
          },
          {
            label: "data",
            type: {
              displayName: ["psp22_external", "TransferInput3"],
              type: 14,
            },
          },
        ],
        default: false,
        docs: [],
        label: "PSP22::transfer",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 10,
        },
        selector: "0xdb20f9f5",
      },
      {
        args: [
          {
            label: "owner",
            type: {
              displayName: ["psp22_external", "AllowanceInput1"],
              type: 8,
            },
          },
          {
            label: "spender",
            type: {
              displayName: ["psp22_external", "AllowanceInput2"],
              type: 8,
            },
          },
        ],
        default: false,
        docs: [],
        label: "PSP22::allowance",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 13,
        },
        selector: "0x4d47d921",
      },
      {
        args: [
          {
            label: "spender",
            type: {
              displayName: ["psp22_external", "ApproveInput1"],
              type: 8,
            },
          },
          {
            label: "value",
            type: {
              displayName: ["psp22_external", "ApproveInput2"],
              type: 0,
            },
          },
        ],
        default: false,
        docs: [],
        label: "PSP22::approve",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 10,
        },
        selector: "0xb20f1bbd",
      },
      {
        args: [
          {
            label: "from",
            type: {
              displayName: ["psp22_external", "TransferFromInput1"],
              type: 8,
            },
          },
          {
            label: "to",
            type: {
              displayName: ["psp22_external", "TransferFromInput2"],
              type: 8,
            },
          },
          {
            label: "value",
            type: {
              displayName: ["psp22_external", "TransferFromInput3"],
              type: 0,
            },
          },
          {
            label: "data",
            type: {
              displayName: ["psp22_external", "TransferFromInput4"],
              type: 14,
            },
          },
        ],
        default: false,
        docs: [],
        label: "PSP22::transfer_from",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 10,
        },
        selector: "0x54b3c76e",
      },
      {
        args: [],
        default: false,
        docs: [],
        label: "PSP22::total_supply",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 13,
        },
        selector: "0x162df8c2",
      },
      {
        args: [],
        default: false,
        docs: [],
        label: "PSP22Metadata::token_decimals",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 15,
        },
        selector: "0x7271b782",
      },
      {
        args: [],
        default: false,
        docs: [],
        label: "PSP22Metadata::token_name",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 16,
        },
        selector: "0x3d261bd4",
      },
      {
        args: [],
        default: false,
        docs: [],
        label: "PSP22Metadata::token_symbol",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 16,
        },
        selector: "0x34205be5",
      },
    ],
  },
  storage: {
    root: {
      layout: {
        struct: {
          fields: [
            {
              layout: {
                struct: {
                  fields: [
                    {
                      layout: {
                        root: {
                          layout: {
                            leaf: {
                              key: "0x270a8fc3",
                              ty: 0,
                            },
                          },
                          root_key: "0x270a8fc3",
                        },
                      },
                      name: "supply",
                    },
                    {
                      layout: {
                        root: {
                          layout: {
                            leaf: {
                              key: "0xc2664826",
                              ty: 0,
                            },
                          },
                          root_key: "0xc2664826",
                        },
                      },
                      name: "balances",
                    },
                    {
                      layout: {
                        root: {
                          layout: {
                            leaf: {
                              key: "0xf8d71e22",
                              ty: 0,
                            },
                          },
                          root_key: "0xf8d71e22",
                        },
                      },
                      name: "allowances",
                    },
                  ],
                  name: "Data",
                },
              },
              name: "psp22",
            },
            {
              layout: {
                struct: {
                  fields: [
                    {
                      layout: {
                        root: {
                          layout: {
                            enum: {
                              dispatchKey: "0x90a00b7d",
                              name: "Option",
                              variants: {
                                0: {
                                  fields: [],
                                  name: "None",
                                },
                                1: {
                                  fields: [
                                    {
                                      layout: {
                                        leaf: {
                                          key: "0x90a00b7d",
                                          ty: 1,
                                        },
                                      },
                                      name: "0",
                                    },
                                  ],
                                  name: "Some",
                                },
                              },
                            },
                          },
                          root_key: "0x90a00b7d",
                        },
                      },
                      name: "name",
                    },
                    {
                      layout: {
                        root: {
                          layout: {
                            enum: {
                              dispatchKey: "0xf8019f84",
                              name: "Option",
                              variants: {
                                0: {
                                  fields: [],
                                  name: "None",
                                },
                                1: {
                                  fields: [
                                    {
                                      layout: {
                                        leaf: {
                                          key: "0xf8019f84",
                                          ty: 1,
                                        },
                                      },
                                      name: "0",
                                    },
                                  ],
                                  name: "Some",
                                },
                              },
                            },
                          },
                          root_key: "0xf8019f84",
                        },
                      },
                      name: "symbol",
                    },
                    {
                      layout: {
                        root: {
                          layout: {
                            leaf: {
                              key: "0xd29264d8",
                              ty: 2,
                            },
                          },
                          root_key: "0xd29264d8",
                        },
                      },
                      name: "decimals",
                    },
                  ],
                  name: "Data",
                },
              },
              name: "metadata",
            },
            {
              layout: {
                leaf: {
                  key: "0x00000000",
                  ty: 0,
                },
              },
              name: "holders",
            },
            {
              layout: {
                root: {
                  layout: {
                    leaf: {
                      key: "0xe0175378",
                      ty: 3,
                    },
                  },
                  root_key: "0xe0175378",
                },
              },
              name: "is_holder",
            },
          ],
          name: "MyPsp22",
        },
      },
      root_key: "0x00000000",
    },
  },
  types: [
    {
      id: 0,
      type: {
        def: {
          primitive: "u128",
        },
      },
    },
    {
      id: 1,
      type: {
        def: {
          primitive: "str",
        },
      },
    },
    {
      id: 2,
      type: {
        def: {
          primitive: "u8",
        },
      },
    },
    {
      id: 3,
      type: {
        def: {
          primitive: "bool",
        },
      },
    },
    {
      id: 4,
      type: {
        def: {
          variant: {
            variants: [
              {
                index: 0,
                name: "None",
              },
              {
                fields: [
                  {
                    type: 1,
                  },
                ],
                index: 1,
                name: "Some",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 1,
          },
        ],
        path: ["Option"],
      },
    },
    {
      id: 5,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 6,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 7,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 6,
          },
          {
            name: "E",
            type: 7,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 6,
      type: {
        def: {
          tuple: [],
        },
      },
    },
    {
      id: 7,
      type: {
        def: {
          variant: {
            variants: [
              {
                index: 1,
                name: "CouldNotReadInput",
              },
            ],
          },
        },
        path: ["ink_primitives", "LangError"],
      },
    },
    {
      id: 8,
      type: {
        def: {
          composite: {
            fields: [
              {
                type: 9,
                typeName: "[u8; 32]",
              },
            ],
          },
        },
        path: ["ink_primitives", "types", "AccountId"],
      },
    },
    {
      id: 9,
      type: {
        def: {
          array: {
            len: 32,
            type: 2,
          },
        },
      },
    },
    {
      id: 10,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 11,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 7,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 11,
          },
          {
            name: "E",
            type: 7,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 11,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 6,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 12,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 6,
          },
          {
            name: "E",
            type: 12,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 12,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 1,
                    typeName: "String",
                  },
                ],
                index: 0,
                name: "Custom",
              },
              {
                index: 1,
                name: "InsufficientBalance",
              },
              {
                index: 2,
                name: "InsufficientAllowance",
              },
              {
                index: 3,
                name: "ZeroRecipientAddress",
              },
              {
                index: 4,
                name: "ZeroSenderAddress",
              },
              {
                fields: [
                  {
                    type: 1,
                    typeName: "String",
                  },
                ],
                index: 5,
                name: "SafeTransferCheckFailed",
              },
            ],
          },
        },
        path: ["openbrush_contracts", "traits", "errors", "psp22", "PSP22Error"],
      },
    },
    {
      id: 13,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 0,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 7,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 0,
          },
          {
            name: "E",
            type: 7,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 14,
      type: {
        def: {
          sequence: {
            type: 2,
          },
        },
      },
    },
    {
      id: 15,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 2,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 7,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 2,
          },
          {
            name: "E",
            type: 7,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 16,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 4,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 7,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 4,
          },
          {
            name: "E",
            type: 7,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 17,
      type: {
        def: {
          variant: {
            variants: [
              {
                index: 0,
                name: "None",
              },
              {
                fields: [
                  {
                    type: 8,
                  },
                ],
                index: 1,
                name: "Some",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 8,
          },
        ],
        path: ["Option"],
      },
    },
    {
      id: 18,
      type: {
        def: {
          composite: {
            fields: [
              {
                type: 9,
                typeName: "[u8; 32]",
              },
            ],
          },
        },
        path: ["ink_primitives", "types", "Hash"],
      },
    },
    {
      id: 19,
      type: {
        def: {
          primitive: "u64",
        },
      },
    },
    {
      id: 20,
      type: {
        def: {
          primitive: "u32",
        },
      },
    },
    {
      id: 21,
      type: {
        def: {
          variant: {},
        },
        path: ["ink_env", "types", "NoChainExtension"],
      },
    },
  ],
  version: "4",
};

// **** PRODUCTION ABI **** //
// export const abiPsp22Token = {
//   source: {
//     hash: "0x9f0fe0edf848ad6ac194fade71a1566c5df0f7982919f6c5f9344926eac0d850",
//     language: "ink! 4.0.0-beta",
//     compiler: "rustc 1.65.0",
//     build_info: {
//       build_mode: "Debug",
//       cargo_contract_version: "2.0.0-beta",
//       rust_toolchain: "stable-aarch64-apple-darwin",
//       wasm_opt_settings: {
//         keep_debug_symbols: false,
//         optimization_passes: "Z",
//       },
//     },
//   },
//   contract: {
//     name: "psp22",
//     version: "0.9.6",
//     authors: ["Yonatan Hornstein <yonatan@panoramaswap.com>"],
//   },
//   spec: {
//     constructors: [
//       {
//         args: [
//           {
//             label: "total_supply",
//             type: {
//               displayName: ["Balance"],
//               type: 0,
//             },
//           },
//           {
//             label: "name",
//             type: {
//               displayName: ["Option"],
//               type: 4,
//             },
//           },
//           {
//             label: "symbol",
//             type: {
//               displayName: ["Option"],
//               type: 4,
//             },
//           },
//           {
//             label: "decimal",
//             type: {
//               displayName: ["u8"],
//               type: 3,
//             },
//           },
//         ],
//         docs: [],
//         label: "new",
//         payable: false,
//         returnType: {
//           displayName: ["ink_primitives", "ConstructorResult"],
//           type: 5,
//         },
//         selector: "0x9bae9d5e",
//       },
//     ],
//     docs: [],
//     events: [],
//     lang_error: {
//       displayName: ["ink", "LangError"],
//       type: 6,
//     },
//     messages: [
//       {
//         args: [
//           {
//             label: "owner",
//             type: {
//               displayName: ["psp22_external", "AllowanceInput1"],
//               type: 7,
//             },
//           },
//           {
//             label: "spender",
//             type: {
//               displayName: ["psp22_external", "AllowanceInput2"],
//               type: 7,
//             },
//           },
//         ],
//         docs: [
//           " Returns the amount which `spender` is still allowed to withdraw from `owner`.",
//           "",
//           " Returns `0` if no allowance has been set `0`.",
//         ],
//         label: "PSP22::allowance",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 9,
//         },
//         selector: "0x4d47d921",
//       },
//       {
//         args: [
//           {
//             label: "spender",
//             type: {
//               displayName: ["psp22_external", "DecreaseAllowanceInput1"],
//               type: 7,
//             },
//           },
//           {
//             label: "delta_value",
//             type: {
//               displayName: ["psp22_external", "DecreaseAllowanceInput2"],
//               type: 0,
//             },
//           },
//         ],
//         docs: [
//           " Atomically decreases the allowance granted to `spender` by the caller.",
//           "",
//           " An `Approval` event is emitted.",
//           "",
//           " # Errors",
//           "",
//           " Returns `InsufficientAllowance` error if there are not enough tokens allowed",
//           " by owner for `spender`.",
//           "",
//           " Returns `ZeroSenderAddress` error if sender's address is zero.",
//           "",
//           " Returns `ZeroRecipientAddress` error if recipient's address is zero.",
//         ],
//         label: "PSP22::decrease_allowance",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 10,
//         },
//         selector: "0xfecb57d5",
//       },
//       {
//         args: [
//           {
//             label: "to",
//             type: {
//               displayName: ["psp22_external", "TransferInput1"],
//               type: 7,
//             },
//           },
//           {
//             label: "value",
//             type: {
//               displayName: ["psp22_external", "TransferInput2"],
//               type: 0,
//             },
//           },
//           {
//             label: "data",
//             type: {
//               displayName: ["psp22_external", "TransferInput3"],
//               type: 2,
//             },
//           },
//         ],
//         docs: [
//           " Transfers `value` amount of tokens from the caller's account to account `to`",
//           " with additional `data` in unspecified format.",
//           "",
//           " On success a `Transfer` event is emitted.",
//           "",
//           " # Errors",
//           "",
//           " Returns `InsufficientBalance` error if there are not enough tokens on",
//           " the caller's account Balance.",
//           "",
//           " Returns `ZeroSenderAddress` error if sender's address is zero.",
//           "",
//           " Returns `ZeroRecipientAddress` error if recipient's address is zero.",
//         ],
//         label: "PSP22::transfer",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 10,
//         },
//         selector: "0xdb20f9f5",
//       },
//       {
//         args: [
//           {
//             label: "owner",
//             type: {
//               displayName: ["psp22_external", "BalanceOfInput1"],
//               type: 7,
//             },
//           },
//         ],
//         docs: [
//           " Returns the account Balance for the specified `owner`.",
//           "",
//           " Returns `0` if the account is non-existent.",
//         ],
//         label: "PSP22::balance_of",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 9,
//         },
//         selector: "0x6568382f",
//       },
//       {
//         args: [
//           {
//             label: "spender",
//             type: {
//               displayName: ["psp22_external", "ApproveInput1"],
//               type: 7,
//             },
//           },
//           {
//             label: "value",
//             type: {
//               displayName: ["psp22_external", "ApproveInput2"],
//               type: 0,
//             },
//           },
//         ],
//         docs: [
//           " Allows `spender` to withdraw from the caller's account multiple times, up to",
//           " the `value` amount.",
//           "",
//           " If this function is called again it overwrites the current allowance with `value`.",
//           "",
//           " An `Approval` event is emitted.",
//           "",
//           " # Errors",
//           "",
//           " Returns `ZeroSenderAddress` error if sender's address is zero.",
//           "",
//           " Returns `ZeroRecipientAddress` error if recipient's address is zero.",
//         ],
//         label: "PSP22::approve",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 10,
//         },
//         selector: "0xb20f1bbd",
//       },
//       {
//         args: [],
//         docs: [" Returns the total token supply."],
//         label: "PSP22::total_supply",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 9,
//         },
//         selector: "0x162df8c2",
//       },
//       {
//         args: [
//           {
//             label: "spender",
//             type: {
//               displayName: ["psp22_external", "IncreaseAllowanceInput1"],
//               type: 7,
//             },
//           },
//           {
//             label: "delta_value",
//             type: {
//               displayName: ["psp22_external", "IncreaseAllowanceInput2"],
//               type: 0,
//             },
//           },
//         ],
//         docs: [
//           " Atomically increases the allowance granted to `spender` by the caller.",
//           "",
//           " An `Approval` event is emitted.",
//           "",
//           " # Errors",
//           "",
//           " Returns `ZeroSenderAddress` error if sender's address is zero.",
//           "",
//           " Returns `ZeroRecipientAddress` error if recipient's address is zero.",
//         ],
//         label: "PSP22::increase_allowance",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 10,
//         },
//         selector: "0x96d6b57a",
//       },
//       {
//         args: [
//           {
//             label: "from",
//             type: {
//               displayName: ["psp22_external", "TransferFromInput1"],
//               type: 7,
//             },
//           },
//           {
//             label: "to",
//             type: {
//               displayName: ["psp22_external", "TransferFromInput2"],
//               type: 7,
//             },
//           },
//           {
//             label: "value",
//             type: {
//               displayName: ["psp22_external", "TransferFromInput3"],
//               type: 0,
//             },
//           },
//           {
//             label: "data",
//             type: {
//               displayName: ["psp22_external", "TransferFromInput4"],
//               type: 2,
//             },
//           },
//         ],
//         docs: [
//           " Transfers `value` tokens on the behalf of `from` to the account `to`",
//           " with additional `data` in unspecified format.",
//           "",
//           " This can be used to allow a contract to transfer tokens on ones behalf and/or",
//           " to charge fees in sub-currencies, for example.",
//           "",
//           " On success a `Transfer` and `Approval` events are emitted.",
//           "",
//           " # Errors",
//           "",
//           " Returns `InsufficientAllowance` error if there are not enough tokens allowed",
//           " for the caller to withdraw from `from`.",
//           "",
//           " Returns `InsufficientBalance` error if there are not enough tokens on",
//           " the the account Balance of `from`.",
//           "",
//           " Returns `ZeroSenderAddress` error if sender's address is zero.",
//           "",
//           " Returns `ZeroRecipientAddress` error if recipient's address is zero.",
//         ],
//         label: "PSP22::transfer_from",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 10,
//         },
//         selector: "0x54b3c76e",
//       },
//       {
//         args: [],
//         docs: [" Returns the token symbol."],
//         label: "PSP22Metadata::token_symbol",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 13,
//         },
//         selector: "0x34205be5",
//       },
//       {
//         args: [],
//         docs: [" Returns the token decimals."],
//         label: "PSP22Metadata::token_decimals",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 14,
//         },
//         selector: "0x7271b782",
//       },
//       {
//         args: [],
//         docs: [" Returns the token name."],
//         label: "PSP22Metadata::token_name",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 13,
//         },
//         selector: "0x3d261bd4",
//       },
//     ],
//   },
//   storage: {
//     root: {
//       layout: {
//         struct: {
//           fields: [
//             {
//               layout: {
//                 struct: {
//                   fields: [
//                     {
//                       layout: {
//                         leaf: {
//                           key: "0x00000000",
//                           ty: 0,
//                         },
//                       },
//                       name: "supply",
//                     },
//                     {
//                       layout: {
//                         root: {
//                           layout: {
//                             leaf: {
//                               key: "0x1d458d3b",
//                               ty: 0,
//                             },
//                           },
//                           root_key: "0x1d458d3b",
//                         },
//                       },
//                       name: "balances",
//                     },
//                     {
//                       layout: {
//                         root: {
//                           layout: {
//                             leaf: {
//                               key: "0x0abd72fb",
//                               ty: 0,
//                             },
//                           },
//                           root_key: "0x0abd72fb",
//                         },
//                       },
//                       name: "allowances",
//                     },
//                     {
//                       layout: {
//                         enum: {
//                           dispatchKey: "0x00000000",
//                           name: "Option",
//                           variants: {
//                             0: {
//                               fields: [],
//                               name: "None",
//                             },
//                             1: {
//                               fields: [
//                                 {
//                                   layout: {
//                                     leaf: {
//                                       key: "0x00000000",
//                                       ty: 1,
//                                     },
//                                   },
//                                   name: "0",
//                                 },
//                               ],
//                               name: "Some",
//                             },
//                           },
//                         },
//                       },
//                       name: "_reserved",
//                     },
//                   ],
//                   name: "Data",
//                 },
//               },
//               name: "psp22",
//             },
//             {
//               layout: {
//                 struct: {
//                   fields: [
//                     {
//                       layout: {
//                         enum: {
//                           dispatchKey: "0x00000000",
//                           name: "Option",
//                           variants: {
//                             0: {
//                               fields: [],
//                               name: "None",
//                             },
//                             1: {
//                               fields: [
//                                 {
//                                   layout: {
//                                     leaf: {
//                                       key: "0x00000000",
//                                       ty: 2,
//                                     },
//                                   },
//                                   name: "0",
//                                 },
//                               ],
//                               name: "Some",
//                             },
//                           },
//                         },
//                       },
//                       name: "name",
//                     },
//                     {
//                       layout: {
//                         enum: {
//                           dispatchKey: "0x00000000",
//                           name: "Option",
//                           variants: {
//                             0: {
//                               fields: [],
//                               name: "None",
//                             },
//                             1: {
//                               fields: [
//                                 {
//                                   layout: {
//                                     leaf: {
//                                       key: "0x00000000",
//                                       ty: 2,
//                                     },
//                                   },
//                                   name: "0",
//                                 },
//                               ],
//                               name: "Some",
//                             },
//                           },
//                         },
//                       },
//                       name: "symbol",
//                     },
//                     {
//                       layout: {
//                         leaf: {
//                           key: "0x00000000",
//                           ty: 3,
//                         },
//                       },
//                       name: "decimals",
//                     },
//                     {
//                       layout: {
//                         enum: {
//                           dispatchKey: "0x00000000",
//                           name: "Option",
//                           variants: {
//                             0: {
//                               fields: [],
//                               name: "None",
//                             },
//                             1: {
//                               fields: [
//                                 {
//                                   layout: {
//                                     leaf: {
//                                       key: "0x00000000",
//                                       ty: 1,
//                                     },
//                                   },
//                                   name: "0",
//                                 },
//                               ],
//                               name: "Some",
//                             },
//                           },
//                         },
//                       },
//                       name: "_reserved",
//                     },
//                   ],
//                   name: "Data",
//                 },
//               },
//               name: "metadata",
//             },
//           ],
//           name: "Contract",
//         },
//       },
//       root_key: "0x00000000",
//     },
//   },
//   types: [
//     {
//       id: 0,
//       type: {
//         def: {
//           primitive: "u128",
//         },
//       },
//     },
//     {
//       id: 1,
//       type: {
//         def: {
//           tuple: [],
//         },
//       },
//     },
//     {
//       id: 2,
//       type: {
//         def: {
//           sequence: {
//             type: 3,
//           },
//         },
//       },
//     },
//     {
//       id: 3,
//       type: {
//         def: {
//           primitive: "u8",
//         },
//       },
//     },
//     {
//       id: 4,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 index: 0,
//                 name: "None",
//               },
//               {
//                 fields: [
//                   {
//                     type: 2,
//                   },
//                 ],
//                 index: 1,
//                 name: "Some",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 2,
//           },
//         ],
//         path: ["Option"],
//       },
//     },
//     {
//       id: 5,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 1,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 6,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 1,
//           },
//           {
//             name: "E",
//             type: 6,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 6,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 index: 1,
//                 name: "CouldNotReadInput",
//               },
//             ],
//           },
//         },
//         path: ["ink_primitives", "LangError"],
//       },
//     },
//     {
//       id: 7,
//       type: {
//         def: {
//           composite: {
//             fields: [
//               {
//                 type: 8,
//                 typeName: "[u8; 32]",
//               },
//             ],
//           },
//         },
//         path: ["ink_primitives", "types", "AccountId"],
//       },
//     },
//     {
//       id: 8,
//       type: {
//         def: {
//           array: {
//             len: 32,
//             type: 3,
//           },
//         },
//       },
//     },
//     {
//       id: 9,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 0,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 6,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 0,
//           },
//           {
//             name: "E",
//             type: 6,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 10,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 11,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 6,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 11,
//           },
//           {
//             name: "E",
//             type: 6,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 11,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 1,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 12,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 1,
//           },
//           {
//             name: "E",
//             type: 12,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 12,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 2,
//                     typeName: "String",
//                   },
//                 ],
//                 index: 0,
//                 name: "Custom",
//               },
//               {
//                 index: 1,
//                 name: "InsufficientBalance",
//               },
//               {
//                 index: 2,
//                 name: "InsufficientAllowance",
//               },
//               {
//                 index: 3,
//                 name: "ZeroRecipientAddress",
//               },
//               {
//                 index: 4,
//                 name: "ZeroSenderAddress",
//               },
//               {
//                 fields: [
//                   {
//                     type: 2,
//                     typeName: "String",
//                   },
//                 ],
//                 index: 5,
//                 name: "SafeTransferCheckFailed",
//               },
//             ],
//           },
//         },
//         path: ["openbrush_contracts", "traits", "errors", "psp22", "PSP22Error"],
//       },
//     },
//     {
//       id: 13,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 4,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 6,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 4,
//           },
//           {
//             name: "E",
//             type: 6,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 14,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 3,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 6,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 3,
//           },
//           {
//             name: "E",
//             type: 6,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//   ],
//   version: "4",
// };
