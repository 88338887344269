import { useEffect, useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Typography, styled } from "@mui/material";
import { BoxInputTitle, InputBoxWrapper } from "../../Style/Components/inputs";
import useResize from "../../hooks/useResize";
import { DateTimePicker, MobileDatePicker, MobileDateTimePicker } from "@mui/x-date-pickers";

const CustomDatePicker = ({ setUnlockDate, disabledDatePicker }) => {
  const resize = useResize();

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={["DatePicker"]} sx={{ marginTop: "15px" }}>
        <InputBoxWrapper
          sx={{
            gap: "5px",
            width: "100%",
            border: "none",
          }}
        >
          <BoxInputTitle>Date until LP unlock</BoxInputTitle>
          {resize > 1028 ? (
            <StyledDatePicker
              disablePast
              disabled={disabledDatePicker}
              onChange={(newValue) => setUnlockDate(newValue)}
            />
          ) : (
            <MobileDateTimePicker disablePast disabled={disabledDatePicker} />
          )}
        </InputBoxWrapper>
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;

export const StyledDatePicker = styled(DateTimePicker)(({ theme, cap }) => ({
  borderRadius: "10px",
  background: "#26262c",
  // border: "1px solid rgba(255, 255, 255, 0.16)",
  ".css-18hbz7a-MuiInputBase-root-MuiOutlinedInput-root": {
    ">fieldset": {
      border: "0 !important",
    },
  },
}));
