import { createMuiTheme, ThemeProvider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import fromExponential from "from-exponential";
import React from "react";
//using for charts

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Box>
        <Typography sx={{ fontSize: "max(14px, 0.8vw)" }}>{`${label}`}</Typography>
        <Typography sx={{ fontWeight: "800" }}>{`price: ${fromExponential(payload[0].value)}`}</Typography>
      </Box>
    );
  }

  return null;
};

export default CustomTooltip;
