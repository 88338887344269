import axios from "axios";

// LOCAL
// const globalUrl = "http://localhost:5000/panorama/";

// PRODUCTION
// const globalUrl = "https://dev-panoramaswap.app/dev/"; // Testnet
const globalUrl = "https://panoramaswap.app/mainnet/"; // Mainnet

// ******************************************* //
// ********** Users api functions *********** //
// ***************************************** //
export const login = async (obj) => {
  return await axios.post(`${globalUrl}login?`, obj);
};
export const getAllUsersInsertedLp = async (obj, network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  return await axios.get(`${globalUrl}getAllUsersInsertedLp?`);
};
export const getUserByAddress = async (pair_address, user_address, network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  return await axios.get(`${globalUrl}getUserByAddress?pair_address=${pair_address}&user_address=${user_address}`);
};
export const insertUserInsertedLp = async (obj, cookie, network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  axios.defaults.headers.common["Authorization"] = cookie;
  return await axios.post(`${globalUrl}insertUserInsertedLp?`, obj);
};
export const deleteUserInsertedLp = async (obj, cookie, network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  axios.defaults.headers.common["Authorization"] = cookie;
  return await axios.post(`${globalUrl}deleteUserInsertedLp?`, obj);
};
export const updateUserInsertedLp = async (obj, cookie, network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  axios.defaults.headers.common["Authorization"] = cookie;
  return await axios.post(`${globalUrl}updateUserInsertedLp?`, obj);
};

export const getJSON50 = async () => {
  return await axios.get(`${globalUrl}json50`);
};
export const getJSON500 = async () => {
  return await axios.get(`${globalUrl}json500`);
};

// ******************************************* //
// ********** Pairs api functions *********** //
// ***************************************** //
export const getAllTokens = async (network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  return await axios.get(`${globalUrl}getAllTokens`);
};

export const getTokenByAddress = async (token_address, network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  return await axios.get(`${globalUrl}getTokenByAddress?token_address=${token_address}`);
};

export const getTokenPairs = async (network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  return await axios.get(`${globalUrl}getTokenPairs`);
};

export const getTokenPairsByAddress = async (token_address, network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  return await axios.get(`${globalUrl}getTokenPairsByAddress?token_address=${token_address}`);
};

export const getTokenPairByAddress = async (pair_address, network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  return await axios.get(`${globalUrl}getTokenPairByAddress?pair_address=${pair_address}`);
};

export const getTokenPairByAddresses = async (token_a_address, token_b_address, network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  return await axios.get(
    `${globalUrl}getTokenPairByAddresses?token_a_address=${token_a_address}&token_b_address=${token_b_address}`
  );
};

// Insert new pair to db
export const insertPair = async (obj, cookie, network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  axios.defaults.headers.common["Authorization"] = cookie;
  return await axios.post(`${globalUrl}insertPair?`, obj);
};

// Insert new token to db
export const insertToken = async (obj, cookie, network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  axios.defaults.headers.common["Authorization"] = cookie;
  return await axios.post(`${globalUrl}insertToken?`, obj);
};

export const getTokenSupply = async (token_address, network) => {
  return await axios.get(`${globalUrl}getTokenSupply?token_address=${token_address}&network=${network}`);
};

// ******************************************* //~
// **** Panx api functions ***** //
// ***************************************** //

// Insert panx price
export const insertPairPrice = async (obj, cookie, network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  axios.defaults.headers.common["Authorization"] = cookie;
  return await axios.post(`${globalUrl}insertPairPrice?`, obj);
};

//Get filtered prices
export const getPricesForStats = async ({ days, address }, network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  return await axios.get(`${globalUrl}getPricesForStats?days=${days}&pair_address=${address}`);
};

//Get filtered prices
export const getLastPairPrice = async (pair_address, network) => {
  return await axios.get(`${globalUrl}getLastPairPrice?pair_address=${pair_address}&network=${network}`);
};

// ******************************************* //
// **** Transactions api functions ***** //
// ***************************************** //

// Get all tokens
export const getAllTxns = async (network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  return await axios.get(`${globalUrl}getAllTxns`);
};

// Get 25 Transactions
export const get25TokenTxns = async (pair_address, network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  return await axios.get(`${globalUrl}getTxnsForStats?pair_address=${pair_address}`);
};

// Get Daily / Total Volume per Token
export const getVolumePerToken = async (address, limit, pair_address, network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  return await axios.get(
    `${globalUrl}getVolumePerToken?limit=${limit}&token_address=${address}&pair_address=${pair_address}`
  );
};

// Get Daily / Total Txns per Token
export const getSumTxns = async (address, limit, pair_address, network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  return await axios.get(`${globalUrl}getSumTxns?limit=${limit}&address=${address}&pair_address=${pair_address}`);
};

// Insert Panx Transaction
export const insertTxn = async (obj, cookie, network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  axios.defaults.headers.common["Authorization"] = cookie;
  return await axios.post(`${globalUrl}insertTxn?`, obj);
};

export const getTxnsByAccount = async (address, network) => {
  axios.defaults.headers.common["Connected_network"] = network;
  return await axios.get(`${globalUrl}getTxnsByAccount?address=${address}`);
};
