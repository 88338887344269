import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useResize from "../../hooks/useResize";

import { getTokenPairs } from "../../shared/api";

import Wrapper from "../Helpers/Wrapper";
import { Box, CircularProgress, Table, TableBody, TableContainer, TableHead } from "@mui/material";
import { StyledTableCell, TopStyledTableRow } from "../../Style/Tables/AllPoolsTable";
import { set_pairs } from "../../features/tokens";
import AllPoolsTableRow from "./AllPoolsTableRow";
import { InputBoxBottom } from "../../Style/Components/inputs";
import { StyledTextField } from "../../Style/general";
import SearchIcon from "@mui/icons-material/Search";
import CustomTableFooter from "./CustomTableFooter";
import useTable from "../../hooks/useTable";

const AllPoolsTable = ({ rowsPerPage }) => {
  const dispatch = useDispatch();
  const resize = useResize();

  const { connectedNetwork } = useSelector((state) => state.account);
  const { pairs } = useSelector((state) => state.tokens);

  const [allPairs, setAllPairs] = useState([]);

  const [page, setPage] = useState(1);
  const { slice, range } = useTable(allPairs, page, rowsPerPage);

  // const [filteredPairs, setFilteredPairs] = useState([]);

  useEffect(() => {
    connectedNetwork && getAllPairs();
  }, [connectedNetwork]);

  const getAllPairs = async () => {
    let pairs = await getTokenPairs(connectedNetwork);
    setAllPairs(pairs.data.data);
    dispatch(set_pairs(pairs.data.data));
  };

  // handle search filter when using the dialog.
  const handleSearchFilter = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.toLowerCase();
    if (searchValue.length > 0) {
      let filtered = [
        ...pairs.filter(
          (el) =>
            el.token_a_address.toLowerCase().startsWith(searchValue) ||
            el.token_b_address.toLowerCase().startsWith(searchValue) ||
            el.pair_address.toLowerCase().startsWith(searchValue) ||
            el.token_a_name.toLowerCase().startsWith(searchValue) ||
            el.token_b_name.toLowerCase().startsWith(searchValue) ||
            el.token_a_symbol.toLowerCase().startsWith(searchValue) ||
            el.token_b_symbol.toLowerCase().startsWith(searchValue)
        ),
      ];
      if (filtered.length > 0) {
        setAllPairs(filtered);
      } else {
        setAllPairs(pairs);
      }
    } else {
      setAllPairs(pairs);
    }
  };

  return (
    <Wrapper>
      {/* Search pools bar */}
      <InputBoxBottom width="100%" border>
        <StyledTextField
          width="95%"
          // defaultValue={searchValue}
          placeholder="Search by token or pair address"
          type="text"
          onInput={(e) => handleSearchFilter(e)}
        />
        <SearchIcon />
      </InputBoxBottom>

      {resize > 768 ? (
        <Wrapper>
          {slice.length > 0 ? (
            <TableContainer
              sx={{
                marginTop: "20px",
                display: "block",
                whiteSpace: "nowrap",
                overflow: "auto hidden",
                maxWidth: "100%",
              }}
            >
              <Table aria-label="simple table" sx={{ marginBottom: "10px" }}>
                <TableHead>
                  <TopStyledTableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Reserves</StyledTableCell>
                    {/* <StyledTableCell>Liquidity</StyledTableCell> */}
                    <StyledTableCell>Volume(24H)</StyledTableCell>
                    {resize > 768 && <StyledTableCell>Fees(24H)</StyledTableCell>}
                    <StyledTableCell>APY</StyledTableCell>
                  </TopStyledTableRow>
                </TableHead>
                <TableBody>
                  {slice?.map((pair, i) => (
                    <AllPoolsTableRow pair={pair} id={i} key={pair.id} />
                  ))}
                </TableBody>
              </Table>
              <CustomTableFooter range={range} slice={slice} setPage={setPage} page={page} />
            </TableContainer>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "100px" }}>
              <CircularProgress />
            </Box>
          )}
        </Wrapper>
      ) : (
        <Wrapper>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px", marginTop: "20px", cursor: "pointer" }}>
            {slice?.map((pair, i) => (
              <AllPoolsTableRow pair={pair} id={i} key={pair.id} />
            ))}
          </Box>
        </Wrapper>
      )}
    </Wrapper>
  );
};

export default AllPoolsTable;
