import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";

import { SupportedChainId } from "@azns/resolver-core";
import { useResolveDomainToAddress, useResolveAddressToDomain } from "@azns/resolver-react";

import { web3Accounts, web3Enable, web3FromAddress } from "@polkadot/extension-dapp";
import { ApiPromise, WsProvider } from "@polkadot/api";
import { ContractPromise } from "@polkadot/api-contract";

import { abiTradingPairAzero } from "./Utils/abi/abiTradingPairAzero";
import { abiPairCreator } from "./Utils/abi/abiPairCreator";
import { abiVesting } from "./Utils/abi/abiVesting";
import { abiAirdrop } from "./Utils/abi/abiAirdrop";

import RoutesNav from "./Routes/RoutesNav";
import useResize from "./hooks/useResize";
import { getAllTokens, getTokenPairByAddresses, getTokenPairs } from "./shared/api";

import { insert_accounts, insert_all_extensions } from "./features/allAccounts";
import {
  select_address,
  select_caller_account,
  set_connectedNetwork,
  set_domain,
  set_injector,
} from "./features/account";
import {
  set_current_pair,
  set_from_token,
  set_pairs,
  set_tokens,
  set_to_token,
  set_network_token,
} from "./features/tokens";
import {
  set_airdrop_contract,
  set_api,
  set_pair_creator_contract,
  set_tpa_contract,
  set_vesting_contract,
} from "./features/contracts";

import ChooseRpcUrlModal from "./Components/Modals/ChooseRpcUrlModal";

import { ThemeProvider, CssBaseline, Paper, Box, Typography } from "@mui/material";

import { theme, themeDark } from "./Style/themes";
import { Page } from "./Style/general";

import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";

import whiteLogo from "./Images/logos/panorama_white.png";
import blackLogo from "./Images/logos/panorama_black.png";
import CustomAlerts from "./Components/Helpers/CustomAlerts";

function App() {
  const resize = useResize();
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const { address } = useSelector((state) => state.account);
  const { connectedNetwork } = useSelector((state) => state.account);

  const { primaryDomain } = useResolveAddressToDomain(address && address[0], {
    // chainId: SupportedChainId.AlephZeroTestnet, // Testnet
    chainId: SupportedChainId.AlephZero, // Mainnet
  });

  const setDomain = () => {
    primaryDomain && cookies.set("domain", JSON.stringify(primaryDomain), { path: "/", maxAge: 86400 });
    dispatch(set_domain(primaryDomain));
  };
  useEffect(() => {
    setDomain();
  }, [address]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
  };

  const [connecting, setConnecting] = useState(false);
  // choose rpcUrl for the desired network.
  const chooseRpcUrl = (name) => {
    setConnecting(true);
    let rpcUrl;
    let tpa_env;
    let pair_creator_env;
    let vesting_env;
    let airdrop_env;

    if (name === "shiden") {
      tpa_env = process.env.REACT_APP_ASTAR_TRADING_PAIR_CONTRACT;
      pair_creator_env = process.env.REACT_APP_ASTAR_PAIR_CREATOR_CONTRACT;
      // rpcUrl = "wss://rpc.shibuya.astar.network/"; // Testnet
      rpcUrl = "wss://shiden-rpc.dwellir.com"; // Mainnet
    } else if (name === "azero") {
      tpa_env = process.env.REACT_APP_A0_TRADING_PAIR_CONTRACT;
      pair_creator_env = process.env.REACT_APP_A0_PAIR_CREATOR_CONTRACT;
      vesting_env = process.env.REACT_APP_A0_VESTING_CONTRACT;
      airdrop_env = process.env.REACT_APP_A0_PAIR_AIRDROP_CONTRACT;
      // rpcUrl = "wss://ws.test.azero.dev"; // Testnet
      rpcUrl = "wss://ws.azero.dev"; // Mainnet
    }

    handleWebSocketConnection(rpcUrl, name, tpa_env, pair_creator_env, vesting_env, airdrop_env);
  };

  useEffect(() => {
    let ls = localStorage.getItem("connectedNetwork");
    !ls && handleOpen();
    ls && chooseRpcUrl(ls);
  }, [connectedNetwork]);

  const [networkToken, setNetworkToken] = useState({});
  const [tokens, setTokens] = useState([]);
  const [darkMode, setDarkMode] = useState(true);
  const [logoSrc, setLogoSrc] = useState(whiteLogo);
  const [api, setApi] = useState();

  const alert_typo = (
    <Box>
      <Typography>This is the official Panorama Swap website.</Typography>
      <Typography>Always make sure you are using the official Panorama Swap website for security reasons.</Typography>
    </Box>
  );

  // A function which gets the extensions and accounts
  const getInjectedAndAccounts = async () => {
    let extensions = await web3Enable("Panorama");
    dispatch(insert_all_extensions(extensions));

    let allAccounts = await web3Accounts();
    dispatch(insert_accounts(allAccounts));
  };

  // DARK - LIGHT THEM TOGGLE
  const themeToggler = () => {
    darkMode === false ? setLogoSrc(whiteLogo) : setLogoSrc(blackLogo);
    setDarkMode(!darkMode);
  };

  // OPEN WEBSOCKET AND api promise
  const handleWebSocketConnection = async (rpcUrl, name, tpa_env, pair_creator_env, vesting_env, airdrop_env) => {
    const wsProvider = new WsProvider(rpcUrl);
    const api = await ApiPromise.create({ provider: wsProvider });
    setApi(api);
    dispatch(set_api(api));
    dispatch(set_connectedNetwork(name));
    localStorage.setItem("connectedNetwork", name);

    const tpaContract = new ContractPromise(api, abiTradingPairAzero, tpa_env);
    dispatch(set_tpa_contract(tpaContract));
    const pairCreatorContract = new ContractPromise(api, abiPairCreator, pair_creator_env);
    dispatch(set_pair_creator_contract(pairCreatorContract));
    const vestingContract = new ContractPromise(api, abiVesting, vesting_env);
    dispatch(set_vesting_contract(vestingContract));
    const airdropContract = new ContractPromise(api, abiAirdrop, airdrop_env);
    dispatch(set_airdrop_contract(airdropContract));

    if (api) {
      handleClose();
      setConnecting(false);
    }
  };

  // A function to check for logged user
  const checkForLoggedUser = async () => {
    let loggedUser = cookies.get("address");
    let loggedUserDomain = cookies.get("domain");
    let accessToken = cookies.get("accessToken");
    if (loggedUser && accessToken) {
      dispatch(select_address(loggedUser?.address));
      dispatch(select_caller_account(loggedUser));
      if (loggedUser) {
        let injector = await web3FromAddress(loggedUser?.address);
        dispatch(set_injector(injector));
      }
    } else {
      cookies.remove("address", { path: "/" });
      cookies.remove("domain", { path: "/" });
      cookies.remove("accessToken", { path: "/" });
      dispatch(select_address(null));
      dispatch(select_caller_account(null));
    }
  };

  // get pairs from db
  const getPairs = async () => {
    let { data: pairs } = await getTokenPairs(connectedNetwork);
    dispatch(set_pairs(pairs?.data));
  };

  // get tokens from db
  const getTokens = async () => {
    let { data: tokens } = await getAllTokens(connectedNetwork);
    let networkToken = tokens?.data?.filter((t) => t.address.toLowerCase() === connectedNetwork.toLowerCase());
    setNetworkToken(networkToken[0]);
    dispatch(set_network_token(networkToken[0]));
    setTokens(tokens?.data);
    dispatch(set_tokens(tokens?.data));
  };

  // get single pair by token addresses
  const getPair = async (token_a_address, token_b_address) => {
    let { data: pair } = await getTokenPairByAddresses(token_a_address, token_b_address, connectedNetwork);
    return pair?.data[0];
  };

  useEffect(() => {
    let psp22T = tokens?.filter((t) => t.address.toLowerCase() !== networkToken?.address);
    let networkT = tokens?.filter((t) => t.address.toLowerCase() === networkToken?.address);
    if (psp22T && networkT) {
      getPair(psp22T[0]?.address, networkT[0]?.address).then((res) => {
        dispatch(set_current_pair(res));
      });
      dispatch(set_from_token(networkT[0]));
      dispatch(set_to_token(psp22T[0]));
    }
  }, [tokens, networkToken]);

  useEffect(() => {
    getTokens();
    getPairs();
    getInjectedAndAccounts();
  }, [connectedNetwork]);

  useEffect(() => {
    checkForLoggedUser();
  }, [api]);

  return (
    <BrowserRouter>
      <CssBaseline>
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          {/* {resize > 1200 ? ( */}
          <Paper style={styles.paperContainer} sx={{ position: "relative" }}>
            <Navbar themeToggler={themeToggler} theme={darkMode ? themeDark : theme} logoSrc={logoSrc} />
            <RoutesNav />
            {/* <Page elevation={0} sx={{ position: "relative" }}>
              <img
                src={whiteLogo}
                alt="panorama swap logo"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  width: "350px",
                  maxWidth: "100%",
                  opacity: "0.1",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "550px",
                  maxWidth: "100%",
                }}
              >
                <Typography variant="maintenance">MAINTENANCE</Typography>
                <Typography variant="p" sx={{ fontSize: "30px", textAlign: "center" }}>
                  We are coming back soon
                </Typography>
                <Typography variant="p" sx={{ marginBlock: "10px", fontSize: "20px", textAlign: "center" }}>
                  Panorama Swap is currently under constructions.
                  <br />
                </Typography>
              </Box>
            </Page> */}
            <Footer />
            <CustomAlerts alert_typo={alert_typo} />
          </Paper>

          <ChooseRpcUrlModal
            handleClose={handleClose}
            open={open}
            chooseRpcUrl={chooseRpcUrl}
            connecting={connecting}
          />
        </ThemeProvider>
      </CssBaseline>
    </BrowserRouter>
  );
}

export default App;

const styles = {
  paperContainer: {
    backgroundImage: `none`,
    borderRadius: "0px",
    position: "relative",
  },
};
