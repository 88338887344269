import { createSlice } from "@reduxjs/toolkit";

export const contractsSlice = createSlice({
  name: "contracts",
  initialState: {
    api: [],
    panx_contract: [],
    // swap_contract: [],
    vesting_contract: [],
    tpa_contract: [],
    pair_creator_contract: [],
    airdrop_contract: [],
  },
  reducers: {
    set_api: (state, action) => {
      return {
        ...state,
        api: [action.payload],
      };
    },
    set_panx_contract: (state, action) => {
      return {
        ...state,
        panx_contract: [action.payload],
      };
    },
    // set_swap_contract: (state, action) => {
    //   return {
    //     ...state,
    //     swap_contract: [action.payload],
    //   };
    // },
    set_vesting_contract: (state, action) => {
      return {
        ...state,
        vesting_contract: action.payload,
      };
    },
    set_tpa_contract: (state, action) => {
      return {
        ...state,
        tpa_contract: [action.payload],
      };
    },
    set_pair_creator_contract: (state, action) => {
      return {
        ...state,
        pair_creator_contract: action.payload,
      };
    },
    set_airdrop_contract: (state, action) => {
      return {
        ...state,
        airdrop_contract: action.payload,
      };
    },
  },
});

export const {
  set_api,
  set_panx_contract,
  // set_swap_contract,
  set_vesting_contract,
  set_tpa_contract,
  set_pair_creator_contract,
  set_airdrop_contract,
} = contractsSlice.actions;

export default contractsSlice.reducer;
