import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { web3FromSource } from "@polkadot/extension-dapp";

import Wrapper from "../Helpers/Wrapper";
import { Box, Button, Typography } from "@mui/material";
import { ContractPromise } from "@polkadot/api-contract";
import { abiPsp22Token } from "../../Utils/abi/abiPsp22Token";
import { getRatio } from "../../Utils/constants";
import { colors } from "../../Style/themes";
import { ratioMultiplier } from "../../Utils/functions/globalFunctions";
import { useLastInjector } from "../../hooks/useLastInjector";
import { getMaxGasLimit } from "@scio-labs/use-inkathon";

const ApprovalButton = ({
  allowanceGas,
  psp22TokenAddress,
  variant,
  approvalInProgress,
  approvalCompleted,
  approvalFailed,
  handleApproveOpen,
  inputToApprove,
  disabled,
  approveBtnText,
  currentAddress,
  valueMsg,
  psp22TokensMsg,
  azeroTokensMsg,
  pairContract,
  withdrawApprove,
}) => {
  const { lastInjector } = useLastInjector();

  const { connectedNetwork } = useSelector((state) => state.account);
  const { address } = useSelector((state) => state.account);
  const { api } = useSelector((state) => state.contracts);

  const handleApprove = async () => {
    approvalInProgress();
    let contract;
    let err;
    let input = ratioMultiplier(inputToApprove, connectedNetwork);
    let gasLimit = getMaxGasLimit(api[0]);

    if (!withdrawApprove) {
      contract = api[0] && new ContractPromise(api[0], abiPsp22Token, psp22TokenAddress);
    } else {
      contract = pairContract;
    }
    try {
      await contract.tx["psp22::approve"]({ gasLimit }, currentAddress, input).signAndSend(
        address[0],
        { signer: lastInjector.signer },
        ({ events = [], status }) => {
          if (status.isInBlock || status.isFinalized) {
            events
              // find/filter for failed events
              .filter(({ event }) => api[0].events.system.ExtrinsicFailed.is(event))
              // we know that data for system.ExtrinsicFailed is
              // (DispatchError, DispatchInfo)
              .forEach(
                ({
                  event: {
                    data: [error, info],
                  },
                }) => {
                  if (error.isModule) {
                    // for module errors, we have the section indexed, lookup
                    const decoded = api[0].registry.findMetaError(error.asModule);
                    const { docs, method, section } = decoded;
                    err = docs.join("");
                    if (method === "ContractTrapped") {
                      approvalFailed("Please readjust your slippage settings.");
                    } else {
                      approvalFailed(err);
                    }
                    // approvalFailed();
                  } else {
                    // Other, CannotLookup, BadOrigin, no extra info
                    // console.log(error.toString());
                  }
                }
              );
          }

          if (status.isInBlock) {
            approvalInProgress();
          } else if (status.isFinalized) {
            // Loop through Vec<EventRecord> to display all events
            events.forEach(({ phase, event: { data, method, section } }) => {
              if (method === "ExtrinsicSuccess") {
                approvalCompleted();
              } else if (method === "ExtrinsicFailed") {
                approvalFailed(err);
              }
            });
          }
        }
      );
    } catch (err) {
      let word = "Rejected";
      if (err.message.includes(word)) {
        approvalFailed(err.message);
      } else {
      }
    }
  };

  return (
    <Wrapper>
      <Button variant={variant ? variant : "contained"} onClick={handleApprove} disabled={disabled}>
        {approveBtnText}{" "}
      </Button>
      <Box
        sx={{
          padding: "5px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          color: colors.red,
        }}
      >
        <Typography variant="p">{valueMsg}</Typography>
        <Typography variant="p">{azeroTokensMsg}</Typography>
        <Typography variant="p">{psp22TokensMsg}</Typography>
      </Box>
    </Wrapper>
  );
};

export default ApprovalButton;
