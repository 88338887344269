import { createSlice } from "@reduxjs/toolkit";

export const accountSlice = createSlice({
  name: "account",
  initialState: {
    isAuthenticated: false,
    connectedNetwork: "azero",
    selected_network: {
      id: 1,
      name: "Aleph Zero Network",
      symbol: "a0",
      icon: "https://i.imagesup.co/images2/71a48bd8b5a5c192b490bbac3b759612e940aa6d.png",
    },
    caller_account: [],
    address: [],
    domain: [],
    injector: [],
    psp22_balance: false,
    azero_balance: false,
    pool_tokens_balance: 0,
    slippage: 0.1,
    azeroID: false,
  },
  reducers: {
    set_isAuthenticated: (state, action) => {
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    },
    set_connectedNetwork: (state, action) => {
      return {
        ...state,
        connectedNetwork: action.payload,
      };
    },
    set_selected_network: (state, action) => {
      return {
        ...state,
        selected_network: action.payload,
      };
    },
    select_caller_account: (state, action) => {
      return {
        ...state,
        caller_account: [action.payload],
      };
    },
    select_address: (state, action) => {
      return {
        ...state,
        address: [action.payload],
      };
    },
    set_domain: (state, action) => {
      return {
        ...state,
        domain: [action.payload],
      };
    },
    set_injector: (state, action) => {
      return {
        ...state,
        injector: [action.payload],
      };
    },
    insert_psp22_balance: (state, action) => {
      return {
        ...state,
        psp22_balance: action.payload,
      };
    },
    insert_azero_balance: (state, action) => {
      return {
        ...state,
        azero_balance: action.payload,
      };
    },
    insert_pool_tokens_balance: (state, action) => {
      return {
        ...state,
        pool_tokens_balance: action.payload,
      };
    },
    set_txn_slippage: (state, action) => {
      return {
        ...state,
        slippage: action.payload,
      };
    },
    set_azeroID: (state, action) => {
      return {
        ...state,
        azeroID: action.payload,
      };
    },
  },
});

export const {
  set_isAuthenticated,
  set_connectedNetwork,
  set_selected_network,
  select_caller_account,
  set_injector,
  select_address,
  insert_psp22_balance,
  insert_azero_balance,
  insert_pool_tokens_balance,
  set_txn_slippage,
  set_azeroID,
  set_domain,
} = accountSlice.actions;

export default accountSlice.reducer;
