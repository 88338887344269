import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Tilt } from "react-tilt";

import { web3FromSource } from "@polkadot/extension-dapp";
import { getMaxGasLimit } from "@scio-labs/use-inkathon";

import useResize from "../../hooks/useResize";
import { insert_psp22_balance } from "../../features/account";
import { getJSON50, getJSON500, getJSON505, getTxnsByAccount } from "../../shared/api";
import { getRatio } from "../../Utils/constants";
import { customToFixed, fixedNumber, numberWithCommas } from "../../Utils/functions/globalFunctions";

import Wrapper from "../../Components/Helpers/Wrapper";
import ClaimTenModal from "../../Components/Modals/ClaimTenModal";
import AssetsTable from "../../Components/Tables/AssetsTable";
import TxnTable from "../../Components/Tables/TxnTable";
import AirdropButton from "../../Components/Buttons/AirdropButton";

import { Box, Button, Skeleton, Typography } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

import { HeroContainer, Page } from "../../Style/general";
import { DetailsPaper, TopDetailsPaper } from "../../Style/Pages/LiquidityPairs";
import { DetailsBox } from "../../Style/Tables/SpecPoolTable";
import { TransactionPaper } from "../../Style/Pages/Stats";
import {
  TypographyTitle,
  TypographyDes,
  TypographyTitlePANX,
  DetailsContainer,
  PaperGreen,
  BoxFlexCenter,
  AssetsDetailsBoxWrapper,
  AssetsDetailsBox,
  ClaimablePanxBtn,
} from "../../Style/Pages/Assets";
import { useAuth } from "../../hooks/useAuth";
import { useLastInjector } from "../../hooks/useLastInjector";
import { errorHandler } from "../../Utils/functions/scErrorHandler";
import { useAzeroId } from "../../hooks/useAzeroId";

import azeroIdIcon from "../../Images/icons/azeroID.png";

const defaultOptions = {
  reverse: false, // reverse the tilt direction
  max: 15, // max tilt rotation (degrees)
  perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
  scale: 1.1, // 2 = 200%, 1.5 = 150%, etc..
  speed: 700, // Speed of the enter/exit transition
  transition: true, // Set a transition on enter/exit.
  axis: null, // What axis should be disabled. Can be X or Y.
  reset: true, // If the tilt effect has to be reset on exit.
  easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
};

const Assets = () => {
  const resize = useResize();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const { lastInjector } = useLastInjector();

  const { connectedNetwork } = useSelector((state) => state.account);
  const { address } = useSelector((state) => state.account); // get logged user address state from redux
  const { vesting_contract } = useSelector((state) => state.contracts);
  const { api } = useSelector((state) => state.contracts);
  const { tokens } = useSelector((state) => state.tokens);

  const azeroID = useAzeroId(address[0]);
  // const azeroIDImg = `https://tzero.id/api/v1/image/${azeroID}.png`; // Testnet
  const azeroIDImg = `https://azero.id/api/v1/image/${azeroID}.png`; // Mainnet

  // const azeroIdUrl = `https://tzero.id/id/${azeroID}`; // Testnet
  const azeroIdUrl = `https://azero.id/id/${azeroID}`; // Mainnet

  // const azeroIdMainUrl = `https://tzero.id/`; // Testnet
  const azeroIdMainUrl = `https://azero.id/`; // Mainnet

  const [TotalVestingAmount, setTotalVestingAmount] = useState(0);
  const [AmountToGiveEachDayForAccount, setAmountToGiveEachDayForAccount] = useState(0);
  const [redeemableAmount, setRedeemableAmount] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const [psp22_balance, setpsp22_balance] = useState();
  const [claimTxt, setClaimTxt] = useState("Claim");
  const [claimed, setClaimed] = useState(false);
  const [claimTenTxt, setClaimTenTxt] = useState("Click to claim your 10% panx allocation from seed round!");
  const [claimedTen, setClaimedTen] = useState(false);
  const [lastClaimingDate, setLastClaimingDate] = useState("");

  const [msgClaimBoolean, setClaimMsgBoolean] = useState(true);
  const [claimFailed, setClaimFailed] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [openClaim, setOpenClaim] = useState(false);

  const [accExistOnAirdrop, setAccExistOnAirdrop] = useState(false);
  const [airdropType, setAirdropType] = useState("");
  const [txns, setTxns] = useState([]);

  const handleClaimOpen = () => setOpenClaim(true);
  const handleClaimClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenClaim(false);
  };

  // get all vesting data of the current logged user.
  // user  wallet address must be added to the vesting contract.
  const getAllVestingData = async () => {
    getTxnHistory();

    let gasLimit;
    if (api[0]) {
      gasLimit = getMaxGasLimit(api[0]);
    }
    let value = 0;
    let RedeemableAmount;

    try {
      await vesting_contract?.query["getRedeemableAmount"](address[0], { gasLimit }).then((result) => {
        if (result.output.toHuman().Ok.Err === "ZeroDaysPassed") {
          setRedeemableAmount("0.0000");
        } else {
          RedeemableAmount = result?.output?.toHuman().Ok.Ok.replace(/,/g, "") / getRatio(connectedNetwork);
          setRedeemableAmount(RedeemableAmount && fixedNumber(RedeemableAmount));
        }
      });
    } catch (err) {}

    try {
      // Get user total vesting amount of panx
      await vesting_contract?.query["getAccountLastRedeem"](address[0], { value, gasLimit }, address[0]).then(
        (result) => {
          let epoch = result?.output?.toHuman()?.Ok?.replace(/,/g, "");
          epoch = moment.unix(epoch).format("dddd, MMMM Do, YYYY h:mm:ss A");
          setLastClaimingDate(epoch);
        }
      );
    } catch (err) {}

    try {
      // Get user total vesting amount of panx
      await vesting_contract?.query["getAccountTotalVestingAmount"](address[0], { value, gasLimit }, address[0]).then(
        (result) => {
          let TotalVestingAmount = result?.output?.toHuman()?.Ok?.replace(/,/g, "") / getRatio(connectedNetwork);
          setTotalVestingAmount(TotalVestingAmount && fixedNumber(TotalVestingAmount));
        }
      );
    } catch (err) {}

    try {
      //Get how many PANX the user will be able to claim every day
      await vesting_contract?.query["getAmountToGiveEachDayToAccount"](
        address[0],
        { value, gasLimit },
        address[0]
      ).then((result) => {
        let AmountToGiveEachDayForAccount =
          result?.output?.toHuman()?.Ok?.replace(/,/g, "") / getRatio(connectedNetwork);
        setAmountToGiveEachDayForAccount(
          AmountToGiveEachDayForAccount && customToFixed(AmountToGiveEachDayForAccount, 4)
        );
      });
    } catch (err) {}
  };

  // Check if user have claimed his 10%
  const isUserClaimedTen = async () => {
    let gasLimit;
    let value = 0;
    if (api[0]) {
      gasLimit = getMaxGasLimit(api[0]);
    }

    try {
      await vesting_contract?.query["userTgeCollectionStatus"](address[0], { value, gasLimit }, address[0]).then(
        (res) => {
          if (res?.output?.toHuman().Ok === "1") {
            setClaimedTen(true);
          } else {
            setClaimedTen(false);
          }
        }
      );
    } catch (err) {}
  };

  // get txn history from db.
  const getTxnHistory = async () => {
    if (address[0]) {
      let { data: txns } = await getTxnsByAccount(address[0], connectedNetwork);
      const cache = {};
      const txns_data = txns?.data.map((txn) => {
        let token_data = {};
        if (cache.hasOwnProperty(txn?.token_address)) {
          token_data = cache[txn.token_address];
        } else {
          //find in tokens
          const token = tokens?.find((t) => t?.address === txn?.token_address);
          if (token) {
            const { icon: token_icon, symbol: token_symbol, name: token_name } = token;
            token_data = { token_icon, token_symbol, token_name };
            cache[txn.token_address] = token_data;
          } else {
            token_data = { token_icon: "unknown image url", token_symbol: "unknown", token_name: "unknown" };
          }
        }
        return { ...txn, ...token_data };
      });
      setTxns(txns_data);
    }
  };

  // check id user exist on any airdrop list and determine which button he will get.
  const checkIfUserExistOnList = async () => {
    setAccExistOnAirdrop(false);
    let json50 = await getJSON50();
    let json500 = await getJSON500();
    json50 = json50.data.data;
    json500 = json500.data.data;

    let isAccIn50 = json50?.find((el) => el?.address === address[0]);
    let isAccIn500 = json500?.find((el) => el?.address === address[0]);

    if (isAccIn50.address) {
      setAirdropType("50");
      setAccExistOnAirdrop(true);
      return;
    } else if (isAccIn500.address) {
      setAirdropType("500");
      setAccExistOnAirdrop(true);
      return;
    }
  };

  // A function that redeems 10% panx allocation.
  const claim10Per = async () => {
    let gasLimit;
    if (api[0]) {
      gasLimit = getMaxGasLimit(api[0]);
    }
    try {
      vesting_contract?.query["collectTgeTokens"](address[0], { gasLimit }).then((res) => {
        let errorModule = res.output.toHuman().Ok;
        if (errorModule === "Ok") {
          let gas = res.gasRequired;
          if (res) {
            tenPerTxn(gas);
          }
        } else {
          setClaimTenTxt(<Typography sx={{ fontSize: "12px" }}>{errorHandler(errorModule.Err)}</Typography>);
          claimTenFailed();
        }
      });
    } catch (err) {
      // console.log(err);
    }
  };

  // claim 10% of panx allocation for those who invest on seed round.
  const tenPerTxn = async (gas) => {
    let value = 0;

    try {
      if (isAuthenticated) {
        await vesting_contract.tx["collectTgeTokens"]({ value, gasLimit: gas }).signAndSend(
          address[0],
          { signer: lastInjector.signer },
          ({ events = [], status }) => {
            if (status.isInBlock) {
              claimTenInProgress();
              setClaimTenTxt("Claiming 10% from vesting amount...");
            } else if (status.isFinalized) {
              // Loop through Vec<EventRecord> to display all events
              events.forEach(({ phase, event: { data, method, section } }) => {
                if (method === "ExtrinsicSuccess") {
                  claimTenCompleted();
                } else if (method === "ExtrinsicFailed") {
                  claimTenFailed();
                }
              });
            }
          }
        );
      } else {
        setClaimTxt(
          <Typography sx={{ fontSize: "12px" }}>
            You must be authenticated to the system.
            <br /> Please try to logout and login to your wallet again.
          </Typography>
        );
        claimFailed();
      }
    } catch (err) {
      if (err.message === "Cancelled") {
        claimTenFailed(err.message);
      } else {
        setClaimTenTxt(<Typography sx={{ fontSize: "12px" }}>{err.message}</Typography>);
        claimTenFailed();
      }
    }
  };

  // set claim msg to in progress and open modal
  const claimTenInProgress = () => {
    handleClaimOpen();
  };

  // set claim msg to completed and close modal
  const claimTenCompleted = () => {
    dispatch(insert_psp22_balance(true));
    setClaimMsgBoolean(false);
    setClaimedTen(true);
    setClaimTenTxt("Claimed 10% PANX from your vesting amount!");
    getAllVestingData();
    setTimeout(() => {
      handleClaimClose();
      setClaimMsgBoolean(true);
      dispatch(insert_psp22_balance(false));
    }, 1500);
  };

  // set claim msg to Failed and close modal
  const claimTenFailed = (cancelled) => {
    if (cancelled === "Cancelled") {
      setCancelled(true);
    }
    setClaimMsgBoolean(false);
    setClaimFailed(true);
    setClaimTenTxt("Claim failed!");
    setTimeout(() => {
      setClaimMsgBoolean(true);
      handleClaimClose();
      setCancelled(false);
      setClaimFailed(false);
      setClaimTenTxt("Click to claim your 10% panx allocation from seed round!");
    }, 3000);
  };

  // redeem claimable panx every 24 hours.
  const redeem = async () => {
    claimTenInProgress();

    let errorModule;
    let gasLimit;
    if (api[0]) {
      gasLimit = getMaxGasLimit(api[0]);
    }
    let gas = 0;
    let value = 0;

    try {
      if (isAuthenticated) {
        await vesting_contract?.query["redeemRedeemableAmount"](address[0], { value, gasLimit }).then((res) => {
          errorModule = res.output.toHuman().Ok;
          if (errorModule === "Ok") {
            gas = res.gasRequired;
          } else {
            setClaimTxt(<Typography sx={{ fontSize: "12px" }}>{errorHandler(errorModule.Err)}</Typography>);
            redeemFailed();
          }
        });

        errorModule === "Ok" &&
          (await vesting_contract.tx["redeemRedeemableAmount"]({ value, gasLimit: gas }).signAndSend(
            address[0],
            { signer: lastInjector.signer },
            ({ events = [], status }) => {
              if (status.isInBlock || status.isFinalized) {
                events
                  // find/filter for failed events
                  .filter(({ event }) => api[0].events.system.ExtrinsicFailed.is(event))
                  // we know that data for system.ExtrinsicFailed is
                  // (DispatchError, DispatchInfo)
                  .forEach(
                    ({
                      event: {
                        data: [error, info],
                      },
                    }) => {
                      if (error.isModule) {
                        // for module errors, we have the section indexed, lookup
                        const decoded = api[0].registry.findMetaError(error.asModule);
                        const { docs, method, section } = decoded;

                        // txnFailedFn();
                      } else {
                        // Other, CannotLookup, BadOrigin, no extra info
                        // console.log(error.toString());
                      }
                    }
                  );
              }

              if (status.isInBlock) {
                setClaimTxt("Claiming...");
              } else if (status.isFinalized) {
                // Loop through Vec<EventRecord> to display all events
                events.forEach(({ phase, event: { data, method, section } }) => {
                  if (method === "ExtrinsicSuccess") {
                    redeemCompleted();
                  } else if (method === "ExtrinsicFailed") {
                    redeemFailed();
                  }
                });
              }
            }
          ));
      } else {
        setClaimTxt(
          <Typography sx={{ fontSize: "12px" }}>
            You must be authenticated to the system.
            <br /> Please try to logout and login to your wallet again.
          </Typography>
        );
        claimFailed();
      }
    } catch (err) {
      if (err.message === "Cancelled") {
        redeemFailed(err.message);
      } else {
        setClaimTxt(<Typography sx={{ fontSize: "12px" }}>{err.message}</Typography>);
        redeemFailed();
      }
    }
  };

  // set claim msg to completed and close modal
  const redeemCompleted = () => {
    setClaimMsgBoolean(false);
    setClaimed(true);
    setClaimTxt("PANX Claimed");
    getAllVestingData();
    setTimeout(() => {
      setClaimed(false);
      handleClaimClose();
      setClaimMsgBoolean(true);
      dispatch(insert_psp22_balance(false));
    }, 1500);
  };

  // set claim msg to Failed and close modal
  const redeemFailed = (cancelled) => {
    if (cancelled === "Cancelled") {
      setCancelled(true);
    }
    setClaimMsgBoolean(false);
    setClaimFailed(true);
    setClaimTxt("Claim failed");
    setTimeout(() => {
      setClaimMsgBoolean(true);
      handleClaimClose();
      setCancelled(false);
      setClaimFailed(false);
      setClaimTxt("Claim");
    }, 3000);
  };

  // disabled redeem panx button.
  const disabledClaimButton = () => {
    if (
      0 >= redeemableAmount ||
      isNaN(redeemableAmount) ||
      redeemableAmount === undefined ||
      redeemableAmount === "undefined"
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  // check id user exist on any airdrop list and determine which button he will get.
  //  const checkIfUserExistOnList = async () => {
  //   setAccExistOnAirdrop(false);
  //   let json50 = await getJSON50();
  //   let json500 = await getJSON500();
  //   json50 = json50.data.data;
  //   json500 = json500.data.data;

  //   let isAccIn50 = json50?.find((el) => el?.address === address[0]);
  //   let isAccIn500 = json500?.find((el) => el?.address === address[0]);

  //   if (isAccIn50.address) {
  //     setAirdropType("50");
  //     setAccExistOnAirdrop(true);
  //     return;
  //   } else if (isAccIn500.address) {
  //     setAirdropType("500");
  //     setAccExistOnAirdrop(true);
  //     return;
  //   }
  // };

  useEffect(() => {
    address[0] && getAllVestingData();
    vesting_contract && isUserClaimedTen();
  }, [address, psp22_balance, connectedNetwork]);

  useEffect(() => {
    disabledClaimButton();
  }, [redeemableAmount, connectedNetwork]);

  return (
    <Wrapper>
      <Page elevation={0} sx={{ position: "relative" }}>
        <HeroContainer paddingtop={"40px"}>
          <PaperGreen>
            <TypographyTitle>Assets</TypographyTitle>
          </PaperGreen>
        </HeroContainer>

        <DetailsContainer margintop={"20px"}>
          <DetailsBox flexDirection="column">
            <DetailsPaper width="100%">
              <TopDetailsPaper elevation={5}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <AccountBalanceWalletIcon fontSize="large" />
                  <TypographyTitlePANX>Balances</TypographyTitlePANX>
                </Box>

                {/* Current details */}
              </TopDetailsPaper>
              <AssetsTable />
            </DetailsPaper>

            {/* Current account details */}
            {TotalVestingAmount > 0 && (
              <AssetsDetailsBoxWrapper columns={resize > 600 ? "2" : "1"}>
                {/* Total vesting amount */}
                <AssetsDetailsBox>
                  <Typography variant="pool_details_title">Total vesting amount</Typography>
                  {address[0] ? (
                    <Typography variant="pool_details_amount">
                      {numberWithCommas(TotalVestingAmount)}
                      <TypographyDes>PANX</TypographyDes>
                    </Typography>
                  ) : (
                    <Skeleton animation="wave" sx={{ width: "150px" }} />
                  )}
                </AssetsDetailsBox>

                {/*  Daily PANX vesting amount */}
                <AssetsDetailsBox>
                  <Typography variant="pool_details_title">Daily PANX vesting amount</Typography>
                  {address[0] ? (
                    <Typography variant="pool_details_amount">
                      {numberWithCommas(AmountToGiveEachDayForAccount)}
                      <TypographyDes>PANX</TypographyDes>
                    </Typography>
                  ) : (
                    <Skeleton animation="wave" sx={{ width: "150px" }} />
                  )}
                </AssetsDetailsBox>

                {/* Claimable panx amount */}
                <AssetsDetailsBox>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="pool_details_title">Claimable PANX</Typography>
                    <ClaimablePanxBtn variant="contained" disabled={disabled} onClick={redeem}>
                      {claimTxt}
                    </ClaimablePanxBtn>
                  </Box>
                  {address[0] ? (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography variant="pool_details_amount">
                        {numberWithCommas(redeemableAmount)}
                        {redeemableAmount > 0 && <TypographyDes>PANX</TypographyDes>}
                      </Typography>
                    </Box>
                  ) : (
                    <Skeleton animation="wave" sx={{ width: "150px" }} />
                  )}
                </AssetsDetailsBox>

                {/* Last caliming date */}
                <AssetsDetailsBox>
                  <Typography variant="pool_details_title">Last Claiming Date</Typography>
                  {address[0] ? (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography variant="pool_details_amount" sx={{ fontSize: "20px" }}>
                        {lastClaimingDate}
                      </Typography>
                    </Box>
                  ) : (
                    <Skeleton animation="wave" sx={{ width: "150px" }} />
                  )}
                </AssetsDetailsBox>
              </AssetsDetailsBoxWrapper>
            )}
          </DetailsBox>
        </DetailsContainer>

        {/* 10% Claiming button */}
        {TotalVestingAmount > 0 && (
          <HeroContainer paddingtop={"20px"}>
            {!claimedTen && (
              <Button
                variant="claim_ten"
                onClick={() => {
                  claim10Per();
                }}
              >
                {claimTenTxt}
              </Button>
            )}
          </HeroContainer>
        )}

        {/* Transactions history and AZERO.ID */}
        <HeroContainer
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: "20px",
            "@media(max-width:678px)": {
              flexDirection: "column",
            },
          }}
        >
          {/* Transactions history here. */}

          <Box
            sx={{
              width: connectedNetwork === "azero" ? "70%" : "100%",
              "@media(max-width:678px)": {
                width: "100%",
              },
            }}
          >
            <TypographyTitlePANX style={{ textTransform: "capitalize", marginTop: "20px" }}>
              Transactions History
            </TypographyTitlePANX>
            <TransactionPaper elevation={5}>
              {txns.length > 0 ? (
                <TxnTable data={txns} />
              ) : (
                <Typography>There are no transactions on your account yet.</Typography>
              )}
            </TransactionPaper>
          </Box>
          {connectedNetwork === "azero" && (
            <Box
              sx={{
                width: "30%",
                maxWidth: "100%",
                // marginBlock: "3vw",
                display: "flex",
                flexDirection: "column",
                "@media(max-width:678px)": {
                  width: "auto",
                },
              }}
            >
              <TypographyTitlePANX style={{ textTransform: "capitalize", marginTop: "20px" }}>
                AZERO.ID
              </TypographyTitlePANX>

              {azeroID ? (
                <Tilt options={defaultOptions}>
                  <img
                    src={azeroIDImg}
                    // src={azeroIDImgLive}
                    alt="azeroId"
                    onClick={() => {
                      window.open(`${azeroIdUrl}`, "_blank");
                      // window.open(`${azeroIdUrlLive}`, "_blank");
                    }}
                    style={{
                      width: "100%",
                      cursor: "pointer",
                    }}
                  />
                </Tilt>
              ) : (
                <Box
                  onClick={() => {
                    window.open(`${azeroIdMainUrl}`, "_blank");
                  }}
                  sx={{
                    marginBlock: "10px",
                    padding: "20px 10px",
                    border: "1px solid gray",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <Box>No AZERO.ID connected to your address was found.</Box> */}
                  <img src={azeroIdIcon} style={{ width: "250px", maxWidth: "100%" }} />
                  <Button variant="azero_id_register">Register your domain</Button>
                </Box>
              )}
            </Box>
          )}
        </HeroContainer>
        {/* {accExistOnAirdrop && <AirdropButton airdropType={airdropType} />} */}
      </Page>

      {/* Claim 10% Modal */}
      <ClaimTenModal
        openClaim={openClaim}
        handleClaimClose={handleClaimClose}
        msgClaimBoolean={msgClaimBoolean}
        claimFailed={claimFailed}
        cancelled={cancelled}
      />
    </Wrapper>
  );
};

export default Assets;
