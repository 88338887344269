import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Wrapper from "../../Components/Helpers/Wrapper";
import { Page } from "../../Style/general";

const NotFound404 = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Page elevation={0}>
        <Box
          sx={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h1">404 </Typography>
          <Typography variant="h6">Page Not Found</Typography>
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: "20px", justifyContent: "center", marginTop: "20px" }}
          >
            <Button
              variant="contained"
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                window.open("https://panoramaswap.com/#contact/", "_blank");
              }}
            >
              Report A Problem
            </Button>
          </Box>
        </Box>
      </Page>
    </Wrapper>
  );
};

export default NotFound404;
