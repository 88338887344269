import React, { useEffect } from "react";
import Wrapper from "../Helpers/Wrapper";
import { Box, CircularProgress, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import moment from "moment";

import { ResponsiveContainer, AreaChart, XAxis, YAxis, Area, Tooltip } from "recharts";
import CustomTooltip from "./CustomToolTip";
import { useState } from "react";
import { getPricesForStats } from "../../shared/api";
import fromExponential from "from-exponential";
import { customToFixed } from "../../Utils/functions/globalFunctions";

const style = {
  outline: "none",
  border: "1px solid #00ccab",
  borderRadius: "15px",
  padding: "10px",
  display: "flex",
  flexDirection: "column",
  rowGap: "max(10px, 4vw)",
  background: "#00000045",
  backdropFilter: "blur(3px)",
};

const PairPricesGraph = ({ tokenA, tokenB, currentPair }) => {
  const [currentDays, setCurrentDays] = useState(1);
  const [filteredChartsData, setFilteredChartsData] = useState([]);
  const [activeBtn, setActiveBtn] = useState(1);
  const [maxChartNum, setMaxChartNum] = useState(0);

  const handleChange = (event, btn) => {
    setActiveBtn(btn);
  };

  const control = {
    value: activeBtn,
    onChange: handleChange,
    exclusive: true,
  };

  const getPairAllPrices = async (days) => {
    days = +days;

    const date_obj = {
      days,
      address: currentPair?.pair_address,
    };
    await getPricesForStats(date_obj).then((res) => {
      const prices = date_obj.days === 1 ? res.data.data : res.data.data.reverse();
      let priceInfo = prices.map((price, key) => {
        return {
          date: moment(price.createdAt).format("DD MMM hh:mm A"),
          price: customToFixed(+price.pair_price, 6),
        };
      });
      if (days >= 7 && days + 1 === priceInfo.length) {
        priceInfo.shift();
      }

      //setting chart highest point by getting highest price
      if (priceInfo.length > 0) {
        let maxItem = priceInfo?.reduce((max, min) => (Number(max.price) > Number(min.price) ? max : min));
        maxItem = maxItem.price;
        setMaxChartNum(maxItem);
      }

      setFilteredChartsData(priceInfo);
    });
  };

  const scientificFormatter = (tickValue) => {
    return tickValue < 0.0001 ? fromExponential(tickValue) : customToFixed(tickValue, 2);
  };

  const children = [
    <ToggleButton
      sx={{ paddingInline: "20px" }}
      value={1}
      key={1}
      color="primary"
      onClick={(e) => {
        getPairAllPrices(e.currentTarget.value);
      }}
    >
      1D
    </ToggleButton>,
    <ToggleButton
      sx={{ paddingInline: "20px" }}
      value={7}
      key={7}
      color="primary"
      onClick={(e) => {
        getPairAllPrices(e.currentTarget.value);
      }}
    >
      7D
    </ToggleButton>,
    <ToggleButton
      sx={{ paddingInline: "20px" }}
      value={30}
      key={30}
      color="primary"
      onClick={(e) => {
        getPairAllPrices(e.currentTarget.value);
      }}
    >
      30D
    </ToggleButton>,
  ];

  useEffect(() => {
    // getPairAllPrices(activeBtn);

    const dataInterval = setInterval(() => {
      getPairAllPrices(activeBtn);
    }, 5000);

    return () => {
      clearInterval(dataInterval);
    };
  }, [currentPair, activeBtn, filteredChartsData]);

  const yAxisStyle = {
    fontSize: 12, // change font size of tick values
  };

  return (
    <Wrapper>
      <Box
        sx={{
          height: "max(400px, 20vw)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingInline: "20px 20px",
          rowGap: "1vw",
          "@media:(max-width:768px)": {
            padding: "10px",
          },
        }}
      >
        {/* <CircularProgress sx={{ color: "#58d3c0" }} /> */}
        {/* <img src={logo} alt="" style={style} />  */}
        <Box sx={{ marginLeft: "auto", paddingBottom: "10px" }}>
          <ToggleButtonGroup size="small" {...control}>
            {children}
          </ToggleButtonGroup>
        </Box>
        {maxChartNum > 0 && filteredChartsData?.length > 0 ? (
          <ResponsiveContainer>
            <AreaChart data={filteredChartsData} sx={{ height: "400px" }}>
              <defs>
                <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#00ccab" stopOpacity={0.5}></stop>
                  <stop offset="95%" stopColor="#00ccab" stopOpacity={0.05}></stop>
                </linearGradient>
              </defs>
              <Area dataKey="price" stroke="#00ccab" fill="url(#color)" />
              <XAxis dataKey="date" axisLine={false} tickLine={false} /* interval={0} */ tick={false} />
              <YAxis
                style={yAxisStyle}
                dataKey="price"
                axisLine={false}
                tickLine={false}
                // tickCount={filteredChartsData.length + 2}
                tickFormatter={scientificFormatter}
                domain={[0, Number(maxChartNum)]}
              />
              <Tooltip wrapperStyle={style} content={<CustomTooltip />} />
              {/* <CartesianGrid opacity={0.1} vertical={false}/> */}
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              {filteredChartsData ? (
                <CircularProgress style={{ color: "#00ccab" }} />
              ) : (
                <Typography>No transactions were made today yet.</Typography>
              )}
              {/* <img src={logo} alt="" width={200} /> */}
            </Box>
          </ResponsiveContainer>
        )}
      </Box>
    </Wrapper>
  );
};

export default PairPricesGraph;
