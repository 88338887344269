import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Wrapper from "../Helpers/Wrapper";
import { Modal, Box, Typography, Paper, Button, Tooltip, CircularProgress } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TouButton from "../Buttons/TouButton";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { fixedNumber, numberWithCommas } from "../../Utils/functions/globalFunctions";
import { StyledTextField } from "../../Style/general";

import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  textAlign: "center",
  maxWidth: "450px",
  // minWidth: "350px",
  width: "100%",
  height: "auto",
  color: "white",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
  "@media(max-width:768px)": {
    width: "90%",
  },
};

const InitPairModal = ({
  pairExist,
  tokenA,
  tokenAValue,
  tokenB,
  tokenBValue,
  initPair,
  provideToPool,
  expectedLpTokens,
  openInit,
  handleSwapClose,
  msgBoolean,
  loader,
  txnFailed,
  cancelled,
  providerFee,
  txnMsg,
}) => {
  const { slippage } = useSelector((state) => state.account); //get account state from redux

  const handleInit = async () => {
    await initPair();
  };
  const handleProvide = async () => {
    await provideToPool();
  };

  return (
    <Wrapper>
      {!pairExist ? (
        <Modal open={openInit} onClose={handleSwapClose}>
          {/* Init pair Paper */}
          <Paper elevation={5} sx={style}>
            {!loader ? (
              <Box>
                <CloseIcon
                  onClick={handleSwapClose}
                  sx={{ cursor: "pointer", position: "absolute", right: "10px", top: "10px" }}
                />
                <Paper
                  elevation={0}
                  sx={{ padding: "10px ", borderRadius: "10px", background: "#33333b", marginTop: "20px" }}
                >
                  <CloseIcon
                    onClick={handleSwapClose}
                    sx={{ cursor: "pointer", position: "absolute", right: "10px", top: "10px" }}
                  />
                  <Typography>You are about to create a new pair using the following tokens:</Typography>
                  {/* Change swap direction button */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "20px",
                      marginBlock: "20px",
                      textTransform: "uppercase",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "5px" }}>
                      <img src={tokenA?.icon} alt="" width={40} style={{ borderRadius: "50%" }} />
                      <Typography>{tokenA?.symbol}</Typography>
                    </Box>
                    <AddRoundedIcon />
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "5px" }}>
                      <img src={tokenB?.icon} alt="" width={40} style={{ borderRadius: "50%" }} />
                      <Typography>{tokenB?.symbol}</Typography>
                    </Box>
                  </Box>
                </Paper>
                {/* Terms of use */}
                <TouButton />
                {/* Confirm Pair button */}
                <Button variant="swap_btn" onClick={handleInit} sx={{ marginTop: "20px" }}>
                  Confirm Pair Initialization
                </Button>{" "}
              </Box>
            ) : (
              <Box
                sx={{
                  minWidth: "300px",
                  minHeight: "150px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {msgBoolean ? (
                  <Box>
                    <CircularProgress color="inherit" />
                    <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Initializing pair...</Typography>
                  </Box>
                ) : (
                  <Box>
                    {!txnFailed ? (
                      <Box>
                        <CheckCircleOutlineIcon fontSize="large" />
                        <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Initialization completed!</Typography>
                      </Box>
                    ) : (
                      <Box>
                        <HighlightOffIcon fontSize="large" sx={{ color: "#e84142" }} />
                        {cancelled ? (
                          <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>
                            Initialization Cancelled!
                          </Typography>
                        ) : (
                          <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Initialization Failed!</Typography>
                        )}
                        {cancelled ? (
                          <></>
                        ) : (
                          <Typography sx={{ fontSize: "14px", opacity: "0.5" }} component="p">
                            {txnMsg}{" "}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Paper>
        </Modal>
      ) : (
        <Modal open={openInit} onClose={handleSwapClose}>
          {/* Add liquidity Paper */}
          <Paper elevation={5} sx={style}>
            {!loader ? (
              <Box>
                <CloseIcon
                  onClick={handleSwapClose}
                  sx={{ cursor: "pointer", position: "absolute", right: "5px", top: "5px" }}
                />
                <Box className="swapInput" sx={{ display: "flex", flexDirection: "column", position: "relative" }}>
                  {/* token you swap information */}
                  <div
                    className="swapInput__bottom"
                    style={{ marginTop: "20px", borderRadius: "10px", display: "flex", background: "#26262c" }}
                  >
                    <StyledTextField value={tokenAValue && tokenAValue} sx={{ width: "65%" }} disabled type="number" />
                    <Button variant="choose_token_btn" disabled>
                      <img src={tokenA?.icon} alt="" />
                      <span>{tokenA?.symbol}</span>
                    </Button>
                  </div>
                </Box>
                {/* Change swap direction button */}
                <Box sx={{ position: "absolute", left: "50%", transform: "translate(-50%,-50%)", zIndex: "999" }}>
                  <Button variant="direction_btn" sx={{ marginInline: "auto", width: "35px", height: "35px" }}>
                    <AddRoundedIcon fontSize="small" />
                  </Button>
                </Box>
                <Box className="swapInput" sx={{ display: "flex", flexDirection: "column", marginTop: "5px" }}>
                  {/* token you receive information */}
                  <div
                    className="swapInput__bottom"
                    style={{ marginTop: "0px", borderRadius: "10px", display: "flex", background: "#26262c" }}
                  >
                    <StyledTextField value={tokenBValue && tokenBValue} sx={{ width: "65%" }} disabled />
                    <Button variant="choose_token_btn" disabled>
                      <img src={tokenB?.icon} alt="" />
                      <span>{tokenB?.symbol}</span>
                    </Button>
                  </div>
                </Box>{" "}
                <Paper
                  elevation={0}
                  sx={{ padding: "10px 20px", borderRadius: "10px", background: "#33333b", marginTop: "20px" }}
                >
                  {/* Expected Output */}
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      Expected Output
                      <Tooltip
                        title="The expected pool tokens amount you will receive in this transaction. Output is estimated."
                        placement="top"
                      >
                        <HelpOutlineIcon fontSize="small" sx={{ marginLeft: "5px" }} />
                      </Tooltip>
                    </Typography>
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      {expectedLpTokens && numberWithCommas(fixedNumber(expectedLpTokens))}{" "}
                      <img src={tokenA?.icon} width={15} style={{ borderRadius: "50%", marginLeft: "3px" }} alt="" />
                      <img src={tokenB?.icon} width={15} style={{ borderRadius: "50%" }} alt="" />
                    </Typography>
                  </Box>
                </Paper>
                <Paper
                  elevation={0}
                  sx={{ padding: "10px 20px", borderRadius: "10px", background: "#33333b", marginTop: "20px" }}
                >
                  {/*Slippage*/}
                  {/* <Box
                    sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "5px" }}
                  >
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      Slippage
                      <Tooltip
                        title="Your transaction will revert if the price changes unfavorably by more than this percentage. Default is 0.5%."
                        placement="top"
                      >
                        <HelpOutlineIcon fontSize="small" sx={{ marginLeft: "5px" }} />
                      </Tooltip>
                    </Typography>
                    <Typography>{slippage}%</Typography>
                  </Box> */}
                  {/*LP providers fee*/}
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "5px" }}
                  >
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      LP providers fee
                      <Tooltip
                        title="LP providers fee is the amount which the swap deducts from output tokens amount to divert to the LP providers."
                        placement="top"
                      >
                        <HelpOutlineIcon fontSize="small" sx={{ marginLeft: "5px" }} />
                      </Tooltip>
                    </Typography>
                    <Typography>{providerFee}%</Typography>
                  </Box>
                </Paper>
                {/* Terms of use */}
                <TouButton />
                {/* Confirm swap button */}
                <Button variant="swap_btn" onClick={handleProvide}>
                  Add Tokens to Pool{" "}
                </Button>{" "}
              </Box>
            ) : (
              <Box
                sx={{
                  minWidth: "300px",
                  minHeight: "150px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {msgBoolean ? (
                  <Box>
                    <CircularProgress color="inherit" />
                    <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Transaction in progress...</Typography>
                  </Box>
                ) : (
                  <Box>
                    {!txnFailed ? (
                      <Box>
                        <CheckCircleOutlineIcon fontSize="large" />
                        <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Transaction completed!</Typography>
                      </Box>
                    ) : (
                      <Box>
                        <HighlightOffIcon fontSize="large" sx={{ color: "#e84142" }} />
                        {cancelled ? (
                          <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Transaction Cancelled!</Typography>
                        ) : (
                          <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Transaction Failed!</Typography>
                        )}
                        {cancelled ? (
                          <></>
                        ) : (
                          <Typography sx={{ fontSize: "14px", opacity: "0.5" }} component="p">
                            {txnMsg}{" "}
                          </Typography>
                        )}
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Paper>
        </Modal>
      )}
    </Wrapper>
  );
};

export default InitPairModal;
