import styled from "@emotion/styled";
import {
  Typography,
  Button,
  Box,
  Paper,
  Container,
  LinearProgress,
  CircularProgress,
  TableContainer,
} from "@mui/material";
import { colors } from "../themes";

export const TradeContainer = styled(Container)(({ theme }) => ({
  maxWidth: "1000px !important",
  width: "100%",
  paddingLeft: "0px",
  paddingRight: "0px",
  display: "flex",
  justifyContent: "center",
  gap: "20px",
}));

export const TradeMainBox = styled(Box)(({ theme }) => ({
  maxWidth: "430px !important",
  width: "100%",
  zIndex: "0",
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%,-30%)",
}));

export const DashboardPaper = styled(Paper)(({ theme, border }) => ({
  padding: "20px",
  borderRadius: "10px",
  background: "#33333b",
  marginTop: "10px",
  border: border,
  "@media(max-width:768px)": {
    padding: "15px 10px",
  },
}));

export const PriceInfoPaper = styled(Paper)(({ theme }) => ({
  padding: "10px 20px",
  borderRadius: "10px",
  background: "#33333b",
  marginTop: "20px",
}));

export const InfoBox = styled(Box)(({ theme, marginTop }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: marginTop,
}));
