import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { set_from_token, set_to_token } from "../../features/tokens";
import { set_swap_from_input } from "../../features/inputs";
import fromExponential from "from-exponential";

import Wrapper from "../Helpers/Wrapper";
import { StyledTextField } from "../../Style/general";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TokensDialog from "../Dialogs/TokensDialog";
import { BoxInputTitle, InputBoxBottom, InputBoxTop, InputBoxWrapper } from "../../Style/Components/inputs";
import { checkIfNumIsExp, customToFixed, getLimitedInput, preventLetters } from "../../Utils/functions/globalFunctions";
import TokenBalance from "../Helpers/TokenBalance";
import { makeDummyTransfer } from "../../Utils/functions/contractsFunctions";

const FromInput = ({ psp22Reserve, azeroReserve, currentPoolLp, currentPair }) => {
  const dispatch = useDispatch();

  const { connectedNetwork } = useSelector((state) => state.account);
  const { network_token } = useSelector((state) => state.tokens);
  const { from_token } = useSelector((state) => state.tokens); //get tokens state from redux
  const { to_token } = useSelector((state) => state.tokens); //get tokens state from redux
  const { address } = useSelector((state) => state.account); // get account state from redux
  const { psp22_balance } = useSelector((state) => state.account); // get account state from redux
  const { azero_balance } = useSelector((state) => state.account); // get account state from redux
  const { api } = useSelector((state) => state.contracts);
  const { swap_from_input } = useSelector((state) => state.inputs);

  const [inputValue, setInputValue] = useState(""); //input value
  // const [token, setToken] = useState(from_token); //token
  const [open, setOpen] = useState(false); // boolean variable to open or close tokens dialog
  const [tokenValue, setTokenValue] = useState(); // selected value from tokens dialog

  // open tokens dialog
  const handleClickOpen = () => {
    setOpen(true);
  };

  // close tokens dialog and set the chosen value
  const handleClose = (value) => {
    if (value?.name === to_token?.name) {
      dispatch(set_to_token(from_token));
    }
    setOpen(false);
    setTokenValue(value);
    // value && setToken(value);
    value && dispatch(set_from_token(value));
  };

  const handleFromValue = (e) => {
    let value = e?.target?.value;
    let input;
    if (value) {
      if (from_token.address.toLowerCase() !== network_token?.address?.toLowerCase()) {
        value > Number(psp22Reserve) ? (input = Number(psp22Reserve)) : (input = value);
      } else {
        value > Number(azeroReserve) ? (input = Number(azeroReserve)) : (input = value);
      }
      if (getLimitedInput(value)) {
        setInputValue(input);
      }
    } else {
      setInputValue("");
    }
  };

  // A function to choose HALF amount of token balance
  const chooseHalfAmount = () => {
    let half;

    if (from_token?.address.toLowerCase() === network_token.address.toLowerCase()) {
      let azeroRes = Number(azeroReserve);
      let balance = Number(azero_balance);
      if (balance < azeroRes) {
        if (balance > 0) {
          half = balance / 2;
          half = checkIfNumIsExp(balance) ? fromExponential(half) : customToFixed(half, 10);
        } else {
          half = "";
        }
      } else {
        half = azeroRes;
      }
    } else {
      let psp22Res = Number(psp22Reserve);
      let balance = Number(psp22_balance);

      if (balance < psp22Res) {
        if (balance > 0) {
          half = balance / 2;
          half = checkIfNumIsExp(balance) ? fromExponential(half) : customToFixed(half, 12);
        } else {
          half = "";
        }
      } else {
        half = psp22Res;
      }
    }

    setInputValue(half);
  };

  // A function to choose MAX amount of token balance
  const chooseMaxAmount = async () => {
    let max;
    // return;
    if (from_token?.address.toLowerCase() === network_token?.address?.toLowerCase()) {
      let azeroRes = Number(azeroReserve);
      let balance = Number(azero_balance);
      if (balance < azeroReserve) {
        max = await makeDummyTransfer(balance, api[0], address[0], balance > 1 ? false : true, connectedNetwork);
        if (max > 0) {
          setInputValue(checkIfNumIsExp(max) ? fromExponential(max) : customToFixed(max, 12));
          return;
        } else {
          max = "";
        }
      } else {
        max = azeroRes;
      }
    } else {
      let psp22Res = Number(psp22Reserve);
      let balance = Number(psp22_balance);
      if (balance < psp22Res) {
        if (balance > 0) {
          max = balance;
        } else {
          max = "";
        }
      } else {
        max = psp22Res;
      }
    }
    setInputValue(max);
  };

  useEffect(() => {
    if (inputValue === 0) {
      handleFromValue(inputValue);
      dispatch(set_swap_from_input(""));
    } else {
      dispatch(set_swap_from_input(inputValue));
    }
  }, [inputValue]);

  // Set token to from_token when from_token state is changed on redux
  // useEffect(() => {
  //   setToken(from_token);
  //   // setInputValue(0);
  // }, [from_token]);

  // useEffect(() => {
  //   setInputValue(swap_from_input);
  // }, [swap_from_input]);

  useEffect(() => {}, [currentPair]);

  return (
    <Wrapper>
      {/* Top input for choosing what user will pay */}
      <InputBoxWrapper>
        <BoxInputTitle>You pay</BoxInputTitle>
        {/* Top box of the input */}
        <InputBoxTop>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ marginRight: "5px" }}>Balance:</Typography>
            <TokenBalance tokenAddress={from_token?.address} />
          </Box>
          <Box>
            <Button variant="balance_btn" onClick={chooseHalfAmount}>
              half
            </Button>
            <Button variant="balance_btn" onClick={chooseMaxAmount} sx={{ marginLeft: "5px" }}>
              max
            </Button>
          </Box>
        </InputBoxTop>
        {/* Bottom box of the input */}
        <InputBoxBottom>
          <StyledTextField
            value={inputValue}
            placeholder="0.00"
            type="number"
            onInput={(e) => handleFromValue(e)}
            onKeyDown={(evt) => preventLetters(evt)}
            disabled={currentPoolLp}
          />
          {currentPair.id ? (
            <Button variant="choose_token_btn" onClick={handleClickOpen}>
              <img src={from_token?.icon} alt="" />
              <span>{from_token?.symbol}</span>
              <ArrowDropDownIcon />
            </Button>
          ) : (
            <Skeleton sx={{ width: "100%", maxWidth: "125px" }} />
          )}
          <TokensDialog tokenValue={tokenValue} open={open} onClose={handleClose} token={from_token} input={"from"} />
        </InputBoxBottom>
      </InputBoxWrapper>
    </Wrapper>
  );
};

export default FromInput;
