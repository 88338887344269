import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SupportedChainId } from "@azns/resolver-core";
import { useResolveDomainToAddress, useResolveAddressToDomain } from "@azns/resolver-react";
import { set_domain } from "../features/account";

export const useAzeroId = (address) => {
  const dispatch = useDispatch();
  const { connectedNetwork } = useSelector((state) => state.account);

  const { primaryDomain } = useResolveAddressToDomain(address, {
    // chainId: SupportedChainId.AlephZeroTestnet, // Testnet
    chainId: SupportedChainId.AlephZero, // Mainnet
  });

  if (primaryDomain) {
    if (connectedNetwork === "azero") {
      let user_domain = primaryDomain;
      return user_domain;
    }
  }
};
