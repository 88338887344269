import { createSlice } from "@reduxjs/toolkit";

export const tokensSlice = createSlice({
  name: "tokens",
  initialState: {
    network_token: {},
    tokens: [],
    filtered_tokens: [],
    pairs: [],
    current_pair: {},
    from_token: {},
    to_token: {},
    revertedTokens: [],
  },
  reducers: {
    set_network_token: (state, action) => {
      return {
        ...state,
        network_token: action.payload,
      };
    },
    set_tokens: (state, action) => {
      return {
        ...state,
        tokens: action.payload,
      };
    },
    set_filtered_tokens: (state, action) => {
      return {
        ...state,
        filtered_tokens: action.payload,
      };
    },
    set_current_pair: (state, action) => {
      return {
        ...state,
        current_pair: action.payload,
      };
    },
    set_pairs: (state, action) => {
      return {
        ...state,
        pairs: action.payload,
      };
    },
    set_from_token: (state, action) => {
      return {
        ...state,
        from_token: action.payload,
      };
    },
    set_to_token: (state, action) => {
      return {
        ...state,
        to_token: action.payload,
      };
    },
    swap_direction: (state, action) => {
      return {
        ...state,
        revertedTokens: [action.payload],
      };
    },
  },
});

export const {
  set_network_token,
  set_tokens,
  set_filtered_tokens,
  set_pairs,
  set_current_pair,
  set_from_token,
  set_to_token,
  swap_direction,
} = tokensSlice.actions;

export default tokensSlice.reducer;
