import { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Box, Fade } from "@mui/material";
import Wrapper from "../Helpers/Wrapper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 0.5,
  "@media:(max-width:768px)": {
    height: "70vh",
  },
};

const C14Modal = ({ address, targetAssetId, clientId, open, handleClose }) => {
  return (
    <Wrapper>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-c14-payment" aria-describedby="modal-c14-payment">
        <Box sx={style}>
          <iframe
            src={`https://pay.c14.money/?targetAddress=${address[0]}&targetAssetId=${targetAssetId}&clientId=${clientId}`}
            width="100%"
            height="100%"
          />
        </Box>
      </Modal>
    </Wrapper>
  );
};
export default C14Modal;
