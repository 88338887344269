import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Abi, ContractPromise } from "@polkadot/api-contract";
import { checkIfNumIsExp, customToFixed, numberWithCommas } from "../../Utils/functions/globalFunctions";
import { abiTradingPairAzero } from "../../Utils/abi/abiTradingPairAzero";
import fromExponential from "from-exponential";

import Wrapper from "./Wrapper";
import { getMaxGasLimit } from "@scio-labs/use-inkathon";
import { getRatio } from "../../Utils/constants";
import { getPoolTotalSupply } from "../../Utils/functions/contractsFunctions";

const ReserveBalance = ({ tokenAddress, pairAddress, volumeTokenA, volumeTokenB }) => {
  const { address } = useSelector((state) => state.account);
  const { api } = useSelector((state) => state.contracts);
  const { add_lp_complete } = useSelector((state) => state.inputs);
  const { connectedNetwork } = useSelector((state) => state.account);
  const { network_token } = useSelector((state) => state.tokens);

  const [reserve, setReserve] = useState("0.000");
  const gasLimit = api[0] && getMaxGasLimit(api[0]);

  const getPsp22Reserve = async (contract, totalSupply) => {
    if (totalSupply > 0) {
      try {
        let res = await contract?.query["getPsp22Balance"](address[0], { gasLimit });
        res = res.output.toHuman().Ok.replace(/,/g, "");
        let reserve = res / getRatio(connectedNetwork);
        // A function to get total Pool Supply to calculate user shares with %

        reserve = checkIfNumIsExp(reserve) ? fromExponential(reserve) : reserve;
        setReserve(reserve > 1 ? numberWithCommas(customToFixed(reserve, 0)) : reserve);
      } catch (err) {}
    } else {
      setReserve(0);
    }
  };

  const getAzeroReserve = async (contract, totalSupply) => {
    if (totalSupply > 0) {
      try {
        let res = await contract?.query["getA0Balance"](address[0], { gasLimit });
        res = res.output.toHuman().Ok.replace(/,/g, "");
        let reserve = res / getRatio(connectedNetwork);
        reserve = checkIfNumIsExp(reserve) ? fromExponential(reserve) : reserve;
        setReserve(reserve > 1 ? numberWithCommas(customToFixed(reserve, 0)) : reserve);
      } catch (err) {}
    } else {
      setReserve(0);
    }
  };

  const getBalance = useCallback(async () => {
    let contract;
    try {
      let abi = new Abi(abiTradingPairAzero, api[0].registry.getChainProperties());
      contract = api[0] && new ContractPromise(api[0], abi, pairAddress);
    } catch (err) {}

    let { total_supply: totalSupply } = await getPoolTotalSupply(contract, address[0], gasLimit, connectedNetwork);

    if (tokenAddress?.toLowerCase() !== network_token?.address?.toLowerCase()) {
      getPsp22Reserve(contract, totalSupply);
    } else {
      getAzeroReserve(contract, totalSupply);
    }
  }, [api, address, pairAddress, tokenAddress, add_lp_complete, volumeTokenA, volumeTokenB]);

  useEffect(() => {
    api.length > 0 && getBalance();

    const dataInterval = setInterval(() => {
      api.length > 0 && getBalance();
    }, 20000);

    return () => {
      clearInterval(dataInterval);
    };
  }, [api, address, pairAddress, tokenAddress, add_lp_complete, volumeTokenA, volumeTokenB]);

  return <Wrapper>{checkIfNumIsExp(reserve) ? fromExponential(reserve) : reserve}</Wrapper>;
};

export default ReserveBalance;
