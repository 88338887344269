import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";
import Wrapper from "../Helpers/Wrapper";
import { LeftBoxStyle } from "../../Style/Pages/Pool";
import pool from "../../Images/pool.png";
import { ContractPromise } from "@polkadot/api-contract";
import { abiTradingPairAzero } from "../../Utils/abi/abiTradingPairAzero";
import { getPoolTotalSupply } from "../../Utils/functions/contractsFunctions";
import { useSelector } from "react-redux";
import useResize from "../../hooks/useResize";

const LeftBox = ({ pairExist, tokensAreSameMsg, tokenA, tokenB, currentPair, getPairTotalSupply }) => {
  const navigate = useNavigate();
  const resize = useResize();

  const { network_token } = useSelector((state) => state.tokens);
  const { address } = useSelector((state) => state.account);
  const { api } = useSelector((state) => state.contracts);

  const [poolTotalSupply, setPoolTotalSupply] = useState();

  const checkForBothTokens = () => {
    if (tokenA && tokenB) {
      return (
        <Typography sx={{ marginBlock: "20px", lineHeight: "200%" }}>
          The ratio of tokens you add will set the price of this pool.
          <br />
          After adding liquidity to the pool, it will be available for everyone on the main pools page.
        </Typography>
      );
    } else {
      return (
        <Typography sx={{ marginBlock: "20px", lineHeight: "200%" }}>
          In order to initialize a liquidity pool, you must choose two different tokens that their pool is no published
          yet. <br />
          After that, you'll be able to insert the amount of tokens you wish to provide as liquidity.{" "}
        </Typography>
      );
    }
  };

  useEffect(() => {
    getPairTotalSupply(currentPair?.pair_address).then((res) => {
      setPoolTotalSupply(res);
    });
    checkForBothTokens();
  }, [currentPair]);

  return (
    <Wrapper>
      <LeftBoxStyle>
        {!pairExist ? (
          <Box>
            <Typography sx={{ fontSize: "26px", fontWeight: "bold", letterSpacing: "1px" }}>Create a pool</Typography>
            {tokensAreSameMsg ? (
              <Typography sx={{ marginBlock: "20px 50px", lineHeight: "200%" }}>{tokensAreSameMsg}</Typography>
            ) : (
              <Box>
                {checkForBothTokens()}
                <Typography sx={{ fontWeight: "600" }}>* PSP22/PSP22 pairs will come later on.</Typography>
              </Box>
            )}
          </Box>
        ) : (
          <Box>
            <Typography
              sx={{ fontSize: "30px", fontWeight: "bold", textTransform: "capitalize", letterSpacing: "1px" }}
            >
              {poolTotalSupply > 0 ? <span>Pool already exist!</span> : <span>Ready to add liquidity?</span>}
            </Typography>
            <Typography
              sx={{
                marginBlock: "20px",
                fontSize: "22px",
                textTransform: "uppercase",
                fontWeight: "bold",
                display: "flex",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {tokenA && (
                  <img src={tokenA?.icon} alt="" style={{ width: "30px", height: "auto", borderRadius: "50%" }} />
                )}
                {tokenB && (
                  <img src={tokenB?.icon} alt="" style={{ width: "30px", height: "auto", borderRadius: "50%" }} />
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {tokenA && <span style={{ marginLeft: "5px" }}>{tokenA?.symbol}</span>}
                <span style={{ marginInline: "1px" }}>/</span>
                {tokenB && <span>{tokenB?.symbol} </span>}
              </Box>{" "}
            </Typography>
            <Typography sx={{ marginBlock: "20px", lineHeight: "200%" }}>
              {poolTotalSupply > 0 ? (
                <span>
                  This pool is already exist. <br />
                  You can enter this specific pool and add liquidity. <br />
                </span>
              ) : (
                <span>
                  Pool have been created successfully! <br />
                  You are now able to approve{" "}
                  {tokenA.address.toLowerCase() !== network_token?.address?.toLowerCase()
                    ? tokenA.symbol
                    : tokenB.symbol}
                  . <br />
                  Please approve the token amount you would like to add and after that add the liquidity to the pool.
                  <br />
                  {address[0] === currentPair?.creator_address && (
                    <span>
                      {" "}
                      In case you do not want to add liquidity right now you can leave this page but there might be a
                      chance that another user will be the first provider of the pool you have created.
                    </span>
                  )}
                </span>
              )}
            </Typography>
            {poolTotalSupply > 0 && (
              <Button
                variant="contained"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    textTransform: "uppercase",
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    onClick={() => {
                      navigate(`/liquidity/${currentPair.pair_address}`);
                    }}
                  >
                    Enter {tokenA && <span style={{ marginLeft: "5px" }}>{tokenA?.name}</span>}
                    <span style={{ marginInline: "1px" }}>/</span>
                    {tokenB && <span style={{ marginRight: "5px" }}>{tokenB?.name}</span>}
                    Pool
                  </Box>
                </Typography>{" "}
              </Button>
            )}
          </Box>
        )}
      </LeftBoxStyle>
    </Wrapper>
  );
};

export default LeftBox;
