import styled from "@emotion/styled";
import { Paper } from "@mui/material";
import { Box, display } from "@mui/system";
import { colors } from "../themes";

// Top styled table row for pool table
export const InputsPaper = styled(Paper)(({ theme, width }) => ({
  width: width ? width : "35%",
  borderRadius: "10px",
  paddingBlock: "10px",
}));
// Top styled table row for pool table
export const DetailsPaper = styled(Paper)(({ theme, width, display, gap }) => ({
  width: width ? width : "65%",
  display: display ? display : "auto",
  borderRadius: "10px",
  // height: "300px",
}));
// Top styled table row for pool table
export const DetailsPaper2d = styled(Paper)(({ theme }) => ({
  width: "100%",
  borderRadius: "10px",
  display: "flex",
}));
// Top styled table row for pool table
export const TopDetailsPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: "20px",
  borderRadius: "10px",
  position: "relative",
}));
export const TopDetailsPaper2d = styled(Paper)(({ theme }) => ({
  display: "flex",
  width: "60%",
  flexDirection: "column",
  justifyContent: "center",
  padding: "20px",
  borderRadius: "10px",
}));

export const TopDetailsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

export const TopDetailsTokenNameBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  ">img": {
    width: "30px",
    height: "30px",
    borderRadius: "50px",
  },
}));

export const AnalyticsTopDataContainer = styled(Box)(({ theme, columns }) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${columns}, minmax(0px, 1fr));`,
  rowGap: "2rem",
  columnGap: "2rem",
}));

export const AnalyticsDataBox = styled(Box)(({ theme }) => ({
  border: "1px solid rgba(255, 255, 255, 0.2)",
  borderRadius: "10px",
  backgroundColor: "rgba(255, 255, 255, 0.05)",
  position: "relative",
  padding: "10px 20px",
  display: "flex",
  flexDirection: "column",
}));
