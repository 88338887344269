import { Box, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const CountdownTimer = ({ currentLockDate }) => {
  // Set the target date (replace with your desired date)

  // Function to calculate the remaining time
  const calculateRemainingTime = () => {
    const currentDate = new Date().getTime();
    const timeDifference = currentLockDate - currentDate;

    if (timeDifference <= 0) {
      // If the target date has passed, set remaining time to 0
      return 0;
    }

    return timeDifference;
  };

  // State to store the remaining time
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

  // Update the remaining time every second
  useEffect(() => {
    const timerInterval = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(timerInterval);
  }, [currentLockDate]);

  // Function to format the remaining time
  const formatTime = (time) => {
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <Box sx={{ marginRight: "10px" }}>
      {remainingTime > 0 ? (
        <Typography>LP Unlocks in - {formatTime(remainingTime)}</Typography>
      ) : (
        <Typography>LP Unlocked</Typography>
      )}
    </Box>
  );
};

export default CountdownTimer;
