import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Cookies from "universal-cookie";

import { ApiPromise, WsProvider } from "@polkadot/api";
import { ContractPromise } from "@polkadot/api-contract";

import { abiTradingPairAzero } from "../../Utils/abi/abiTradingPairAzero";
import { abiPairCreator } from "../../Utils/abi/abiPairCreator";
import { abiAirdrop } from "../../Utils/abi/abiAirdrop";
import { abiVesting } from "../../Utils/abi/abiVesting";
import { getNightlyAdapter } from "../../Utils/nightlyAdapter";

import {
  insert_azero_balance,
  insert_psp22_balance,
  select_address,
  set_connectedNetwork,
} from "../../features/account";
import {
  set_airdrop_contract,
  set_api,
  set_pair_creator_contract,
  set_tpa_contract,
  set_vesting_contract,
} from "../../features/contracts";

import routes from "../../Routes/routes";
import Wrapper from "../Helpers/Wrapper";
import useResize from "../../hooks/useResize";

import ChooseRpcUrlModal from "../Modals/ChooseRpcUrlModal";
import C14Modal from "../Modals/C14Modal";

import {
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  styled,
  Box,
  Drawer,
  Button,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import BugReportIcon from "@mui/icons-material/BugReport";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import GitHubIcon from "@mui/icons-material/GitHub";
import WifiIcon from "@mui/icons-material/Wifi";

import { colors } from "../../Style/themes";

export default function NavDrawer() {
  const { address } = useSelector((state) => state.account);
  const { connectedNetwork } = useSelector((state) => state.account);
  const { domain } = useSelector((state) => state.account);

  const resize = useResize();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const targetAssetId = "01d80aba-7688-4e5b-97b8-5f3f77dd6714";
  const clientId = "37c1af36-164c-4aba-8b40-0dbba6d089f5";
  const [openC14, setOpenC14] = useState(false);
  const handleOpenC14 = () => setOpenC14(true);
  const handleCloseC14 = () => setOpenC14(false);

  const [state, setState] = useState({
    right: false,
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    if (connectedNetwork === "azero") {
      setAzeroNetworkDisabled(true);
      setShidenNetworkDisabled(false);
    } else if (connectedNetwork === "shiden") {
      console.log("here", connectedNetwork);
      setAzeroNetworkDisabled(false);
      setShidenNetworkDisabled(true);
    } else {
      setAzeroNetworkDisabled(false);
      setShidenNetworkDisabled(false);
    }
  };
  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpen(false);
  };

  const [connecting, setConnecting] = useState(false);
  const [networkName, setNetworkName] = useState();
  const [azeroNetworkDisabled, setAzeroNetworkDisabled] = useState(false);
  const [shidenNetworkDisabled, setShidenNetworkDisabled] = useState(false);
  // choose rpcUrl for the desired network.
  const chooseRpcUrl = (name) => {
    setNetworkName(name);
    setConnecting(true);
    setAzeroNetworkDisabled(true);
    setShidenNetworkDisabled(true);
    let rpcUrl;
    let tpa_env;
    let pair_creator_env;
    let vesting_env;
    let airdrop_env;

    if (name === "shiden") {
      tpa_env = process.env.REACT_APP_ASTAR_TRADING_PAIR_AZERO_CONTRACT;
      pair_creator_env = process.env.REACT_APP_ASTAR_PAIR_CREATOR_CONTRACT;
      // rpcUrl = "wss://rpc.shibuya.astar.network/"; // Testnet
      rpcUrl = "wss://shiden-rpc.dwellir.com"; // Mainnet
    } else if (name === "azero") {
      tpa_env = process.env.REACT_APP_A0_TRADING_PAIR_AZERO_CONTRACT;
      pair_creator_env = process.env.REACT_APP_A0_PAIR_CREATOR_CONTRACT;
      vesting_env = process.env.REACT_APP_A0_VESTING_CONTRACT;
      airdrop_env = process.env.REACT_APP_A0_PAIR_AIRDROP_CONTRACT;
      // rpcUrl = "wss://ws.test.azero.dev"; // Testnet
      rpcUrl = "wss://ws.azero.dev"; // Mainnet
    }

    handleWebSocketConnection(rpcUrl, name, tpa_env, pair_creator_env, vesting_env, airdrop_env);
  };

  // OPEN WEBSOCKET AND api promise
  const handleWebSocketConnection = async (rpcUrl, name, tpa_env, pair_creator_env, vesting_env, airdrop_env) => {
    const wsProvider = new WsProvider(rpcUrl);
    const api = await ApiPromise.create({ provider: wsProvider });
    dispatch(set_api(api));
    dispatch(set_connectedNetwork(name));
    localStorage.setItem("connectedNetwork", name);

    const tpaContract = new ContractPromise(api, abiTradingPairAzero, tpa_env);
    dispatch(set_tpa_contract(tpaContract));
    const pairCreatorContract = new ContractPromise(api, abiPairCreator, pair_creator_env);
    dispatch(set_pair_creator_contract(pairCreatorContract));
    const vestingContract = new ContractPromise(api, abiVesting, vesting_env);
    dispatch(set_vesting_contract(vestingContract));
    const airdropContract = new ContractPromise(api, abiAirdrop, airdrop_env);
    dispatch(set_airdrop_contract(airdropContract));

    if (api) {
      handleClose();
      setConnecting(false);
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const logoutUser = async () => {
    cookies.remove("address", { path: "/" });
    cookies.remove("domain", { path: "/" });
    cookies.remove("accessToken", { path: "/" });
    localStorage.removeItem("NIGHTLY_CONNECT_SESSION_ID_AlephZero");
    localStorage.removeItem("NIGHTLY_CONNECT_SELECTOR_IS_DESKTOP_CONNECTED_AlephZero");
    localStorage.removeItem("NIGHTLY_CONNECT_SELECTOR_RECENT_STANDARD_WALLET_AlephZero");
    dispatch(select_address(null));
    dispatch(insert_psp22_balance(0));
    dispatch(insert_azero_balance(0));
    await getNightlyAdapter().disconnect();
    window.location.reload();
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {resize < 993 &&
          routes.map(
            ({ Component, path, title, newFeature, navbar, icon, soon }, index) =>
              navbar && (
                <Wrapper key={title}>
                  {newFeature ? (
                    <Box style={{ color: "white", textDecoration: "none" }}>
                      <Link
                        to="#"
                        onClick={address[0] && handleOpenC14}
                        key={title}
                        style={{ color: "white", textDecoration: "none" }}
                      >
                        <ListItemButton disabled={address[0] ? false : true}>
                          <ListItemIcon>{icon}</ListItemIcon>
                          <ListItemText>{title}</ListItemText>
                          <Typography
                            style={{
                              position: "absolute",
                              top: "0px",
                              right: "50px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: colors.darkLightTorq,
                            }}
                          >
                            New
                          </Typography>{" "}
                        </ListItemButton>
                      </Link>
                    </Box>
                  ) : soon ? (
                    <Box style={{ color: "white", textDecoration: "none" }}>
                      <Link
                        to={path}
                        style={{ color: "white", textDecoration: "none" }}
                        onClick={(e) => e.preventDefault()}
                      >
                        <ListItemButton disabled>
                          <ListItemIcon>{icon}</ListItemIcon>
                          <ListItemText>{title}</ListItemText>
                          <Typography
                            style={{
                              position: "absolute",
                              top: "0px",
                              right: "50px",
                              fontSize: "12px",
                              fontWeight: "bold",
                              color: colors.red,
                            }}
                          >
                            Soon
                          </Typography>{" "}
                        </ListItemButton>
                      </Link>
                    </Box>
                  ) : (
                    <Link to={path} style={{ color: "white", textDecoration: "none" }}>
                      <ListItemButton>
                        <ListItemIcon>{icon}</ListItemIcon>
                        <ListItemText>{title}</ListItemText>
                      </ListItemButton>
                    </Link>
                  )}

                  <Divider />
                </Wrapper>
              )
          )}

        <ListItemButton onClick={handleOpen}>
          <ListItemIcon>
            <WifiIcon sx={{ marginRight: "5px" }} />
          </ListItemIcon>
          <ListItemText>Change Network</ListItemText>
        </ListItemButton>

        <Divider />

        <ListItemButton
          onClick={() => {
            window.open("https://panoramaswap.com/bug-bounty", "_blank");
          }}
          sx={{ cursor: "pointer", display: "flex" }}
        >
          <ListItemIcon>
            {" "}
            <BugReportIcon sx={{ marginRight: "5px" }} />
          </ListItemIcon>
          <ListItemText>Bug Bounty</ListItemText>
        </ListItemButton>

        <Divider />

        <ListItemButton
          onClick={() => {
            window.open("https://github.com/RottenKiwi/Panorama-Swap-INK-SC", "_blank");
          }}
        >
          <ListItemIcon>
            {" "}
            <GitHubIcon sx={{ marginRight: "5px" }} />
          </ListItemIcon>
          <ListItemText>SC Repository</ListItemText>
        </ListItemButton>

        <Divider />
        {address[0] && (
          <ListItemButton sx={{ marginTop: "auto" }} onClick={logoutUser}>
            <ListItemIcon>
              {" "}
              <LogoutIcon sx={{ marginLeft: "3px" }} />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </ListItemButton>
        )}
        <Divider />
      </List>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)} variant="menu_btn">
            {" "}
            <MenuIcon fontSize="large" />
          </Button>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}

      <C14Modal
        address={address}
        targetAssetId={targetAssetId}
        clientId={clientId}
        open={openC14}
        handleClose={handleCloseC14}
      />

      <ChooseRpcUrlModal
        handleClose={handleClose}
        open={open}
        chooseRpcUrl={chooseRpcUrl}
        connecting={connecting}
        networkName={networkName}
        azeroNetworkDisabled={azeroNetworkDisabled}
        shidenNetworkDisabled={shidenNetworkDisabled}
      />
    </div>
  );
}
