import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import routes from "../../Routes/routes";
import useResize from "../../hooks/useResize";

import C14Modal from "../Modals/C14Modal";
import NavDrawer from "../Drawer/NavDrawer";
import ConnectWalletButton from "../Buttons/ConnectWalletButton";

import Wrapper from "../Helpers/Wrapper";
import {
  NavItem,
  NavList,
  NavListWrapper,
  NavIconsWrapper,
  NavLogo,
  NavTitle,
  NavWrapper,
  Container,
} from "../../Style/nav";

import { colors } from "../../Style/themes";
import { DarkModeSwitch } from "../../Style/general";
import { Box, Button, ListItemButton, Typography } from "@mui/material";
import NetworkSelector from "../Helpers/NetworkSelector";

const Navbar = ({ themeToggler, theme, logoSrc }) => {
  const resize = useResize();
  let location = useLocation();
  let navigate = useNavigate();
  const { address } = useSelector((state) => state.account);
  const { connectedNetwork } = useSelector((state) => state.account);

  const targetAssetId = "01d80aba-7688-4e5b-97b8-5f3f77dd6714";
  const clientId = "37c1af36-164c-4aba-8b40-0dbba6d089f5";

  const networks = [
    {
      id: 1,
      name: "Aleph Zero Network",
      symbol: "a0",
      icon: "https://i.imagesup.co/images2/71a48bd8b5a5c192b490bbac3b759612e940aa6d.png",
    },
    {
      id: 2,
      name: "Shiden Network",
      symbol: "sdn",
      icon: "https://i.imagesup.co/images2/7065218c358c85ff53e7bd2276816e5e451449b6.png",
    },
    // Add more networks as needed
  ];

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Wrapper>
      <NavWrapper>
        <Container>
          <NavLogo
            onClick={() => {
              navigate(`/`);
            }}
          >
            <NavTitle>
              <img src={logoSrc} alt="" width={70} />
            </NavTitle>
          </NavLogo>

          {resize > 992 && (
            <NavListWrapper>
              <NavList>
                {routes.map(
                  ({ Component, path, title, navbar, newFeature, soon }, index) =>
                    navbar && (
                      <Wrapper>
                        {newFeature ? (
                          <Link to="#" onClick={handleOpen} key={`${title}-${index}`}>
                            {connectedNetwork === "azero" && (
                              <Box sx={{ display: "flex", position: "relative" }}>
                                <Typography
                                  style={{
                                    position: "absolute",
                                    top: "-4px",
                                    right: "17px",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    opacity: "0.8",
                                    color: colors.darkLightTorq,
                                  }}
                                >
                                  New
                                </Typography>
                                <NavItem key={index} className={location.pathname === path ? "current-item" : ""}>
                                  {title}
                                </NavItem>
                              </Box>
                            )}
                          </Link>
                        ) : soon ? (
                          <Link to={path} key={`${title}-${index}`} onClick={(e) => e.preventDefault()}>
                            <Box sx={{ display: "flex", position: "relative" }}>
                              <Typography
                                style={{
                                  position: "absolute",
                                  top: "-4px",
                                  right: "17px",
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  opacity: "0.8",
                                  color: colors.red,
                                }}
                              >
                                Soon
                              </Typography>
                              <NavItem key={index} className={location.pathname === path ? "current-item" : ""}>
                                {title}
                              </NavItem>
                            </Box>
                          </Link>
                        ) : (
                          <Link to={path} key={`${title}-${index}`}>
                            <NavItem key={index} className={location.pathname === path ? "current-item" : ""}>
                              {title}
                            </NavItem>
                          </Link>
                        )}
                      </Wrapper>
                    )
                )}
              </NavList>
            </NavListWrapper>
          )}

          <NavIconsWrapper>
            {/* <NetworkSelector networks={networks} /> */}
            <ConnectWalletButton />
            <NavDrawer />
          </NavIconsWrapper>
        </Container>
      </NavWrapper>

      <C14Modal
        address={address}
        targetAssetId={targetAssetId}
        clientId={clientId}
        open={open}
        handleClose={handleClose}
      />
    </Wrapper>
  );
};

export default Navbar;
