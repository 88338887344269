import styled from "@emotion/styled";
import { Box, TableCell, TableRow } from "@mui/material";
import { colors } from "../themes";

// Top styled table row for pool table
export const TopStyledTableRow = styled(TableRow)(({ theme }) => ({
  "td, th": { border: 0, color: colors.gray, fontSize: "14px" },
}));

//  Body styled table row for pool table
export const StyledTableRow = styled(TableRow)(({ theme, hover }) => ({
  position: "relative",
  td: {
    border: 0,
    "&:first-of-type": {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "&:last-child": {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
  "&:hover": hover && {
    background: colors.gray,
    cursor: "pointer",
  },
}));
//  Body styled table row for pool table
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  // width: "auto",
  padding: "10px",
  "@media(max-width:768px)": {
    fontSize: "12px",
    // padding: "5px",
  },
}));

// Top styled table row for pool table
export const CellBoxValue = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
  ">img": {
    width: "15px",
    borderRadius: "50px",
    marginRight: "3px",
  },
  "@media(max-width:768px)": {
    fontSize: "12px",
  },
}));
