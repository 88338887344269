import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { set_from_token, set_to_token, swap_direction } from "../../features/tokens";
import { set_swap_to_input } from "../../features/inputs";
import { BN } from "@polkadot/util";

import Wrapper from "../Helpers/Wrapper";
import { StyledTextField } from "../../Style/general";
import { BoxInputTitle, InputBoxBottom, InputBoxTop, InputBoxWrapper } from "../../Style/Components/inputs";
import { Button, Skeleton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import TokensDialog from "../Dialogs/TokensDialog";
import { checkIfNumIsExp, customToFixed, preventLetters, ratioMultiplier } from "../../Utils/functions/globalFunctions";
import fromExponential from "from-exponential";
import { getRatio } from "../../Utils/constants";
import { errorHandler } from "../../Utils/functions/scErrorHandler";

const ToInput = ({ psp22Reserve, azeroReserve, priceImpact, currentContract, currentPoolLp, gas, currentPair }) => {
  const dispatch = useDispatch();

  const { network_token } = useSelector((state) => state.tokens);
  const { from_token } = useSelector((state) => state.tokens); //get tokens state from redux
  const { to_token } = useSelector((state) => state.tokens); //get tokens state from redux
  const { swap_from_input } = useSelector((state) => state.inputs); //get inputs state from redux
  const { swap_to_input } = useSelector((state) => state.inputs); //get inputs state from redux
  const { refresh } = useSelector((state) => state.inputs); //get inputs state from redux
  // const { revertedTokens } = useSelector((state) => state.tokens); //get tokens state from redux
  const { address } = useSelector((state) => state.account); // get account state from redux
  const { connectedNetwork } = useSelector((state) => state.account); // get account state from redux
  const { api } = useSelector((state) => state.contracts); //get inputs state from redux

  const [toInputValue, setToInputValue] = useState(); //input value
  const [open, setOpen] = useState(false); // boolean variable to open or close tokens dialog
  const [selectedValue, setSelectedValue] = useState(); // selected value from tokens dialog

  const handleClickOpen = (token) => {
    setOpen(true);
  };

  const handleClose = async (value) => {
    if (value?.name === from_token?.name) {
      dispatch(set_from_token(to_token));
    }
    setOpen(false);
    setSelectedValue(value);
    value && dispatch(set_to_token(value));
  };

  ////////////////////////////////////////
  ////////////////////////////////////////
  ////////////////////////////////////////
  ////////////////////////////////////////

  // A function to retrieve to_input value
  const getToInputValue = async () => {
    let value = 0;
    let toInputTrueValue = 0;
    let queryName =
      to_token?.address?.toLowerCase() === network_token?.address?.toLowerCase()
        ? "getEstPricePsp22ToA0"
        : "getEstPriceA0ToPsp22";
    let user_input = swap_from_input;
    user_input = ratioMultiplier(swap_from_input, connectedNetwork);
    if (swap_from_input > 0) {
      try {
        await currentContract?.query[queryName](address[0], { value, gasLimit: gas }, user_input).then((res) => {
          if (res.output.toHuman().Ok.Err) {
            alert(errorHandler(res.output.toHuman().Ok.Err));
            return;
          } else {
            toInputTrueValue = res.output.toHuman().Ok.Ok.replace(/,/g, "") / getRatio(connectedNetwork);
            if (toInputTrueValue === 0) {
              setToInputValue("");
            } else {
              toInputTrueValue = checkIfNumIsExp(toInputTrueValue)
                ? fromExponential(toInputTrueValue)
                : toInputTrueValue;
              setToInputValue(toInputTrueValue);
            }
          }
        });
      } catch (err) {}
    } else {
      setToInputValue("");
    }
  };

  // Set token to from_token when from_token state is changed on redux

  useEffect(() => {
    let reserve =
      to_token?.address?.toLowerCase() === network_token?.address?.toLowerCase()
        ? Number(psp22Reserve)
        : Number(azeroReserve);
    if (swap_from_input <= reserve) {
      getToInputValue();
    }
  }, [swap_from_input, from_token, to_token]);

  useEffect(() => {
    if (toInputValue === 0) {
      dispatch(set_swap_to_input(""));
    } else {
      dispatch(set_swap_to_input(toInputValue));
    }
  }, [toInputValue]);

  useEffect(() => {
    getToInputValue();
    priceImpact < 100 && getToInputValue();
    const toInputInterval = setInterval(() => {
      getToInputValue();
    }, 10000);
    return () => {
      clearInterval(toInputInterval);
    };
  }, [refresh, swap_from_input, to_token]);

  return (
    <Wrapper>
      {/* Bottom input for choosing what user will get */}
      <InputBoxWrapper>
        <InputBoxTop>
          <BoxInputTitle>You receive</BoxInputTitle>
        </InputBoxTop>
        <InputBoxBottom>
          <StyledTextField
            value={toInputValue && toInputValue}
            placeholder="0.00"
            type="number"
            onKeyDown={(evt) => preventLetters(evt)}
            disabled={currentPoolLp}
          />
          {currentPair.id ? (
            <Button
              variant="choose_token_btn"
              onClick={() => {
                handleClickOpen(to_token);
              }}
            >
              <img src={to_token?.icon} alt="" />
              <span>{to_token?.symbol}</span>
              <ArrowDropDownIcon />
            </Button>
          ) : (
            <Skeleton sx={{ maxWidth: "125px", width: "100%" }} />
          )}

          <TokensDialog selectedValue={selectedValue} open={open} onClose={handleClose} token={to_token} input={"to"} />
        </InputBoxBottom>
      </InputBoxWrapper>
    </Wrapper>
  );
};

export default ToInput;
