import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Identicon from "@polkadot/react-identicon";

import { List, ListItem, Typography, Box, ListItemButton, Divider, Button, LinearProgress } from "@mui/material";
import { StyledDialog } from "../../Style/general";
import CloseIcon from "@mui/icons-material/Close";
import { getNightlyAdapter } from "../../Utils/nightlyAdapter";
import Wrapper from "../Helpers/Wrapper";
import { useAzeroId } from "../../hooks/useAzeroId";

const AccountsDialog = ({
  onClose,
  selectedValue,
  open,
  getInjector,
  accounts,
  loggedUser,
  chosenWallet,
  injector,
  connecting,
}) => {
  const [disconnecting, setDisconnecting] = useState(false);
  const [chosenUser, setChosenUser] = useState();

  const handleClose = () => {
    try {
      if (selectedValue) {
        onClose(loggedUser?.address ? loggedUser?.address : selectedValue);
      } else {
        onClose();
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const handleListItemClick = async (value) => {
    setChosenUser(value);
  };

  const connectToUserAddress = () => {
    getInjector(chosenUser?.address);
    onClose(chosenUser);
  };

  const disconnectFromWallet = async () => {
    setDisconnecting(true);
    await getNightlyAdapter().disconnect();
    let wallet_obj = { type: "wallet", name: chosenWallet };
    onClose(wallet_obj);
    setDisconnecting(false);
  };

  useEffect(() => {
    chosenUser && connectToUserAddress();
  }, [chosenUser]);

  return (
    <StyledDialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        style: {
          minHeight: "200px",
          width: "100%",
          maxWidth: "450px",
          padding: "20px",
          borderRadius: "10px",
          margin: "10px",
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
        <Typography sx={{ fontSize: "22px", textTransform: "capitalize" }}>Choose Account</Typography>
        <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
      </Box>{" "}
      <Divider />
      {!disconnecting ? (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBlock: "20px 0px" }}>
          <Typography>Connected to {chosenWallet}</Typography>
          <Button
            variant="contained"
            onClick={() => {
              disconnectFromWallet();
            }}
          >
            disconnect
          </Button>
        </Box>
      ) : (
        <Wrapper>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              marginBlock: "20px",
              gap: "10px",
            }}
          >
            <Box>Disconnecting from {chosenWallet}...</Box>
            <LinearProgress sx={{ width: "80%" }} />
          </Box>
          <Divider />
        </Wrapper>
      )}
      {accounts?.length < 1 ? (
        <Box>
          <Typography>
            It look like you have no accounts related to {chosenWallet}. <br />
            Please go back to the {chosenWallet} extension and connect a new user to your Azero wallet address.
          </Typography>
        </Box>
      ) : (
        <Wrapper>
          <Box>
            {!connecting ? (
              <List>
                {accounts?.map((account, i) => (
                  <ListItemButton
                    onClick={() => handleListItemClick(account)}
                    key={i}
                    sx={{
                      marginBlock: "10px",
                      background: "#282830",
                      borderRadius: "10px !important",
                      padding: "10px",
                      display: "flex",
                      gap: "10px",
                      "&:hover": {
                        background: "#4caf50",
                      },
                    }}
                  >
                    <Identicon value={account.address} size="32" theme="polkadot" />

                    <Box sx={{ textOverflow: "ellipsis", overflow: "hidden", width: "100%", whiteSpace: "nowrap" }}>
                      <Typography sx={{ fontSize: "18px" }}>{account.name} </Typography>
                      <Typography component="span">{account.address}</Typography>
                      {/* <ListItemText primary={account.meta.source} /> */}
                    </Box>
                  </ListItemButton>
                ))}
              </List>
            ) : (
              <Wrapper>
                <Divider sx={{ marginTop: "20px" }} />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    marginTop: "20px",
                    gap: "10px",
                  }}
                >
                  <Box>Connecting to {chosenUser?.name}...</Box>
                  <LinearProgress sx={{ width: "80%" }} />
                </Box>
              </Wrapper>
            )}
          </Box>
        </Wrapper>
      )}
    </StyledDialog>
  );
};

AccountsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string,
};

export default AccountsDialog;
