import fromExponential from "from-exponential";
import { getRatio } from "../constants";
import {
  deleteUserInsertedLp,
  getUserByAddress,
  getVolumePerToken,
  insertUserInsertedLp,
  updateUserInsertedLp,
} from "../../shared/api";

// ********* GLOBAL FUNCTIONS RELATED TO API CALLS ********* //
export const insertUserData = async (obj, cookie, network) => {
  let { user_address, pair_address } = obj;
  let isUserExist = await getUserByAddress(pair_address, user_address, network);
  isUserExist = isUserExist?.data?.data;
  if (isUserExist) {
    obj.lp_tokens = +obj.lp_tokens + +isUserExist.lp_tokens;
    obj.psp22_amount = +obj.psp22_amount + +isUserExist.psp22_amount;
    obj.azero_amount = +obj.azero_amount + +isUserExist.azero_amount;
    updateUserInsertedLp(obj, cookie, network);
  } else {
    await insertUserInsertedLp(obj, cookie, network);
  }
};

export const deleteUserData = async (obj, userPoolTokens, cookie, network) => {
  let { pair_address, user_address, lp_tokens } = obj;
  let isUserExist = await getUserByAddress(pair_address, user_address, network);
  isUserExist = isUserExist?.data?.data;
  if (isUserExist) {
    if (lp_tokens < userPoolTokens) {
      obj.lp_tokens = +isUserExist.lp_tokens - +obj.lp_tokens;
      obj.psp22_amount = +isUserExist.psp22_amount - +obj.psp22_amount;
      obj.azero_amount = +isUserExist.azero_amount - +obj.azero_amount;
      updateUserInsertedLp(obj, cookie, network);
    } else {
      await deleteUserInsertedLp(obj, cookie, network);
    }
  }
};

export const getTokenVolume = async (token_address, pair_address, network) => {
  let volume = await getVolumePerToken(token_address, 1, pair_address, network);
  volume = volume.data.data.total_amount;
  volume === null && (volume = 0);
  volume = volume > 1 ? customToFixed(volume, 0) : volume;
  return volume;
};

// ********* CUSTOM FUNCTIONS ********* //
export const numberWithCommas = (number) => {
  let newNumber;
  let parts = number?.toString().split(".");
  if (parts) {
    newNumber = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
  }
  return newNumber;
};

export const preventLetters = (evt) => {
  if (evt.key === "e" || evt.key === "E" || evt.key === "-" || evt.key === "=" || evt.key === "+" || evt.key === "/") {
    evt.preventDefault();
    return;
  }
};

export const fixedNumber = (balance) => {
  let query = /^\d+(?:\.\d{0,4})?/;
  let num = Number(balance.toString().match(query));
  num = num.toFixed(4);
  return num;
};

export const makeId = (length) => {
  let result = "";
  let chars = "0123456789";
  let charLength = chars.length;
  for (var i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
};

export const checkIfNumIsExp = (num) => {
  let bool = num?.toString().includes("e") ? true : false;
  return bool;
};

export const getLimitedInput = (num) => {
  const regex = /^\d+(\.\d{1,12})?$/;
  return regex.test(num);
};

export const customToFixed = (n, precision) => {
  const s = `${n}`,
    i = `${n}`.split("."),
    p = Math.max(precision | 0, 0);
  return i[0] + (p ? `.${(i[1] ?? "").padEnd(p, "0")}`.slice(0, p + 1) : "");
};

export const ratioMultiplier = (value, network) => {
  value = value?.toString();
  value = fromExponential(value * getRatio(network));
  value = parseInt(value);
  value = value > 0 && BigInt(value);
  return value;
};
