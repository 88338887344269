import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { web3FromSource } from "@polkadot/extension-dapp";
import { ContractPromise } from "@polkadot/api-contract";
import { getMaxGasLimit } from "@scio-labs/use-inkathon";
import Cookies from "universal-cookie";

import { useAuth } from "../../hooks/useAuth";
import { getTokenPairByAddresses, insertPair } from "../../shared/api";
import { abiPsp22Token } from "../../Utils/abi/abiPsp22Token";
import { insertUserData, makeId, ratioMultiplier } from "../../Utils/functions/globalFunctions";
import { getRatio } from "../../Utils/constants";
import { abiTradingPairAzero } from "../../Utils/abi/abiTradingPairAzero";

import Wrapper from "../Helpers/Wrapper";
import ApprovalButton from "./ApprovalButton";
import ApprovalModal from "../Modals/ApprovalModal";
import InitPairModal from "../Modals/InitPairModal";

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { colors } from "../../Style/themes";
import fromExponential from "from-exponential";
import { useLastInjector } from "../../hooks/useLastInjector";
import { errorHandler } from "../../Utils/functions/scErrorHandler";
import moment from "moment";
import { getExpectedLpTokens } from "../../Utils/functions/contractsFunctions";

const InitPairButton = ({
  variant,
  tokenA,
  tokenB,
  tokenAValue,
  tokenBValue,
  pairExist,
  checkIfPairExist,
  currentPair,
  fee,
  unlockDate,
  isBtnDisabled,
  initBtnMsg,
  valueMsg,
  psp22TokensMsg,
  azeroTokensMsg,
  disableButton,
  getPairTotalSupply,
}) => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const { lastInjector } = useLastInjector();

  const { network_token } = useSelector((state) => state.tokens);
  const { connectedNetwork } = useSelector((state) => state.account);
  const { slippage } = useSelector((state) => state.account); //get logged user address.
  const { address } = useSelector((state) => state.account); //get logged user address.
  const { pair_creator_contract } = useSelector((state) => state.contracts); // get swap contract address
  const { api } = useSelector((state) => state.contracts);

  const [expectedLpTokens, setExpectedLpTokens] = useState();

  const [loader, setLoader] = useState(false);

  const handleInitOpen = () => setOpenInit(true);
  const handleInitClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenInit(false);
  };

  const [msgBoolean, setMsgBoolean] = useState(true);
  const [txnFailed, setTxnFailed] = useState(false);
  const [openInit, setOpenInit] = useState(false);

  const [txnMsg, setTxnMsg] = useState("");

  const handleApproveOpen = () => setOpenApprove(true); // open approve modal
  const handleApproveClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenApprove(false);
  }; // close approve modal
  const [approveBtnText, setApproveBtnText] = useState(`Approve`);
  const [msgApprovalBoolean, setApprovalMsgBoolean] = useState(true);
  const [aprvFailed, setAprvFailed] = useState(false);
  const [approvalFailedText, setApprovalFailedText] = useState("");
  const [openApprove, setOpenApprove] = useState(false);
  const [needToApprove, setNeedToApprove] = useState(false); // set boolean if need to approve or not (true/false)
  const [tokenToApprove, setTokenToApprove] = useState(); // token to approve
  const [inputToApprove, setInputToApprove] = useState(); // input value of token to approve
  const [psp22TokenAddress, setPsp22TokenAddress] = useState();
  const [psp22Contract, setPsp22Contract] = useState();
  const [allowanceGas, setAllowanceGas] = useState();

  const [cancelled, setCancelled] = useState(false);

  // Allowance function which show if user needs to approve tokens or not
  const allowance = async (token, tokenValue) => {
    let gasLimit;
    let contract;
    let { data: currentPairAddress } = await getTokenPairByAddresses(tokenA.address, tokenB.address, connectedNetwork);
    currentPairAddress = currentPairAddress?.data[0]?.pair_address;
    let totalSupply = await getPairTotalSupply(currentPairAddress);

    if (api[0]) {
      gasLimit = getMaxGasLimit(api[0]);
      contract = new ContractPromise(api[0], abiPsp22Token, token?.address);
    }

    setPsp22Contract(contract);
    setTokenToApprove(token);
    setInputToApprove(tokenValue);

    try {
      await contract?.query["psp22::allowance"](address[0], { gasLimit }, address[0], currentPairAddress).then(
        (result) => {
          let allowanceValue = result.output.toHuman().Ok.replace(/,/g, "") / getRatio(connectedNetwork);
          allowanceValue = Number(fromExponential(allowanceValue));
          setAllowanceGas(result.gasRequired);

          if (allowanceValue === 0 || tokenValue > allowanceValue) {
            setPsp22TokenAddress(token?.address);
            setNeedToApprove(true);
            if (pairExist && totalSupply > 0) {
              setApproveBtnText(`Pool already exist`);
            } else {
              setApproveBtnText(`Approve ${token.symbol}`);
            }
          } else if (tokenValue >= allowanceValue >= 0) {
            setNeedToApprove(false);
          }
        }
      );
    } catch (err) {
      // console.log(err);
    }
  };

  // set approval msg to in progress and open modal
  const approvalInProgress = () => {
    handleApproveOpen();
  };

  // set approval msg to completed and close modal
  const approvalCompleted = () => {
    setApprovalMsgBoolean(false);
    setNeedToApprove(false);
    setTimeout(() => {
      handleApproveClose();
      setApprovalMsgBoolean(true);
    }, 1500);
  };

  // set approval msg to Failed and close modal
  const approvalFailed = (txt) => {
    if (txt === "Cancelled") {
      setCancelled(true);
    }
    setApprovalFailedText(txt);
    setApprovalMsgBoolean(false);
    setNeedToApprove(true);
    setAprvFailed(true);
    setTimeout(() => {
      setApprovalMsgBoolean(true);
      handleApproveClose();
      setCancelled(false);
      setAprvFailed(false);
    }, 5000);
  };

  // set swap msg to in progress and open modal
  const txnInProgress = () => {
    handleInitOpen();
  };

  // set swap msg to completed and close modal
  const txnCompleted = (pairAddress) => {
    setMsgBoolean(false);
    checkIfPairExist(tokenA, tokenB);
    setTimeout(() => {
      handleInitClose();
      setLoader(false);
      setMsgBoolean(true);
      pairAddress && navigate(`/liquidity/${pairAddress}`);
    }, 1500);
  };

  // set swap msg to completed and close modal
  const txnFailedFn = (cancelled) => {
    if (cancelled === "Cancelled") {
      setCancelled(true);
    }
    setMsgBoolean(false);
    setTxnFailed(true);
    setTimeout(() => {
      handleInitClose();
      setLoader(false);
      setMsgBoolean(true);
      setCancelled(false);
      setTxnFailed(false);
    }, 3000);
  };

  // Main wrapper function for initializing new pair.
  const initPair = () => {
    makeNewPairContract();
  };

  // A function that makes new pair contract.
  const makeNewPairContract = async () => {
    setLoader(true);
    let gasLimit = getMaxGasLimit(api[0]);

    let vault_address;
    let azeroTradingPairHash;
    let panx;
    let psp22token = tokenA.address !== network_token?.address?.toLowerCase() ? tokenA.address : tokenB.address;
    let randomNum = makeId(9);

    if (connectedNetwork.toLowerCase() === "shiden") {
      vault_address = process.env.REACT_APP_ASTAR_VAULT_ADDRESS;
    } else if (connectedNetwork.toLowerCase() === "azero") {
      vault_address = process.env.REACT_APP_A0_VAULT_ADDRESS;
    }

    if (connectedNetwork.toLowerCase() === "shiden") {
      azeroTradingPairHash = process.env.REACT_APP_ASTAR_TPA_HASH;
    } else if (connectedNetwork.toLowerCase() === "azero") {
      azeroTradingPairHash = process.env.REACT_APP_A0_TPA_HASH;
    }

    if (connectedNetwork.toLowerCase() === "shiden") {
      panx = process.env.REACT_APP_ASTAR_PANX_ADDRESS;
    } else if (connectedNetwork.toLowerCase() === "azero") {
      panx = process.env.REACT_APP_A0_PANX_ADDRESS;
    }

    let pairFee = ratioMultiplier(fee, connectedNetwork);
    let dateObject = new Date(unlockDate);
    // Get the timestamp in milliseconds
    let timestamp = dateObject.getTime();

    let unlockLpDateTimestamp = timestamp / 1000;

    let newPair = {
      creator_address: address[0],
      token_a_address: tokenA.address,
      token_a_name: tokenA.name,
      token_a_symbol: tokenA.symbol,
      token_b_address: tokenB.address,
      token_b_name: tokenB.name,
      token_b_symbol: tokenB.symbol,
      pair_address: null,
      pair_fee: fee,
      unlock_lp_date: unlockDate,
    };

    try {
      pair_creator_contract?.query["createAzeroTradingPair"](
        address[0],
        { gasLimit },
        azeroTradingPairHash,
        randomNum,
        psp22token,
        pairFee,
        panx,
        vault_address,
        unlockLpDateTimestamp
      ).then((res) => {
        let errorModule = res.output.toHuman().Ok;
        if (errorModule.Ok) {
          let gas = res.gasRequired;
          newPair.pair_address = res.output.toHuman().Ok.Ok;
          if (newPair) {
            publishNewPairContract(
              azeroTradingPairHash,
              randomNum,
              psp22token,
              pairFee,
              panx,
              newPair,
              gas,
              vault_address,
              unlockLpDateTimestamp
            );
          }
        } else {
          setTxnMsg(<Typography sx={{ fontSize: "12px" }}>{errorHandler(errorModule.Err)}</Typography>);
          txnFailedFn();
        }
      });
    } catch (err) {
      // console.log("error occurred while initializing pair:", err);
    }
  };

  // A function that publishes the new pair contract to network.
  const publishNewPairContract = async (
    azeroTradingPairHash,
    randomNum,
    psp22token,
    pairFee,
    panx,
    newPair,
    gas,
    vault_address,
    unlockLpDateTimestamp
  ) => {
    let value = 0;
    try {
      if (isAuthenticated) {
        await pair_creator_contract?.tx["createAzeroTradingPair"](
          { value, gasLimit: gas },
          azeroTradingPairHash,
          randomNum,
          psp22token,
          pairFee,
          panx,
          vault_address,
          unlockLpDateTimestamp
        ).signAndSend(address[0], { signer: lastInjector.signer }, ({ events = [], status }) => {
          if (status.isInBlock || status.isFinalized) {
            events
              // find/filter for failed events
              .filter(({ event }) => api[0].events.system.ExtrinsicFailed.is(event))
              // we know that data for system.ExtrinsicFailed is
              // (DispatchError, DispatchInfo)
              .forEach(
                ({
                  event: {
                    data: [error, info],
                  },
                }) => {
                  if (error.isModule) {
                    // for module errors, we have the section indexed, lookup
                    const decoded = api[0].registry.findMetaError(error.asModule);
                    const { docs, method, section } = decoded;
                    setTxnMsg(<Typography sx={{ fontSize: "12px" }}>{docs.join("")}</Typography>);
                    txnFailedFn();
                  } else {
                    // Other, CannotLookup, BadOrigin, no extra info
                    // console.log(error.toString());
                  }
                }
              );
          }

          if (status.isInBlock) {
            txnInProgress();
          } else if (status.isFinalized) {
            // Loop through Vec<EventRecord> to display all events
            events.forEach(({ phase, event: { data, method, section } }) => {
              if (method === "ExtrinsicSuccess") {
                insertPair(newPair, cookies.get("accessToken"), connectedNetwork).then(async (res) => {
                  res && txnCompleted();
                });
              } else if (method === "ExtrinsicFailed") {
                txnFailedFn();
              }
            });
          }
        });
      } else {
        setTxnMsg(
          <Typography sx={{ fontSize: "12px" }}>
            You must be authenticated to the system.
            <br /> Please try to logout and login to your wallet again.
          </Typography>
        );
        txnFailedFn();
      }
    } catch (err) {
      if (err.message === "Cancelled") {
        txnFailedFn(err.message);
      } else {
        setTxnMsg(<Typography sx={{ fontSize: "12px" }}>{err.message}</Typography>);
        txnFailedFn();
      }
    }
  };

  // Main wrapper function for providing liquidity to pool.
  const provideToPool = () => {
    addTokensToPoolQuery();
  };

  // QUERY function to add tokens to pool - getting gasRequired from the query.
  const addTokensToPoolQuery = async () => {
    setLoader(true);

    let getCurrentPair = await getTokenPairByAddresses(tokenA.address, tokenB.address, connectedNetwork);
    let pairAddress = getCurrentPair.data.data[0].pair_address;
    const contract = api[0] && new ContractPromise(api[0], abiTradingPairAzero, pairAddress);

    let psp22tokenName;
    let psp22tokenAddress;
    let azeroName;
    let azeroAddress;
    let gasLimit = getMaxGasLimit(api[0]);

    if (tokenA?.address?.toLowerCase() !== network_token?.address?.toLowerCase()) {
      psp22tokenName = tokenA.name;
      psp22tokenAddress = tokenA.address;
      azeroName = tokenB.name;
      azeroAddress = tokenB.address;
    } else {
      psp22tokenName = tokenB.name;
      psp22tokenAddress = tokenB.address;
      azeroName = tokenA.name;
      azeroAddress = tokenA.address;
    }

    let psp22Amount =
      tokenA?.address?.toLowerCase() !== network_token?.address?.toLowerCase() ? tokenAValue : tokenBValue;

    let a0DepositAmount =
      tokenA?.address?.toLowerCase() === network_token.address.toLowerCase() ? tokenAValue : tokenBValue;

    let userObj = {
      pair_address: contract.address.toHuman(),
      user_address: address[0],
      lp_tokens: expectedLpTokens,
      pair_address: pairAddress,
      psp22_amount: psp22Amount,
      psp22_name: psp22tokenName,
      psp22_address: psp22tokenAddress,
      azero_amount: a0DepositAmount,
      azero_name: azeroName,
      azero_address: azeroAddress,
    };

    let lpTokens = ratioMultiplier(expectedLpTokens, connectedNetwork);
    let slippageAmount = ratioMultiplier(slippage, connectedNetwork);
    psp22Amount = ratioMultiplier(psp22Amount, connectedNetwork);
    a0DepositAmount = ratioMultiplier(a0DepositAmount, connectedNetwork);
    let value = a0DepositAmount;
    ////////////////////////////////////////////////
    //////////////////////////////////////////////
    //////////////////////////////////////////////

    try {
      await contract?.query["provideToPool"](
        address[0],
        { value, gasLimit },
        psp22Amount,
        a0DepositAmount,
        lpTokens,
        slippageAmount
      ).then((res) => {
        let errorModule = res.output.toHuman().Ok.Err;
        if (errorModule) {
          setTxnMsg(errorHandler(errorModule));
          txnFailedFn();
        } else {
          let gas = res.gasRequired;
          res &&
            addTokensToPool(
              contract,
              a0DepositAmount,
              psp22Amount,
              lpTokens,
              slippageAmount,
              gas,
              pairAddress,
              userObj,
              value
            );
        }
      });
    } catch (err) {
      // console.log(err);
    }
  };

  // TXN function to add tokens to pool.
  const addTokensToPool = async (
    pair_contract,
    a0DepositAmount,
    psp22Amount,
    lpTokens,
    slippageAmount,
    gas,
    pairAddress,
    userObj,
    value
  ) => {
    try {
      if (isAuthenticated) {
        await pair_contract?.tx["provideToPool"](
          { value, gasLimit: gas },
          psp22Amount,
          a0DepositAmount,
          lpTokens,
          slippageAmount
        ).signAndSend(address[0], { signer: lastInjector.signer }, ({ events = [], status }) => {
          if (status.isInBlock || status.isFinalized) {
            events
              // find/filter for failed events
              .filter(({ event }) => api[0].events.system.ExtrinsicFailed.is(event))
              // we know that data for system.ExtrinsicFailed is
              // (DispatchError, DispatchInfo)
              .forEach(
                ({
                  event: {
                    data: [error, info],
                  },
                }) => {
                  if (error.isModule) {
                    // for module errors, we have the section indexed, lookup
                    const decoded = api[0].registry.findMetaError(error.asModule);
                    const { docs, method, section } = decoded;
                    if (method === "ContractTrapped") {
                      setTxnMsg(
                        <Typography sx={{ fontSize: "12px" }}>Please readjust your slippage settings. </Typography>
                      );
                    } else {
                      setTxnMsg(<Typography sx={{ fontSize: "12px" }}>{docs.join("")}</Typography>);
                    }
                    // txnFailedFn();
                  } else {
                    // Other, CannotLookup, BadOrigin, no extra info
                    // console.log(error.toString());
                  }
                }
              );
          }

          if (status.isInBlock) {
            txnInProgress();
          } else if (status.isFinalized) {
            // Loop through Vec<EventRecord> to display all events
            events.forEach(({ phase, event: { data, method, section } }) => {
              if (method === "ExtrinsicSuccess") {
                txnCompleted(pairAddress);
                insertUserData(userObj, cookies.get("accessToken"), connectedNetwork);
              } else if (method === "ExtrinsicFailed") {
                txnFailedFn();
              }
            });
          }
        });
      } else {
        setTxnMsg(
          <Typography sx={{ fontSize: "12px" }}>
            You must be authenticated to the system.
            <br /> Please try to logout and login to your wallet again.
          </Typography>
        );
        txnFailedFn();
      }
    } catch (err) {
      // console.log(err);
      if (err.message === "Cancelled") {
        txnFailedFn(err.message);
      } else {
        setTxnMsg(<Typography sx={{ fontSize: "12px" }}>{err.message}</Typography>);
        txnFailedFn();
      }
    }
  };

  const getExpectedLpTokensAmount = async () => {
    let exLpTokens = await getExpectedLpTokens(
      tokenA,
      tokenB,
      connectedNetwork,
      api,
      network_token,
      tokenAValue,
      tokenBValue,
      address
    );

    setExpectedLpTokens(exLpTokens);
  };

  const createCorrectButton = async () => {
    let totalSupply = await getPairTotalSupply(currentPair?.pair_address);
    // ////////////////////// NEED TO GO BACK HERE////////////////
    // ////////////////////// NEED TO GO BACK HERE////////////////
    // ////////////////////// NEED TO GO BACK HERE////////////////
    // ////////////////////// NEED TO GO BACK HERE////////////////
    // ////////////////////// NEED TO GO BACK HERE////////////////
    if (pairExist && totalSupply === 0) {
      if (tokenA?.address?.toLowerCase() !== network_token?.address?.toLowerCase()) {
        allowance(tokenA, Number(tokenAValue));
      } else if (tokenB?.address?.toLowerCase() !== network_token?.address?.toLowerCase()) {
        allowance(tokenB, Number(tokenBValue));
      }
    } else {
      setNeedToApprove(false);
    }
  };

  useEffect(() => {
    createCorrectButton();
    getExpectedLpTokensAmount();
  }, [tokenA, tokenB, pairExist, currentPair]);

  return (
    <Wrapper>
      {needToApprove === true ? (
        <ApprovalButton
          variant="swap_btn"
          psp22TokenAddress={psp22TokenAddress}
          allowanceGas={allowanceGas}
          approvalInProgress={approvalInProgress}
          approvalCompleted={approvalCompleted}
          approvalFailed={approvalFailed}
          handleApproveOpen={handleApproveOpen}
          inputToApprove={inputToApprove}
          approveBtnText={approveBtnText}
          disabled={isBtnDisabled}
          valueMsg={valueMsg}
          psp22TokensMsg={psp22TokensMsg}
          azeroTokensMsg={azeroTokensMsg}
          currentAddress={currentPair?.pair_address}
        />
      ) : (
        <Box>
          <Button variant={variant ? variant : "contained"} disabled={isBtnDisabled} onClick={handleInitOpen}>
            {initBtnMsg}
          </Button>
          <Box
            sx={{
              padding: "5px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              color: colors.red,
            }}
          >
            <Typography variant="p">{valueMsg}</Typography>
            <Typography variant="p">{azeroTokensMsg}</Typography>
            <Typography variant="p">{psp22TokensMsg}</Typography>
          </Box>
        </Box>
      )}

      {/* Approval Modal */}
      <ApprovalModal
        openApprove={openApprove}
        handleApproveClose={handleApproveClose}
        msgApprovalBoolean={msgApprovalBoolean}
        tokenToApprove={tokenToApprove}
        aprvFailed={aprvFailed}
        approvalFailedText={approvalFailedText}
        cancelled={cancelled}
      />

      {/* Init Pair Modal */}
      <InitPairModal
        pairExist={pairExist}
        tokenA={tokenA}
        tokenAValue={tokenAValue}
        tokenB={tokenB}
        tokenBValue={tokenBValue}
        initPair={initPair}
        provideToPool={provideToPool}
        expectedLpTokens={expectedLpTokens}
        openInit={openInit}
        handleSwapClose={handleInitClose}
        msgBoolean={msgBoolean}
        loader={loader}
        txnFailed={txnFailed}
        cancelled={cancelled}
        providerFee={fee}
        txnMsg={txnMsg}
      />
    </Wrapper>
  );
};

export default InitPairButton;

// if (status.isInBlock || status.isFinalized) {
//   events
//     // find/filter for failed events
//     .filter(({ event }) => api[0].events.system.ExtrinsicFailed.is(event))
//     // we know that data for system.ExtrinsicFailed is
//     // (DispatchError, DispatchInfo)
//     .forEach(
//       ({
//         event: {
//           data: [error, info],
//         },
//       }) => {
//         if (error.isModule) {
//           // for module errors, we have the section indexed, lookup
//           const decoded = api[0].registry.findMetaError(error.asModule);
//           const { docs, method, section } = decoded;
//           console.log(`${section}.${method}: ${docs.join(" ")}`);
//         } else {
//           // Other, CannotLookup, BadOrigin, no extra info
//           console.log(error.toString());
//         }
//       }
//     );
// }
