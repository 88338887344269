import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { set_pool_withdraw_input } from "../../features/inputs";
import { web3FromSource } from "@polkadot/extension-dapp";

import Wrapper from "../Helpers/Wrapper";
import { Button, Typography } from "@mui/material";
import WithdrawFromPoolModal from "../Modals/WithdrawFromPoolModal";
import { getMaxGasLimit } from "@scio-labs/use-inkathon";
import { useAuth } from "../../hooks/useAuth";
import { getRatio } from "../../Utils/constants";
import { ratioMultiplier } from "../../Utils/functions/globalFunctions";
import { useLastInjector } from "../../hooks/useLastInjector";
import { errorHandler } from "../../Utils/functions/scErrorHandler";
import { deleteUserData } from "../../Utils/functions/globalFunctions";
import Cookies from "universal-cookie";
import ApprovalButton from "./ApprovalButton";
import ApprovalModal from "../Modals/ApprovalModal";
import fromExponential from "from-exponential";

const WithdrawTokensFromPool = ({
  variant,
  setTxnCompleted,
  withdrawnPsp22,
  withdrawnAzero,
  tokenA,
  tokenB,
  contract,
  pairAddress,
  userPoolTokens,
}) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const { lastInjector } = useLastInjector();

  const { network_token } = useSelector((state) => state.tokens);
  const { connectedNetwork } = useSelector((state) => state.account);
  const { account } = useSelector((state) => state);
  const { address } = useSelector((state) => state.account);
  const { pool_tokens_balance } = useSelector((state) => state.account);
  const { pool_withdraw_input } = useSelector((state) => state.inputs);
  const { api } = useSelector((state) => state.contracts);

  const [txnMsg, setTxnMsg] = useState("");
  const [buttonMsg, setButtonMsg] = useState(`Withdraw Tokens`);
  const [disabled, setDisabled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [msgBoolean, setMsgBoolean] = useState(true);
  const [withdrawFailed, setWithdrawFailed] = useState(false);
  const [openWithdrawFromModal, setOpenWithdrawFromModal] = useState(false);
  const openWithdrawFromPool = () => setOpenWithdrawFromModal(true);
  const closeWithdrawFromPool = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenWithdrawFromModal(false);
  };

  const handleApproveOpen = () => setOpenApprove(true); // open approve modal
  const handleApproveClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenApprove(false);
  }; // close approve modal
  const [approveBtnText, setApproveBtnText] = useState(`Approve`);
  const [msgApprovalBoolean, setApprovalMsgBoolean] = useState(true);
  const [aprvFailed, setAprvFailed] = useState(false);
  const [approvalFailedText, setApprovalFailedText] = useState("");
  const [openApprove, setOpenApprove] = useState(false);
  const [needToApprove, setNeedToApprove] = useState(false); // set boolean if need to approve or not (true/false)
  const [tokenToApprove, setTokenToApprove] = useState(); // token to approve
  const [inputToApprove, setInputToApprove] = useState(); // input value of token to approve
  const [psp22TokenAddress, setPsp22TokenAddress] = useState();
  const [psp22Contract, setPsp22Contract] = useState();
  const [allowanceGas, setAllowanceGas] = useState();

  // set swap msg to in progress and open modal
  const txnInProgress = () => {
    openWithdrawFromPool();
  };

  // set swap msg to completed and close modal
  const txnCompleted = () => {
    setMsgBoolean(false);
    setTimeout(() => {
      dispatch(set_pool_withdraw_input(""));
      setTxnCompleted();
      setLoader(false);
      setMsgBoolean(true);
      closeWithdrawFromPool();
    }, 1500);
  };

  // set swap msg to completed and close modal
  const txnFailedFn = (cancelled) => {
    if (cancelled === "Cancelled") {
      setCancelled(true);
    }
    setMsgBoolean(false);
    setWithdrawFailed(true);
    setTimeout(() => {
      closeWithdrawFromPool();
      setLoader(false);
      setCancelled(false);
      setMsgBoolean(true);
      setWithdrawFailed(false);
    }, 5000);
  };

  // A function to Withdraw tokens from pool
  const withdrawTokensFromPool = async () => {
    let psp22tokenName;
    let psp22tokenAddress;
    let azeroName;
    let azeroAddress;

    if (tokenA?.address?.toLowerCase() !== network_token?.address?.toLowerCase()) {
      psp22tokenName = tokenA.name;
      psp22tokenAddress = tokenA.address;
      azeroName = tokenB.name;
      azeroAddress = tokenB.address;
    } else {
      psp22tokenName = tokenB.name;
      psp22tokenAddress = tokenB.address;
      azeroName = tokenA.name;
      azeroAddress = tokenA.address;
    }

    let userObj = {
      pair_address: pairAddress,
      user_address: address[0],
      lp_tokens: pool_withdraw_input,
      psp22_amount: withdrawnPsp22,
      psp22_name: psp22tokenName,
      psp22_address: psp22tokenAddress,
      azero_amount: withdrawnAzero,
      azero_name: azeroName,
      azero_address: azeroAddress,
    };

    const gasLimit = getMaxGasLimit(api[0]);

    setLoader(true);
    let errorModule;
    let gas = 0;
    let value = 0;
    let withdrawInput = ratioMultiplier(pool_withdraw_input, connectedNetwork);

    try {
      if (isAuthenticated) {
        await contract?.query["withdrawSpecificAmount"](address[0], { value, gasLimit }, withdrawInput).then((res) => {
          errorModule = res.output.toHuman().Ok;
          if (errorModule === "Ok") {
            gas = res.gasRequired;
          } else {
            setTxnMsg(errorHandler(errorModule.Err));
            txnFailedFn();
          }
        });

        errorModule === "Ok" &&
          (await contract.tx["withdrawSpecificAmount"]({ value, gasLimit: gas }, withdrawInput).signAndSend(
            address[0],
            { signer: lastInjector.signer },
            ({ events = [], status }) => {
              if (status.isInBlock || status.isFinalized) {
                events
                  // find/filter for failed events
                  .filter(({ event }) => api[0].events.system.ExtrinsicFailed.is(event))
                  // we know that data for system.ExtrinsicFailed is
                  // (DispatchError, DispatchInfo)
                  .forEach(
                    ({
                      event: {
                        data: [error, info],
                      },
                    }) => {
                      if (error.isModule) {
                        // for module errors, we have the section indexed, lookup
                        const decoded = api[0].registry.findMetaError(error.asModule);
                        const { docs, method, section } = decoded;
                        if (method === "ContractTrapped") {
                          setTxnMsg(
                            <Typography sx={{ fontSize: "12px" }}>Please readjust your slippage settings. </Typography>
                          );
                        } else {
                          setTxnMsg(docs.join(""));
                        }
                        txnFailedFn();
                      } else {
                        // Other, CannotLookup, BadOrigin, no extra info
                        // console.log(error.toString());
                      }
                    }
                  );
              }

              if (status.isInBlock) {
                txnInProgress();
              } else if (status.isFinalized) {
                // Loop through Vec<EventRecord> to display all events
                events.forEach(({ phase, event: { data, method, section } }) => {
                  if (method === "ExtrinsicSuccess") {
                    txnCompleted();
                    let res = deleteUserData(userObj, userPoolTokens, cookies.get("accessToken"), connectedNetwork);
                    // console.log(res.data);
                  } else if (method === "ExtrinsicFailed") {
                    txnFailedFn();
                  }
                });
              }
            }
          ));
      } else {
        setTxnMsg(
          <Typography sx={{ fontSize: "12px" }}>
            You must be authenticated to the system.
            <br /> Please try to logout and login to your wallet again.
          </Typography>
        );
        txnFailedFn();
      }
    } catch (err) {
      // console.log(err);
      if (err.message === "Cancelled") {
        txnFailedFn(err.message);
      } else {
        setTxnMsg(err.message);
        txnFailedFn();
      }
    }
  };

  useEffect(() => {
    disableButton();
  }, [pool_withdraw_input, account]);

  const disableButton = () => {
    let input = Number(pool_withdraw_input);
    if (input === null || isNaN(input) || input == 0) {
      setDisabled(true);
    } else if (input > 0) {
      if (input > pool_tokens_balance) {
        setDisabled(true);
        setButtonMsg(`Insufficient LP tokens balance`);
      } else {
        setDisabled(false);
        setButtonMsg(`Withdraw Tokens`);
      }
    }
  };

  useEffect(() => {
    allowance(pool_withdraw_input);
  }, [pool_withdraw_input]);

  // Allowance function which show if user needs to approve tokens or not
  const allowance = async (input_value) => {
    let gasLimit;
    if (api[0]) {
      gasLimit = getMaxGasLimit(api[0]);
    }

    setInputToApprove(input_value);

    try {
      await contract?.query["psp22::allowance"](address[0], { gasLimit }, address[0], pairAddress).then((result) => {
        let allowanceValue = result.output.toHuman().Ok.replace(/,/g, "") / getRatio(connectedNetwork);
        allowanceValue = Number(fromExponential(allowanceValue));
        setAllowanceGas(result.gasRequired);

        if (allowanceValue === 0 || pool_withdraw_input > allowanceValue) {
          if (Number(pool_withdraw_input) > userPoolTokens) {
            setDisabled(true);
            setApproveBtnText(`Insufficient LP Tokens balance`);
          } else {
            setApproveBtnText(`Approve ${tokenA.symbol}/${tokenB.symbol}`);
          }
          setNeedToApprove(true);
        } else if (pool_withdraw_input >= allowanceValue >= 0) {
          setNeedToApprove(false);
        }
      });
    } catch (err) {
      // console.log(err);
    }
  };

  // set approval msg to in progress and open modal
  const approvalInProgress = () => {
    handleApproveOpen();
  };

  // set approval msg to completed and close modal
  const approvalCompleted = () => {
    setApprovalMsgBoolean(false);
    setNeedToApprove(false);
    setTimeout(() => {
      handleApproveClose();
      setApprovalMsgBoolean(true);
    }, 1500);
  };

  // set approval msg to Failed and close modal
  const approvalFailed = (txt) => {
    if (txt === "Cancelled") {
      setCancelled(true);
    }
    setApprovalFailedText(txt);
    setApprovalMsgBoolean(false);
    setNeedToApprove(true);
    setAprvFailed(true);
    setTimeout(() => {
      setApprovalMsgBoolean(true);
      handleApproveClose();
      setCancelled(false);
      setAprvFailed(false);
    }, 5000);
  };

  return (
    <Wrapper>
      {/* {needToApprove ? (
        <ApprovalButton
          variant="swap_btn"
          allowanceGas={allowanceGas}
          approvalInProgress={approvalInProgress}
          approvalCompleted={approvalCompleted}
          approvalFailed={approvalFailed}
          handleApproveOpen={handleApproveOpen}
          inputToApprove={inputToApprove}
          approveBtnText={approveBtnText}
          // disabled={isBtnDisabled}
          // valueMsg={valueMsg}
          // psp22TokensMsg={psp22TokensMsg}
          // azeroTokensMsg={azeroTokensMsg}
          currentAddress={pairAddress}
          pairContract={contract}
          withdrawApprove={true}
        />
      ) : ( */}
      <Button variant={variant ? variant : "contained"} disabled={disabled} onClick={openWithdrawFromPool}>
        {buttonMsg}
      </Button>
      {/* )} */}
      {/* Approval Modal */}
      <ApprovalModal
        openApprove={openApprove}
        handleApproveClose={handleApproveClose}
        msgApprovalBoolean={msgApprovalBoolean}
        tokenToApprove={tokenToApprove}
        aprvFailed={aprvFailed}
        approvalFailedText={approvalFailedText}
        cancelled={cancelled}
      />

      {/* Withdraw from Pool Modal */}
      <WithdrawFromPoolModal
        openWithdrawFromModal={openWithdrawFromModal}
        closeWithdrawFromPool={closeWithdrawFromPool}
        msgBoolean={msgBoolean}
        loader={loader}
        withdrawFailed={withdrawFailed}
        withdrawTokensFromPool={withdrawTokensFromPool}
        tokenA={tokenA}
        tokenB={tokenB}
        withdrawnPsp22={withdrawnPsp22}
        withdrawnAzero={withdrawnAzero}
        cancelled={cancelled}
        txnMsg={txnMsg}
      />
    </Wrapper>
  );
};

export default WithdrawTokensFromPool;
