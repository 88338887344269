import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const CreditsWrapper = styled(Box)(({ theme }) => ({
  maxWidth: "1600px",
  width: "100%",
  marginInline: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  paddingBlock: "50px 20px",
}));

export const Credits = styled(Typography)(({ theme }) => ({
  opacity: "0.5",
  transition: "all ease-in-out 0.3s",
}));
