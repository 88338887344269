import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Cookies from "universal-cookie";

export const useAuth = () => {
  const cookies = new Cookies();
  const { address } = useSelector((state) => state.account); //get logged user address.
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // setIsAuthenticated(true);
    // You can replace this dummy code with your actual authentication logic
    const address = cookies.get("accessToken");
    if (address) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, [address]);

  return { isAuthenticated };
};
