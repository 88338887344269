import styled from "@emotion/styled";
import { Typography, Button, Box, Paper, Container } from "@mui/material";
import { colors } from "../themes";

// -----------------------------------JOY------------------------------------ //

export const AssetsDetailsBoxWrapper = styled(Box)(({ theme, columns }) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${columns}, minmax(0px, 1fr));`,
  rowGap: "1rem",
  columnGap: "1rem",
}));

export const AssetsDetailsBox = styled(Paper)(({ theme, columns }) => ({
  position: "relative",
  padding: "15px",
  display: "flex",
  flexDirection: "column",
}));

export const ClaimablePanxBtn = styled(Button)(({ theme, columns }) => ({
  width: "fit-content",
  padding: "3px",
  fontSize: "10px",
}));

// -----------------------------------ASAF------------------------------------ //
export const TypographyDes = styled(Typography)(({ theme }) => ({
  color: colors.darkTorq,
  fontSize: "12px",
}));

export const DarkButtonText = styled(Button)(({ theme }) => ({
  color: colors.gray,
  backgroundColor: colors.blueGray,
  "&:hover": {
    backgroundColor: colors.blueGray,
    cursor: "default",
  },
}));

export const TypographyDesW = styled(Typography)(({ theme }) => ({
  color: colors.gray,
  fontSize: "70%",
}));

export const TypographyLeftLine = styled(Typography)(({ theme }) => ({
  borderLeft: "3px solid",
  color: colors.white,
  paddingLeft: "16px",
  borderColor: colors.neonTorq,
  borderTopLeftRadius: "2.7px",
}));

export const TypographyTitlePANX = styled(Typography)(({ theme }) => ({
  marginLeft: "5px",
  fontSize: "30px",
  fontWeight: "500",
}));

export const TypographyClaimPress = styled(Typography)(({ theme }) => ({
  paddingLeft: "16px",
  color: colors.white,
}));

export const TypographyTitleIMG = styled(Typography)(({ theme }) => ({
  color: colors.white,
  paddingRight: "16px",
}));

export const TypographyTitle = styled(Typography)(({ theme }) => ({
  fontSize: "50px",
  fontWeight: "bold",
  letterSpacing: "2px",
}));

export const TypographyLeftOrange = styled(Typography)(({ theme }) => ({}));

export const TypographyYellow = styled(Typography)(({ theme }) => ({
  marginLeft: "10px",
  color: colors.orange,
  fontSize: "90%",
}));

export const TypographyGreen = styled(Typography)(({ theme }) => ({
  marginLeft: "-17px",
  color: colors.torq,
  fontSize: "180%",
}));

export const TypographyPanxWhiteBig = styled(Typography)(({ theme }) => ({
  marginLeft: "0px",
  color: colors.gray,
  fontSize: "120%",
}));

export const TypographyPanxWhite = styled(Typography)(({ theme }) => ({
  marginLeft: "0px",
  color: colors.gray,
  fontSize: "67%",
}));

export const ButtonClaim = styled(Button)(({ theme }) => ({
  marginLeft: "5px",
  paddingRight: "50px",
  paddingLeft: "50px",
  border: "2.67px solid",
  borderColor: colors.orangeLight,
  "&:hover": {
    backgroundColor: colors.orangeLight,
  },
}));

export const ButtonClaim10 = styled(Button)(({ theme }) => ({
  border: "2.67px solid",
  paddingLeft: "17px",
  paddingRight: "17px",
}));

export const BoxFlex = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "20px",
  marginTop: "10px",
  padding: "17px",
  borderRadius: "10px",
  borderBottomLeftRadius: "2px",
  marginBottom: "10px",
}));

export const BoxFlexCenter = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const PaperGreen = styled(Paper)(({ theme }) => ({
  background: colors.torq,
  padding: "10px",
  borderRadius: "10px",
}));

export const DetailsContainer = styled(Container)(({ theme, margintop }) => ({
  maxWidth: "1400px !important",
  width: "100%",
  marginTop: margintop ? margintop : "0px",
  paddingLeft: "0px",
  paddingRight: "0px",
}));
