import { styled as MUIstyled } from "@mui/material/styles";
import styled from "@emotion/styled";
import { colors, themeDark } from "./themes";
import { Box, List, ListItem, Typography } from "@mui/material";

export const NavWrapper = styled.div`
  width: 100%;
  padding: 10px;
`;

export const Container = styled.div`
  width: 100%;
  margin-inline: auto;
  display: flex;
  align-items: center;
  @media (max-width: 992px) {
    justify-content: space-between;
  }
`;

export const NavLogo = styled(Box)(({ theme }) => ({
  width: "12%",
  display: "flex",
  justifyContent: "start",
  // "@media (max-width: 992px)": {
  //   width: "auto",
  // },
  span: {
    display: "inline-flex",
    alignItems: "center",
    textDecoration: "none",
  },
}));

export const NavTitle = MUIstyled(Typography)(({ theme }) => ({
  fontSize: "30px",
  fontWeight: "bold",
  letterSpacing: "0.5px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  img: {
    width: "40px",
  },
  span: {
    paddingLeft: "15px",
  },
}));

export const NavListWrapper = styled.div`
  width: 76%;
  display: flex;
  justify-content: center;
`;

export const NavList = MUIstyled(List)(({ theme }) => ({
  display: "flex",
  a: {
    textDecoration: "none",
    "&:not(:first-of-type)": {
      marginLeft: "30px",
    },
  },
}));

export const NavItem = MUIstyled(ListItem)(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.mode === "dark" ? colors.white : colors.black,
  fontSize: "16px",
  textTransform: "uppercase",
  fontWeight: "500",
  opacity: "0.5",
  cursor: "pointer",
  position: "relative",
  transition: "all ease-in-out 0.3s",

  "&:hover": {
    opacity: "1",
  },

  "&.current-item": {
    opacity: "1",
  },
}));

export const NavIconsWrapper = styled.div`
  position: relative;
  width: 15%;
  display: flex;
  justify-content: end;
  align-items: center;
  @media (max-width: 992px) {
    width: auto;
  }
  > .MuiFormGroup-root {
    display: flex;
    flex-direction: row;
    @media (max-width: 1200px) {
      justify-content: center;
      > label {
        margin-right: unset;
        margin-left: unset;
      }
    }
  }
`;
