import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import TokenBalance from "../Helpers/TokenBalance";
import { preventLetters } from "../../Utils/functions/globalFunctions";

import { Box, Button, Slider, Typography } from "@mui/material";
import { BoxInputTitle, InputBoxBottom, InputBoxTop, InputBoxWrapper } from "../../Style/Components/inputs";
import { StyledTextField } from "../../Style/general";
import { RightBoxStyle } from "../../Style/Pages/Pool";
import { DashboardPaper } from "../../Style/Pages/Trade";
import TokensDialog from "../Dialogs/TokensDialog";
import Wrapper from "../Helpers/Wrapper";
import CustomDatePicker from "../Helpers/CustomDatePicker";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import InitPairButton from "../Buttons/InitPairButton";
import ConnectWalletButton from "../Buttons/ConnectWalletButton";
import ApprovalModal from "../Modals/ApprovalModal";
import { ContractPromise } from "@polkadot/api-contract";
import { abiPsp22Token } from "../../Utils/abi/abiPsp22Token";
import { abiTradingPairAzero } from "../../Utils/abi/abiTradingPairAzero";
import { getPoolTotalSupply } from "../../Utils/functions/contractsFunctions";
import { getMaxGasLimit } from "@scio-labs/use-inkathon";
import moment from "moment";

const RightBox = ({
  tokenA,
  inputAValue,
  tokenB,
  inputBValue,
  handleTokenValue,
  inputDisabled,
  handleClickOpenA,
  handleClickOpenB,
  fee,
  setFeeFunction,
  disabledFee,
  unlockDateFN,
  unlockDate,
  disabledDatePicker,
  marks,
  open,
  tokenLetter,
  tokenAValue,
  tokenBValue,
  handleCloseToken,
  pairExist,
  initPair,
  checkIfPairExist,
  currentPair,
  isBtnDisabled,
  initBtnMsg,
  valueMsg,
  psp22TokensMsg,
  azeroTokensMsg,
  disableButton,
  getPairTotalSupply,
}) => {
  const { address } = useSelector((state) => state.account);
  const { api } = useSelector((state) => state.contracts);

  // Get init button or choose account button
  const buildInitButton = () => {
    if (address.length > 0 && address[0] !== undefined) {
      return (
        <InitPairButton
          variant="swap_btn"
          tokenA={tokenA}
          tokenB={tokenB}
          tokenAValue={inputAValue}
          tokenBValue={inputBValue}
          currentPair={currentPair}
          checkIfPairExist={checkIfPairExist}
          pairExist={pairExist}
          initPair={initPair}
          fee={fee}
          unlockDate={unlockDate}
          isBtnDisabled={isBtnDisabled}
          initBtnMsg={initBtnMsg}
          valueMsg={valueMsg}
          psp22TokensMsg={psp22TokensMsg}
          azeroTokensMsg={azeroTokensMsg}
          disableButton={disableButton}
          getPairTotalSupply={getPairTotalSupply}
          sx={{ marginTop: "10px" }}
        />
      );
    } else {
      return <ConnectWalletButton variant="swap_btn" sx={{ marginTop: "10px" }} />;
    }
  };

  return (
    <Wrapper>
      <RightBoxStyle>
        <DashboardPaper elevation={5}>
          {/* Token A Input */}
          <InputBoxWrapper>
            {/* Top box of the input */}
            <InputBoxTop>
              <BoxInputTitle>input</BoxInputTitle>
              <Typography variant="balance_amount" sx={{ paddingTop: "2px", display: "flex", gap: "3px" }}>
                Balance: <TokenBalance tokenAddress={tokenA?.address} token={tokenA && tokenA} />
              </Typography>
            </InputBoxTop>
            {/* Bottom box of the input */}
            <InputBoxBottom>
              <StyledTextField
                value={inputAValue}
                placeholder="0.00"
                type="number"
                onInput={(e) => handleTokenValue(e, "A")}
                onKeyDown={(evt) => preventLetters(evt)}
                disabled={inputDisabled}
              />
              <Button
                variant="choose_token_btn"
                onClick={() => handleClickOpenA("A")}
                disabled={address[0] ? false : true}
              >
                {tokenA ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={tokenA?.icon} alt="" />
                    <span>{tokenA?.symbol}</span>
                  </Box>
                ) : (
                  <Typography sx={{ fontSize: "12px" }}>Select a token</Typography>
                )}
                <ArrowDropDownIcon />
              </Button>
            </InputBoxBottom>
          </InputBoxWrapper>

          <Box sx={{ display: "flex", justifyContent: "center", marginBlock: "20px 0px" }}>
            <AddRoundedIcon />
          </Box>

          {/* Token B Input */}
          <InputBoxWrapper marginT="20px">
            {/* Top box of the input */}
            <InputBoxTop>
              <BoxInputTitle>input</BoxInputTitle>
              <Typography variant="balance_amount" sx={{ paddingTop: "2px", display: "flex", gap: "3px" }}>
                Balance: <TokenBalance tokenAddress={tokenB?.address} token={tokenB && tokenB} />
              </Typography>
            </InputBoxTop>
            {/* Bottom box of the input */}
            <InputBoxBottom>
              <StyledTextField
                value={inputBValue}
                placeholder="0.00"
                type="number"
                onInput={(e) => handleTokenValue(e, "B")}
                onKeyDown={(evt) => preventLetters(evt)}
                disabled={inputDisabled}
              />
              <Button
                variant="choose_token_btn"
                onClick={() => handleClickOpenB("B")}
                disabled={address[0] ? false : true}
              >
                {tokenB ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={tokenB?.icon} alt="" />
                    <span>{tokenB?.symbol}</span>
                  </Box>
                ) : (
                  <Typography sx={{ fontSize: "12px" }}>Select a token</Typography>
                )}
                <ArrowDropDownIcon />
              </Button>
            </InputBoxBottom>

            <CustomDatePicker setUnlockDate={unlockDateFN} disabledDatePicker={disabledDatePicker} />

            <Slider
              value={fee}
              min={0.1}
              max={3}
              marks={marks}
              step={0.1}
              aria-label="Default"
              valueLabelDisplay="auto"
              sx={{ color: "#00ccab", marginTop: "20px" }}
              disabled={disabledFee}
              onChange={(e) => setFeeFunction(e)}
              onInput={(e) => setFeeFunction(e)}
              onBlur={(e) => setFeeFunction(e)}
            />

            {/* Init pair button */}
            {buildInitButton()}

            <TokensDialog
              open={open}
              tokenLetter={tokenLetter}
              tokenValue={tokenLetter === "A" ? tokenAValue : tokenBValue}
              onClose={handleCloseToken}
            />
          </InputBoxWrapper>
        </DashboardPaper>
      </RightBoxStyle>
    </Wrapper>
  );
};

export default RightBox;
