import React from "react";

import { createRoot } from "react-dom/client";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

import accountReducer from "./features/account";
import allAccountsReducer from "./features/allAccounts";
import tokensReducer from "./features/tokens";
import contractsReducer from "./features/contracts";
import inputsReducer from "./features/inputs";

import App from "./App";
import Wrapper from "./Components/Helpers/Wrapper";

const container = document.getElementById("app");
const root = createRoot(container);

const store = configureStore({
  reducer: {
    account: accountReducer,
    accounts: allAccountsReducer,
    tokens: tokensReducer,
    contracts: contractsReducer,
    inputs: inputsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

root.render(
  <Wrapper>
    <Provider store={store}>
      <CookiesProvider>
        <App tab="trade" />
      </CookiesProvider>
    </Provider>
  </Wrapper>
);
