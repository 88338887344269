import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PoolTopInput from "../Inputs/PoolTopInput";
import PoolBottomInput from "../Inputs/PoolBottomInput";
import { StyledTabPanel } from "../../Style/Components/tabs";
import ConnectWalletButton from "../Buttons/ConnectWalletButton";
import AddLiquidityToPool from "../Buttons/AddLiquidityToPool";
import WithdrawTokensFromPool from "../Buttons/WithdrawTokensFromPool";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Tab, Box, Card, Typography, Button, Skeleton } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import PoolTokensInput from "../Inputs/PoolTokensInput";
import { checkIfNumIsExp, numberWithCommas, ratioMultiplier } from "../../Utils/functions/globalFunctions";
import RefreshIcon from "@mui/icons-material/Refresh";

import { StyledRefreshButton } from "../../Style/general";
import SlippageButton from "../Buttons/SlippageButton";
import { set_refresh } from "../../features/inputs";
import SlippageModal from "../Modals/SlippageModal";
import { getPriceForOnePsp22 } from "../../Utils/functions/contractsFunctions";
import { getAllTokens, getLastPairPrice, getTokenPairByAddress } from "../../shared/api";
import { useParams } from "react-router-dom";
import fromExponential from "from-exponential";
import { getRatio } from "../../Utils/constants";

const PoolTabs = ({ setTxnCompleted, userPoolTokens, contract, gas, poolTotalSupply }) => {
  const { name: pair_address } = useParams();

  const dispatch = useDispatch();

  const { network_token } = useSelector((state) => state.tokens);
  const { connectedNetwork } = useSelector((state) => state.account);
  const { slippage } = useSelector((state) => state.account);
  const { address } = useSelector((state) => state.account);
  const { pool_bottom_input } = useSelector((state) => state.inputs);
  const { api } = useSelector((state) => state.contracts);

  const [refreshing, setRefreshing] = useState(false);

  const [tokenA, setTokenA] = useState();
  const [tokenB, setTokenB] = useState();

  const [singlePsp22Price, setSinglePsp22Price] = useState();
  const [withdrawnAzero, setWithdrawnAzero] = useState();
  const [withdrawnPsp22, setWithdrawnPsp22] = useState();
  const [tabValue, setTabValue] = useState("add");

  // slippage modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle tab value for changing tabs.
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === "remove") {
      setWithdrawnAzero("0.0000");
      setWithdrawnPsp22("0.0000");
    }
  };

  // Get swap button or choose account button
  const buildTxnButton = () => {
    if (address[0]?.length > 0 && address[0] !== undefined) {
      if (tabValue === "add") {
        return (
          <AddLiquidityToPool
            variant="swap_btn"
            setTxnCompleted={setTxnCompleted}
            tokenA={tokenA}
            tokenB={tokenB}
            contract={contract}
          />
        );
      } else if (tabValue === "remove") {
        return (
          <WithdrawTokensFromPool
            variant="swap_btn"
            setTxnCompleted={setTxnCompleted}
            withdrawnPsp22={withdrawnPsp22}
            withdrawnAzero={withdrawnAzero}
            tokenA={tokenA}
            tokenB={tokenB}
            contract={contract}
            userPoolTokens={userPoolTokens}
            pairAddress={pair_address}
          />
        );
      }
    } else {
      return <ConnectWalletButton variant="swap_btn" />;
    }
  };

  const getPooledTokensAmount = async (withdrawInput) => {
    // const gasLimit = getMaxGasLimit(api[0]);

    if (withdrawInput) {
      withdrawInput = ratioMultiplier(withdrawInput, connectedNetwork);
      let value = 0;
      await contract?.query["getWithdrawTokensAmountWithLp"](address[0], { value, gasLimit: gas }, withdrawInput).then(
        (res) => {
          let azero = res.output.toHuman().Ok.Ok[0].replace(/,/g, "") / getRatio(connectedNetwork);
          let psp22 = res.output.toHuman().Ok.Ok[1].replace(/,/g, "") / getRatio(connectedNetwork);
          azero = checkIfNumIsExp(azero) ? fromExponential(azero) : azero;
          psp22 = checkIfNumIsExp(psp22) ? fromExponential(psp22) : psp22;
          setWithdrawnAzero(azero);
          setWithdrawnPsp22(psp22);
        }
      );
    } else {
      setWithdrawnAzero("0.0000");
      setWithdrawnPsp22("0.0000");
    }
  };

  const getPairByPairAddress = async () => {
    let { data: tokens } = await getAllTokens(connectedNetwork);
    tokens = tokens.data;

    let { data: pair } = await getTokenPairByAddress(pair_address, connectedNetwork);
    let tokenA = tokens.filter((t) => t.address === pair?.data[0]?.token_a_address);
    let tokenB = tokens.filter((t) => t.address === pair?.data[0]?.token_b_address);

    setTokenA(tokenA[0]);
    setTokenB(tokenB[0]);
  };

  const getPsp22Price = async () => {
    let price = await getPriceForOnePsp22(contract, address[0], gas, connectedNetwork);
    setSinglePsp22Price(price);
  };

  useEffect(() => {
    poolTotalSupply > 0 && getPsp22Price();
    const priceInterval = setInterval(() => {
      poolTotalSupply > 0 && getPsp22Price();
    }, 15000);

    return () => {
      clearInterval(priceInterval);
    };
  }, [contract, tokenA, tokenB, poolTotalSupply]);

  const refresh = () => {
    setRefreshing(true);
    dispatch(set_refresh(true));
    setTimeout(() => {
      setRefreshing(false);
      dispatch(set_refresh(false));
    }, 2000);
  };

  useEffect(() => {
    getPairByPairAddress();
    refresh();
  }, [pool_bottom_input]);

  return (
    <Box>
      <TabContext value={tabValue}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <TabList
            onChange={handleChange}
            TabIndicatorProps={{
              style: { background: "none", color: "white" },
            }}
            textColor="inherit"
          >
            <Tab sx={{ padding: "7px", minWidth: "50px", marginLeft: "5px" }} disableRipple label="Add" value="add" />
            <Tab sx={{ padding: "7px", minWidth: "50px" }} disableRipple label="Remove" value="remove" />
          </TabList>
          <div className="two_buttons">
            {!refreshing ? (
              <Button variant="refresh_btn" onClick={refresh} sx={{ background: "transparent !important" }}>
                <RefreshIcon
                  sx={{ color: "white", opacity: "0.5", transition: "all .3s ease", "&:hover": { opacity: "1" } }}
                />
              </Button>
            ) : (
              <StyledRefreshButton variant="refresh_btn" sx={{ background: "transparent !important" }}>
                <RefreshIcon sx={{ color: "white" }} />
              </StyledRefreshButton>
            )}

            <SlippageButton slippage={slippage} handleOpen={handleOpen} />
          </div>
        </Box>

        {/* Add tokens from LP */}
        <StyledTabPanel value="add">
          {/* Add top input */}
          <PoolTopInput token={tokenA} contract={contract} gas={gas} />
          <Box sx={{ display: "flex", justifyContent: "center", marginBlock: "20px 0px" }}>
            <AddRoundedIcon />
          </Box>
          {/* Add bottom input */}
          <PoolBottomInput token={tokenB} contract={contract} gas={gas} />
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "20px" }}>
            <Box>
              <Typography
                sx={{
                  "@media(max-width:992px)": {
                    fontSize: "12px",
                  },
                  "@media(max-width:768px)": {
                    fontSize: "14px",
                  },
                }}
              >
                Price:
              </Typography>
            </Box>
            {poolTotalSupply > 0 ? (
              <Box>
                {tokenA?.address?.toLowerCase() !== network_token?.address?.toLowerCase() ? (
                  <Typography
                    sx={{
                      "@media(max-width:992px)": {
                        fontSize: "12px",
                      },
                    }}
                  >
                    <Typography sx={{ textTransform: "uppercase" }}>
                      1 {tokenA?.symbol.toUpperCase()} = {singlePsp22Price} {tokenB?.symbol.toUpperCase()}
                    </Typography>
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      "@media(max-width:992px)": {
                        fontSize: "12px",
                      },
                      "@media(max-width:768px)": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    1 {tokenB?.symbol.toUpperCase()} = {singlePsp22Price} {tokenA?.symbol.toUpperCase()}
                  </Typography>
                )}
              </Box>
            ) : (
              <Skeleton animation="wave" sx={{ width: "70%" }} />
            )}
          </Box>
          {buildTxnButton()}
        </StyledTabPanel>

        {/* Remove tokens from LP */}
        <StyledTabPanel value="remove">
          <PoolTokensInput getPooledTokensAmount={getPooledTokensAmount} userPoolTokens={userPoolTokens} />

          <Box sx={{ display: "flex", justifyContent: "center", marginBlock: "20px" }}>
            <ArrowDownwardIcon />
          </Box>
          <Card sx={{ padding: "20px" }}>
            <Box>
              {/* Top input withdrawn token */}
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>
                  {tokenA?.address.toLowerCase() !== network_token?.address?.toLowerCase()
                    ? numberWithCommas(withdrawnPsp22)
                    : numberWithCommas(withdrawnAzero)}
                </Typography>
                <Typography sx={{ display: "flex", alignItems: "center", textTransform: "uppercase" }}>
                  <img src={tokenA?.icon} alt="" style={{ width: "20px", borderRadius: "50%", marginRight: "5px" }} />{" "}
                  {tokenA?.symbol}
                </Typography>
              </Box>

              {/* Bottom input withdrawn token */}
              <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                <Typography>
                  {tokenB?.address.toLowerCase() !== network_token?.address?.toLowerCase()
                    ? numberWithCommas(withdrawnPsp22)
                    : numberWithCommas(withdrawnAzero)}
                </Typography>
                <Typography sx={{ display: "flex", alignItems: "center", textTransform: "uppercase" }}>
                  <img src={tokenB?.icon} alt="" style={{ width: "18px", borderRadius: "50%", marginRight: "5px" }} />
                  {tokenB?.symbol}
                </Typography>
              </Box>
            </Box>
          </Card>
          {buildTxnButton()}
        </StyledTabPanel>
      </TabContext>

      {/* Slippage Modal */}
      <SlippageModal open={open} handleClose={handleClose} />
    </Box>
  );
};

export default PoolTabs;
