import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, Typography } from "@mui/material";
import Wrapper from "../Helpers/Wrapper";
import { StyledTableRow, TopStyledTableRow } from "../../Style/Tables/PanxTableStake";

import { customToFixed, numberWithCommas } from "../../Utils/functions/globalFunctions";
import moment from "moment";
import useResize from "../../hooks/useResize";
import { useLocation } from "react-router-dom";

import LaunchIcon from "@mui/icons-material/Launch";

const TxnTable = ({ data, tokenA, tokenB }) => {
  const { connectedNetwork } = useSelector((state) => state.account);

  const resize = useResize();
  const location = useLocation();

  const txnUrl =
    connectedNetwork === "azero" ? "https://alephzero.subscan.io/account/" : "https://shibuya.subscan.io/account/";

  return (
    <Wrapper>
      <TableContainer
        sx={{
          marginTop: "20px",
          display: "block",
          whiteSpace: "nowrap",
          overflow: "auto",
          maxWidth: "100%",
          maxHeight: "250px",

          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#979797",
            height: "10px",
            borderRadius: "15px",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            background: "#d9d9d9",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#c9c9c9",
          },
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TopStyledTableRow>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Time</TableCell>
            </TopStyledTableRow>
          </TableHead>
          <TableBody>
            {data?.map((txn, i) => (
              <StyledTableRow key={i}>
                {/* From token & quantity */}
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center", width: "150px" }}>
                    <Typography sx={{ "@media(max-width:768px)": { fontSize: "12px" } }}>
                      {txn?.from_token_amount < 0.0001
                        ? numberWithCommas(txn?.from_token_amount)
                        : numberWithCommas(customToFixed(txn?.from_token_amount, 4))}
                    </Typography>

                    <Typography
                      sx={{
                        marginLeft: "5px",
                        color: "white",
                        fontSize: "10px",
                        opacity: "0.4",
                        textTransform: "uppercase",
                        "@media(max-width:768px)": { fontSize: "8px" },
                      }}
                    >
                      {txn?.from_token_symbol}
                    </Typography>
                  </Box>
                </TableCell>

                {/* To token & quantity */}
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center", width: "150px" }}>
                    <Typography sx={{ "@media(max-width:768px)": { fontSize: "12px" } }}>
                      {txn?.to_token_amount < 0.0001
                        ? numberWithCommas(txn?.to_token_amount)
                        : numberWithCommas(customToFixed(txn?.to_token_amount, 4))}
                    </Typography>

                    <Typography
                      sx={{
                        marginLeft: "5px",
                        color: "white",
                        fontSize: "10px",
                        opacity: "0.4",
                        textTransform: "uppercase",
                        "@media(max-width:768px)": { fontSize: "8px" },
                      }}
                    >
                      {txn?.to_token_symbol}
                    </Typography>
                  </Box>
                </TableCell>

                {/* Price */}
                <TableCell sx={{}}>
                  <Typography sx={{ "@media(max-width:768px)": { fontSize: "12px" } }}>
                    {numberWithCommas(txn.price)}
                  </Typography>
                </TableCell>

                {/* Txn Time */}
                <TableCell sx={{}}>
                  <Typography
                    sx={{
                      textTransform: "capitalize",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      // width: "160px",
                      whiteSpace: "nowrap",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                      "@media(max-width:768px)": { fontSize: "12px" },
                    }}
                  >
                    <a
                      href={`${txnUrl}${txn?.acc_address}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        display: "flex",
                        alignItems: "center",
                        gap: "3px",
                      }}
                    >
                      {moment(txn?.createdAt).startOf("second").fromNow()} <LaunchIcon fontSize="small" />
                    </a>
                  </Typography>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Wrapper>
  );
};

export default TxnTable;
