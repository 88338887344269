import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Abi, ContractPromise } from "@polkadot/api-contract";
import { Skeleton, Typography } from "@mui/material";
import { abiPsp22Token } from "../../Utils/abi/abiPsp22Token";
import { checkIfNumIsExp, customToFixed, fixedNumber, numberWithCommas } from "../../Utils/functions/globalFunctions";
import Wrapper from "./Wrapper";
import { useLocation } from "react-router-dom";
import { insert_azero_balance, insert_psp22_balance } from "../../features/account";
import { getMaxGasLimit } from "@scio-labs/use-inkathon";
import fromExponential from "from-exponential";
import { getRatio } from "../../Utils/constants";

const TokenBalance = ({ tokenAddress, token }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { network_token } = useSelector((state) => state.tokens);
  const { connectedNetwork } = useSelector((state) => state.account);
  const { address } = useSelector((state) => state.account);
  const { psp22_balance } = useSelector((state) => state.account);
  const { azero_balance } = useSelector((state) => state.account);
  const { api } = useSelector((state) => state.contracts);

  const [balance, setBalance] = useState("0.0000");

  const getPsp22TokenBalance = (contract) => {
    let gasLimit;
    if (api[0]) {
      gasLimit = getMaxGasLimit(api[0]);
    }

    if (tokenAddress?.toLowerCase() !== network_token?.address?.toLowerCase()) {
      let value = 0;
      if (contract && address) {
        contract?.query["psp22::balanceOf"](address[0], { value, gasLimit }, address[0]).then((res) => {
          res = res?.output?.toHuman()?.Ok?.replace(/,/g, "");
          let pspBalance = res / getRatio(connectedNetwork);
          setBalance(isNaN(pspBalance) ? "0.0000" : numberWithCommas(pspBalance));
          dispatch(insert_psp22_balance(pspBalance));
        });
      } else {
        setBalance("0.0000");
      }
    }
  };

  // get AZERO balance
  const getAzeroBalance = async () => {
    if (address[0]) {
      let data = await api[0]?.query?.system.account(address[0]);
      let azeroBalance = data?.data?.free / getRatio(connectedNetwork);
      setBalance(isNaN(azeroBalance) ? "0.0000" : numberWithCommas(azeroBalance));
      dispatch(insert_azero_balance(azeroBalance));
    } else {
      setBalance("0.0000");
    }
  };

  const getBalance = React.useCallback(() => {
    let contract;
    if (tokenAddress?.toLowerCase() !== network_token?.address?.toLowerCase()) {
      try {
        let abi = new Abi(abiPsp22Token, api[0].registry.getChainProperties());
        contract = api[0] && new ContractPromise(api[0], abi, tokenAddress);
      } catch (err) {}
    }

    if (tokenAddress?.toLowerCase() !== network_token?.address?.toLowerCase()) {
      getPsp22TokenBalance(contract);
    } else {
      getAzeroBalance();
    }
  }, [api, address, token, tokenAddress, psp22_balance, azero_balance, network_token]);

  React.useEffect(() => {
    api.length > 0 && getBalance();
  }, [api, address, token, tokenAddress, psp22_balance, azero_balance, network_token]);

  return <Wrapper>{checkIfNumIsExp(balance) ? fromExponential(balance) : balance}</Wrapper>;
};

export default TokenBalance;
