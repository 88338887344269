import { createSlice } from "@reduxjs/toolkit";

export const inputsSlice = createSlice({
  name: "inputs",
  initialState: {
    priceImpact: 0,
    refresh: false,
    add_lp_complete: false,
    swap_from_input: "",
    swap_to_input: "",
    big_swap_to_input: "",
    pool_top_input: "",
    pool_bottom_input: "",
  },
  reducers: {
    set_priceImpact: (state, action) => {
      return {
        ...state,
        priceImpact: action.payload,
      };
    },
    set_refresh: (state, action) => {
      return {
        ...state,
        refresh: action.payload,
      };
    },
    set_add_lp_complete: (state, action) => {
      return {
        ...state,
        add_lp_complete: action.payload,
      };
    },
    set_swap_from_input: (state, action) => {
      return {
        ...state,
        swap_from_input: action.payload,
      };
    },
    set_swap_to_input: (state, action) => {
      return {
        ...state,
        swap_to_input: action.payload,
      };
    },
    set_big_swap_to_input: (state, action) => {
      return {
        ...state,
        big_swap_to_input: action.payload,
      };
    },
    set_pool_top_input: (state, action) => {
      return {
        ...state,
        pool_top_input: action.payload,
      };
    },
    set_pool_bottom_input: (state, action) => {
      return {
        ...state,
        pool_bottom_input: action.payload,
      };
    },
    set_pool_withdraw_input: (state, action) => {
      return {
        ...state,
        pool_withdraw_input: action.payload,
      };
    },
  },
});

export const {
  set_priceImpact,
  set_refresh,
  set_add_lp_complete,
  set_swap_from_input,
  set_swap_to_input,
  set_big_swap_to_input,
  set_pool_top_input,
  set_pool_bottom_input,
  set_pool_withdraw_input,
} = inputsSlice.actions;

export default inputsSlice.reducer;
