import React from "react";
import TuneIcon from "@mui/icons-material/Tune";
import Wrapper from "../Helpers/Wrapper";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";

const SlippageButton = ({ slippage, handleOpen }) => {
  const location = useLocation();

  return (
    <Wrapper>
      <Button
        variant="slippage_btn"
        onClick={handleOpen}
        sx={{ color: "white", background: "none", marginRight: location.pathname !== "/" ? "15px" : "0" }}
      >
        <TuneIcon />
        {slippage}%
      </Button>
    </Wrapper>
  );
};

export default SlippageButton;
