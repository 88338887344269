import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Box, Button, Paper, Typography } from "@mui/material";

import { InputBoxBottom } from "../../Style/Components/inputs";
import { HeroContainer, Page, StyledTextField } from "../../Style/general";

import Wrapper from "../../Components/Helpers/Wrapper";
import { colors } from "../../Style/themes";

const TokensTransfer = () => {
  // const { row } = route.params;
  const location = useLocation();
  const { token_name, token_address, token_balance, token_img } = location.state;
  const { tokens } = useSelector((state) => state.tokens); // tokens list from redux
  const { connectedNetwork } = useSelector((state) => state.account);

  const [recipient, setRecipient] = useState();
  const [transferAmount, setTransferAmount] = useState();

  const [disabled, setDisabled] = useState(true);
  const [recErrorMsg, setRecErrorMsg] = useState("");
  const [amountErrorMsg, setAmountErrorMsg] = useState("");

  const handleRecipientValue = (e) => {
    setRecipient(e.target.value);
  };

  const handleTransferValue = (e) => {
    setTransferAmount(+e.target.value);
  };

  const disableButton = () => {
    if (recipient && transferAmount) {
      if (recipient.length > 9 && transferAmount > 0 && transferAmount < token_balance) {
        setDisabled(false);
        setRecErrorMsg("");
        setAmountErrorMsg("");
      } else {
        setDisabled(true);
        recipient.length < 10
          ? setRecErrorMsg(
              <Typography sx={{ fontSize: "12px", color: colors.red, marginBlock: "0px 10px" }}>
                Recipient address is not valid.{" "}
              </Typography>
            )
          : setRecErrorMsg("");
        transferAmount > token_balance
          ? setAmountErrorMsg(
              <Typography sx={{ fontSize: "12px", color: colors.red, marginBlock: "10px" }}>
                Insufficient transfer amount.{" "}
              </Typography>
            )
          : setAmountErrorMsg("");
      }
    } else {
      setDisabled(true);
    }
  };

  useEffect(() => {
    disableButton();
  }, [recipient, transferAmount, connectedNetwork]);

  return (
    <Wrapper>
      <Page elevation={0} sx={{ position: "relative" }}>
        <HeroContainer paddingtop={"60px"} sx={{ textAlign: "center" }}>
          <Paper elevation={2} sx={{ padding: "50px", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography>Send {token_name} to Panorama Swap users.</Typography>
            <Typography>Please choose the exact amount of tokens you would like to transfer.</Typography>
            <Typography>Be sure to insert the correct wallet address you want to transfer the tokens too.</Typography>
            <Box sx={{ marginTop: "50px", width: "400px" }}>
              <InputBoxBottom width="100%">
                <StyledTextField
                  value={recipient}
                  placeholder="Recipient's Wallet Address"
                  type="text"
                  onInput={(e) => handleRecipientValue(e)}
                  // onKeyDown={(evt) => preventLetters(evt)}
                  // disabled={currentPoolLp}
                  sx={{ width: "100%" }}
                />
              </InputBoxBottom>
            </Box>
            <Box sx={{ marginTop: "30px", width: "400px" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", opacity: "0.8" }}>
                <Typography sx={{ marginRight: "5px", fontSize: "12px" }}>Balance: {token_balance}</Typography>
                {/* <TokenBalance tokenAddress={token_address} /> */}
              </Box>
              <InputBoxBottom width="100%">
                <StyledTextField
                  // value={inputValue}
                  placeholder="Amount To Transfer"
                  type="number"
                  onInput={(e) => handleTransferValue(e)}
                  // onKeyDown={(evt) => preventLetters(evt)}
                  // disabled={currentPoolLp}
                  sx={{ width: "80%" }}
                />
                <Box sx={{ display: "flex", alignItems: "center", gap: "3px", width: "10%" }}>
                  <img style={{ width: "20px", borderRadius: "50px" }} src={token_img} />
                  <span>{token_name}</span>
                </Box>
              </InputBoxBottom>
            </Box>
            <Box sx={{ width: "400px", textAlign: "left", marginTop: "20px" }}>
              {recErrorMsg}
              {amountErrorMsg}
              <Button variant="transfer" disabled={disabled}>
                Transfer {token_name}
              </Button>
            </Box>
          </Paper>
        </HeroContainer>
      </Page>
    </Wrapper>
  );
};

export default TokensTransfer;
