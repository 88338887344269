import { createSlice } from "@reduxjs/toolkit";

export const allAccountsSlice = createSlice({
  name: "accounts",
  initialState: {
    allAccounts: [],
    extensions: [],
    wallet: {},
  },
  reducers: {
    insert_accounts: (state, action) => {
      return {
        ...state,
        allAccounts: [...state.allAccounts, action.payload],
      };
    },
    insert_all_extensions: (state, action) => {
      return {
        ...state,
        extensions: [...state.extensions, action.payload],
      };
    },
    set_wallet: (state, action) => {
      return {
        ...state,
        wallet: action.payload,
      };
    },
  },
});

export const { insert_accounts, insert_all_extensions, set_wallet } = allAccountsSlice.actions;

export default allAccountsSlice.reducer;
