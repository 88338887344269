import { createTheme } from "@mui/material";

export const colors = {
  primary: "#283593",
  secondary: "#3f51b5",
  red: "#e84142",
  green: "#4caf50",
  white: "#FFFFFF",
  black: "#000",
  orange: "#ff5722",
  orangeLight: "#ccab00",
  torq: "#00ccab",
  neonTorq: "#58d3c0",
  darkTorq: "#009b84",
  darkLightTorq: "#00af95",
  gray: "#9f9f9f",
  blueGray: "#37474f",
};

// Light theme
export const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#000",
    },
    background: {
      paper: "#fff",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
  components: {
    MuiButton: {
      //override styled
      variants: [
        {
          //override variant pagination btn
          props: { variant: "transfer_tokens" },
          style: {
            padding: "5px",
            // background: colors.blueGray,
            height: "fit-content",
            "&:hover": {
              background: colors.torq,
            },
          },
        },
        {
          //override variant pagination btn
          props: { variant: "pagination" },
          style: {
            padding: "5px",
            background: colors.blueGray,
            height: "fit-content",
            "&:hover": {
              background: colors.torq,
            },
          },
        },
        {
          //override variant CONTAINED btn
          props: { variant: "contained" },
          style: {
            background: colors.blueGray,
            height: "fit-content",
            "&:hover": {
              background: colors.torq,
            },
          },
        },
        {
          // custom variant Settings btn
          props: { variant: "settings" },
          style: {
            background: colors.gray,
            height: "fit-content",
            minWidth: "auto",
            padding: "5px 10px",
            marginLeft: "15px",
            "&:hover": {
              background: colors.gray,
            },
          },
        },
        {
          // Balance buttons - custom variant
          props: { variant: "balance_btn" },
          style: {
            background: colors.gray,
            height: "fit-content",
            minWidth: "auto",
            padding: "1px 10px",
            marginLeft: "15px",
            borderRadius: "30px",
            fontSize: "10px",
            "&:hover": {
              background: colors.gray,
            },
          },
        },
        {
          // Direction change button - custom variant
          props: { variant: "direction_btn" },
          style: {
            background: colors.gray,
            height: "40px",
            width: "40px",
            minWidth: "auto",
            borderRadius: "50px",
            padding: "1px 10px",
            marginLeft: "15px",
            fontSize: "10px",
            "&:hover": {
              background: colors.darkTorq,
            },
          },
        },
        {
          // SWAP button - custom variant
          props: { variant: "swap_btn" },
          style: {
            background: "#141418",
            width: "100%",
            minWidth: "auto",
            borderRadius: "10px",
            padding: "8px 10px",
            fontSize: "18px",
            marginTop: "30px",
            textTransform: "capitalize",
            "&:hover": {
              background: colors.darkTorq,
            },
          },
        },
        {
          // SWAP button - custom variant
          props: { variant: "save_settings" },
          style: {
            background: "#141418",
            width: "100%",
            minWidth: "auto",
            borderRadius: "10px",
            padding: "8px 10px",
            fontSize: "18px",
            marginTop: "30px",
            "&:hover": {
              background: colors.green,
            },
          },
        },
        {
          // Choose token button - custom variant
          props: { variant: "choose_token_btn" },
          style: {
            background: colors.gray,
            height: "40px",
            width: "35%",
            minWidth: "auto",
            borderRadius: "10px",
            padding: "1px 10px",
            marginLeft: "15px",
            fontSize: "10px",
            "&:hover": {
              background: colors.blueGray,
            },
          },
        },
        {
          // Choose wallet button - custom variant
          props: { variant: "wallet_btn" },
          style: {
            width: "100%",
            border: "0",
            background: "#282830",
            borderRadius: "10px !important",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "capitalize",
            fontSize: "14px",
            "&:hover": {
              background: colors.darkTorq,
            },
          },
        },
        {
          // Choose wallet button - custom variant
          props: { variant: "create_pool_btn" },
          style: {
            width: "fit-content",
            border: "0",
            background: "none",
            borderRadius: "10px !important",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "capitalize",
            fontSize: "20px",
            "&:hover": {
              background: colors.darkTorq,
            },
          },
        },
        {
          //Slippage btn - custom variant
          props: { variant: "slippage_btn" },
          style: {
            padding: "5px 10px",
            width: "auto",
            borderRadius: "10px !important",
            background: "#33333b",
          },
        },
        {
          // Refresh btn - custom variant
          props: { variant: "refresh_btn" },
          style: {
            padding: "5px",
            minWidth: "auto !important",
            width: "fit-content",
            borderRadius: "50% !important",
            background: "#33333b",
            marginRight: "10px",
          },
        },
        {
          // Stats btn - custom variant
          props: { variant: "stats_btn" },
          style: {
            padding: "5px 10px",
            width: "fit-content",
            minWidth: "auto !important",
            borderRadius: "10px !important",
            background: "#33333b",
          },
        },
        {
          // Claim only 10% of panx allocation - custom variant
          props: { variant: "claim_ten" },
          style: {
            width: "100%",
            boxShadow: "0px 0px 10px #58d3c0",
            background: colors.torq,
            color: colors.white,
            borderRadius: "10px !important",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "none",
            fontSize: "22px",
            fontWeight: "600",
            letterSpacing: "0.5px",
            textTransform: "uppercase",
            padding: "10px",
            "&:hover": {
              background: colors.darkTorq,
            },
          },
        },
        {
          // Claim only 10% of panx allocation - custom variant
          props: { variant: "azero_id_register" },
          style: {
            background: "none",
            color: colors.white,
            borderRadius: "10px !important",
            // display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid white",

            "&:hover": {
              background: "white",
              color: "black",
            },
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          // Pool details title - custom variant
          props: { variant: "pool_details_title" },
          style: {
            width: "fit-content",
            color: colors.gray,
            "@media(max-width:992px)": {
              fontSize: "12px",
            },
          },
        },
        {
          // Pool details amount - custom variant
          props: { variant: "pool_details_amount" },
          style: {
            width: "fit-content",
            fontSize: "24px",
            letterSpacing: "1px",
            fontWeight: "500",
            display: "flex",
            gap: "5px",
            alignItems: "center",
            ">img": {
              borderRadius: "50%",
              marginRight: "7px",
              width: "25px",
            },
            "@media(max-width:992px)": {
              fontSize: "18px",
              ">img": {
                width: "20px",
              },
            },
          },
        },

        {
          // Stats Trade chart title - custom variant
          props: { variant: "stats_trade_chart" },
          style: {
            width: "fit-content",
            fontSize: "30px",
            fontWeight: "500",
            textTransform: "capitalize",
          },
        },
        {
          // Balance amount text - custom variant
          props: {
            variant: "balance_amount",
          },
          style: {
            width: "fit-content",
            fontSize: "12px",
            "@media(max-width:768px)": {
              fontSize: "12px",
            },
          },
        },
        {
          // Balance amount text - custom variant
          props: {
            variant: "trade_info_paper",
          },
          style: {
            fontSize: "14px",
            "@media(max-width:768px)": {
              fontSize: "12px",
            },
          },
        },
        {
          // Specific pool pair tokens name title - custom variant
          props: {
            variant: "pair_title",
          },
          style: {
            marginLeft: "5px",
            fontSize: "30px",
            fontWeight: "500",
            textTransform: "uppercase",
            "@media(max-width:768px)": {
              fontSize: "24px",
            },
          },
        },
        {
          // Specific pool pair tokens name title - custom variant
          props: {
            variant: "maintenance",
          },
          style: {
            fontSize: "30px",
            textTransform: "uppercase",
            "@media(max-width:768px)": {
              fontSize: "24px",
            },
          },
        },
      ],
    },
  },
});

// Dark theme
export const themeDark = createTheme({
  palette: {
    mode: "dark",
    background: {
      paper: "#282830",
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
  components: {
    MuiButton: {
      //override styled
      variants: [
        {
          //override variant pagination btn
          props: { variant: "transfer_tokens" },
          style: {
            padding: "1px",
            fontSize: "12px",
            textTransform: "Capitalize",
            opacity: 0.5,
            height: "fit-content",
            "&:hover": {
              background: "none",
              opacity: 1,
              textDecoration: "underline",
              transition: "all ease 0.3s",
            },
          },
        },
        {
          //override variant pagination btn
          props: { variant: "transfer" },
          style: {
            background: colors.darkTorq,
            color: "white",
            height: "fit-content",
            width: "100%",
            textTransform: "capitalize",
            "&:hover": {
              background: colors.darkLightTorq,
            },
            "&:disabled": {
              background: colors.gray,
            },
          },
        },
        {
          //override variant pagination btn
          props: { variant: "pagination" },
          style: {
            padding: "5px",
            background: "#141418",
            height: "fit-content",
            "&:hover": {
              background: colors.darkTorq,
            },
          },
        },
        {
          //override variant CONTAINED btn
          props: { variant: "contained" },
          style: {
            background: "#141418",
            color: "white",
            height: "fit-content",
            "&:hover": {
              background: colors.darkTorq,
            },
          },
        },
        {
          // custom variant Settings btn
          props: { variant: "settings" },
          style: {
            background: colors.gray,
            height: "fit-content",
            minWidth: "auto",
            padding: "5px 10px",
            marginLeft: "15px",
            "&:hover": {
              background: colors.gray,
            },
          },
        },
        {
          // Balance buttons - custom variant
          props: { variant: "balance_btn" },
          style: {
            background: colors.gray,
            height: "fit-content",
            minWidth: "auto",
            padding: "1px 10px",
            marginLeft: "15px",
            borderRadius: "30px",
            fontSize: "10px",
            "&:hover": {
              background: colors.gray,
            },
          },
        },
        {
          // Direction change button - custom variant
          props: { variant: "direction_btn" },
          style: {
            background: colors.gray,
            height: "40px",
            width: "40px",
            minWidth: "auto",
            borderRadius: "50px",
            padding: "1px 10px",
            marginLeft: "15px",
            fontSize: "10px",
            "&:hover": {
              background: colors.darkTorq,
            },
          },
        },
        {
          // SWAP button - custom variant
          props: { variant: "swap_btn" },
          style: {
            background: "#141418",
            width: "100%",
            minWidth: "auto",
            borderRadius: "10px",
            padding: "8px 10px",
            fontSize: "18px",
            marginTop: "30px",
            textTransform: "capitalize",
            "&:hover": {
              background: colors.darkTorq,
            },
          },
        },
        {
          // APPROVE button - custom variant
          props: { variant: "approve_btn" },
          style: {
            background: colors.gray,
            width: "100%",
            minWidth: "auto",
            borderRadius: "10px",
            padding: "8px 10px",
            fontSize: "18px",
            marginTop: "30px",
            "&:hover": {
              background: colors.green,
            },
          },
        },
        {
          // SAVE SETTINGS button - custom variant
          props: { variant: "save_settings" },
          style: {
            background: "#141418",
            width: "100%",
            minWidth: "auto",
            borderRadius: "10px",
            padding: "8px 10px",
            fontSize: "18px",
            "&:hover": {
              background: colors.green,
            },
          },
        },
        {
          // Choose token button - custom variant
          props: { variant: "choose_token_btn" },
          style: {
            background: "transparent",
            width: "35%",
            minWidth: "auto",
            borderRadius: "10px",
            margin: "5px",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            "&:hover": {
              background: colors.gray,
            },
            img: {
              width: "20px",
              height: "auto",
              borderRadius: "50%",
            },
            span: {
              marginLeft: "5px",
            },
          },
        },
        {
          // Choose wallet button - custom variant
          props: { variant: "wallet_btn" },
          style: {
            width: "100%",
            border: "0",
            background: "#282830",
            borderRadius: "10px !important",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "capitalize",
            fontSize: "14px",
            "&:hover": {
              background: colors.darkTorq,
            },
          },
        },
        {
          // Create pool button - custom variant
          props: { variant: "create_pool_btn" },
          style: {
            width: "fit-content",
            border: "1px solid rgba(255, 255, 255, 0.16)",
            background: "none",
            borderRadius: "10px !important",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "none",
            fontSize: "18px",
            paddingInline: "10px",
            "&:hover": {
              background: colors.darkTorq,
            },
          },
        },
        {
          // Remove amount from pool button - custom variant
          props: { variant: "pool_percentage_btn" },
          style: {
            width: "auto",
            border: `2px solid  ${colors.darkTorq}`,
            background: "none",
            borderRadius: "10px !important",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "none",
            fontSize: "14px",
            letterSpacing: "1px",
            paddingInline: "10px",
            minWidth: "50px",
            maxWidth: "100px",
            width: "100%",
            "&:hover": {
              background: colors.darkTorq,
            },
          },
        },
        {
          //Slippage btn - custom variant
          props: { variant: "slippage_btn" },
          style: {
            padding: "5px 10px",
            width: "auto",
            borderRadius: "10px !important",
            background: "#33333b",
          },
        },
        {
          // Refresh btn - custom variant
          props: { variant: "refresh_btn" },
          style: {
            padding: "5px",
            minWidth: "auto !important",
            width: "fit-content",
            borderRadius: "50% !important",
            background: "#33333b",
            marginRight: "10px",
          },
        },
        {
          // Stats btn - custom variant
          props: { variant: "stats_btn" },
          style: {
            padding: "5px 10px",
            width: "fit-content",
            minWidth: "auto !important",
            borderRadius: "10px !important",
            background: "#33333b",
          },
        },
        {
          // Claim only 10% of panx allocation - custom variant
          props: { variant: "claim_ten" },
          style: {
            width: "100%",
            boxShadow: "0px 0px 10px #58d3c0",
            background: colors.torq,
            color: colors.white,
            borderRadius: "10px !important",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "none",
            fontSize: "22px",
            fontWeight: "600",
            letterSpacing: "0.5px",
            textTransform: "uppercase",
            padding: "10px",
            "&:hover": {
              background: colors.darkTorq,
            },
          },
        },
        {
          // Claim only 10% of panx allocation - custom variant
          props: { variant: "menu_btn" },
          style: {
            // minWidth: "25px !important",
            background: "none",
            color: colors.white,
            borderRadius: "10px !important",
            // display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              background: "none",
            },
          },
        },
        {
          // Claim only 10% of panx allocation - custom variant
          props: { variant: "azero_id_register" },
          style: {
            background: "none",
            color: colors.white,
            borderRadius: "10px !important",
            // display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid white",

            "&:hover": {
              background: "white",
              color: "black",
            },
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          // Pool details title - custom variant
          props: { variant: "pool_details_title" },
          style: {
            width: "fit-content",
            color: colors.gray,
            "@media(max-width:992px)": {
              fontSize: "12px",
            },
          },
        },
        {
          // Pool details amount - custom variant
          props: { variant: "pool_details_amount" },
          style: {
            width: "fit-content",
            fontSize: "24px",
            fontWeight: "500",
            display: "flex",
            gap: "5px",
            alignItems: "center",
            ">img": {
              borderRadius: "50%",
              marginRight: "7px",
              width: "25px",
            },
            "@media(max-width:992px)": {
              fontSize: "18px",
              ">img": {
                width: "20px",
              },
            },
          },
        },
        {
          // Stats Trade chart title - custom variant
          props: { variant: "stats_trade_chart" },
          style: {
            width: "fit-content",
            fontSize: "30px",
            fontWeight: "500",
            textTransform: "capitalize",
          },
        },
        {
          // Balance amount text - custom variant
          props: {
            variant: "balance_amount",
          },
          style: {
            width: "fit-content",
            fontSize: "12px",
            "@media(max-width:768px)": {
              fontSize: "12px",
            },
          },
        },
        {
          // Balance amount text - custom variant
          props: {
            variant: "trade_info_paper",
          },
          style: {
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            "@media(max-width:768px)": {
              fontSize: "12px",
            },
          },
        },
        {
          // Specific pool pair tokens name title - custom variant
          props: {
            variant: "pair_title",
          },
          style: {
            marginLeft: "5px",
            fontSize: "30px",
            fontWeight: "500",
            textTransform: "uppercase",
            "@media(max-width:768px)": {
              fontSize: "24px",
            },
          },
        },
        {
          // Specific pool pair tokens name title - custom variant
          props: {
            variant: "maintenance",
          },
          style: {
            fontSize: "60px",
            textTransform: "uppercase",
            "@media(max-width:768px)": {
              fontSize: "34px",
            },
          },
        },
      ],
    },
  },
});
