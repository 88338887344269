import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Wrapper from "../Helpers/Wrapper";
import { Modal, Box, Typography, CircularProgress, Paper } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  textAlign: "center",
  width: "auto",
  height: "auto",
  color: "white",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
};

const ApprovalModal = ({
  openApprove,
  handleApproveClose,
  msgApprovalBoolean,
  aprvFailed,
  approvalFailedText,
  cancelled,
  tokenToApprove,
}) => {
  return (
    <Wrapper>
      <Modal open={openApprove} onClose={handleApproveClose}>
        <Paper elevation={5} sx={style}>
          <Box
            sx={{
              minWidth: "300px",
              minHeight: "150px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {msgApprovalBoolean ? (
              <Box>
                <CircularProgress color="inherit" />
                <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>
                  Approving <span style={{ textTransform: "uppercase" }}>{tokenToApprove?.symbol}</span>
                </Typography>
              </Box>
            ) : (
              <Box>
                {!aprvFailed ? (
                  <Box>
                    <CheckCircleOutlineIcon fontSize="large" />
                    <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Approval completed!</Typography>
                  </Box>
                ) : (
                  <Box>
                    <HighlightOffIcon fontSize="large" sx={{ color: "#e84142" }} />
                    {cancelled ? (
                      <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Approval Cancelled!</Typography>
                    ) : (
                      <Box>
                        <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Approval Failed!</Typography>
                        <Typography sx={{ fontSize: "14px", opacity: "0.5" }} component="p">
                          {approvalFailedText}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Paper>
      </Modal>
    </Wrapper>
  );
};

export default ApprovalModal;
