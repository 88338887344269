import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "universal-cookie";

import { ContractPromise } from "@polkadot/api-contract";

import fromExponential from "from-exponential";

import { abiPsp22Token } from "../../Utils/abi/abiPsp22Token";
import {
  checkIfNumIsExp,
  insertUserData,
  numberWithCommas,
  ratioMultiplier,
} from "../../Utils/functions/globalFunctions";
import { getExpectedLpTokens, getPoolTotalSupply } from "../../Utils/functions/contractsFunctions";
import { getRatio } from "../../Utils/constants";

import { useAuth } from "../../hooks/useAuth";
import { useLastInjector } from "../../hooks/useLastInjector";

import { insert_psp22_balance, insert_azero_balance } from "../../features/account";
import { set_pool_top_input, set_pool_bottom_input } from "../../features/inputs";

import Wrapper from "../Helpers/Wrapper";
import ApprovalModal from "../Modals/ApprovalModal";
import AddToPoolModal from "../Modals/AddToPoolModal";
import ApprovalButton from "./ApprovalButton";

import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { colors } from "../../Style/themes";
import { getMaxGasLimit } from "@scio-labs/use-inkathon";
import { errorHandler } from "../../Utils/functions/scErrorHandler";

const AddLiquidityToPool = ({ variant, setTxnCompleted, tokenA, tokenB, contract }) => {
  const cookies = new Cookies();
  const dispatch = useDispatch();

  const { name: pair_address } = useParams();
  const { isAuthenticated } = useAuth();
  const { lastInjector } = useLastInjector();

  const { account } = useSelector((state) => state);
  const { address } = useSelector((state) => state.account);
  const { slippage } = useSelector((state) => state.account);
  const { psp22_balance } = useSelector((state) => state.account);
  const { azero_balance } = useSelector((state) => state.account);
  const { pool_bottom_input } = useSelector((state) => state.inputs);
  const { pool_top_input } = useSelector((state) => state.inputs);
  const { api } = useSelector((state) => state.contracts);
  const { network_token } = useSelector((state) => state.tokens);
  const { connectedNetwork } = useSelector((state) => state.account);

  const [txnMsg, setTxnMsg] = useState("");
  const [expectedLpTokens, setExpectedLpTokens] = useState();
  const [buttonMsg, setButtonMsg] = useState(`Add Tokens To Pool`);
  const [approveBtnText, setApproveBtnText] = useState(``);
  const [disabled, setDisabled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [msgBoolean, setMsgBoolean] = useState(true);
  const [addFailed, setAddFailed] = useState(false);
  const [openAddToPoolModal, setOpenAddToPoolModal] = useState(false);
  const openAddToPool = () => setOpenAddToPoolModal(true);
  const closeAddToPool = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenAddToPoolModal(false);
  };

  const [needToApprove, setNeedToApprove] = useState(false);
  const [msgApprovalBoolean, setApprovalMsgBoolean] = useState(true);
  const [aprvFailed, setAprvFailed] = useState(false);
  const [cancelled, setCancelled] = useState(false);
  const [openApprove, setOpenApprove] = useState(false);
  const handleApproveOpen = () => setOpenApprove(true);
  const handleApproveClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenApprove(false);
  };

  const [inputToApprove, setInputToApprove] = useState();
  const [tokenToApprove, setTokenToApprove] = useState(); // token to approve
  const [psp22TokenAddress, setPsp22TokenAddress] = useState();
  const [allowanceGas, setAllowanceGas] = useState();

  const [azeroTokensMsg, setAzeroTokensMsg] = useState("");
  const [psp22TokensMsg, setPsp22TokensMsg] = useState("");
  const [valueMsg, setValueMsg] = useState("");

  // Allowance function which show if user needs to approve tokens or not
  const allowance = async (token, tokenValue) => {
    let contract;
    let gasLimit;
    if (api[0]) {
      gasLimit = getMaxGasLimit(api[0]);
      contract = new ContractPromise(api[0], abiPsp22Token, token?.address);
    }
    setTokenToApprove(token);
    setInputToApprove(tokenValue);

    try {
      if (tokenValue < psp22_balance + 1) {
        setApproveBtnText(`Approve ${token.symbol.toUpperCase()}`);
        await contract?.query["psp22::allowance"](address[0], { gasLimit }, address[0], pair_address).then((result) => {
          let allowanceValue = result.output.toHuman().Ok.replace(/,/g, "") / getRatio(connectedNetwork);
          setAllowanceGas(result.gasRequired);
          if (allowanceValue === 0 || tokenValue > allowanceValue) {
            setDisabled(false);
            setPsp22TokenAddress(token?.address);
            // setApproveBtnText(`Approve ${token.symbol.toUpperCase()}`);
            setNeedToApprove(true);
          } else if (tokenValue >= allowanceValue >= 0) {
            setNeedToApprove(false);
          }
        });
      } else {
        setDisabled(true);
        setApproveBtnText(`Insufficient ${token.symbol.toUpperCase()} tokens`);
      }
    } catch (err) {}
  };

  // set approval msg to in progress and open modal
  const approvalInProgress = () => {
    handleApproveOpen();
  };

  // set approval msg to completed and close modal
  const approvalCompleted = () => {
    setApprovalMsgBoolean(false);
    setNeedToApprove(false);
    setTimeout(() => {
      handleApproveClose();
      setApprovalMsgBoolean(true);
    }, 1500);
  };

  // set approval msg to Failed and close modal
  const approvalFailed = (cancelled) => {
    if (cancelled === "Cancelled") {
      setCancelled(true);
    }
    setApprovalMsgBoolean(false);
    setNeedToApprove(true);
    setAprvFailed(true);
    setTimeout(() => {
      setApprovalMsgBoolean(true);
      handleApproveClose();
      setCancelled(false);
      setAprvFailed(false);
    }, 2000);
  };

  // set swap msg to in progress and open modal
  const txnInProgress = () => {
    openAddToPool();
  };

  // set swap msg to completed and close modal
  const txnCompleted = (psp22Balance, poolTopInput, azeroBalance, poolBottomInput) => {
    let newPsp22Balance;
    let newAzeroBalance;

    if (tokenA.address.toLowerCase() !== network_token?.address?.toLowerCase()) {
      newPsp22Balance = psp22Balance - poolTopInput;
      newAzeroBalance = azeroBalance - poolBottomInput;
    } else {
      newPsp22Balance = psp22Balance - poolBottomInput;
      newAzeroBalance = azeroBalance - poolTopInput;
    }
    dispatch(insert_psp22_balance(newPsp22Balance));
    dispatch(insert_azero_balance(newAzeroBalance));
    setMsgBoolean(false);
    dispatch(set_pool_top_input(""));
    dispatch(set_pool_bottom_input(""));
    setTimeout(() => {
      closeAddToPool();
      setLoader(false);
      setMsgBoolean(true);
      setTxnCompleted();
    }, 1500);
  };

  // set swap msg to completed and close modal
  const txnFailedFn = (cancelled) => {
    if (cancelled === "Cancelled") {
      setCancelled(true);
    }
    setMsgBoolean(false);
    setAddFailed(true);
    setTimeout(() => {
      closeAddToPool();
      setLoader(false);
      setCancelled(false);
      setMsgBoolean(true);
      setAddFailed(false);
    }, 5000);
  };

  // Main wrapper function for providing liquidity to pool.
  const provideToPool = () => {
    addTokensToPoolQuery();
  };

  const addTokensToPoolQuery = async () => {
    setLoader(true);

    let errorModule;
    let psp22tokenName;
    let psp22tokenAddress;
    let azeroName;
    let azeroAddress;
    let gasLimit = getMaxGasLimit(api[0]);

    if (tokenA?.address?.toLowerCase() !== network_token?.address?.toLowerCase()) {
      psp22tokenName = tokenA.name;
      psp22tokenAddress = tokenA.address;
      azeroName = tokenB.name;
      azeroAddress = tokenB.address;
    } else {
      psp22tokenName = tokenB.name;
      psp22tokenAddress = tokenB.address;
      azeroName = tokenA.name;
      azeroAddress = tokenA.address;
    }

    let psp22Amount =
      tokenA?.address?.toLowerCase() !== network_token?.address?.toLowerCase()
        ? Number(pool_top_input)
        : Number(pool_bottom_input);
    let a0DepositAmount =
      tokenA?.address?.toLowerCase() === network_token.address.toLowerCase() ? pool_top_input : pool_bottom_input;

    let userObj = {
      pair_address: contract.address.toHuman(),
      user_address: address[0],
      lp_tokens: expectedLpTokens,
      pair_address: pair_address,
      psp22_amount: psp22Amount,
      psp22_name: psp22tokenName,
      psp22_address: psp22tokenAddress,
      azero_amount: a0DepositAmount,
      azero_name: azeroName,
      azero_address: azeroAddress,
    };

    // console.log("(BEFORE)lpTokens:", expectedLpTokens);
    // console.log("(BEFORE)slippageAmount:", slippage);
    // console.log("(BEFORE)psp22Amount:", psp22Amount);
    // console.log("(BEFORE)a0DepositAmount:", a0DepositAmount);

    let lpTokens = ratioMultiplier(expectedLpTokens, connectedNetwork);
    let slippageAmount = ratioMultiplier(slippage, connectedNetwork);
    psp22Amount = ratioMultiplier(psp22Amount, connectedNetwork);
    a0DepositAmount = ratioMultiplier(a0DepositAmount, connectedNetwork);
    let value = a0DepositAmount;
    // console.log("(AFTER RATIO)lpTokens:", lpTokens);
    // console.log("(AFTER RATIO)slippageAmount:", slippageAmount);
    // console.log("(AFTER RATIO)psp22Amount:", psp22Amount);
    // console.log("(AFTER RATIO)a0DepositAmount:", a0DepositAmount);

    try {
      await contract?.query["provideToPool"](
        address[0],
        { value, gasLimit },
        psp22Amount,
        a0DepositAmount,
        lpTokens,
        slippageAmount
      ).then((res) => {
        errorModule = res.output.toHuman().Ok.Err;
        if (errorModule) {
          setTxnMsg(<Typography sx={{ fontSize: "12px" }}>{errorHandler(errorModule)}</Typography>);
          txnFailedFn();
        } else {
          let gas = res.gasRequired;
          res && addTokensToPool(contract, value, a0DepositAmount, psp22Amount, lpTokens, slippageAmount, gas, userObj);
        }
      });
    } catch (err) {
      // console.log("zain", err);
      if (err.message === "Cancelled") {
        txnFailedFn(err.message);
      } else {
        setTxnMsg(<Typography sx={{ fontSize: "12px" }}>{err.message}</Typography>);
        txnFailedFn();
      }
    }
  };

  // A function to Add tokens to pool
  const addTokensToPool = async (
    contract,
    value,
    a0DepositAmount,
    psp22Amount,
    lpTokens,
    slippageAmount,
    gas,
    userObj
  ) => {
    // let gasLimit = getMaxGasLimit(api[0]);
    try {
      if (isAuthenticated) {
        await contract?.tx["provideToPool"](
          { value, gasLimit: gas },
          psp22Amount,
          a0DepositAmount,
          lpTokens,
          slippageAmount
        ).signAndSend(address[0], { signer: lastInjector.signer }, ({ events = [], status }) => {
          if (status.isInBlock || status.isFinalized) {
            events
              // find/filter for failed events
              .filter(({ event }) => api[0].events.system.ExtrinsicFailed.is(event))
              // we know that data for system.ExtrinsicFailed is
              // (DispatchError, DispatchInfo)
              .forEach(
                ({
                  event: {
                    data: [error, info],
                  },
                }) => {
                  if (error.isModule) {
                    // for module errors, we have the section indexed, lookup
                    const decoded = api[0].registry.findMetaError(error.asModule);
                    const { docs, method, section } = decoded;
                    // console.log("method:", method, "|", "section:", section, "|", docs.join(""));
                    if (method === "ContractTrapped") {
                      setTxnMsg(
                        <Typography sx={{ fontSize: "12px" }}>Please readjust your slippage settings. </Typography>
                      );
                    } else {
                      setTxnMsg(<Typography sx={{ fontSize: "12px" }}>{docs.join("")}</Typography>);
                    }
                    // txnFailedFn();
                  } else {
                    // Other, CannotLookup, BadOrigin, no extra info
                    // console.log(error.toString());
                  }
                }
              );
          }

          if (status.isInBlock) {
            txnInProgress();
          } else if (status.isFinalized) {
            // Loop through Vec<EventRecord> to display all events
            events.forEach(({ phase, event: { data, method, section } }) => {
              if (method === "ExtrinsicSuccess") {
                txnCompleted();
                insertUserData(userObj, cookies.get("accessToken"), connectedNetwork);
              } else if (method === "ExtrinsicFailed") {
                txnFailedFn();
              }
            });
          }
        });
      } else {
        setTxnMsg(
          <Typography sx={{ fontSize: "12px" }}>
            You must be authenticated to the system.
            <br /> Please try to logout and login to your wallet again.
          </Typography>
        );
        txnFailedFn();
      }
    } catch (err) {
      // console.log(err);
      if (err.message === "Cancelled") {
        txnFailedFn(err.message);
      } else {
        setTxnMsg(<Typography sx={{ fontSize: "12px" }}>{err.message}</Typography>);
        txnFailedFn();
      }
    }
  };

  // const getExpectedLpTokensAmount = async () => {
  //   let gasLimit;
  //   if (api[0]) {
  //     gasLimit = getMaxGasLimit(api[0]);
  //   }

  //   // let topInput = pool_top_input * getRatio(connectedNetwork);

  //   if (pool_top_input > 0 && !isNaN(pool_bottom_input) && !isNaN(pool_top_input)) {
  //     let networkTokenAmount =
  //       tokenA?.address?.toLowerCase() === network_token?.address?.toLowerCase() ? pool_top_input : pool_bottom_input;
  //     let psp22Amount =
  //       tokenA?.address?.toLowerCase() !== network_token?.address?.toLowerCase() ? pool_top_input : pool_bottom_input;

  //     networkTokenAmount = ratioMultiplier(networkTokenAmount);
  //     psp22Amount = ratioMultiplier(psp22Amount);

  //     await contract?.query["getExpectedLpTokenAmount"](address[0], { gasLimit }, networkTokenAmount, psp22Amount).then(
  //       (res) => {
  //         let lpTokens = res.output.toHuman().Ok.Ok.replace(/,/g, "") / getRatio(connectedNetwork);
  //         console.log("lpTokens:", lpTokens);
  //         setExpectedLpTokens(checkIfNumIsExp(Number(lpTokens)) ? fromExponential(Number(lpTokens)) : Number(lpTokens));
  //       }
  //     );
  //   }
  // };

  const getExpectedLpTokensAmount = async () => {
    let exLpTokens = await getExpectedLpTokens(
      tokenA,
      tokenB,
      connectedNetwork,
      api,
      network_token,
      pool_top_input,
      pool_bottom_input,
      address
    );

    setExpectedLpTokens(exLpTokens);
  };

  const disableButton = async () => {
    let gasLimit;
    if (api[0]) {
      gasLimit = getMaxGasLimit(api[0]);
    }
    let { total_supply: poolTotalSupply } = await getPoolTotalSupply(contract, address[0], gasLimit, connectedNetwork);
    let top = Number(pool_top_input); // top input value
    let bot = Number(pool_bottom_input); // bottom input value

    if (top === "" && isNaN(top) && top === 0 && bot === "" && isNaN(bot) && bot === 0) {
      setDisabled(true);
    } else {
      if (top > 0 && bot > 0) {
        if (poolTotalSupply === 0 || poolTotalSupply === null || poolTotalSupply === undefined) {
          if (top < 1 || bot < 1) {
            setDisabled(true);
            setValueMsg(
              `* The minimum amount of tokens to initialize a liquidity pool must be equal or greater than 1.`
            );
            return;
          } else {
            setValueMsg();
          }
        }
        setDisabled(false);
        setButtonMsg(`Add Tokens To Pool`);
        if (tokenA?.address?.toLowerCase() !== network_token?.address?.toLowerCase()) {
          if (top > psp22_balance) {
            setPsp22TokensMsg(`* Insufficient ${tokenA?.symbol?.toUpperCase()} tokens amount.`);
            setDisabled(true);
            return;
          } else {
            setDisabled(false);
            setPsp22TokensMsg();
          }
        } else if (tokenA?.address.toLowerCase() === network_token.address.toLowerCase()) {
          if (top > azero_balance) {
            setAzeroTokensMsg(`* Insufficient ${tokenA?.symbol?.toUpperCase()} tokens amount.`);
            setDisabled(true);
            return;
          } else {
            setDisabled(false);
            setAzeroTokensMsg();
          }
        }
        if (tokenB?.address?.toLowerCase() !== network_token?.address?.toLowerCase()) {
          if (bot > psp22_balance) {
            setPsp22TokensMsg(`* Insufficient ${tokenB?.symbol?.toUpperCase()} tokens amount.`);
            setDisabled(true);
            return;
          } else {
            setDisabled(false);
            setPsp22TokensMsg();
          }
        } else if (tokenB?.address.toLowerCase() === network_token.address.toLowerCase()) {
          if (bot > azero_balance) {
            setAzeroTokensMsg(`* Insufficient ${tokenB?.symbol?.toUpperCase()} tokens amount.`);
            setDisabled(true);
          } else {
            setDisabled(false);
            setAzeroTokensMsg();
          }
        }
      } else {
        setDisabled(true);
      }
    }
  };

  useEffect(() => {
    disableButton(tokenA, tokenB);

    if (pair_address) {
      if (tokenA?.address?.toLowerCase() !== network_token?.address?.toLowerCase() && pool_top_input > 0) {
        allowance(tokenA, pool_top_input);
      } else if (tokenB?.address?.toLowerCase() !== network_token?.address?.toLowerCase() && pool_bottom_input > 0) {
        allowance(tokenB, pool_bottom_input);
      }
    }
  }, [pool_top_input, pool_bottom_input, account]);

  useEffect(() => {
    getExpectedLpTokensAmount();
  }, [pool_top_input, pool_bottom_input]);

  useEffect(() => {
    disableButton();
  }, [needToApprove]);

  return (
    <Wrapper>
      {needToApprove === true ? (
        <ApprovalButton
          variant="swap_btn"
          psp22TokenAddress={psp22TokenAddress}
          allowanceGas={allowanceGas}
          approvalInProgress={approvalInProgress}
          approvalCompleted={approvalCompleted}
          approvalFailed={approvalFailed}
          handleApproveOpen={handleApproveOpen}
          disabled={disabled}
          inputToApprove={inputToApprove}
          approveBtnText={approveBtnText}
          currentAddress={pair_address}
        />
      ) : (
        <Box>
          <Button variant={variant ? variant : "contained"} disabled={disabled} onClick={openAddToPool}>
            {buttonMsg}
          </Button>
          <Box
            sx={{
              padding: "5px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              color: colors.red,
            }}
          >
            <Typography variant="p">{valueMsg}</Typography>
            <Typography variant="p">{azeroTokensMsg}</Typography>
            <Typography variant="p">{psp22TokensMsg}</Typography>
          </Box>
        </Box>
      )}

      {/* Approval Modal */}
      <ApprovalModal
        openApprove={openApprove}
        handleApproveClose={handleApproveClose}
        msgApprovalBoolean={msgApprovalBoolean}
        tokenToApprove={tokenToApprove}
        cancelled={cancelled}
        aprvFailed={aprvFailed}
      />
      {/* Add to Pool Modal */}
      <AddToPoolModal
        tokenA={tokenA}
        tokenAValue={pool_top_input}
        tokenB={tokenB}
        tokenBValue={pool_bottom_input}
        openAddToPoolModal={openAddToPoolModal}
        closeAddToPool={closeAddToPool}
        msgBoolean={msgBoolean}
        loader={loader}
        addFailed={addFailed}
        addTokensToPool={provideToPool}
        expectedLpTokens={expectedLpTokens}
        cancelled={cancelled}
        txnMsg={txnMsg}
      />
    </Wrapper>
  );
};

export default AddLiquidityToPool;
