import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "universal-cookie";

import { web3FromAddress } from "@polkadot/extension-dapp";
import { useAzeroId } from "../../hooks/useAzeroId";

import { login } from "../../shared/api";
import {
  insert_psp22_balance,
  insert_azero_balance,
  set_domain,
  set_injector,
  select_caller_account,
  select_address,
} from "../../features/account";

import { Button } from "@mui/material";

import Wrapper from "../Helpers/Wrapper";
import AccountsDialog from "../Dialogs/AccountsDialog";
import { getNightlyAdapter } from "../../Utils/nightlyAdapter";
// import WalletModal from "../Modals/WalletModal";

const ConnectWalletButton = ({ variant }) => {
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const loggedUser = cookies.get("address");
  const chosenWallet = localStorage.getItem("NIGHTLY_CONNECT_SELECTOR_RECENT_STANDARD_WALLET_AlephZero"); // get nightly chosen wallet.

  const { address } = useSelector((state) => state.account);
  // const { domain } = useSelector((state) => state.account);

  const user_domain = useAzeroId(address[0]);
  const [injector, setInjector] = useState();
  const [selectedValue, setSelectedValue] = useState();

  const [nightlyAccounts, setNightlyAccounts] = useState();
  const [titleToShow, setTitleToShow] = useState(address[0]);
  const [psp22BalanceTrue, setPsp22BalanceTrue] = useState(0);
  const [azeroBalanceTrue, setAzeroBalanceTrue] = useState(0);
  const [open, setOpen] = useState(false);
  const [connecting, setConnecting] = useState(false); // Boolean parameter for connecting

  // A function which builds the Nightly adapter.
  // The function should work once inside the 'handleClickOpen' function.

  const getInjector = useCallback(async (value) => {
    let injector = await web3FromAddress(value);
    dispatch(set_injector(injector));
    setInjector(injector);
  }, []);

  // A function which opens the nightly wallet selector popup.
  const handleClickOpen = async () => {
    let accounts = "";
    let adapter = await getNightlyAdapter();
    try {
      await adapter.connect();
      accounts = await adapter.accounts.get();
      setNightlyAccounts(accounts);
      accounts.length > 0 && setOpen(true);
    } catch (err) {
      // console.log(err);
    }
  };

  const handleClose = async (value) => {
    try {
      if (value.type === "wallet") {
        cookies.remove("address", { path: "/" });
        cookies.remove("domain", { path: "/" });
        cookies.remove("accessToken", { path: "/" });
        localStorage.removeItem("NIGHTLY_CONNECT_SESSION_ID_AlephZero");
        localStorage.removeItem("NIGHTLY_CONNECT_SELECTOR_IS_DESKTOP_CONNECTED_AlephZero");
        localStorage.removeItem("NIGHTLY_CONNECT_SELECTOR_RECENT_STANDARD_WALLET_AlephZero");
        dispatch(select_address(null));
        setOpen(false);
        handleClickOpen();
        return;
      } else {
        if (value) {
          setConnecting(true);
          let accessToken = await login(value);
          accessToken = accessToken?.data?.data;
          cookies.set("accessToken", JSON.stringify(accessToken), { path: "/", maxAge: 86400 });
          cookies.set("address", JSON.stringify(value), { path: "/", maxAge: 86400 });
          dispatch(select_caller_account(value));
          dispatch(select_address(value?.address));
          setSelectedValue(value);
          accessToken &&
            setTimeout(() => {
              setConnecting(false);
            }, "1000");
        } else {
          setTimeout(() => {
            setConnecting(false);
          }, "1000");
        }
      }
    } catch (err) {
      // console.log(err);
    }
    setOpen(false);
  };

  useEffect(() => {
    dispatch(set_domain(user_domain));
  }, [address]);

  // useEffect function to dispatch panx balance
  useEffect(() => {
    dispatch(insert_psp22_balance(psp22BalanceTrue));
  }, [psp22BalanceTrue]);

  // // useEffect function to dispatch azero balance
  useEffect(() => {
    dispatch(insert_azero_balance(azeroBalanceTrue));
  }, [azeroBalanceTrue]);

  return (
    <Wrapper>
      <Button variant={variant ? variant : "contained"} sx={{ fontSize: "12px" }} onClick={handleClickOpen}>
        {address[0] ? (
          <span
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "150px",
              whiteSpace: "nowrap",
            }}
          >
            {user_domain ? user_domain : address[0]}
          </span>
        ) : (
          <span>Connect Wallet</span>
        )}
      </Button>

      {/* <WalletModal open={open} onClose={handleClose} nightlyAccounts={nightlyAccounts} /> */}
      <AccountsDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        getInjector={getInjector}
        accounts={nightlyAccounts}
        loggedUser={loggedUser}
        chosenWallet={chosenWallet}
        injector={injector}
        connecting={connecting}
      />
    </Wrapper>
  );
};

export default ConnectWalletButton;
