import styled from "@emotion/styled";
import { Paper } from "@mui/material";
import { Box, display } from "@mui/system";
import { colors } from "../themes";

export const MainCreatePoolPaper = styled(Paper)(({ theme, width }) => ({
  display: "grid",
  gridTemplateColumns: "50% 50%",
  marginBlock: "30px",
  padding: "30px",
  // "@media(max-width:992px)": {
  //   gridTemplateColumns: "50% 50%",
  // },
  "@media(max-width:768px)": {
    gridTemplateColumns: "1fr",
  },
}));
export const LeftBoxStyle = styled(Box)(({ theme, width }) => ({
  position: "relative",
  paddingRight: "25px",
  "@media(max-width:768px)": {
    paddingRight: "0",
  },
}));
export const RightBoxStyle = styled(Box)(({ theme, width }) => ({}));
