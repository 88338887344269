import { useState } from "react";

import { Box, Alert, IconButton, Collapse, Button } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";

const CustomAlerts = ({ alert_typo }) => {
  const [open, setOpen] = useState(true);

  // Initialize your local storage parameter
  useEffect(() => {
    localStorage.getItem("PANORAMA_SWAP_OFFICIAL_SITE");
  }, []);

  // Function to handle the close button click
  const handleCloseClick = () => {
    localStorage.setItem("PANORAMA_SWAP_OFFICIAL_SITE", true);
    setOpen(false);
  };

  // Add the beforeunload event to remove the local storage parameter when closing the tab or browser
  useEffect(() => {
    const beforeUnloadHandler = () => {
      localStorage.removeItem("PANORAMA_SWAP_OFFICIAL_SITE");
    };

    window.addEventListener("beforeunload", beforeUnloadHandler);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, []);

  return (
    <Box sx={{ position: "fixed", bottom: "0px", left: "10px", paddingRight: "10px" }}>
      <Collapse in={open}>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small" onClick={handleCloseClick}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {alert_typo}
        </Alert>
      </Collapse>
    </Box>
  );
};

export default CustomAlerts;
