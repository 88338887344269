// **** VESTING ABI **** //
// **** lOCAL TESTING **** //
export const abiVesting = {
  source: {
    hash: "0xd47bc22d16e399cacfc2cf4ae3371349329c5ebc68c11c45d9a09ac08e05bf69",
    language: "ink! 4.0.0",
    compiler: "rustc 1.65.0",
    build_info: {
      build_mode: "Debug",
      cargo_contract_version: "2.0.0-beta",
      rust_toolchain: "stable-aarch64-apple-darwin",
      wasm_opt_settings: {
        keep_debug_symbols: false,
        optimization_passes: "Z",
      },
    },
  },
  contract: {
    name: "vesting_contract",
    version: "1.0.0",
    authors: ["Yonatan Hornstein <yonatan@panoramaswap.com>"],
  },
  spec: {
    constructors: [
      {
        args: [
          {
            label: "panx_contract",
            type: {
              displayName: ["AccountId"],
              type: 0,
            },
          },
        ],
        docs: [],
        label: "new",
        payable: false,
        returnType: {
          displayName: ["ink_primitives", "ConstructorResult"],
          type: 4,
        },
        selector: "0x9bae9d5e",
      },
    ],
    docs: [],
    events: [
      {
        args: [
          {
            docs: [],
            indexed: false,
            label: "added_account",
            type: {
              displayName: ["AccountId"],
              type: 0,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "total_vesting_amount",
            type: {
              displayName: ["Balance"],
              type: 3,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "panx_amount_to_give_each_day",
            type: {
              displayName: ["Balance"],
              type: 3,
            },
          },
        ],
        docs: [],
        label: "AddToVestingProgram",
      },
      {
        args: [
          {
            docs: [],
            indexed: false,
            label: "caller",
            type: {
              displayName: ["AccountId"],
              type: 0,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "panx_given_amount",
            type: {
              displayName: ["Balance"],
              type: 3,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "caller_new_balance",
            type: {
              displayName: ["Balance"],
              type: 3,
            },
          },
        ],
        docs: [],
        label: "CollectedTGE",
      },
      {
        args: [
          {
            docs: [],
            indexed: false,
            label: "caller",
            type: {
              displayName: ["AccountId"],
              type: 0,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "panx_given_amount",
            type: {
              displayName: ["Balance"],
              type: 3,
            },
          },
          {
            docs: [],
            indexed: false,
            label: "caller_new_balance",
            type: {
              displayName: ["Balance"],
              type: 3,
            },
          },
        ],
        docs: [],
        label: "Redeem",
      },
    ],
    lang_error: {
      displayName: ["ink", "LangError"],
      type: 6,
    },
    messages: [
      {
        args: [
          {
            label: "account",
            type: {
              displayName: ["AccountId"],
              type: 0,
            },
          },
          {
            label: "panx_to_give_overall",
            type: {
              displayName: ["Balance"],
              type: 3,
            },
          },
        ],
        docs: [
          "adding seed events participants to vesting contract and their PANX vesting allocation",
          "Only manager can use this function",
        ],
        label: "add_to_vesting",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 7,
        },
        selector: "0x30745746",
      },
      {
        args: [],
        docs: ["function to collect TGE (10%) for caller"],
        label: "collect_tge_tokens",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 7,
        },
        selector: "0x59ffade9",
      },
      {
        args: [],
        docs: ["function to get caller redeemable amount of tokens"],
        label: "get_redeemable_amount",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 10,
        },
        selector: "0x8cd0cc42",
      },
      {
        args: [],
        docs: ["function for caller to redeem his redeemable tokens."],
        label: "redeem_redeemable_amount",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 7,
        },
        selector: "0x7f8d53af",
      },
      {
        args: [
          {
            label: "account",
            type: {
              displayName: ["AccountId"],
              type: 0,
            },
          },
        ],
        docs: ["function to get caller total locked tokens"],
        label: "get_account_total_vesting_amount",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 12,
        },
        selector: "0x8d5d6341",
      },
      {
        args: [
          {
            label: "account",
            type: {
              displayName: ["AccountId"],
              type: 0,
            },
          },
        ],
        docs: ["funtion to get caller last redeem in timestamp"],
        label: "get_account_last_redeem",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 12,
        },
        selector: "0xa010f12f",
      },
      {
        args: [
          {
            label: "account",
            type: {
              displayName: ["AccountId"],
              type: 0,
            },
          },
        ],
        docs: ["function to get the amount of tokens to give to account each day."],
        label: "get_amount_to_give_each_day_to_account",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 12,
        },
        selector: "0xbab87d10",
      },
      {
        args: [],
        docs: ["funtion to get vesting contract PANX reserve"],
        label: "get_vesting_contract_panx_reserve",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 12,
        },
        selector: "0x2be9b6fa",
      },
      {
        args: [
          {
            label: "account",
            type: {
              displayName: ["AccountId"],
              type: 0,
            },
          },
        ],
        docs: ["function to get account TGE collection status"],
        label: "user_tge_collection_status",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 12,
        },
        selector: "0x56835988",
      },
      {
        args: [],
        docs: ["funtion to get the started date since issuing the vesting contract in timpstamp and str"],
        label: "get_started_date",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 12,
        },
        selector: "0xa38dfcc2",
      },
      {
        args: [],
        docs: ["function to get current timpstamp in seconds"],
        label: "get_current_timestamp",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 12,
        },
        selector: "0x17ecd294",
      },
      {
        args: [],
        docs: [],
        label: "get_days_passed_since_issue",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 10,
        },
        selector: "0x5bf32c49",
      },
    ],
  },
  storage: {
    root: {
      layout: {
        struct: {
          fields: [
            {
              layout: {
                leaf: {
                  key: "0x00000000",
                  ty: 0,
                },
              },
              name: "manager",
            },
            {
              layout: {
                leaf: {
                  key: "0x00000000",
                  ty: 0,
                },
              },
              name: "panx_psp22",
            },
            {
              layout: {
                leaf: {
                  key: "0x00000000",
                  ty: 3,
                },
              },
              name: "started_date_in_timestamp",
            },
            {
              layout: {
                root: {
                  layout: {
                    leaf: {
                      key: "0xb2ca19c1",
                      ty: 3,
                    },
                  },
                  root_key: "0xb2ca19c1",
                },
              },
              name: "balances",
            },
            {
              layout: {
                root: {
                  layout: {
                    leaf: {
                      key: "0x036bc759",
                      ty: 3,
                    },
                  },
                  root_key: "0x036bc759",
                },
              },
              name: "collected_tge",
            },
            {
              layout: {
                root: {
                  layout: {
                    leaf: {
                      key: "0xbb91c7ab",
                      ty: 3,
                    },
                  },
                  root_key: "0xbb91c7ab",
                },
              },
              name: "panx_to_give_in_a_day",
            },
            {
              layout: {
                root: {
                  layout: {
                    leaf: {
                      key: "0x722912a2",
                      ty: 3,
                    },
                  },
                  root_key: "0x722912a2",
                },
              },
              name: "last_redeemed",
            },
          ],
          name: "VestingContract",
        },
      },
      root_key: "0x00000000",
    },
  },
  types: [
    {
      id: 0,
      type: {
        def: {
          composite: {
            fields: [
              {
                type: 1,
                typeName: "[u8; 32]",
              },
            ],
          },
        },
        path: ["ink_primitives", "types", "AccountId"],
      },
    },
    {
      id: 1,
      type: {
        def: {
          array: {
            len: 32,
            type: 2,
          },
        },
      },
    },
    {
      id: 2,
      type: {
        def: {
          primitive: "u8",
        },
      },
    },
    {
      id: 3,
      type: {
        def: {
          primitive: "u128",
        },
      },
    },
    {
      id: 4,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 5,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 6,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 5,
          },
          {
            name: "E",
            type: 6,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 5,
      type: {
        def: {
          tuple: [],
        },
      },
    },
    {
      id: 6,
      type: {
        def: {
          variant: {
            variants: [
              {
                index: 1,
                name: "CouldNotReadInput",
              },
            ],
          },
        },
        path: ["ink_primitives", "LangError"],
      },
    },
    {
      id: 7,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 8,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 6,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 8,
          },
          {
            name: "E",
            type: 6,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 8,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 5,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 9,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 5,
          },
          {
            name: "E",
            type: 9,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 9,
      type: {
        def: {
          variant: {
            variants: [
              {
                index: 0,
                name: "CallerIsNotManager",
              },
              {
                index: 1,
                name: "CallerCollectedTGEAlready",
              },
              {
                index: 2,
                name: "CallerInsufficientLockedTokens",
              },
              {
                index: 3,
                name: "ZeroDaysPassed",
              },
              {
                index: 4,
                name: "PSP22TransferFailed",
              },
              {
                index: 5,
                name: "Overflow",
              },
            ],
          },
        },
        path: ["vesting_contract", "vesting_contract", "VestingProgramErrors"],
      },
    },
    {
      id: 10,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 11,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 6,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 11,
          },
          {
            name: "E",
            type: 6,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 11,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 3,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 9,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 3,
          },
          {
            name: "E",
            type: 9,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 12,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 3,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 6,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 3,
          },
          {
            name: "E",
            type: 6,
          },
        ],
        path: ["Result"],
      },
    },
  ],
  version: "4",
};

// **** PRODUCTION **** //
// export const abiVesting = {
//   source: {
//     hash: "0xd47bc22d16e399cacfc2cf4ae3371349329c5ebc68c11c45d9a09ac08e05bf69",
//     language: "ink! 4.0.0",
//     compiler: "rustc 1.65.0",
//     build_info: {
//       build_mode: "Debug",
//       cargo_contract_version: "2.0.0-beta",
//       rust_toolchain: "stable-aarch64-apple-darwin",
//       wasm_opt_settings: {
//         keep_debug_symbols: false,
//         optimization_passes: "Z",
//       },
//     },
//   },
//   contract: {
//     name: "vesting_contract",
//     version: "1.0.0",
//     authors: ["Yonatan Hornstein <yonatan@panoramaswap.com>"],
//   },
//   spec: {
//     constructors: [
//       {
//         args: [
//           {
//             label: "panx_contract",
//             type: {
//               displayName: ["AccountId"],
//               type: 0,
//             },
//           },
//         ],
//         docs: [],
//         label: "new",
//         payable: false,
//         returnType: {
//           displayName: ["ink_primitives", "ConstructorResult"],
//           type: 4,
//         },
//         selector: "0x9bae9d5e",
//       },
//     ],
//     docs: [],
//     events: [
//       {
//         args: [
//           {
//             docs: [],
//             indexed: false,
//             label: "added_account",
//             type: {
//               displayName: ["AccountId"],
//               type: 0,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "total_vesting_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 3,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "panx_amount_to_give_each_day",
//             type: {
//               displayName: ["Balance"],
//               type: 3,
//             },
//           },
//         ],
//         docs: [],
//         label: "AddToVestingProgram",
//       },
//       {
//         args: [
//           {
//             docs: [],
//             indexed: false,
//             label: "caller",
//             type: {
//               displayName: ["AccountId"],
//               type: 0,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "panx_given_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 3,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "caller_new_balance",
//             type: {
//               displayName: ["Balance"],
//               type: 3,
//             },
//           },
//         ],
//         docs: [],
//         label: "CollectedTGE",
//       },
//       {
//         args: [
//           {
//             docs: [],
//             indexed: false,
//             label: "caller",
//             type: {
//               displayName: ["AccountId"],
//               type: 0,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "panx_given_amount",
//             type: {
//               displayName: ["Balance"],
//               type: 3,
//             },
//           },
//           {
//             docs: [],
//             indexed: false,
//             label: "caller_new_balance",
//             type: {
//               displayName: ["Balance"],
//               type: 3,
//             },
//           },
//         ],
//         docs: [],
//         label: "Redeem",
//       },
//     ],
//     lang_error: {
//       displayName: ["ink", "LangError"],
//       type: 6,
//     },
//     messages: [
//       {
//         args: [
//           {
//             label: "account",
//             type: {
//               displayName: ["AccountId"],
//               type: 0,
//             },
//           },
//           {
//             label: "panx_to_give_overall",
//             type: {
//               displayName: ["Balance"],
//               type: 3,
//             },
//           },
//         ],
//         docs: [
//           "adding seed events participants to vesting contract and their PANX vesting allocation",
//           "Only manager can use this function",
//         ],
//         label: "add_to_vesting",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 7,
//         },
//         selector: "0x30745746",
//       },
//       {
//         args: [],
//         docs: ["function to collect TGE (10%) for caller"],
//         label: "collect_tge_tokens",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 7,
//         },
//         selector: "0x59ffade9",
//       },
//       {
//         args: [],
//         docs: ["function to get caller redeemable amount of tokens"],
//         label: "get_redeemable_amount",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 10,
//         },
//         selector: "0x8cd0cc42",
//       },
//       {
//         args: [],
//         docs: ["function for caller to redeem his redeemable tokens."],
//         label: "redeem_redeemable_amount",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 7,
//         },
//         selector: "0x7f8d53af",
//       },
//       {
//         args: [
//           {
//             label: "account",
//             type: {
//               displayName: ["AccountId"],
//               type: 0,
//             },
//           },
//         ],
//         docs: ["function to get caller total locked tokens"],
//         label: "get_account_total_vesting_amount",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 12,
//         },
//         selector: "0x8d5d6341",
//       },
//       {
//         args: [
//           {
//             label: "account",
//             type: {
//               displayName: ["AccountId"],
//               type: 0,
//             },
//           },
//         ],
//         docs: ["funtion to get caller last redeem in timestamp"],
//         label: "get_account_last_redeem",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 12,
//         },
//         selector: "0xa010f12f",
//       },
//       {
//         args: [
//           {
//             label: "account",
//             type: {
//               displayName: ["AccountId"],
//               type: 0,
//             },
//           },
//         ],
//         docs: ["function to get the amount of tokens to give to account each day."],
//         label: "get_amount_to_give_each_day_to_account",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 12,
//         },
//         selector: "0xbab87d10",
//       },
//       {
//         args: [],
//         docs: ["funtion to get vesting contract PANX reserve"],
//         label: "get_vesting_contract_panx_reserve",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 12,
//         },
//         selector: "0x2be9b6fa",
//       },
//       {
//         args: [
//           {
//             label: "account",
//             type: {
//               displayName: ["AccountId"],
//               type: 0,
//             },
//           },
//         ],
//         docs: ["function to get account TGE collection status"],
//         label: "user_tge_collection_status",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 12,
//         },
//         selector: "0x56835988",
//       },
//       {
//         args: [],
//         docs: ["funtion to get the started date since issuing the vesting contract in timpstamp and str"],
//         label: "get_started_date",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 12,
//         },
//         selector: "0xa38dfcc2",
//       },
//       {
//         args: [],
//         docs: ["function to get current timpstamp in seconds"],
//         label: "get_current_timestamp",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 12,
//         },
//         selector: "0x17ecd294",
//       },
//       {
//         args: [],
//         docs: [],
//         label: "get_days_passed_since_issue",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 10,
//         },
//         selector: "0x5bf32c49",
//       },
//     ],
//   },
//   storage: {
//     root: {
//       layout: {
//         struct: {
//           fields: [
//             {
//               layout: {
//                 leaf: {
//                   key: "0x00000000",
//                   ty: 0,
//                 },
//               },
//               name: "manager",
//             },
//             {
//               layout: {
//                 leaf: {
//                   key: "0x00000000",
//                   ty: 0,
//                 },
//               },
//               name: "panx_psp22",
//             },
//             {
//               layout: {
//                 leaf: {
//                   key: "0x00000000",
//                   ty: 3,
//                 },
//               },
//               name: "started_date_in_timestamp",
//             },
//             {
//               layout: {
//                 root: {
//                   layout: {
//                     leaf: {
//                       key: "0xb2ca19c1",
//                       ty: 3,
//                     },
//                   },
//                   root_key: "0xb2ca19c1",
//                 },
//               },
//               name: "balances",
//             },
//             {
//               layout: {
//                 root: {
//                   layout: {
//                     leaf: {
//                       key: "0x036bc759",
//                       ty: 3,
//                     },
//                   },
//                   root_key: "0x036bc759",
//                 },
//               },
//               name: "collected_tge",
//             },
//             {
//               layout: {
//                 root: {
//                   layout: {
//                     leaf: {
//                       key: "0xbb91c7ab",
//                       ty: 3,
//                     },
//                   },
//                   root_key: "0xbb91c7ab",
//                 },
//               },
//               name: "panx_to_give_in_a_day",
//             },
//             {
//               layout: {
//                 root: {
//                   layout: {
//                     leaf: {
//                       key: "0x722912a2",
//                       ty: 3,
//                     },
//                   },
//                   root_key: "0x722912a2",
//                 },
//               },
//               name: "last_redeemed",
//             },
//           ],
//           name: "VestingContract",
//         },
//       },
//       root_key: "0x00000000",
//     },
//   },
//   types: [
//     {
//       id: 0,
//       type: {
//         def: {
//           composite: {
//             fields: [
//               {
//                 type: 1,
//                 typeName: "[u8; 32]",
//               },
//             ],
//           },
//         },
//         path: ["ink_primitives", "types", "AccountId"],
//       },
//     },
//     {
//       id: 1,
//       type: {
//         def: {
//           array: {
//             len: 32,
//             type: 2,
//           },
//         },
//       },
//     },
//     {
//       id: 2,
//       type: {
//         def: {
//           primitive: "u8",
//         },
//       },
//     },
//     {
//       id: 3,
//       type: {
//         def: {
//           primitive: "u128",
//         },
//       },
//     },
//     {
//       id: 4,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 5,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 6,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 5,
//           },
//           {
//             name: "E",
//             type: 6,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 5,
//       type: {
//         def: {
//           tuple: [],
//         },
//       },
//     },
//     {
//       id: 6,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 index: 1,
//                 name: "CouldNotReadInput",
//               },
//             ],
//           },
//         },
//         path: ["ink_primitives", "LangError"],
//       },
//     },
//     {
//       id: 7,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 8,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 6,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 8,
//           },
//           {
//             name: "E",
//             type: 6,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 8,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 5,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 9,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 5,
//           },
//           {
//             name: "E",
//             type: 9,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 9,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 index: 0,
//                 name: "CallerIsNotManager",
//               },
//               {
//                 index: 1,
//                 name: "CallerCollectedTGEAlready",
//               },
//               {
//                 index: 2,
//                 name: "CallerInsufficientLockedTokens",
//               },
//               {
//                 index: 3,
//                 name: "ZeroDaysPassed",
//               },
//               {
//                 index: 4,
//                 name: "PSP22TransferFailed",
//               },
//               {
//                 index: 5,
//                 name: "Overflow",
//               },
//             ],
//           },
//         },
//         path: ["vesting_contract", "vesting_contract", "VestingProgramErrors"],
//       },
//     },
//     {
//       id: 10,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 11,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 6,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 11,
//           },
//           {
//             name: "E",
//             type: 6,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 11,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 3,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 9,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 3,
//           },
//           {
//             name: "E",
//             type: 9,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 12,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 3,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 6,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 3,
//           },
//           {
//             name: "E",
//             type: 6,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//   ],
//   version: "4",
// };
