import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { customToFixed, fixedNumber } from "../../Utils/functions/globalFunctions";
import Wrapper from "../Helpers/Wrapper";

import { Modal, Box, Typography, Paper, Button, Tooltip, CircularProgress } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { StyledTextField } from "../../Style/general";
import TouButton from "../Buttons/TouButton";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  textAlign: "center",
  maxWidth: "450px",
  // minWidth: "350px",
  width: "100%",
  height: "auto",
  color: "white",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
  "@media(max-width:768px)": {
    width: "90%",
  },
};

const SwapModal = ({
  openSwap,
  handleSwapClose,
  msgBoolean,
  swapTokens,
  priceImpact,
  pairFee,
  loader,
  txnFailed,
  cancelled,
  txnMsg,
}) => {
  const { tokens } = useSelector((state) => state); //get tokens state from redux
  const { swap_from_input } = useSelector((state) => state.inputs); //get inputs state from redux
  const { swap_to_input } = useSelector((state) => state.inputs); //get inputs state from redux
  const { account } = useSelector((state) => state); //get account state from redux

  const [fromToken, setFromToken] = useState(tokens.from_token);
  const [toToken, setToToken] = useState(tokens.to_token);

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    let fToken = tokens?.from_token;
    setFromToken(fToken);
    let tToken = tokens?.to_token;
    setToToken(tToken);
  }, [tokens]);

  useEffect(() => {
    priceImpact > 70 ? setDisabled(true) : setDisabled(false);
  }, [openSwap, priceImpact]);

  const handleSwap = async () => {
    await swapTokens();
  };

  return (
    <Wrapper>
      <Modal open={openSwap} onClose={handleSwapClose}>
        {/* Swap dashboard paper */}

        <Paper elevation={5} sx={style}>
          {!loader ? (
            <Box>
              <Box sx={{ cursor: "pointer", position: "absolute", right: "10px", top: "10px" }}>
                <CloseIcon onClick={handleSwapClose} />
              </Box>
              <Box className="swapInput" sx={{ display: "flex", flexDirection: "column", position: "relative" }}>
                {/* token you swap information */}
                <div
                  className="swapInput__bottom"
                  style={{ marginTop: "20px", borderRadius: "10px", display: "flex", background: "#26262c" }}
                >
                  <StyledTextField value={swap_from_input} sx={{ width: "65%" }} disabled />
                  <Button variant="choose_token_btn" disabled>
                    <img src={fromToken?.icon} alt="" />
                    <span>{fromToken?.symbol}</span>
                  </Button>
                </div>
              </Box>
              {/* Change swap direction button */}
              <Box sx={{ position: "absolute", left: "50%", transform: "translate(-50%,-50%)", zIndex: "999" }}>
                <Button variant="direction_btn" sx={{ marginInline: "auto", width: "35px", height: "35px" }}>
                  <ArrowDownwardIcon fontSize="small" />
                </Button>
              </Box>
              <Box className="swapInput" sx={{ display: "flex", flexDirection: "column", marginTop: "5px" }}>
                {/* token you receive information */}
                <div
                  className="swapInput__bottom"
                  style={{ marginTop: "0px", borderRadius: "10px", display: "flex", background: "#26262c" }}
                >
                  <StyledTextField value={swap_to_input} sx={{ width: "65%" }} disabled />
                  <Button variant="choose_token_btn" disabled>
                    <img src={toToken?.icon} alt="" />
                    <span>{toToken?.symbol}</span>
                  </Button>
                </div>
              </Box>{" "}
              <Paper
                elevation={0}
                sx={{ padding: "10px ", borderRadius: "10px", background: "#33333b", marginTop: "20px" }}
              >
                {/* Expected Output */}
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    Expected Output
                    <Tooltip title="The expected token amount you will receive in this transaction." placement="top">
                      <HelpOutlineIcon fontSize="small" sx={{ marginLeft: "5px" }} />
                    </Tooltip>
                  </Typography>
                  <Typography sx={{ display: "flex", alignItems: "center", gap: "3px" }}>
                    {swap_to_input && swap_to_input}{" "}
                    <img src={toToken?.icon} width={15} style={{ borderRadius: "50%" }} alt="" />
                  </Typography>
                </Box>
                {/* price impact */}
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "5px" }}>
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    Price Impact
                    <Tooltip
                      title="The difference between the market price and estimated price due to trade size."
                      placement="top"
                    >
                      <HelpOutlineIcon fontSize="small" sx={{ marginLeft: "5px" }} />
                    </Tooltip>
                  </Typography>
                  <Typography sx={{ color: priceImpact < 61 ? "#00e676" : "#e84142" }}>
                    {customToFixed(priceImpact, 6)}%{" "}
                  </Typography>
                </Box>
              </Paper>
              <Paper
                elevation={0}
                sx={{ padding: "10px ", borderRadius: "10px", background: "#33333b", marginTop: "20px" }}
              >
                {/*Slippage*/}
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    Slippage
                    <Tooltip
                      title="Your transaction will revert if the price changes unfavorably by more than this percentage. Default is 0.5%."
                      placement="top"
                    >
                      <HelpOutlineIcon fontSize="small" sx={{ marginLeft: "5px" }} />
                    </Tooltip>
                  </Typography>
                  <Typography>{account?.slippage}%</Typography>
                </Box>

                {/*LP providers fee*/}
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "5px" }}>
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    LP providers fee
                    <Tooltip
                      title="LP providers fee is the amount which the swap deducts from output tokens amount to divert to the LP providers."
                      placement="top"
                    >
                      <HelpOutlineIcon fontSize="small" sx={{ marginLeft: "5px" }} />
                    </Tooltip>
                  </Typography>
                  <Typography>{pairFee}%</Typography>
                </Box>
              </Paper>
              {/* Terms of use */}
              <TouButton />
              {/* Confirm swap button */}
              <Button variant="swap_btn" onClick={handleSwap} sx={{ marginTop: "20px" }} disabled={disabled}>
                Confirm Swap
              </Button>{" "}
            </Box>
          ) : (
            <Box
              sx={{
                minWidth: "300px",
                minHeight: "150px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {msgBoolean ? (
                <Box>
                  <CircularProgress color="inherit" />
                  <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Transaction in progress...</Typography>
                </Box>
              ) : (
                <Box>
                  {!txnFailed ? (
                    <Box>
                      <CheckCircleOutlineIcon fontSize="large" />
                      <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Transaction completed!</Typography>
                    </Box>
                  ) : (
                    <Box>
                      <HighlightOffIcon fontSize="large" sx={{ color: "#e84142" }} />
                      {cancelled ? (
                        <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Transaction Cancelled!</Typography>
                      ) : (
                        <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Transaction Failed!</Typography>
                      )}
                      {cancelled ? (
                        <></>
                      ) : (
                        <Typography sx={{ fontSize: "14px", opacity: "0.5" }} component="p">
                          {txnMsg}{" "}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Paper>
      </Modal>
    </Wrapper>
  );
};

export default SwapModal;
