import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { set_pool_top_input, set_pool_withdraw_input } from "../../features/inputs";

import Wrapper from "../Helpers/Wrapper";
import { StyledTextField } from "../../Style/general";

import { BoxInputTitle, InputBoxBottom, InputBoxTop, InputBoxWrapper } from "../../Style/Components/inputs";
import {
  checkIfNumIsExp,
  customToFixed,
  fixedNumber,
  getLimitedInput,
  numberWithCommas,
  preventLetters,
} from "../../Utils/functions/globalFunctions";
import { Box, Button } from "@mui/material";
import { PoolPercentageBtnBox } from "../../Style/Components/tabs";
import fromExponential from "from-exponential";

const PoolTokensInput = ({ getPooledTokensAmount, userPoolTokens }) => {
  const dispatch = useDispatch();
  const { pool_tokens_balance } = useSelector((state) => state.account); // get user pool tokens amount from account state
  const { pool_withdraw_input } = useSelector((state) => state.inputs); // get user pool tokens amount from account state
  const [inputValue, setInputValue] = useState(); //input value

  const handleFromValue = (e) => {
    if (e.target) {
      if (e.target.value) {
        // if (getLimitedInput(e.target.value)) {
        setInputValue(e.target.value);
        // }
      } else {
        setInputValue("");
      }
    } else {
      let val;
      if (e === "25%") {
        val = pool_tokens_balance / 4;
      } else if (e === "50%") {
        val = pool_tokens_balance / 2;
      } else if (e === "75%") {
        val = (pool_tokens_balance / 4) * 3;
      } else if (e === "100%") {
        val = pool_tokens_balance;
      }
      val = checkIfNumIsExp(pool_tokens_balance) ? fromExponential(val) : val;
      setInputValue(val);
    }
  };

  const getUserTokenBalance = () => {
    if (userPoolTokens) {
      return <div className="balance">Balance: {numberWithCommas(userPoolTokens)}</div>;
    } else {
      return <div className="balance">Balance: 0</div>;
    }
  };

  // A functions that occurs when from_input is changed then dispatch the value to redux for external using.
  const dispatchWithdrawInputAmount = async (value) => {
    await dispatch(set_pool_withdraw_input(value));
  };

  useEffect(() => {
    inputValue ? dispatchWithdrawInputAmount(inputValue) : dispatchWithdrawInputAmount(null);
    getPooledTokensAmount(inputValue);
  }, [inputValue, userPoolTokens]);

  useEffect(() => {
    setInputValue(pool_withdraw_input);
  }, [pool_withdraw_input]);

  return (
    <Wrapper>
      {/* Top input for choosing what user will pay */}
      <InputBoxWrapper>
        <InputBoxTop>
          <BoxInputTitle fontSize="14px">LP tokens</BoxInputTitle>
          <div className="swapInput__top-right" style={{ display: "flex", flexDirection: "row" }}>
            {getUserTokenBalance()}
          </div>
        </InputBoxTop>

        <InputBoxBottom>
          <StyledTextField
            value={inputValue && inputValue}
            placeholder="0.00"
            type="number"
            onChange={(e) => handleFromValue(e)}
            onKeyDown={(evt) => preventLetters(evt)}
            Width="100%"
          />
        </InputBoxBottom>
        <PoolPercentageBtnBox>
          <Button variant="pool_percentage_btn" onClick={() => handleFromValue("25%")}>
            25%
          </Button>
          <Button variant="pool_percentage_btn" onClick={() => handleFromValue("50%")}>
            50%
          </Button>
          <Button variant="pool_percentage_btn" onClick={() => handleFromValue("75%")}>
            75%
          </Button>
          <Button variant="pool_percentage_btn" onClick={() => handleFromValue("100%")}>
            Max
          </Button>
        </PoolPercentageBtnBox>
      </InputBoxWrapper>
    </Wrapper>
  );
};

export default PoolTokensInput;
