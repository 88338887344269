import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ContractPromise } from "@polkadot/api-contract";
import { getMaxGasLimit } from "@scio-labs/use-inkathon";

import { insert_azero_balance, insert_psp22_balance, select_address } from "../../features/account";
import { abiPsp22Token } from "../../Utils/abi/abiPsp22Token";
import { preventLetters, ratioMultiplier } from "../../Utils/functions/globalFunctions";
import TokenBalance from "../Helpers/TokenBalance";
import { useLastInjector } from "../../hooks/useLastInjector";

import { colors } from "../../Style/themes";
import { HeroContainer, StyledTextField } from "../../Style/general";
import { InputBoxBottom } from "../../Style/Components/inputs";
import { CircularProgress, Paper, Typography, Box, Drawer, Button } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { errorHandler } from "../../Utils/functions/scErrorHandler";
import { getAzeroBalance, getPsp22TokenBalance } from "../../Utils/functions/contractsFunctions";

export default function TransferDrawer({ state, toggleDrawer, token, transferIsCompleted }) {
  const dispatch = useDispatch();
  const { lastInjector } = useLastInjector();

  const { network_token } = useSelector((state) => state.tokens);
  const { connectedNetwork } = useSelector((state) => state.account);
  const { address } = useSelector((state) => state.account);
  const { api } = useSelector((state) => state.contracts); // tokens list from redux

  const [isTransferCompleted, setIsTransferCompleted] = useState(false);
  const [transferring, setTransferring] = useState(false);
  const [recipient, setRecipient] = useState();
  const [transferAmount, setTransferAmount] = useState();

  const [disabled, setDisabled] = useState(true);
  const [recErrorMsg, setRecErrorMsg] = useState("");
  const [amountErrorMsg, setAmountErrorMsg] = useState("");
  const [transferMsg, setTransferMsg] = useState("");

  const txnUrl =
    connectedNetwork === "azero" ? "https://alephzero.subscan.io/account/" : "https://shibuya.subscan.io/account/";

  // Handle recipient value to send tokens to
  const handleRecipientValue = (e) => {
    setRecipient(e.target.value);
  };

  // Handle transfer amount input value
  const handleTransferValue = (e) => {
    setTransferAmount(e.target.value);
  };

  const chooseHalfBalance = async () => {
    setTransferAmount(
      token.token_address.toLowerCase() !== network_token?.address?.toLowerCase()
        ? (await getPsp22TokenBalance(api, token.token_address, address, network_token, connectedNetwork)) / 2
        : (await getAzeroBalance(api, address, connectedNetwork)) / 2
    );
  };
  const chooseMaxBalance = async () => {
    setTransferAmount(
      token.token_address.toLowerCase() !== network_token?.address?.toLowerCase()
        ? await getPsp22TokenBalance(api, token.token_address, address, network_token, connectedNetwork)
        : await getAzeroBalance(api, address, connectedNetwork)
    );
  };

  const transferInProgress = async () => {
    setTransferring(true);
    setIsTransferCompleted(false);
  };

  const transferCompleted = async (transferAmount) => {
    // let newBalance = token.token_balance - Number(transferAmount);
    transferIsCompleted();
    // token.token_address.toLowerCase() !== network_token?.address?.toLowerCase()
    //   ? dispatch(insert_psp22_balance(newBalance))
    //   : dispatch(insert_azero_balance(newBalance));
    setIsTransferCompleted(true);
    setTransferMsg(
      <Box>
        <img style={{ width: "50px", borderRadius: "50px" }} src={token?.token_img} />
        <Typography sx={{ marginBlock: "10px" }}>
          Transferred {transferAmount} {token?.token_symbol} to:{" "}
          <Typography>
            <a href={`${txnUrl}${recipient}`} style={{ textDecoration: "none", color: "inherit" }}>
              {recipient}
            </a>
          </Typography>
        </Typography>
        <Typography sx={{ fontSize: "22px" }}>Transfer completed!</Typography>
        <CheckCircleOutlineIcon fontSize="large" />
      </Box>
    );
    setTimeout(() => {
      setTransferring(false);
      setTransferAmount();
      setRecipient();
      setIsTransferCompleted(false);
      setTransferMsg();
    }, 5000);
  };

  const transferFailed = async (err) => {
    setIsTransferCompleted(true);
    setTransferMsg(
      <Box>
        <img style={{ width: "50px", borderRadius: "50px" }} src={token?.token_img} />
        <Typography sx={{ fontSize: "22px" }}>
          {err.toLowerCase() === "cancelled" ? "Transfer cancelled!" : "Transfer Failed!"}
        </Typography>
        <Typography sx={{ marginBlock: "10px" }}>
          Your transfer of {transferAmount} {token?.token_symbol} has failed.
        </Typography>
        <Typography>{err.toLowerCase() !== "Cancelled" && err}</Typography>
      </Box>
    );
    setTimeout(() => {
      setTransferring(false);
      setTransferAmount();
      setRecipient();
      setIsTransferCompleted(false);
      setTransferMsg();
    }, 5000);
  };

  // Transfer tokens main function
  const transferTokens = async () => {
    transferInProgress();
    let errorModule;
    let contract;
    let gas;
    let value = ratioMultiplier(transferAmount, connectedNetwork);

    if (api[0] && token.token_address.toLowerCase() !== network_token?.address?.toLowerCase()) {
      gas = getMaxGasLimit(api[0]);
      contract = new ContractPromise(api[0], abiPsp22Token, token.token_address);
    }
    if (token.token_address.toLowerCase() !== network_token?.address?.toLowerCase()) {
      await contract.query["psp22::transfer"](address[0], { gasLimit: gas }, recipient, value, []).then((res) => {
        gas = res.gasRequired;
        errorModule = res.output.toHuman().Ok;
        if (errorModule === "Ok") {
          gas = res.gasRequired;
        } else {
          transferFailed(errorHandler(errorModule.Err));
        }
      });

      try {
        errorModule === "Ok" &&
          (await contract.tx["psp22::transfer"]({ gasLimit: gas }, recipient, value, []).signAndSend(
            address[0],
            { signer: lastInjector.signer },
            ({ events = [], status }) => {
              if (status.isInBlock || status.isFinalized) {
                events
                  // find/filter for failed events
                  .filter(({ event }) => api[0].events.system.ExtrinsicFailed.is(event))
                  // we know that data for system.ExtrinsicFailed is
                  // (DispatchError, DispatchInfo)
                  .forEach(
                    ({
                      event: {
                        data: [error, info],
                      },
                    }) => {
                      if (error.isModule) {
                        // for module errors, we have the section indexed, lookup
                        const decoded = api[0].registry.findMetaError(error.asModule);
                        const { docs, method, section } = decoded;
                      } else {
                        // Other, CannotLookup, BadOrigin, no extra info
                        // console.log(error.toString());
                      }
                    }
                  );
              }

              if (status.isInBlock) {
              } else if (status.isFinalized) {
                // Loop through Vec<EventRecord> to display all events
                events.forEach(({ phase, event: { data, method, section } }) => {
                  if (method === "ExtrinsicSuccess") {
                    transferCompleted(transferAmount);
                  } else if (method === "ExtrinsicFailed") {
                    transferFailed();
                  }
                });
              }
            }
          ));
      } catch (err) {
        if (err.message.includes("Rejected")) {
          transferFailed(err.message);
        } else {
          transferFailed(err.message);
        }
      }
    } else {
      try {
        const transfer = api[0].tx.balances.transfer(recipient, value);
        // Sign and send the transaction using our account
        transfer &&
          (await transfer.signAndSend(address[0], { signer: lastInjector.signer }, ({ events = [], status }) => {
            if (status.isInBlock || status.isFinalized) {
              events
                // find/filter for failed events
                .filter(({ event }) => api[0].events.system.ExtrinsicFailed.is(event))
                // we know that data for system.ExtrinsicFailed is
                // (DispatchError, DispatchInfo)
                .forEach(
                  ({
                    event: {
                      data: [error, info],
                    },
                  }) => {
                    if (error.isModule) {
                      // for module errors, we have the section indexed, lookup
                      const decoded = api[0].registry.findMetaError(error.asModule);
                      const { docs, method, section } = decoded;
                      transferFailed(docs.join(""));
                    } else {
                      // Other, CannotLookup, BadOrigin, no extra info
                      // console.log(error.toString());
                    }
                  }
                );
            }

            if (status.isInBlock) {
            } else if (status.isFinalized) {
              // Loop through Vec<EventRecord> to display all events
              events.forEach(({ phase, event: { data, method, section } }) => {
                if (method === "ExtrinsicSuccess") {
                  transferCompleted(transferAmount);
                } else if (method === "ExtrinsicFailed") {
                  transferFailed();
                }
              });
            }
          }));
        // console.log("Transfer sent with hash", hash?.toHex());
      } catch (err) {
        if (err.message.includes("Rejected")) {
          transferFailed(err.message);
        } else {
          transferFailed(err.message);
        }
      }
    }
  };

  // disable or enable the button function
  const disableButton = () => {
    setRecErrorMsg("");
    setAmountErrorMsg("");

    // check for a valid recipient address
    if (recipient) {
      if (recipient.length > 9) {
        setDisabled(false);
        setRecErrorMsg("");
        if (!transferAmount) {
          setDisabled(true);
          setAmountErrorMsg("Transfer amount can't be empty.");
        }
      } else {
        setDisabled(true);
        recipient.length < 10 ? setRecErrorMsg("Recipient address is not valid.") : setRecErrorMsg("");
      }
    } else {
      setDisabled(true);
    }

    // check for a valid transferable amount
    if (transferAmount) {
      if (transferAmount > 0 && transferAmount <= token?.token_balance) {
        setAmountErrorMsg("");
        if (!recipient) {
          setDisabled(true);
          setRecErrorMsg("Recipient address can't be empty.");
        }
      } else {
        setDisabled(true);
        if (transferAmount > token?.token_balance) {
          setAmountErrorMsg("Insufficient transfer amount.");
        } else if (0 == transferAmount || 0 > transferAmount || !transferAmount) {
          setAmountErrorMsg("Transfer amount must be greater than 0.");
        }
      }
    }
  };

  useEffect(() => {
    disableButton();
  }, [recipient, transferAmount]);

  return (
    <React.Fragment key={"bottom"}>
      <Drawer anchor={"bottom"} open={state["bottom"]} onClose={toggleDrawer("bottom", false)}>
        {!transferring ? (
          <HeroContainer
            paddingtop={"40px"}
            paddingbottom={"50px"}
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img style={{ width: "50px", borderRadius: "50px" }} src={token?.token_img} />

            <Typography sx={{ marginBlock: "10px", fontSize: "26px" }}>
              Send {token?.token_symbol} to Panorama Swap users.
            </Typography>
            <Typography>Please choose the exact amount of tokens you would like to transfer.</Typography>
            <Typography>Be sure to insert the correct wallet address you want to transfer the tokens to.</Typography>
            <Box sx={{ marginTop: "30px", width: "400px" }}>
              <InputBoxBottom width="100%">
                <StyledTextField
                  value={recipient}
                  placeholder="Recipient's Wallet Address"
                  type="text"
                  onInput={(e) => handleRecipientValue(e)}
                  // onKeyDown={(evt) => preventLetters(evt)}
                  // disabled={currentPoolLp}
                  sx={{ width: "100%" }}
                />
              </InputBoxBottom>
            </Box>
            <Box sx={{ marginTop: "30px", width: "400px" }}>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", opacity: "0.8" }}>
                <Typography sx={{ marginRight: "5px", fontSize: "12px" }}>
                  Balance: <TokenBalance tokenAddress={token?.token_address} />
                </Typography>
                <Box>
                  <Button variant="balance_btn" onClick={chooseHalfBalance}>
                    half
                  </Button>
                  <Button variant="balance_btn" onClick={chooseMaxBalance} sx={{ marginLeft: "5px" }}>
                    max
                  </Button>
                </Box>
                {/*  */}
              </Box>
              <InputBoxBottom width="100%">
                <StyledTextField
                  value={transferAmount}
                  placeholder="Amount To Transfer"
                  type="number"
                  onInput={(e) => handleTransferValue(e)}
                  onKeyDown={(evt) => preventLetters(evt)}
                  // disabled={currentPoolLp}
                  sx={{ width: "100%" }}
                />
              </InputBoxBottom>
            </Box>
            <Box sx={{ width: "400px", textAlign: "left", marginTop: "20px" }}>
              <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: colors.red, marginBlock: "0px 10px" }}>
                {recErrorMsg}
              </Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: colors.red, marginBlock: "0px 10px" }}>
                {amountErrorMsg}
              </Typography>
              <Button variant="transfer" disabled={disabled} onClick={transferTokens}>
                Transfer {token?.token_symbol}
              </Button>
            </Box>
          </HeroContainer>
        ) : (
          <HeroContainer
            paddingtop={"40px"}
            paddingbottom={"50px"}
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {!isTransferCompleted ? (
              <Box>
                <img style={{ width: "50px", borderRadius: "50px" }} src={token?.token_img} />
                <Typography sx={{ marginBlock: "10px" }}>
                  Transferring {transferAmount} {token?.token_symbol} to:{" "}
                  <Typography>
                    <a
                      href={`${txnUrl}${recipient}`}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {recipient}
                    </a>
                  </Typography>
                </Typography>
                <Typography sx={{ fontSize: "18px" }}>Please wait for the transaction to complete.</Typography>
                <CircularProgress sx={{ marginTop: "10px" }} />
              </Box>
            ) : (
              <React.Fragment>{transferMsg}</React.Fragment>
            )}
          </HeroContainer>
        )}
      </Drawer>
    </React.Fragment>
  );
}
