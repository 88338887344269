import styled from "@emotion/styled";
import { Box, Container, Paper, TableRow, Typography } from "@mui/material";
import { colors } from "../themes";

// Top styled table row for pool table
export const TopStyledTableRow = styled(TableRow)(({ theme }) => ({
  "td, th": {
    border: 0,
    color: colors.gray,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingTop: 0,
  },
}));

//  Body styled table row for pool table
export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  td: {
    border: 0,
    paddingTop: "5px",
    paddingLeft: 0,
    "&:first-of-type": {
      borderTopLeftRadius: "10px",
      borderBottomLeftRadius: "10px",
    },
    "&:last-child": {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
}));

export const HeroContainer = styled(Container)(({ theme }) => ({
  maxWidth: "1400px !important",
  width: "100%",
  paddingTop: "40px",
  paddingLeft: "0px",
  paddingRight: "0px",
}));

export const HeroPaper = styled(Paper)(({ theme }) => ({
  background: "#00ccab",
  padding: "20px",
  borderRadius: "10px",
}));
export const HeroBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "10px",
}));

export const HeroTypography = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  fontWeight: "bold",
  letterSpacing: "0.5px",
  "@media(max-width:768px)": {
    fontSize: "22px",
  },
}));

export const DetailsContainer = styled(Container)(({ theme }) => ({
  maxWidth: "1400px !important",
  width: "100%",
  marginBlock: "20px",
  paddingLeft: "0px",
  paddingRight: "0px",
}));

export const DetailsBox = styled(Box)(({ theme, flexDirection }) => ({
  display: "flex",
  flexDirection: flexDirection,
  gap: "20px",
  marginTop: "20px",
}));

//  Account pool details box style
export const AccPoolDetailsBox = styled(Box)(({ theme, columns, marginTop }) => ({
  marginTop: marginTop,
  display: "grid",
  gridTemplateColumns: `repeat(${columns}, minmax(0px, 1fr));`,
  rowGap: "1rem",
  columnGap: "1rem",
  padding: "20px",
}));
