// **** AIRDROP ABI **** //
// **** lOCAL TESTING **** //
export const abiAirdrop = {
  source: {
    hash: "0x428da89b627d046e263422f4a425a4a436891b29853182be315a138d4f4cfcc5",
    language: "ink! 4.0.0",
    compiler: "rustc 1.65.0",
    build_info: {
      build_mode: "Debug",
      cargo_contract_version: "2.0.0-beta",
      rust_toolchain: "stable-aarch64-apple-darwin",
      wasm_opt_settings: {
        keep_debug_symbols: false,
        optimization_passes: "Z",
      },
    },
  },
  contract: {
    name: "airdrop_contract",
    version: "1.0.0",
    authors: ["Yonatan Hornstein <yonatan@panoramaswap.com>"],
  },
  spec: {
    constructors: [
      {
        args: [
          {
            label: "panx_contract",
            type: {
              displayName: ["AccountId"],
              type: 0,
            },
          },
        ],
        docs: [],
        label: "new",
        payable: false,
        returnType: {
          displayName: ["ink_primitives", "ConstructorResult"],
          type: 4,
        },
        selector: "0x9bae9d5e",
      },
    ],
    docs: [],
    events: [
      {
        args: [
          {
            docs: [],
            indexed: false,
            label: "caller",
            type: {
              displayName: ["AccountId"],
              type: 0,
            },
          },
        ],
        docs: [],
        label: "PanxClaim50",
      },
      {
        args: [
          {
            docs: [],
            indexed: false,
            label: "caller",
            type: {
              displayName: ["AccountId"],
              type: 0,
            },
          },
        ],
        docs: [],
        label: "PanxClaim500",
      },
    ],
    lang_error: {
      displayName: ["ink", "LangError"],
      type: 6,
    },
    messages: [
      {
        args: [],
        docs: ["function to collect 50 PANX"],
        label: "collect_50_tokens",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 7,
        },
        selector: "0xf7b579f7",
      },
      {
        args: [],
        docs: ["function to collect 500 PANX"],
        label: "collect_500_tokens",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 7,
        },
        selector: "0x783745ff",
      },
      {
        args: [],
        docs: ["funtion to get airdrop contract PANX reserve"],
        label: "get_airdrop_contract_panx_reserve",
        mutates: false,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 10,
        },
        selector: "0x3aa6a746",
      },
      {
        args: [
          {
            label: "account",
            type: {
              displayName: ["AccountId"],
              type: 0,
            },
          },
        ],
        docs: ["function to get account airdrop collection status"],
        label: "user_airdrop_collection_status",
        mutates: true,
        payable: false,
        returnType: {
          displayName: ["ink", "MessageResult"],
          type: 12,
        },
        selector: "0x84e93d32",
      },
    ],
  },
  storage: {
    root: {
      layout: {
        struct: {
          fields: [
            {
              layout: {
                leaf: {
                  key: "0x00000000",
                  ty: 0,
                },
              },
              name: "panx_psp22",
            },
            {
              layout: {
                root: {
                  layout: {
                    leaf: {
                      key: "0x7902e3db",
                      ty: 3,
                    },
                  },
                  root_key: "0x7902e3db",
                },
              },
              name: "collected_airdrop",
            },
          ],
          name: "AirdropContract",
        },
      },
      root_key: "0x00000000",
    },
  },
  types: [
    {
      id: 0,
      type: {
        def: {
          composite: {
            fields: [
              {
                type: 1,
                typeName: "[u8; 32]",
              },
            ],
          },
        },
        path: ["ink_primitives", "types", "AccountId"],
      },
    },
    {
      id: 1,
      type: {
        def: {
          array: {
            len: 32,
            type: 2,
          },
        },
      },
    },
    {
      id: 2,
      type: {
        def: {
          primitive: "u8",
        },
      },
    },
    {
      id: 3,
      type: {
        def: {
          primitive: "i64",
        },
      },
    },
    {
      id: 4,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 5,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 6,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 5,
          },
          {
            name: "E",
            type: 6,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 5,
      type: {
        def: {
          tuple: [],
        },
      },
    },
    {
      id: 6,
      type: {
        def: {
          variant: {
            variants: [
              {
                index: 1,
                name: "CouldNotReadInput",
              },
            ],
          },
        },
        path: ["ink_primitives", "LangError"],
      },
    },
    {
      id: 7,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 8,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 6,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 8,
          },
          {
            name: "E",
            type: 6,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 8,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 5,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 9,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 5,
          },
          {
            name: "E",
            type: 9,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 9,
      type: {
        def: {
          variant: {
            variants: [
              {
                index: 0,
                name: "CallerRedeemedAirdrop",
              },
              {
                index: 1,
                name: "Overflow",
              },
              {
                index: 2,
                name: "PSP22TransferFailed",
              },
            ],
          },
        },
        path: ["airdrop_contract", "airdrop_contract", "AirDropErrors"],
      },
    },
    {
      id: 10,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 11,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 6,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 11,
          },
          {
            name: "E",
            type: 6,
          },
        ],
        path: ["Result"],
      },
    },
    {
      id: 11,
      type: {
        def: {
          primitive: "u128",
        },
      },
    },
    {
      id: 12,
      type: {
        def: {
          variant: {
            variants: [
              {
                fields: [
                  {
                    type: 3,
                  },
                ],
                index: 0,
                name: "Ok",
              },
              {
                fields: [
                  {
                    type: 6,
                  },
                ],
                index: 1,
                name: "Err",
              },
            ],
          },
        },
        params: [
          {
            name: "T",
            type: 3,
          },
          {
            name: "E",
            type: 6,
          },
        ],
        path: ["Result"],
      },
    },
  ],
  version: "4",
};

// **** PRODUCTION **** //
// export const abiAirdrop = {
//   source: {
//     hash: "0x428da89b627d046e263422f4a425a4a436891b29853182be315a138d4f4cfcc5",
//     language: "ink! 4.0.0",
//     compiler: "rustc 1.65.0",
//     build_info: {
//       build_mode: "Debug",
//       cargo_contract_version: "2.0.0-beta",
//       rust_toolchain: "stable-aarch64-apple-darwin",
//       wasm_opt_settings: {
//         keep_debug_symbols: false,
//         optimization_passes: "Z",
//       },
//     },
//   },
//   contract: {
//     name: "airdrop_contract",
//     version: "1.0.0",
//     authors: ["Yonatan Hornstein <yonatan@panoramaswap.com>"],
//   },
//   spec: {
//     constructors: [
//       {
//         args: [
//           {
//             label: "panx_contract",
//             type: {
//               displayName: ["AccountId"],
//               type: 0,
//             },
//           },
//         ],
//         docs: [],
//         label: "new",
//         payable: false,
//         returnType: {
//           displayName: ["ink_primitives", "ConstructorResult"],
//           type: 4,
//         },
//         selector: "0x9bae9d5e",
//       },
//     ],
//     docs: [],
//     events: [
//       {
//         args: [
//           {
//             docs: [],
//             indexed: false,
//             label: "caller",
//             type: {
//               displayName: ["AccountId"],
//               type: 0,
//             },
//           },
//         ],
//         docs: [],
//         label: "PanxClaim50",
//       },
//       {
//         args: [
//           {
//             docs: [],
//             indexed: false,
//             label: "caller",
//             type: {
//               displayName: ["AccountId"],
//               type: 0,
//             },
//           },
//         ],
//         docs: [],
//         label: "PanxClaim500",
//       },
//     ],
//     lang_error: {
//       displayName: ["ink", "LangError"],
//       type: 6,
//     },
//     messages: [
//       {
//         args: [],
//         docs: ["function to collect 50 PANX"],
//         label: "collect_50_tokens",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 7,
//         },
//         selector: "0xf7b579f7",
//       },
//       {
//         args: [],
//         docs: ["function to collect 500 PANX"],
//         label: "collect_500_tokens",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 7,
//         },
//         selector: "0x783745ff",
//       },
//       {
//         args: [],
//         docs: ["funtion to get airdrop contract PANX reserve"],
//         label: "get_airdrop_contract_panx_reserve",
//         mutates: false,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 10,
//         },
//         selector: "0x3aa6a746",
//       },
//       {
//         args: [
//           {
//             label: "account",
//             type: {
//               displayName: ["AccountId"],
//               type: 0,
//             },
//           },
//         ],
//         docs: ["function to get account airdrop collection status"],
//         label: "user_airdrop_collection_status",
//         mutates: true,
//         payable: false,
//         returnType: {
//           displayName: ["ink", "MessageResult"],
//           type: 12,
//         },
//         selector: "0x84e93d32",
//       },
//     ],
//   },
//   storage: {
//     root: {
//       layout: {
//         struct: {
//           fields: [
//             {
//               layout: {
//                 leaf: {
//                   key: "0x00000000",
//                   ty: 0,
//                 },
//               },
//               name: "panx_psp22",
//             },
//             {
//               layout: {
//                 root: {
//                   layout: {
//                     leaf: {
//                       key: "0x7902e3db",
//                       ty: 3,
//                     },
//                   },
//                   root_key: "0x7902e3db",
//                 },
//               },
//               name: "collected_airdrop",
//             },
//           ],
//           name: "AirdropContract",
//         },
//       },
//       root_key: "0x00000000",
//     },
//   },
//   types: [
//     {
//       id: 0,
//       type: {
//         def: {
//           composite: {
//             fields: [
//               {
//                 type: 1,
//                 typeName: "[u8; 32]",
//               },
//             ],
//           },
//         },
//         path: ["ink_primitives", "types", "AccountId"],
//       },
//     },
//     {
//       id: 1,
//       type: {
//         def: {
//           array: {
//             len: 32,
//             type: 2,
//           },
//         },
//       },
//     },
//     {
//       id: 2,
//       type: {
//         def: {
//           primitive: "u8",
//         },
//       },
//     },
//     {
//       id: 3,
//       type: {
//         def: {
//           primitive: "i64",
//         },
//       },
//     },
//     {
//       id: 4,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 5,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 6,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 5,
//           },
//           {
//             name: "E",
//             type: 6,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 5,
//       type: {
//         def: {
//           tuple: [],
//         },
//       },
//     },
//     {
//       id: 6,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 index: 1,
//                 name: "CouldNotReadInput",
//               },
//             ],
//           },
//         },
//         path: ["ink_primitives", "LangError"],
//       },
//     },
//     {
//       id: 7,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 8,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 6,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 8,
//           },
//           {
//             name: "E",
//             type: 6,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 8,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 5,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 9,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 5,
//           },
//           {
//             name: "E",
//             type: 9,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 9,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 index: 0,
//                 name: "CallerRedeemedAirdrop",
//               },
//               {
//                 index: 1,
//                 name: "Overflow",
//               },
//               {
//                 index: 2,
//                 name: "PSP22TransferFailed",
//               },
//             ],
//           },
//         },
//         path: ["airdrop_contract", "airdrop_contract", "AirDropErrors"],
//       },
//     },
//     {
//       id: 10,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 11,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 6,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 11,
//           },
//           {
//             name: "E",
//             type: 6,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//     {
//       id: 11,
//       type: {
//         def: {
//           primitive: "u128",
//         },
//       },
//     },
//     {
//       id: 12,
//       type: {
//         def: {
//           variant: {
//             variants: [
//               {
//                 fields: [
//                   {
//                     type: 3,
//                   },
//                 ],
//                 index: 0,
//                 name: "Ok",
//               },
//               {
//                 fields: [
//                   {
//                     type: 6,
//                   },
//                 ],
//                 index: 1,
//                 name: "Err",
//               },
//             ],
//           },
//         },
//         params: [
//           {
//             name: "T",
//             type: 3,
//           },
//           {
//             name: "E",
//             type: 6,
//           },
//         ],
//         path: ["Result"],
//       },
//     },
//   ],
//   version: "4",
// };
