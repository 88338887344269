import React, { useState, useEffect } from "react";
import fromExponential from "from-exponential";
import { useSelector, useDispatch } from "react-redux";
import { from_token } from "../../features/tokens";
import { set_pool_top_input } from "../../features/inputs";

import Wrapper from "../Helpers/Wrapper";
import { StyledTextField } from "../../Style/general";
import { Box, Button, Skeleton, Typography } from "@mui/material";
import TokensDialog from "../Dialogs/TokensDialog";
import { BoxInputTitle, InputBoxBottom, InputBoxTop, InputBoxWrapper } from "../../Style/Components/inputs";
import {
  checkIfNumIsExp,
  customToFixed,
  fixedNumber,
  getLimitedInput,
  numberWithCommas,
  preventLetters,
} from "../../Utils/functions/globalFunctions";
import { PoolPercentageBtnBox } from "../../Style/Components/tabs";
import TokenBalance from "../Helpers/TokenBalance";
import { makeDummyTransfer, getPsp22TokenBalance, getAzeroBalance } from "../../Utils/functions/contractsFunctions";

const PoolTopInput = ({ token }) => {
  const dispatch = useDispatch();

  const { network_token } = useSelector((state) => state.tokens);
  const { api } = useSelector((state) => state.contracts); //get tokens state from redux
  const { address } = useSelector((state) => state.account); // get account state from redux
  const { connectedNetwork } = useSelector((state) => state.account); // get account state from redux
  const { azero_balance } = useSelector((state) => state.account); // get account state from redux
  const { pool_top_input } = useSelector((state) => state.inputs); // get pool top input from redaux

  const [inputValue, setInputValue] = useState(); //input value
  // const [currentToken, setToken] = useState(token); //token

  const handleFromValue = async (e) => {
    let balance;
    let val;

    if (e.target) {
      if (e.target.value) {
        if (getLimitedInput(e.target.value)) {
          setInputValue(e.target.value);
        }
      } else {
        setInputValue("");
      }
    } else {
      balance =
        token.address.toLowerCase() !== network_token?.address?.toLowerCase()
          ? await getPsp22TokenBalance(api, token.address, address, network_token, connectedNetwork)
          : await getAzeroBalance(api, address, connectedNetwork);
      if (e === "25%") {
        val = balance / 4;
      } else if (e === "50%") {
        val = balance / 2;
      } else if (e === "75%") {
        val = (balance / 4) * 3;
      } else if (e === "100%") {
        val =
          token.address.toLowerCase() !== network_token?.address?.toLowerCase()
            ? balance
            : await makeDummyTransfer(balance, api[0], address[0], Number(balance) > 1 ? false : true);
      }
      val = checkIfNumIsExp(balance) ? fromExponential(val) : val;
      setInputValue(customToFixed(val, 0));
      // dispatchTopInputAmount(val);1
    }
  };

  // A functions that occurs when from_input is changed then dispatch the value to redux for external using.
  const dispatchTopInputAmount = async (value) => {
    await dispatch(set_pool_top_input(value));
  };

  useEffect(() => {
    dispatchTopInputAmount(inputValue);
  }, [inputValue]);

  useEffect(() => {
    setInputValue(pool_top_input);
  }, [pool_top_input]);

  return (
    <Wrapper>
      {/* Top input for choosing what user will pay */}
      <InputBoxWrapper>
        <InputBoxTop>
          <BoxInputTitle fontSize="12px">input</BoxInputTitle>
          <Box sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
            <Typography sx={{ marginRight: "3px", fontSize: "14px" }}>Balance:</Typography>
            <TokenBalance tokenAddress={token?.address} />
          </Box>
        </InputBoxTop>

        <InputBoxBottom>
          <StyledTextField
            value={inputValue}
            placeholder="0.00"
            type="number"
            onChange={(e) => handleFromValue(e)}
            onKeyDown={(evt) => preventLetters(evt)}
          />
          <Button variant="choose_token_btn" disabled>
            <img src={token?.icon} alt="" />
            <span>{token?.symbol}</span>
          </Button>
        </InputBoxBottom>

        <PoolPercentageBtnBox>
          <Button variant="pool_percentage_btn" onClick={() => handleFromValue("25%")}>
            25%
          </Button>
          <Button variant="pool_percentage_btn" onClick={() => handleFromValue("50%")}>
            50%
          </Button>
          <Button variant="pool_percentage_btn" onClick={() => handleFromValue("75%")}>
            75%
          </Button>
          <Button variant="pool_percentage_btn" onClick={() => handleFromValue("100%")}>
            Max
          </Button>
        </PoolPercentageBtnBox>
      </InputBoxWrapper>
    </Wrapper>
  );
};

export default PoolTopInput;
