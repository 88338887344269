import { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";

function useJwtExpiration(jwtToken) {
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const decodedJwt = jwtDecode(jwtToken);

    if (decodedJwt.exp < Date.now() / 1000) {
      setIsExpired(true);
    } else {
      setIsExpired(false);
    }
  }, [jwtToken]);

  return isExpired;
}

export default useJwtExpiration;
