import { Box, Button } from "@mui/material";
import React, { useEffect } from "react";
import Wrapper from "../Helpers/Wrapper";

const CustomTableFooter = ({ range, setPage, page, slice }) => {
  useEffect(() => {
    if (slice.length < 1 && page !== 1) {
      setPage(page - 1);
    }
  }, [slice, page, setPage]);
  return (
    <Wrapper>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          borderTop: "1px solid rgba(255, 255, 255, .1)",
          paddingTop: "10px",
        }}
      >
        {range.map((el, index) => (
          <Button variant="pagination" key={index} onClick={() => setPage(el)} disabled={el === page ? true : false}>
            {el}
          </Button>
        ))}
      </Box>
    </Wrapper>
  );
};

export default CustomTableFooter;
