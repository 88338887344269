import { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabel,
  Typography,
  Skeleton,
} from "@mui/material";
import { ContractPromise } from "@polkadot/api-contract";
import { getMaxGasLimit } from "@scio-labs/use-inkathon";
import { checkIfNumIsExp, customToFixed, numberWithCommas } from "../../Utils/functions/globalFunctions";
import { abiPsp22Token } from "../../Utils/abi/abiPsp22Token";
import CustomTableFooter from "./CustomTableFooter";
import useTable from "../../hooks/useTable";
import { StyledTableRow, TopStyledTableRow } from "../../Style/Tables/AllPoolsTable";
import { useNavigate } from "react-router-dom";
import { getRatio } from "../../Utils/constants";
import fromExponential from "from-exponential";
import { getAzeroBalance, getPsp22TokenBalance } from "../../Utils/functions/contractsFunctions";
import Wrapper from "../Helpers/Wrapper";
import TransferDrawer from "../Drawer/TransferDrawer";
import { getAllTokens } from "../../shared/api";
import { set_tokens } from "../../features/tokens";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "token_name",
    label: "Token Name",
  },
  {
    id: "token_symbol",
    label: "Token Symbol",
  },
  {
    id: "token_balance",
    label: "Balance Amount",
  },
  {
    id: "transfer",
    label: "",
  },
];

// Assets table head build function
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TopStyledTableRow sx={{ border: 0 }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            // padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TopStyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

// Assets table main function
export default function EnhancedTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { network_token } = useSelector((state) => state.tokens);
  const { connectedNetwork } = useSelector((state) => state.account);
  const { address } = useSelector((state) => state.account);
  const { azero_balance } = useSelector((state) => state.account);
  const { psp22_balance } = useSelector((state) => state.account);
  const { api } = useSelector((state) => state.contracts);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("amount");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [newTokensArr, setNewTokensArr] = useState([]);
  const [transferCompleted, setTransferCompleted] = useState(false);

  const { slice, range } = useTable(newTokensArr, page, rowsPerPage);

  const [tokens, setTokens] = useState();
  const [tokenToTransfer, setTokenToTransfer] = useState({});
  const [state, setState] = useState({
    bottom: false,
  });

  const demoArray = ["", "", "", ""];

  const transferIsCompleted = () => {
    setTransferCompleted(true);
  };
  const toggleDrawer = (anchor, open, row) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setTokenToTransfer(row);
    setState({ ...state, [anchor]: open });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getTokensListByNetwork = useCallback(async () => {
    getAllTokens(connectedNetwork).then((res) => {
      setTokens(res.data.data);
    });
  }, [connectedNetwork]);

  useEffect(() => {
    connectedNetwork && getTokensListByNetwork();
  }, [connectedNetwork]);

  const makeNewTokensArr = useCallback(async () => {
    setNewTokensArr([]);

    let newArr = [];
    let tokenObj = {
      token_img: "",
      token_name: "",
      token_symbol: "",
      token_balance: "",
    };
    for (let i = 0; i < tokens?.length; i++) {
      tokenObj = {
        token_img: tokens[i].icon,
        token_name: tokens[i].name.toUpperCase(),
        token_symbol: tokens[i].symbol.toUpperCase(),
        token_balance:
          tokens[i].address.toLowerCase() !== network_token?.address?.toLowerCase()
            ? await getPsp22TokenBalance(api, tokens[i].address, address, network_token, connectedNetwork)
            : await getAzeroBalance(api, address, connectedNetwork),
        token_address: tokens[i].address,
      };
      newArr.push(tokenObj);
    }
    setNewTokensArr(newArr);
  }, [address, tokens, transferCompleted]);

  useEffect(() => {
    if (connectedNetwork && network_token) makeNewTokensArr();
  }, [address, tokens, transferCompleted]);

  return (
    <Wrapper>
      <Box sx={{ width: "100%" }}>
        {slice.length > 0 ? (
          <TableContainer
            sx={{
              marginBlock: "20px",
              display: "block",
              whiteSpace: "nowrap",
              overflow: "auto hidden",
              maxWidth: "100%",
            }}
          >
            <Table aria-labelledby="tableTitle">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={tokens.length}
              />
              <TableBody>
                {stableSort(slice, getComparator(order, orderBy)).map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow hover tabIndex={-1} key={row.token_name + index}>
                      <TableCell id={labelId} scope="row">
                        <Box sx={{ display: "flex", alignItems: "center", gap: "2px" }}>
                          {" "}
                          <img
                            src={row.token_img}
                            alt=""
                            style={{ width: "20px", height: "20px", borderRadius: "50%", marginRight: "1.5px" }}
                          />{" "}
                          {row.token_name}
                        </Box>
                      </TableCell>
                      <TableCell>{row.token_symbol}</TableCell>
                      <TableCell>
                        {row.token_balance ? numberWithCommas(fromExponential(row.token_balance)) : "0.0000"}
                      </TableCell>
                      <TableCell sx={{ alignItems: "center" }}>
                        <Button variant="transfer_tokens" onClick={toggleDrawer("bottom", true, row)}>
                          <Typography>transfer</Typography>
                        </Button>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>

            <CustomTableFooter range={range} slice={slice} setPage={setPage} page={page} />
          </TableContainer>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", marginBlock: "50px" }}>
            <Table aria-labelledby="tableTitle">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={tokens?.length}
              />
              <TableBody>
                {demoArray.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow hover tabIndex={-1} key={index}>
                      <TableCell id={labelId} scope="row">
                        <Skeleton SX={{ width: "10px" }} />
                      </TableCell>
                      <TableCell>
                        <Skeleton SX={{ width: "10px" }} />
                      </TableCell>
                      <TableCell>
                        <Skeleton SX={{ width: "10px" }} />
                      </TableCell>
                      <TableCell sx={{ alignItems: "center" }}>
                        <Skeleton SX={{ width: "10px" }} />
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        )}
        {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={tokens.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}

        {/* <Pagination count={tokens.length} /> */}
      </Box>

      <TransferDrawer
        state={state}
        toggleDrawer={toggleDrawer}
        token={tokenToTransfer}
        transferIsCompleted={transferIsCompleted}
      />
    </Wrapper>
  );
}
