import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { set_current_pair, set_from_token, set_to_token } from "../../features/tokens";
import { set_priceImpact, set_refresh } from "../../features/inputs";

import { Abi, ContractPromise } from "@polkadot/api-contract";
import { getMaxGasLimit } from "@scio-labs/use-inkathon";

import {
  checkIfNumIsExp,
  customToFixed,
  numberWithCommas,
  ratioMultiplier,
} from "../../Utils/functions/globalFunctions";
import { getPoolTotalSupply, getPriceForOnePsp22 } from "../../Utils/functions/contractsFunctions";
import { abiTradingPairAzero } from "../../Utils/abi/abiTradingPairAzero";
import { getRatio } from "../../Utils/constants";
import { getTokenPairByAddresses } from "../../shared/api";
import fromExponential from "from-exponential";

// My components
import Wrapper from "../../Components/Helpers/Wrapper";
import SwapFromInput from "../../Components/Inputs/SwapFromInput";
import SwapToInput from "../../Components/Inputs/SwapToInput";
import SwapButton from "../../Components/Buttons/SwapButton";
import ConnectWalletButton from "../../Components/Buttons/ConnectWalletButton";
import SlippageModal from "../../Components/Modals/SlippageModal";
import SlippageButton from "../../Components/Buttons/SlippageButton";

import { Button, Box, Typography, Tooltip } from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import RefreshIcon from "@mui/icons-material/Refresh";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SwapVertIcon from "@mui/icons-material/SwapVert";

import { Page, StyledRefreshButton } from "../../Style/general";
import { DashboardPaper, InfoBox, PriceInfoPaper, TradeContainer, TradeMainBox } from "../../Style/Pages/Trade";

const Trade = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { account } = useSelector((state) => state);
  const { connectedNetwork } = useSelector((state) => state.account);
  const { address } = useSelector((state) => state.account);
  const { slippage } = useSelector((state) => state.account);
  const { api } = useSelector((state) => state.contracts);
  const { tokens } = useSelector((state) => state);
  const { network_token } = useSelector((state) => state.tokens);
  const { from_token } = useSelector((state) => state.tokens);
  const { to_token } = useSelector((state) => state.tokens);
  const { swap_from_input } = useSelector((state) => state.inputs);
  const { swap_to_input } = useSelector((state) => state.inputs);
  const { refresh } = useSelector((state) => state.inputs);

  const [gas, setGas] = useState(null);
  const [singlePsp22Price, setSinglePsp22Price] = useState(0);
  const [currentPair, setCurrentPair] = useState({});
  const [currentContract, setCurrentContract] = useState();

  const [psp22Reserve, setPsp22Reserve] = useState();
  const [azeroReserve, setAzeroReserve] = useState();

  const [refreshing, setRefreshing] = useState(false);
  const [priceImpact, setPriceImpact] = useState(""); // price impact
  const [currentPoolLp, setCurrentPoolLp] = useState();

  // slippage modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (closed) => {
    setOpen(false);
  };

  // A function to get token pair
  const getTokenPair = useCallback(async () => {
    let pair = await getTokenPairByAddresses(from_token?.address, to_token?.address, connectedNetwork);
    pair.data.data[0] && dispatch(set_current_pair(pair.data.data[0]));
    let pairAddress = pair?.data?.data[0]?.pair_address;
    if (pairAddress && api[0]) {
      const gas = getMaxGasLimit(api[0]);
      // let abi = new Abi(abiTradingPairAzero, api[0].registry.getChainProperties());
      const contract = new ContractPromise(api[0], abiTradingPairAzero, pairAddress);
      setGas(gas);
      setCurrentContract(contract);
      setCurrentPair(pair.data.data[0]);
    }
  }, [api, from_token, to_token, connectedNetwork]);

  const getPsp22Balance = async (contract) => {
    let res = await contract?.query["getPsp22Balance"](address[0], { gasLimit: gas });
    let psp22ResBalance = res?.output?.toHuman()?.Ok?.replace(/,/g, "") / getRatio(connectedNetwork);
    setPsp22Reserve(psp22ResBalance);
  };

  const getAzeroBalance = async (contract) => {
    let res = await contract?.query["getA0Balance"](address[0], { gasLimit: gas });
    let azeroResBalance = res?.output?.toHuman()?.Ok?.replace(/,/g, "") / getRatio(connectedNetwork);
    setAzeroReserve(azeroResBalance);
  };

  // A function to get token PRICE IMPACT
  const getPriceImpact = async (contract) => {
    let value = ratioMultiplier(swap_from_input, connectedNetwork);

    let queryName =
      from_token?.address?.toLowerCase() === network_token.address.toLowerCase()
        ? "getPriceImpactA0ToPsp22"
        : "getPriceImpactPsp22ToA0";

    let res = swap_from_input > 0 && (await contract?.query[queryName](address[0], { gasLimit: gas }, value));
    let number = res?.output?.toHuman().Ok.Ok.replace(/,/g, "");

    let pImpact = number / getRatio(connectedNetwork);
    let pImpactToShow = Math.abs(swap_to_input - pImpact);
    pImpactToShow = (Number(pImpactToShow) / ((Number(swap_to_input) + pImpact) / 2)) * 100;
    setPriceImpact(pImpactToShow);
    dispatch(set_priceImpact(pImpactToShow));
  };

  // A function to check if there is a user logged in and a value inserted to the SWAP FROM INPUT
  const isLoggedAndValue = () => {
    if (swap_to_input > 0 && account.address[0] && !isNaN(priceImpact)) {
      return true;
    } else {
      return false;
    }
  };

  // Get swap button or choose account button
  const buildSwapButton = () => {
    if (!currentPoolLp) {
      if (account.address.length > 0 && account.address[0] !== undefined) {
        return <SwapButton variant="swap_btn" pairFee={currentPair.pair_fee} />;
      } else {
        return <ConnectWalletButton variant="swap_btn" />;
      }
    } else {
      return (
        <Box sx={{ padding: "15px 5px 0px 5px" }}>
          <Typography>Oops, the Pool is empty!</Typography>
          <Typography>You need to add liquidity first so people will be able to swap tokens.</Typography>
          <Button
            variant="contained"
            sx={{ width: "100%", marginTop: "10px" }}
            onClick={() => {
              navigate(`/liquidity/${currentPair.pair_address}`);
            }}
          >
            Manage pool{" "}
          </Button>
        </Box>
      );
    }
  };

  const makeRefresh = () => {
    setRefreshing(true);
    dispatch(set_refresh(true));
    setTimeout(() => {
      setRefreshing(false);
      dispatch(set_refresh(false));
    }, 2000);
  };

  const getPsp22Price = async (contract) => {
    let price = await getPriceForOnePsp22(contract, address[0], gas, connectedNetwork);
    setSinglePsp22Price(price);
  };

  useEffect(() => {
    getTokenPair();
  }, [api, from_token, to_token, connectedNetwork]);

  const getCurrentPoolSupply = async () => {
    const gasLimit = getMaxGasLimit(api[0]);

    let { total_supply: supply } = await getPoolTotalSupply(currentContract, address[0], gasLimit, connectedNetwork);
    supply > 0 ? setCurrentPoolLp(false) : setCurrentPoolLp(true);
  };

  useEffect(() => {
    if (currentContract) {
      getCurrentPoolSupply();
      getPsp22Balance(currentContract);
      getAzeroBalance(currentContract);
      getPsp22Price(currentContract);
    }
  }, [from_token, to_token, currentContract, refreshing, gas, connectedNetwork, refresh]);

  useEffect(() => {
    currentContract && getPriceImpact(currentContract);
    currentContract && getPsp22Price(currentContract);
  }, [swap_to_input, refreshing, refresh]);

  const swapInputs = () => {
    dispatch(set_from_token(to_token));
    dispatch(set_to_token(from_token));
  };

  return (
    <Wrapper>
      <Page elevation={0} sx={{ position: "relative" }}>
        <TradeContainer>
          <TradeMainBox>
            {/* Refresh and settings row */}
            <div className="setting_rows" style={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Button
                  onClick={() => {
                    navigate(`/liquidity/${currentPair.pair_address}`);
                  }}
                  variant="stats_btn"
                >
                  <BarChartIcon sx={{ color: "white" }} />
                </Button>
              </Box>
              <div className="two_buttons">
                {!refreshing ? (
                  <Button variant="refresh_btn" onClick={makeRefresh}>
                    <RefreshIcon
                      sx={{ color: "white", opacity: "0.5", transition: "all .3s ease", "&:hover": { opacity: "1" } }}
                    />
                  </Button>
                ) : (
                  <StyledRefreshButton variant="refresh_btn">
                    <RefreshIcon sx={{ color: "white" }} />
                  </StyledRefreshButton>
                )}

                <SlippageButton slippage={slippage} handleOpen={handleOpen} />
              </div>
            </div>

            {/* Swap dashboard paper */}
            <DashboardPaper elevation={5}>
              {/* From input component */}
              <SwapFromInput
                psp22Reserve={psp22Reserve}
                azeroReserve={azeroReserve}
                currentPoolLp={currentPoolLp}
                currentPair={currentPair}
                gas={gas}
              />

              {/* Change swap direction button */}
              {/* <SwapDirButton /> */}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="direction_btn"
                  sx={{ marginBlock: "20px 10px", marginInline: "auto" }}
                  onClick={swapInputs}
                >
                  <SwapVertIcon />
                </Button>
              </Box>

              {/* To input component */}
              <SwapToInput
                psp22Reserve={psp22Reserve}
                azeroReserve={azeroReserve}
                currentContract={currentContract}
                currentPair={currentPair}
                priceImpact={priceImpact}
                currentPoolLp={currentPoolLp}
                gas={gas}
              />

              {/* Swap/Approve/Connect button */}
              {buildSwapButton()}
            </DashboardPaper>

            {/* price info paper */}
            {isLoggedAndValue() && (
              <PriceInfoPaper elevation={5}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBlock: "10px",
                  }}
                >
                  <Box>
                    <Typography variant="trade_info_paper">
                      Price
                      <Tooltip
                        title="The current price value for the token you swap calculated to azero."
                        placement="top"
                      >
                        <HelpOutlineIcon fontSize="small" sx={{ marginLeft: "5px" }} />
                      </Tooltip>
                    </Typography>
                  </Box>
                  <Box>
                    {currentPair?.token_a_address?.toLowerCase() !== network_token?.address?.toLowerCase() ? (
                      <Typography
                        sx={{
                          "@media(max-width:992px)": {
                            fontSize: "12px",
                          },
                        }}
                      >
                        <Typography sx={{ textTransform: "uppercase" }}>
                          1 {currentPair?.token_a_symbol?.toUpperCase()} = {singlePsp22Price}{" "}
                          {currentPair?.token_b_symbol?.toUpperCase()}
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          "@media(max-width:992px)": {
                            fontSize: "12px",
                          },
                          "@media(max-width:768px)": {
                            fontSize: "14px",
                          },
                        }}
                      >
                        1 {currentPair?.token_b_symbol?.toUpperCase()} = {singlePsp22Price}{" "}
                        {currentPair?.token_a_symbol?.toUpperCase()}
                      </Typography>
                    )}
                  </Box>
                </Box>
                {priceImpact > 70 && (
                  <Typography sx={{ fontSize: "12px", marginBottom: "10px", color: "#e84142" }}>
                    Price impact too high. <br /> Please decrease the token amount you would like to trade.
                  </Typography>
                )}
                {/* Expected Output */}
                <InfoBox>
                  <Typography variant="trade_info_paper">
                    Estimated Output
                    <Tooltip title="The estimated token amount you will receive in this transaction." placement="top">
                      <HelpOutlineIcon fontSize="small" sx={{ marginLeft: "5px" }} />
                    </Tooltip>
                  </Typography>
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    {checkIfNumIsExp(Number(swap_to_input))
                      ? fromExponential(Number(Number(swap_to_input)))
                      : numberWithCommas(customToFixed(Number(swap_to_input), 6))}{" "}
                    <img
                      src={tokens?.to_token?.icon}
                      width={15}
                      style={{ borderRadius: "50%", marginLeft: "5px" }}
                      alt=""
                    />
                  </Typography>
                </InfoBox>
                {/* price impact */}
                <InfoBox margintop={"5px"}>
                  <Typography variant="trade_info_paper">
                    Price Impact
                    <Tooltip
                      title="The difference between the market price and estimated price due to trade size."
                      placement="top"
                    >
                      <HelpOutlineIcon fontSize="small" sx={{ marginLeft: "5px" }} />
                    </Tooltip>
                  </Typography>
                  <Typography sx={{ color: priceImpact < 61 ? "#00e676" : "#e84142" }}>
                    {customToFixed(priceImpact, 6)}%{" "}
                  </Typography>
                </InfoBox>
                {/*LP providers fee*/}
                <InfoBox margintop={"5px"}>
                  <Typography variant="trade_info_paper">
                    LP providers fee
                    <Tooltip
                      title="LP providers fee is the amount which the swap deducts from output tokens amount to divert to the LP providers."
                      placement="top"
                    >
                      <HelpOutlineIcon fontSize="small" sx={{ marginLeft: "5px" }} />
                    </Tooltip>
                  </Typography>
                  <Typography>{currentPair?.pair_fee}%</Typography>
                </InfoBox>
              </PriceInfoPaper>
            )}
          </TradeMainBox>
        </TradeContainer>
      </Page>

      {/* Slippage Modal */}
      <SlippageModal open={open} handleClose={handleClose} />
    </Wrapper>
  );
};

export default Trade;
