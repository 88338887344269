import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ContractPromise } from "@polkadot/api-contract";
import { getAllTokens, getTokenByAddress, getTokenPairsByAddress, insertToken } from "../../shared/api";
import { numberWithCommas } from "../../Utils/functions/globalFunctions";
import { abiPsp22Token } from "../../Utils/abi/abiPsp22Token";
import { set_filtered_tokens, set_tokens } from "../../features/tokens";
import PropTypes from "prop-types";

import {
  List,
  ListItemText,
  DialogTitle,
  Dialog,
  Divider,
  Box,
  Typography,
  ListItemButton,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";

import TokenBalance from "../Helpers/TokenBalance";
import { InputBoxBottom } from "../../Style/Components/inputs";
import { StyledTextField } from "../../Style/general";
import { TableWrapper } from "../../Style/Pages/Stats";
import { getMaxGasLimit } from "@scio-labs/use-inkathon";
import { convertLength } from "@mui/material/styles/cssUtils";
import { getRatio } from "../../Utils/constants";
import Cookies from "universal-cookie";

const TokensDialog = ({ onClose, tokenValue, open, tokenLetter, token, input }) => {
  const dispatch = useDispatch();
  const cookies = new Cookies();

  const { pathname } = useLocation();

  const { tokens } = useSelector((state) => state.tokens);
  const { filtered_tokens } = useSelector((state) => state.tokens);
  const { from_token } = useSelector((state) => state.tokens);
  const { to_token } = useSelector((state) => state.tokens);
  const { address } = useSelector((state) => state.account);
  const { connectedNetwork } = useSelector((state) => state.account);
  const { api } = useSelector((state) => state.contracts);

  const [allTokensArr, setAllTokensArr] = useState([]);
  const [filteredTokensArr, setFilteredTokensArr] = useState([]);
  const [missingDetailsMsg, setMissingDetailsMsg] = useState("");

  useEffect(() => {
    open && getTokensListBySecondToken();
    open && getAllTokensList();
  }, [open]);

  //  A function to get tokens by the second token chosen.
  const getTokensListBySecondToken = async () => {
    let dummyArr = [];
    let { address } = input === "from" ? to_token : input === "to" ? from_token : "";
    let { data: tokens } = await getTokenPairsByAddress(address, connectedNetwork);
    for (let token of tokens.data) {
      let test = await getTokenByAddress(
        address === token.token_a_address ? token.token_b_address : token.token_a_address,
        connectedNetwork
      );
      test = test.data.data[0];
      dummyArr.push(test);
    }

    const uniqueArr = Object.values(
      dummyArr.reduce((acc, obj) => {
        acc[obj.name.toLowerCase()] = obj;
        return acc;
      }, {})
    );

    setFilteredTokensArr(uniqueArr);
    dispatch(set_filtered_tokens(uniqueArr));
  };

  const getAllTokensList = async () => {
    let tokens = await getAllTokens(connectedNetwork);
    setAllTokensArr(tokens.data.data);
    dispatch(set_tokens(tokens.data.data));
  };

  const handleClose = () => {
    if (tokenValue && tokenLetter) {
      onClose(tokenValue, tokenLetter);
    } else {
      onClose();
    }
    setFilteredTokensArr();
    setAllTokensArr(tokens);
    setMissingDetailsMsg();
  };

  const handleListItemClick = (value) => {
    onClose(value);
    setAllTokensArr(tokens);
  };

  const buildNewToken = async (tokenAddress) => {
    let gasLimit;
    let contract;
    let newTokensArr = [];
    if (api[0]) {
      gasLimit = getMaxGasLimit(api[0]);
      contract = api[0] && new ContractPromise(api[0], abiPsp22Token, tokenAddress);
    }

    try {
      if (contract && address) {
        let tokenName = await contract?.query["psp22Metadata::tokenName"](address[0], { gasLimit });
        tokenName = tokenName?.output.toHuman().Ok;

        let tokenSymbol = await contract?.query["psp22Metadata::tokenSymbol"](address[0], { gasLimit });
        tokenSymbol = tokenSymbol?.output.toHuman().Ok;

        let tokenSupply = await contract?.query["psp22::totalSupply"](address[0], { gasLimit });
        tokenSupply = tokenSupply?.output.toHuman().Ok.replace(/,/g, "") / getRatio(connectedNetwork);

        let newToken = {
          name: tokenName,
          address: tokenAddress,
          symbol: tokenSymbol,
          total_supply: numberWithCommas(tokenSupply),
          icon: "https://i.imagesup.co/images2/880cf70ea1083553d8e3fe9b33ac07be0c466ee1.png",
        };

        if (tokenSupply > 0 && tokenName && tokenSymbol) {
          let token = await insertToken(newToken, cookies.get("accessToken"), connectedNetwork);

          token = token.data.data;
          if (token) {
            newTokensArr = await getAllTokens(connectedNetwork);
            setAllTokensArr(newTokensArr.data.data);
            dispatch(set_tokens(newTokensArr.data.data));
          }
        } else {
          setMissingDetailsMsg(
            <Box sx={{ marginTop: "10px" }}>
              <Typography>
                Please make sure that the token you are trying to import has a symbol and a name.{" "}
              </Typography>
            </Box>
          );
        }
      }
    } catch (err) {
      setMissingDetailsMsg(
        <Box sx={{ marginTop: "10px" }}>
          <Typography>
            Something went wrong.
            <br />
            Please try again later or contact us for support.{" "}
          </Typography>
        </Box>
      );
    }

    return newTokensArr;
  };

  // handle search filter when using the dialog.
  const handleSearchFilter = (e) => {
    let searchValue = e.target.value;
    let tokens_array = pathname === "/create-pool" ? tokens : filtered_tokens;

    if (searchValue.length > 0) {
      let filtered = [
        ...tokens_array.filter(
          (el) =>
            el.name.toLowerCase().startsWith(searchValue) ||
            el.symbol.toLowerCase().startsWith(searchValue) ||
            el.address === searchValue
        ),
      ];
      if (filtered.length > 0) {
        pathname === "/create-pool" ? setAllTokensArr(filtered) : setFilteredTokensArr(filtered);
      } else {
        pathname === "/create-pool" && buildNewToken(searchValue);
        pathname !== "/create-pool" && setFilteredTokensArr(filtered_tokens);
      }
    } else {
      setAllTokensArr(tokens);
      setFilteredTokensArr(filtered_tokens);
      setMissingDetailsMsg();
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <Box
        sx={{
          width: "450px",
          maxWidth: "100%",
        }}
      >
        <DialogTitle>Choose token to swap</DialogTitle>
        {/* Search pools bar */}
        <Box sx={{ paddingInline: "15px", marginBottom: "20px" }}>
          <InputBoxBottom width="100%" border margintop="0">
            <StyledTextField
              width="95%"
              // defaultValue={searchValue}
              placeholder="Search by name or address"
              type="text"
              onInput={(e) => handleSearchFilter(e)}
            />
            <SearchIcon sx={{ marginRight: "10px" }} />
          </InputBoxBottom>
          {missingDetailsMsg && missingDetailsMsg}
        </Box>

        <Divider />

        <TableWrapper customHeight="350px" radius="true">
          {pathname !== "/create-pool" && (
            <List>
              {filteredTokensArr?.length > 0 ? (
                filteredTokensArr?.map((token, i) => (
                  <ListItemButton
                    onClick={() => handleListItemClick(token)}
                    key={i}
                    sx={{ marginBottom: "10px", display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img src={token.icon} alt="" width={25} height={25} style={{ borderRadius: "50%" }} />
                      <StyledListItemText primary={token.symbol} />
                    </Box>
                    <Typography>
                      <TokenBalance tokenAddress={token?.address} token={token && token} />
                    </Typography>{" "}
                  </ListItemButton>
                ))
              ) : (
                <Box sx={{ padding: "20px", display: "flex", flexDirection: "column", gap: "20px" }}>
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Skeleton variant="circular" width={25} height={25} />
                    <Skeleton sx={{ width: "100%" }} />
                  </Box>
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Skeleton variant="circular" width={25} height={25} />
                    <Skeleton sx={{ width: "100%" }} />
                  </Box>
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Skeleton variant="circular" width={25} height={25} />
                    <Skeleton sx={{ width: "100%" }} />
                  </Box>
                </Box>
              )}
            </List>
          )}

          {pathname === "/create-pool" && (
            <List>
              {allTokensArr?.length > 0 ? (
                allTokensArr?.map((token, i) => (
                  // <ListItemButton onClick={() => handleListItemClick(token)} key={i} sx={{ marginBottom: "10px" }}>
                  //   <img src={token.icon} alt="" width={35} style={{ borderRadius: "50%" }} />
                  //   <StyledListItemText primary={token.symbol} />
                  // </ListItemButton>

                  <ListItemButton
                    onClick={() => handleListItemClick(token)}
                    key={i}
                    sx={{ marginBottom: "10px", display: "flex", justifyContent: "space-between" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img src={token.icon} alt="" width={25} height={25} style={{ borderRadius: "50%" }} />
                      <StyledListItemText primary={token.symbol} />
                    </Box>
                    <Typography>
                      <TokenBalance tokenAddress={token?.address} token={token && token} />
                    </Typography>
                  </ListItemButton>
                ))
              ) : (
                <Box sx={{ padding: "20px", display: "flex", flexDirection: "column", gap: "20px" }}>
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Skeleton variant="circular" width={25} height={25} />
                    <Skeleton sx={{ width: "100%" }} />
                  </Box>
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Skeleton variant="circular" width={25} height={25} />
                    <Skeleton sx={{ width: "100%" }} />
                  </Box>
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Skeleton variant="circular" width={25} height={25} />
                    <Skeleton sx={{ width: "100%" }} />
                  </Box>
                </Box>
              )}
            </List>
          )}
        </TableWrapper>
      </Box>
    </Dialog>
  );
};

TokensDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // selectedValue: PropTypes.string.isRequired,
};

export default TokensDialog;

export const StyledListItemText = styled(ListItemText)(({ theme, cap }) => ({
  marginLeft: "10px",
  fontSize: "16px",
  textTransform: cap ? cap : "uppercase",
}));
