import React, { useEffect, useState } from "react";
import Wrapper from "../Helpers/Wrapper";

import { Box, CircularProgress, LinearProgress, List, ListItemButton, Modal, Paper, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { StyledListItemText } from "../Dialogs/TokensDialog";

import azero from "../../Images/icons/networks/azero.svg";
import shiden from "../../Images/icons/tokens/sdn-token.png";
import { colors } from "../../Style/themes";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  textAlign: "center",
  width: "400px",
  maxWidth: "90%",
  height: "auto",
  color: "white",
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
  p: 3,
};

const ChooseRpcUrlModal = ({
  handleClose,
  open,
  chooseRpcUrl,
  connecting,
  networkName,
  azeroNetworkDisabled,
  shidenNetworkDisabled,
}) => {
  const currentNetwork = localStorage.getItem("connectedNetwork");

  const networkClick = (network) => {
    console.log(shidenNetworkDisabled);
    chooseRpcUrl(network);
  };

  return (
    <Wrapper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper elevation={5} sx={style}>
          {currentNetwork && (
            <CloseIcon
              onClick={handleClose}
              sx={{ cursor: "pointer", position: "absolute", right: "10px", top: "10px" }}
            />
          )}
          <Box sx={{ marginBlock: "20px" }}>
            <Box>
              <Typography variant="h6">Please select the network</Typography>
            </Box>
            <List sx={{ display: "grid", gap: "20px", marginTop: "20px" }}>
              <ListItemButton
                onClick={() => networkClick("azero")}
                key="azero"
                disabled={azeroNetworkDisabled}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: `1px solid  #ffffff2e`,
                  height: "60px",
                  padding: "3px",
                }}
              >
                <img src={azero} alt="" style={{ width: "150px", height: "auto" }} />
                {/* <StyledListItemText cap="capitalize" /> */}
              </ListItemButton>
              <ListItemButton
                onClick={() => networkClick("shiden")}
                key="shiden"
                disabled={shidenNetworkDisabled}
                // disabled={true}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: `1px solid #ffffff2e `,
                  height: "60px",
                }}
              >
                <img src={shiden} alt="" style={{ width: "30px", height: "auto" }} />
                <Typography sx={{ letterSpacing: "1px" }}>SHIDEN NETWORK</Typography>
              </ListItemButton>
            </List>
            {connecting && (
              <Box
                sx={{ marginTop: "20px", display: "flex", flexDirection: "column", alignItems: "center", gap: "5px" }}
              >
                <LinearProgress sx={{ width: "60px" }} />
                <Typography>
                  Connecting to {networkName === "shiden" ? "Shiden Network" : "Aleph Zero Network"}
                </Typography>
              </Box>
            )}
          </Box>
        </Paper>
      </Modal>
    </Wrapper>
  );
};

export default ChooseRpcUrlModal;
