import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import fromExponential from "from-exponential";

import { getMaxGasLimit } from "@scio-labs/use-inkathon";
import { ContractPromise } from "@polkadot/api-contract";

import { getAllTxns, getTokenPairs } from "../../shared/api";
import useResize from "../../hooks/useResize";
import { checkIfNumIsExp, customToFixed, numberWithCommas } from "../../Utils/functions/globalFunctions";
import { getRatio } from "../../Utils/constants";
import { abiTradingPairAzero } from "../../Utils/abi/abiTradingPairAzero";

import Wrapper from "../../Components/Helpers/Wrapper";
import AllPoolsTable from "../../Components/Tables/AllPoolsTable";

import { Container, Box, Paper, Typography, Button } from "@mui/material";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import { HeroContainer, Page, StyledTextField } from "../../Style/general";
import pool from "../../Images/pool.png";

const Liquidity = () => {
  const navigate = useNavigate();
  const resize = useResize();

  const { address } = useSelector((state) => state.account);
  const { connectedNetwork } = useSelector((state) => state.account);
  const { api } = useSelector((state) => state.contracts);

  const [azeroTVL, setAzeroTVL] = useState(0);
  const [totalTxns, setTotalTxns] = useState(0);

  useEffect(() => {
    api[0] && getTotalA0TVL();
  }, [api, connectedNetwork]);

  const getTotalA0TVL = async () => {
    let arrayTVL = [];
    let pairs = await getTokenPairs(connectedNetwork);
    pairs = pairs.data.data;

    if (pairs.length > 0) {
      pairs.map(async (pair, index) => {
        const contract = new ContractPromise(api[0], abiTradingPairAzero, pair.pair_address);

        let azero = await getAzeroReserve(contract);
        arrayTVL.push(azero);
        const sum = arrayTVL.reduce((acc, val) => {
          const num = val > 0 ? customToFixed(val, 2) : 0;
          return acc + num;
        }, 0);

        setAzeroTVL(sum > 0 ? sum : 0);
      });

      let totalTxn = await getAllTxns(connectedNetwork);
      setTotalTxns(totalTxn.data.data.length);
    }
  };

  const getAzeroReserve = async (contract) => {
    const gasLimit = getMaxGasLimit(api[0]);

    try {
      let res = await contract?.query["getA0Balance"](address[0], { gasLimit });
      res = res.output.toHuman().Ok.replace(/,/g, "");

      let reserve = res / getRatio(connectedNetwork);

      reserve = checkIfNumIsExp(reserve) ? fromExponential(reserve) : reserve;
      return reserve > 1 ? numberWithCommas(customToFixed(reserve, 5)) : reserve;
    } catch (err) {}
  };

  return (
    <Wrapper>
      <Page elevation={0} sx={{ position: "relative" }}>
        <HeroContainer paddingtop="60px">
          <Paper sx={{ background: "#00ccab", padding: "5px 20px", borderRadius: "10px", position: "relative" }}>
            <Box>
              <Typography sx={{ fontSize: "50px", fontWeight: "bold", letterSpacing: "2px" }}>Pool</Typography>
              {/* <Button variant="contained"> Zap</Button> */}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
              <Typography>Provide liquidity and earn fees.</Typography>
            </Box>
            {resize > 992 && (
              <img
                src={pool}
                alt=""
                style={{
                  width: "250px",
                  position: "absolute",
                  top: "50%",
                  right: "10%",
                  transform: "translateY(-50%)",
                }}
              />
            )}
          </Paper>
        </HeroContainer>

        <Box>
          <HeroContainer>
            <Box
              sx={{
                display: "flex",
                // justifyContent: "space-between",
                alignItems: "center",
                marginBlock: "20px",
                gap: "20px",
                "@media(max-width:680px)": {
                  flexDirection: "column",
                  alignItems: "start",
                  gap: "10px",
                },
              }}
            >
              {/* Bottom box of the input */}
              <Box
                sx={{
                  // background: colors.torq,
                  borderBottom: "1px solid rgba(255, 255, 255, 0.16)",

                  padding: "5px 20px",
                  borderRadius: "10px",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  "@media(max-width:680px)": {
                    width: "100%",
                  },
                }}
              >
                <Typography sx={{ fontSize: "16px" }}>
                  {connectedNetwork.toUpperCase()} TVL: <span>{numberWithCommas(customToFixed(azeroTVL, 2))}</span>
                </Typography>
              </Box>
              <Box
                sx={{
                  // background: colors.torq,
                  borderBottom: "1px solid rgba(255, 255, 255, 0.16)",
                  padding: "5px 20px",
                  borderRadius: "10px",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  "@media(max-width:680px)": {
                    width: "100%",
                  },
                }}
              >
                <Typography sx={{ fontSize: "16px" }}>
                  Transactions: <span>{numberWithCommas(totalTxns)}</span>
                </Typography>
              </Box>
              <Button
                sx={{
                  width: "30%",
                  marginLeft: "auto",
                  "@media(max-width:680px)": {
                    width: "100%",
                  },
                }}
                variant="create_pool_btn"
                onClick={() => {
                  navigate(`/create-pool`);
                }}
                // disabled
              >
                Create a Pool <FiberNewIcon sx={{ marginLeft: "3px" }} />
              </Button>
            </Box>

            {/* Pools table */}
            <AllPoolsTable rowsPerPage={10} />

            {/* Sorted Pools table
            <PoolsTable rowsPerPage={10} /> */}
          </HeroContainer>
        </Box>
      </Page>
    </Wrapper>
  );
};

export default Liquidity;
