import { NightlyConnectAdapter } from '@nightlylabs/wallet-selector-polkadot'

let _adapter = null
export const getNightlyAdapter = () => {
  if (_adapter) return _adapter
  _adapter = NightlyConnectAdapter.buildLazy(
    {
      appMetadata: {
        name: 'NC TEST AlephZero',
        description: 'Nightly Connect Test',
        icon: 'https://docs.nightly.app/img/logo.png',
        additionalInfo: 'Courtesy of Nightly Connect team',
      },
      network: 'AlephZero',
    },
    true // should session be persisted
  )
  // Trigger connection
  return _adapter
}
// Load adapter on first import
getNightlyAdapter()
