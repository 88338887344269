import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Wrapper from "../Helpers/Wrapper";

import { Modal, Box, Typography, Paper, Button, Tooltip, CircularProgress } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { StyledTextField } from "../../Style/general";
import { checkIfNumIsExp, customToFixed, fixedNumber, numberWithCommas } from "../../Utils/functions/globalFunctions";
import TouButton from "../Buttons/TouButton";
import fromExponential from "from-exponential";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  textAlign: "center",
  width: "100%",
  maxWidth: "400px",
  height: "auto",
  color: "white",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
  "@media(max-width:768px)": {
    width: "90%",
  },
};

const AddToPoolModal = ({
  tokenA,
  tokenAValue,
  tokenB,
  tokenBValue,
  openAddToPoolModal,
  closeAddToPool,
  msgBoolean,
  loader,
  addFailed,
  addTokensToPool,
  expectedLpTokens,
  cancelled,
  txnMsg,
}) => {
  const { tokens } = useSelector((state) => state.tokens); //get tokens state from redux
  const { pool_top_input } = useSelector((state) => state.inputs); //get account state from redux
  const { pool_bottom_input } = useSelector((state) => state.inputs); //get account state from redux
  const { slippage } = useSelector((state) => state.account); //get account state from redux

  const [fromToken, setFromToken] = useState(tokens[0]);
  const [toToken, setToToken] = useState(tokens[1]);
  const [topInput, setTopInput] = useState(pool_top_input);
  const [bottomInput, setBottomInput] = useState(pool_bottom_input);

  // useEffect(() => {
  //   setTopInput(pool_top_input);
  //   setBottomInput(pool_bottom_input);
  // }, [pool_top_input, pool_bottom_input]);

  const handleAddToPool = async () => {
    addTokensToPool();
  };

  return (
    <Wrapper>
      <Modal open={openAddToPoolModal} onClose={closeAddToPool}>
        {/* Swap dashboard paper */}
        <Paper elevation={5} sx={style}>
          {!loader ? (
            <Box>
              <CloseIcon
                id="testClose"
                onClick={closeAddToPool}
                sx={{ cursor: "pointer", position: "absolute", right: "10px", top: "10px" }}
              />
              <Box
                className="swapInput"
                sx={{ display: "flex", flexDirection: "column", position: "relative", marginBlock: "10px" }}
              >
                {/* token you swap information */}
                <div
                  className="swapInput__bottom"
                  style={{ marginTop: "20px", borderRadius: "10px", display: "flex", background: "#26262c" }}
                >
                  <StyledTextField defaultValue={tokenAValue} sx={{ width: "65%" }} disabled type="number" />
                  <Button variant="choose_token_btn" disabled>
                    <img src={tokenA?.icon} alt="" />
                    <span>{tokenA?.symbol}</span>
                  </Button>
                </div>
              </Box>
              {/* Change swap direction button */}
              <Box sx={{ position: "absolute", left: "50%", transform: "translate(-50%,-50%)", zIndex: "999" }}>
                <Button variant="direction_btn" sx={{ marginInline: "auto", width: "35px", height: "35px" }}>
                  <AddRoundedIcon fontSize="small" />
                </Button>
              </Box>
              <Box className="swapInput" sx={{ display: "flex", flexDirection: "column", marginTop: "5px" }}>
                {/* token you receive information */}
                <div
                  className="swapInput__bottom"
                  style={{ marginTop: "0px", borderRadius: "10px", display: "flex", background: "#26262c" }}
                >
                  <StyledTextField defaultValue={tokenBValue} sx={{ width: "65%" }} disabled />
                  <Button variant="choose_token_btn" disabled>
                    <img src={tokenB?.icon} alt="" />
                    <span>{tokenB?.symbol}</span>
                  </Button>
                </div>
              </Box>{" "}
              <Paper
                elevation={0}
                sx={{ padding: "10px 20px", borderRadius: "10px", background: "#33333b", marginTop: "20px" }}
              >
                {/* Expected Output */}
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
                    Expected Output
                    <Tooltip
                      title="The expected pool tokens amount you will receive in this transaction. Output is estimated."
                      placement="top"
                    >
                      <HelpOutlineIcon fontSize="small" sx={{ marginLeft: "5px" }} />
                    </Tooltip>
                  </Typography>
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    {expectedLpTokens &&
                      numberWithCommas(
                        checkIfNumIsExp(expectedLpTokens) ? fromExponential(expectedLpTokens) : expectedLpTokens
                      )}{" "}
                    <img src={tokenA?.icon} width={15} style={{ borderRadius: "50%", marginLeft: "3px" }} alt="" />
                    <img src={tokenB?.icon} width={15} style={{ borderRadius: "50%" }} alt="" />
                  </Typography>
                </Box>
              </Paper>
              <Paper
                elevation={0}
                sx={{ padding: "10px 20px", borderRadius: "10px", background: "#33333b", marginTop: "20px" }}
              >
                {/*Slippage*/}
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "5px" }}>
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    Slippage
                    <Tooltip
                      title="Your transaction will revert if the price changes unfavorably by more than this percentage. Default is 0.5%."
                      placement="top"
                    >
                      <HelpOutlineIcon fontSize="small" sx={{ marginLeft: "5px" }} />
                    </Tooltip>
                  </Typography>
                  <Typography>{slippage}%</Typography>
                </Box>
                {/*LP providers fee*/}
                {/* <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "5px" }}>
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    LP providers fee
                    <Tooltip
                      title="LP providers fee is the amount which the swap deducts from output tokens amount to divert to the LP providers."
                      placement="top"
                    >
                      <HelpOutlineIcon fontSize="small" sx={{ marginLeft: "5px" }} />
                    </Tooltip>
                  </Typography>
                  <Typography>0.3%</Typography>
                </Box> */}
              </Paper>
              {/* Terms of use */}
              <TouButton />
              {/* Confirm swap button */}
              <Button variant="swap_btn" onClick={handleAddToPool}>
                Add Tokens to Pool{" "}
              </Button>{" "}
            </Box>
          ) : (
            <Box
              sx={{
                minWidth: "300px",
                minHeight: "150px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {msgBoolean ? (
                <Box>
                  <CircularProgress color="inherit" />
                  <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Transaction in progress...</Typography>
                </Box>
              ) : (
                <Box>
                  {!addFailed ? (
                    <Box>
                      <CheckCircleOutlineIcon fontSize="large" />
                      <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Transaction completed!</Typography>
                    </Box>
                  ) : (
                    <Box>
                      <HighlightOffIcon fontSize="large" sx={{ color: "#e84142" }} />
                      {cancelled ? (
                        <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Transaction Cancelled!</Typography>
                      ) : (
                        <Typography sx={{ marginTop: "10px", fontSize: "20px" }}>Transaction Failed!</Typography>
                      )}
                      {cancelled ? (
                        <></>
                      ) : (
                        <Typography sx={{ fontSize: "14px", opacity: "0.5" }} component="p">
                          {txnMsg}{" "}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Paper>
      </Modal>
    </Wrapper>
  );
};

export default AddToPoolModal;
